import { useContext, useEffect, useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { AuthContext } from '../contexts/AuthContext';



type NavItemPropsType = {
  title: string
  value: string
  to?: string
  className?: string
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
};

const NavItem = ({ to, title, value, className, selected, setSelected }: NavItemPropsType) => {
  return (
    <Link to={to ? to : value}>
      <li
        onClick={() => setSelected(value)}
        className={'p-4 group whitespace-nowrap cursor-pointer transition ' + (selected === value ? 'text-primary-light ' : ' ') + className}
      >
        {title}
        <div className='relative'>
          <span className='absolute w-full'>
            {selected === value
              ? <span className={"topBarUnderline group-hover:max-w-full"} />
              : null}
          </span>
          <span className={"topBarUnderline max-w-0 group-hover:max-w-full"}></span>
        </div>
      </li>
    </Link>
  )
};

const NavItemWithHash = ({ to, title, value, className, selected, setSelected }: NavItemPropsType) => {
  return (
    <HashLink smooth to={to ? to : value}>
      <li
        onClick={() => setSelected(value)}
        className={'p-4 group whitespace-nowrap cursor-pointer transition ' + (selected === value ? 'text-primary-light ' : ' ') + className}
      >
        {title}
        <div className='relative'>
          <span className='absolute w-full'>
            {selected === value
              ? <span className={"topBarUnderline group-hover:max-w-full"} />
              : null}
          </span>
          <span className={"topBarUnderline max-w-0 group-hover:max-w-full"}></span>
        </div>
      </li>
    </HashLink>
  )
};

const TopBar = () => {
  const [nav, setNav] = useState(false);
  const [selected, setSelected] = useState('home');
  const user = useContext(AuthContext)?.user;

  const handleNav = () => {
    setNav(!nav);
  };
  
  
  useEffect(() => {
    const path = window.location.pathname;
    if(path ==='/') setSelected('home')
    if(path.startsWith('/catalog/formations')) setSelected('formations');
    if(path.startsWith('/catalog/figures')) setSelected('figures');
    if(path.startsWith('/console')) setSelected('console');
    if(path.startsWith('/more')) setSelected('more');
    setNav(false);
  }, [selected])
  return (
    <div className='flex z-10 justify-between items-center h-16 mx-auto px-4 text-white bg-primary w-full'>
      <h1 className='flex w-full text-3xl font-bold text-primary-light'>
        <span>
          CEAS Elearning
        </span>
        {
          user ?
            <Link
              title={user.name + ' ' + user.surname + '\n' + user.email}
              to={`/console/${user.accountType[0]}s/${user.id}`}
            >
              <div className='flex items-center justify-center mx-4'>
                <img
                  className='h-9 w-9 rounded-lg'
                  src={user.profileImgUrl}
                  alt=""
                />
              </div>
            </Link>
            : null
        }
      </h1>
      <ul className='hidden md:flex'>
        <NavItem
          title='Accueil'
          value='home'
          to='/'
          selected={selected}
          setSelected={setSelected}
        />
        {/* <NavItem
            title='Actualites'
            value='news'
            selected={selected}
            setSelected={setSelected}
        /> */}
        <NavItemWithHash
          title='Nos Formations'
          to='/#ourFormations'
          value='formations'
          className='border-b border-gray-600'
          selected={selected} // never marked as selected in the TopBar
          setSelected={setSelected}
        />
        <NavItem
          title='Formateurs & Experts'
          value='figures'
          to='catalog/figures'
          selected={selected}
          setSelected={setSelected}
        />
        <NavItem
          title='Mon Espace'
          value='console'
          to='/console/tasks'
          selected={selected}
          setSelected={setSelected}
        />
        <NavItem
          title='Plus'
          value='/more'
          selected={selected}
          setSelected={setSelected}
        />
      </ul>
      <div onClick={handleNav} className='absolute right-6 top-6 block md:hidden z-10'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <ul className={nav ? 'fixed left-0 top-0 w-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500'
        : 'left-0 ease-in-out duration-500 w-full fixed top-[-100%]'}>
        <h1 className='w-full text-3xl font-bold text-primary-light m-4'>CEAS Elearning</h1>
        <NavItem
          title='Accueil'
          value='home'
          to='/'
          className='border-b border-gray-600'
          selected={selected}
          setSelected={setSelected}
        />
        {/* <NavItem
            title='Actualites'
            value='news'
            className='border-b border-gray-600'
            selected={selected}
            setSelected={setSelected}
        /> */}
        <NavItemWithHash
          title='Nos Formations'
          to='/#ourFormations'
          value='formations'
          className='border-b border-gray-600'
          selected={selected}
          setSelected={setSelected}
        />
        <NavItem
          title='Formateurs & Experts'
          value='figures'
          to='catalog/figures'
          className='border-b border-gray-600'
          selected={selected}
          setSelected={setSelected}
        />
        <NavItem
          title='Espace Personnel'
          value='console'
          to='/console/tasks'
          className='border-b border-gray-600'
          selected={selected}
          setSelected={setSelected}
        />
        <NavItem
          title='Plus'
          value='/more'
          className='border-b border-gray-600'
          selected={selected}
          setSelected={setSelected}
        />
        {/* {
                    user?
                    <Link
                        to={`/console/${user.accountType}s/${user.id}`}
                    >
                        <div className='flex items-center justify-center mx-4'>
                            <img
                                className='h-9 w-9 rounded-lg'
                                src={user.profileImgUrl}
                                alt=""
                            />
                        </div>
                        <div>
                            <span>{user.name +' '+ user.surname}</span>
                            <span>{user.email}</span>
                        </div>
                    </Link>
                :null
                } */}
      </ul>
    </div>
  );
};

export default TopBar;
