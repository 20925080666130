import * as Yup from 'yup';
import { Formik, Form, FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import CustomButton from "./CustomButton";
import CustomInput from "./CustomInput";
import { ExtraFieldsType, GlobalFormationType, UserType, accountTypes, otherAccountData } from '../global/types';
import { CustomFormikRichTextEditor } from './CustomRichEditor';
import { dropdowns, userProfile } from '../assets/languages';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../App';
import { getFileUploadSource } from '../utils/files';
import UserProfileContextualMenu from './UserProfileContextualMenu';
import CustomSelect from './CustomSelect';
import { AuthContext } from '../contexts/AuthContext';
import { AuthErrorCodes } from 'firebase/auth';


interface FormValues {
  id: string;
  name: string;
  surname: string;
  email: string;
  occupation: string;
  phone: string;
  country: string;
  description: string;
  profileImg: File | null | undefined;
  profileImgUrl: string
  expertiseDomain?: string;
  followingFormations?: string[];
  teachedCourses?: string[];
  accountType: accountTypes;
}

const initialValues: FormValues = {
  id: "",
  name: "",
  surname: "",
  email: "",
  occupation: "",
  phone: "",
  country: "",
  description: "",
  profileImg: null,
  profileImgUrl: "",
  expertiseDomain: "",
  followingFormations: [],
  teachedCourses: [],
  accountType: []
};

type AdminFormPropsType = {
  user?: UserType
  otherAccountData: otherAccountData;
  extraFields: ExtraFieldsType;
  accountType: accountTypes;
};

const userRoles: { label: string, value: accountTypes['0'] }[] = [
  { label: 'Apprenant', value: 'student' },
  { label: 'Expert', value: 'expert' },
  { label: 'Formateur', value: 'teacher' },
  { label: 'Administrateur', value: 'admin' },
  { label: 'Super Administrateur', value: 'super' }
];

const ProfileViewForm: React.FC<AdminFormPropsType> = ({ extraFields, accountType, user, otherAccountData }) => {
  const [profileImg, setProfileImg] = useState<File | null | undefined>(null);
  const [editingDisabled, setEditingDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formations, setFormations] = useState<Omit<GlobalFormationType, 'pillars'>[]>([]);
  const [error, setError] = useState<string | null>(null);
  const loggedUser = useContext(AuthContext)?.user;


  const validationSchema = Yup.object({
    // id: Yup.string().required("Champ obligatoire"),
    name: Yup.string().required("Champ obligatoire"),
    surname: Yup.string().required("Champ obligatoire"),
    email: Yup.string().email("Format d'addresse email invalide").required("Champ obligatoire"),
    occupation: Yup.string().required("Champ obligatoire"),
    phone: Yup.string().required("Champ obligatoire"),
    country: Yup.string().required("Champ obligatoire"),
    // expertiseDomain:extraFields.expertiseDomain? Yup.string().required("Champ Obligatoire") : Yup.string().notRequired(),
    // followingFormations:extraFields.followingFormations? Yup.string().required("Champ Obligatoire") : Yup.string().notRequired(),
    // teachedCourses:extraFields.teachedCourses? Yup.string().required("Champ Obligatoire") : Yup.string().notRequired()
  });

  const onSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    if (loading) return;
    setLoading(true);
    setError(null);

    try {
      let profileImgSource = null;

      if (values.profileImg) {
        profileImgSource = await getFileUploadSource(values.profileImg)
      }

      let uploadableValues = {
        ...values,
        profileImgSource,
        options: otherAccountData.accountOptions,
        telegramNumber: otherAccountData.telegramNumber,
        whatsAppNumber: otherAccountData.whatsAppNumber,
        linkedInLink: otherAccountData.linkedInLink,
        twitterLink: otherAccountData.twitterLink
      };

      // The user id is required to know which user have to be updated
      if (user) uploadableValues.id = user.id;

      delete uploadableValues.profileImg;

      Object.keys(extraFields).forEach(key => {
        if (!extraFields[key as keyof ExtraFieldsType]) {
          delete uploadableValues[key as keyof ExtraFieldsType];
        }
      });

      const updateUser = httpsCallable(functions, 'updateUser');
      const resultUser = (await updateUser(uploadableValues)).data;
      console.log('User: ', resultUser);

      setEditingDisabled(true);
    } catch (error: any) {
      console.log('Error while updating user: ', error);
      
      if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
        setError('Addresse email deja utilisée')
      } else if(error.code === AuthErrorCodes.INVALID_PHONE_NUMBER) {
        setError('Numero de téléphone invalide')
      } else if (error.code === "functions/already-exists") {
        setError('Numero de téléphone déjà utilisé')
      } else if(error.code === AuthErrorCodes.INVALID_EMAIL) {
        setError('Format d\'addresse email invalide');
      } else {
        // functions/unknown
        setError('Une erreur inconnue est survenue.')
      }
      
    }
    setLoading(false)
  };


  const getAndSetFormations = async () => {
    try {
      const getAllFormations = httpsCallable(functions, 'getAllFormations');
      const resultData = (await getAllFormations()).data as Omit<GlobalFormationType, 'pillars'>[];
      setFormations(resultData || []);
    } catch (error) {
      console.log('Error while fetching list of formations', error)
    }
  }

  useEffect(() => {
    getAndSetFormations();
  }, []);
  return (
    <Formik
      initialValues={user ?
        {
          id: user.id,
          name: user.name,
          surname: user.surname,
          country: user.country,
          followingFormations: user.followingFormations,
          teachedCourses: user.teachedCourses,
          description: user.description || "",
          email: user.email,
          occupation: user.occupation,
          phone: user.phone || "",
          profileImgUrl: user.profileImgUrl,
          profileImg: null,
          expertiseDomain: user.expertiseDomain,
          accountType: user.accountType
        }
        :
        initialValues
      }
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form className='w-full'>
          <div className="text-2xl font-medium mb-4 self-start flex justify-between items-center">
            Presentation
            <UserProfileContextualMenu user={user} />
          </div>
          <div className="md:flex">
            <div className="rounded-3xl overflow-hidden h-40 w-40 md:h-56 md:w-56 m-8 md:mr-12 mb-4 border border-primary-light border-opacity-60 min-w-max">
              <label  >
                <div className="relative">
                  <img
                    src={
                      profileImg ? URL.createObjectURL(profileImg)
                        :
                        user?.profileImgUrl ?
                          user.profileImgUrl
                          :
                          require("../assets/noprofile.jpg")
                    }
                    className="h-40 w-40 md:h-56 md:w-56 cursor-pointer"
                    alt="Student profile"
                  />
                  <div className="absolute inset-0 bg-black opacity-0 cursor-pointer hover:opacity-50 transition-opacity"></div>
                </div>
                <input
                  disabled={editingDisabled}
                  name="profileImg"
                  accept=".webp,.jpeg,.jpg,.png"
                  style={{ position: 'absolute', opacity: 0, display: 'none' }}
                  type="file"
                  // required
                  placeholder="Choisir une image"
                  onChange={(e) => {
                    setProfileImg(e.target.files?.item(0));
                    setFieldValue('profileImg', e.target.files?.item(0));
                  }}
                />
              </label>
            </div>

            <div className="lg:grid lg:grid-cols-2 justify-center w-full gap-x-4">
              <CustomInput
                name="name"
                label={userProfile.labels.name}
                placeholder=""
                disabled={editingDisabled}
                hidden={editingDisabled}
              />
              <CustomInput
                name="surname"
                label={userProfile.labels.surname}
                placeholder=""
                disabled={editingDisabled}
                hidden={editingDisabled}
              />
              <CustomInput
                name="phone"
                label={userProfile.labels.phone}
                placeholder=""
                disabled={editingDisabled}
                hidden={editingDisabled}
              />
              {/* <CustomInput
                  name="id"
                  label="ID :"
                  placeholder=""
                  disabled={true}
                  hidden={editingDisabled}
              /> */}
              <CustomInput
                name="email"
                label={userProfile.labels.email}
                placeholder=""
                type="email"
                disabled={editingDisabled}
                hidden={editingDisabled}
              />
              <CustomInput
                name="occupation"
                label={userProfile.labels.occupation}
                placeholder=""
                disabled={editingDisabled}
                hidden={editingDisabled}
              />
              <CustomSelect
                name="country"
                label={userProfile.labels.country}
                disabled={editingDisabled}
                value={dropdowns.countries.find(item => item.value === values.country)}
                onChange={(newValue: any) => setFieldValue('country', newValue.value)}
                labelClassName='mb-0'
                options={dropdowns.countries}
              />

              {
                extraFields.expertiseDomain ?
                  <CustomSelect
                    name="expertiseDomain"
                    disabled={editingDisabled}
                    label={userProfile.labels.expertiseDomain}
                    onChange={(newValue: any) => setFieldValue('expertiseDomain', newValue.value)}
                    labelClassName='mb-0'
                    value={dropdowns.expertiseDomain.find(item => item.value == values.expertiseDomain)}
                    options={dropdowns.expertiseDomain}
                  />
                  :
                  null
              }
              {
                extraFields.followingFormations ?
                  <CustomSelect
                    containerClassName='col-span-2'
                    name="followingFormations"
                    label={userProfile.labels.followingFormations}
                    onChange={(newValue: any) => setFieldValue('followingFormations', newValue.value == 'none' ? [] : [newValue.value])}
                    labelClassName='mb-0'
                    value={values.followingFormations?.map(formationId => ({
                      label: formations.find(item => item.id === formationId)?.title,
                      value: formationId
                    }))}
                    options={formations.map(formation => ({ label: formation.title, value: formation.id })).concat({ label: 'Aucune', value: 'none' })}
                    disabled={editingDisabled || !loggedUser?.accountType.includes('admin')}
                    hidden={editingDisabled}
                    placeholder='Aucune (Sélectionner une formation...)'
                  />
                  :
                  null
              }

        <div className='col-span-2'>
              <CustomFormikRichTextEditor
                name="description"
                label={userProfile.labels.description}
                as="textarea"
                rows={4}
                placeholder=""
                disabled={editingDisabled}
                hidden={editingDisabled}
              />
              </div>
            
            {
              extraFields.teachedCourses ?
                <CustomSelect
                  name="teachedCourses"
                  label={userProfile.labels.teachedCourses}
                  onChange={(newValue: any) => setFieldValue('teachedCourses', newValue.value)}
                  labelClassName='mb-0'
                  options={dropdowns.expertiseDomain}
                />
                :
                null
            }
            {
              (
                loggedUser?.accountType.includes('super') && user?.accountType.includes('admin')
              )
                ||
                (
                  loggedUser?.accountType.includes('admin') && ['admin', 'teacher', 'expert', 'student'].some((item: any) => user?.accountType.includes(item))
                )
                ?
                <CustomSelect
                  isMulti
                  label="Role(s) de l'utilisateur:"
                  disabled={editingDisabled}
                  containerClassName='mt-4 col-span-2'
                  value={
                    values.accountType.map(type => userRoles.find(item => item.value == type))
                      .filter(item => item != undefined)
                      .map((item, index) => index == 0 ? ({ label: item?.label + '  (rôle primaire)', value: item?.value }) : item)
                  }
                  options={userRoles.filter(item => loggedUser.accountType.includes('super') ? true : item.value != 'super')}
                  onChange={(newValue: any) => setFieldValue('accountType', newValue.map((item: any) => item.value))}
                />
                :
                null
            }
            {
              error?
              <p className='text-red-500 font-medium'>{error}</p>
            :null}
            {
              !(['super', 'admin'].some((item: any) => loggedUser?.accountType.includes(item)) || loggedUser?.id === user?.id) ? null :
                editingDisabled ?
                  <div className='mt-4 col-span-2 grid lg:grid-cols-2 gap-x-6 gap-y-2'>
                    <div/>
                    <CustomButton
                      title="Editer..."
                      className='w-full'
                      onClick={() => setEditingDisabled(false)}
                    />
                  </div>
                  :
                  <div className="mt-4 col-span-2 grid lg:lg:grid-cols-2 gap-x-6 gap-y-2">
                    <CustomButton
                      title="Annuler"
                      variant="btnDanger"
                      onClick={() => setEditingDisabled(true)}
                    />
                    <CustomButton
                      loading={loading}
                      title="Enregister"
                      btnType="submit"
                    />
                  </div>
            }
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};


export default ProfileViewForm;