import { useContext, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { auth, functions } from "../../App";
import { AuthErrorCodes, signInWithEmailAndPassword } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { SetUserType, UserType } from "../../global/types";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
    let user:UserType|null = null;
    let setUser:SetUserType|null = null;

    const authContext = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string|null>(null);
    const navigate = useNavigate();

    if(authContext) {
        user = authContext.user;
        setUser = authContext.setUser;
    };

    const heroImg = require('../Home/assets/heroImg.jpg');

    const onSignIn = async () => {
      if (loading) return;
      setLoading(true);
      setError(null); // Clear any previous error
      try {
          const userCred = await signInWithEmailAndPassword(auth, email, password);
          const getCurrentUser = httpsCallable(functions, 'getCurrentUser');
          const currentUser = (await getCurrentUser()).data as UserType;
          localStorage.setItem('storedUser', JSON.stringify(currentUser));
          if (setUser) setUser(currentUser);
          navigate('/');
      } catch (error:any) {
          if (error.code) {
              switch (error.code) {
                  case AuthErrorCodes.USER_DELETED:
                      setError('The user was not found.');
                      break;
                  case AuthErrorCodes.INVALID_PASSWORD:
                      setError('The password is invalid.');
                      break;
                  case AuthErrorCodes.USER_DISABLED:
                      setError('The user account has been disabled.');
                      break;
                  case AuthErrorCodes.INVALID_EMAIL:
                      setError('The email address is badly formatted.');
                      break;
                  case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
                      setError('Access to this account has been temporarily disabled due to many failed login attempts. Please try again later.');
                      break;
                  case AuthErrorCodes.NETWORK_REQUEST_FAILED:
                      setError('Network error. Please check your internet connection.');
                      break;
                  default:
                      setError('An error occurred during sign in.');
              }
          } else {
              setError('An error occurred during sign in.');
          }
          console.log('An error occurred when signing in: ', error);
      }
      setLoading(false);
  };

    return (
      <div style={{backgroundImage: `url(${heroImg})`}} className=" min-h-[61vh] relative bg-cover flex flex-1">
        <div className="backdrop-brightness-[30%] flex flex-1 items-center justify-center">
            <div className="border border-primary-light shadow-inner w-96 h-80 rounded-[2rem] px-8 py-4 my-12 bg-white ">
                <p className="text-2xl font-medium mb-6">
                    Connexion au compte
                </p>
                <div className="" >
                    <input
                        type="email"
                        className="textInput" 
                        required
                        placeholder="addresse email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        className="textInput mt-4" 
                        required
                        placeholder="mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    
                    <div className={"text-red-500 mt-8 "+ (!error&&' opacity-0')}>{error||'No Error'}</div>
                    <CustomButton
                        loading={loading}
                        onClick={onSignIn}
                        title="Se Connecter"
                        className="mt-2 w-full"
                    />
                </div>
            </div>
        </div>
      </div>
    )
  };
  
  export default SignIn;