import { otherAccountData } from '../../../../../global/types';
import ProfileAddForm from '../../../../../components/ProfileAddForm';


type ExpertFormPropsType = {
    otherAccountData:otherAccountData
};

const ExpertForm:React.FC<ExpertFormPropsType> = ({otherAccountData}) => {
    return (
        <ProfileAddForm
            accountType={['expert']}
            extraFields={{expertiseDomain:true}}
            otherAccountData={otherAccountData}
        />
    );
};


export default ExpertForm;