import CustomSelect from "../../../../components/CustomSelect";
import { MatchQuestionOptionType, MatchQuestionType } from "../../../../global/types";

type MatchQuestionPropsType = {
  qIndex:number;
  question:MatchQuestionType;
  handleQuestionChange: (qIndex: number, question: MatchQuestionType) => void;
}

const MatchQuestion = ({qIndex, question, handleQuestionChange}:MatchQuestionPropsType) => {
  
  const handleOptionChange = (optionIndex:number, option:MatchQuestionOptionType) => {
    const newOptions = [...question.options];
    const duplicate = newOptions.find(item => item.selected===option.selected);
    if(duplicate) duplicate.selected = undefined;

    newOptions[optionIndex]= option;
    handleQuestionChange(qIndex, {...question, options:newOptions});
  };
  return (
    <div className="bg-slate-50 dark:bg-slate-200 p-2 lg:p-4 rounded-md">
      <div
        className="pb-2"
        dangerouslySetInnerHTML={{ __html: question.label+`(${question.weight} crédits)` }}
      />
      <div className="flex flex-col gap-y-2">
        {
          question.options.map((option, optionIndex) => (
            <div className="flex flex-1 border border-gray-300 dark:border-gray-400 shadow-inner py-1 px-1 lg:px-0 rounded-lg lg:border-none flex-col lg:flex-row gap-x-2">
              <input
                type="text"
                className="customInput !text-base !h-10"
                value={option.part1}
                disabled
              />
              <CustomSelect
                containerClassName="!w-full"
                placeholder="Veuillez sélectionner une option"
                value={
                  question.options.find(item => item.metaIndex ===option.selected)?
                    {label:question.options.find(item => item.metaIndex ===option.selected)?.part2, value:option.selected}
                    : null
                }
                options={question.options.filter(item => (item.metaIndex ===option.metaIndex) || (item.part2 !==option.part2)).map(option => ({label:option.part2, value:option.metaIndex}))}
                onChange={(newValue:any) => handleOptionChange(optionIndex, ({...option, selected:newValue.value}))}
              />
            </div>
          ))
        }
      </div>
    </div>
  )
};

export default MatchQuestion;