import CustomButton from "../../../../components/CustomButton";
import { useEffect, useState } from 'react';
import CustomRichEditor from "../../../../components/CustomRichEditor";
import { functions } from "../../../../App";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { BlogArticle } from "../../../../global/types";
import CustomSelect from "../../../../components/CustomSelect";
import { CustomFileInput } from "../../../../components/CustomInput";
import { getFileUploadSource } from "../../../../utils/files";
import { predefinedArticles } from "../Articles";

type extendProperties = {
  imageFile: File | null;
  imageSource?: {
    type: string;
    extension: string;
    base64: string;
  }
};

const initialValues: BlogArticle & extendProperties = {
  title: "",
  description: "",
  id: "",
  options: { public: false },
  period: '',
  rawHtml: '',
  imageFile: null,
};


const ArticleAdd = () => {

  const navigate = useNavigate();
  const { articleId } = useParams();
  const state: { articleData: BlogArticle | undefined } | null = useLocation().state;
  const isPredefined = Boolean(predefinedArticles.find(article => article.id ===articleId))

  const initialContent = state?.articleData ? { ...initialValues, ...state.articleData } : initialValues
  const [articleInfos, setArticleInfos] = useState(initialContent);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState("");

  const checkFormValid = () => {
    setFormError("");
    if (!articleInfos.title) {
      setFormError("Vous devez spécifier un titre pour l'article de blog");
      return false;
    } else if (!articleInfos.rawHtml) {
      setFormError("Veuillez entrer du contenu pour l'article s'il vous plaît");
      return false;
    };
    return true;
  };


  const onArticleSubmit = async () => {
    if (loading || !checkFormValid()) return;
    setLoading(true);

    try {

      if (articleInfos.imageFile) {
        articleInfos.imageSource = await getFileUploadSource(articleInfos.imageFile);
      };

      if (articleId) {
        const updateBlogArticle = httpsCallable(functions, 'updateBlogArticle');
        await updateBlogArticle({ article: articleInfos, articleId });
      } else {
        const createBlogArticle = httpsCallable(functions, 'createBlogArticle');
        await createBlogArticle({ article: articleInfos });
      };
      navigate(-1);
    } catch (error) {
      console.log('An error occured while submitting the article', error)
    }
    setLoading(false);
  };

  const getAndSetCurrentArticle = async () => {
    try {
      const getBlogArticles = httpsCallable(functions, 'getBlogArticles');
      const result = (await getBlogArticles({ ids: [articleId] })).data as { articles: BlogArticle[] };
      const article = result.articles.at(0);
      if (article) setArticleInfos(value => ({ ...value, ...article }));
    } catch (error) {
      console.log('An error occured while getting the current article data: ', error);
    }
  };

  useEffect(() => {
    if (articleId) getAndSetCurrentArticle();
  }, []);
  return (
    <div className="mt-4 mb-10 mx-auto flex flex-1 flex-col max-w-7xl ">
      <div className="p-3  lg:p-6 rounded-2xl flex flex-1 flex-col bg-secondary-light">
        <div className="w-full max-w-6xl mx-auto">
          <h2 className="text-3xl font-medium mb-6 self-start">{articleId ? "Mettre à Jour l'Article" : "Publier un Article"}</h2>
          <div className='mt-2 mb-1'>
            Titre de l'article:
          </div>
          <input
            value={articleInfos.title}
            onChange={(e) => setArticleInfos(infos => ({ ...infos, title: e.target.value }))}
            className='textInput'
            placeholder='Entrez quelque chose...'
          />
          <div className="flex flex-col lg:flex-row gap-x-8">
            <div className="w-full">
              <div className='mt-2 mb-1'>
                Visibilité de l'article:
              </div>
              <CustomSelect
                value={[{ label: 'Publique', value: true }, { label: 'Privée', value: false }].find(item => item.value === (isPredefined || articleInfos.options.public))}
                options={[{ label: 'Publique', value: true }, { label: 'Privée', value: false }]}
                onChange={(newValue: any) => setArticleInfos(value => ({ ...value, options: { ...value.options, public: newValue.value } }))}
                disabled={isPredefined}
              />
            </div>
            <div className="w-full">
              <div className="mt-2 mb-1">
                Date/Période
              </div>
              <input
                value={articleInfos.period}
                onChange={(e) => setArticleInfos(infos => ({ ...infos, period: e.target.value }))}
                className={`textInput ${(isPredefined?'!bg-primary-light !bg-opacity-20 !border-opacity-60 !border-primary-light':'')}`}
                placeholder='Décrivez une période (eg. Août 2023)'
                disabled={isPredefined}
              />
            </div>
          </div>

          {
            isPredefined?null:
            <>
              <div className='mt-2 mb-1'>
                Description:
              </div>
              <textarea
                className="textInput"
                value={articleInfos.description}
                onChange={(e) => setArticleInfos(infos => ({ ...infos, description: e.target.value }))}
              />
            </>
          }
          <div className='mt-2 mb-1'>
            Contenu de l'article:
          </div>
          <CustomRichEditor
            value={articleInfos.rawHtml}
            onChange={(value) => setArticleInfos(infos => ({ ...infos, rawHtml: value }))}
          />
          {
            isPredefined?null:
          <>
            <div className='mt-2'>
              Image d'illustration:
            </div>
            <div className="pt-1 bg-slate-200 rounded-xl">
              <CustomFileInput
                onChange={(e) => setArticleInfos(infos => ({ ...infos, imageFile: e.target.files?.item(0) || null }))}
                accept="image/*"
                placeholder="Choisir une image d'illustration"
                label={(
                  <div className="rounded-xl max-w-4xl mx-auto overflow-hidden cursor-pointer hover:brightness-50 transition duration-200">
                    <img
                      className="mt-3"
                      src={articleInfos.imageFile ? URL.createObjectURL(articleInfos.imageFile) : articleInfos.imageUrl}
                      alt=""
                    />
                  </div>
                )}
              />
            </div>
          </>
          }

          {
            formError ?
              <div className="mt-4 text-red-600 font-medium">
                {formError}
              </div>
              :
              null
          }
          <div className="flex ml-auto justify-end px-8 gap-x-8 mt-6 max-w-lg">
            <CustomButton
              title="Annuler"
              variant="btnDanger"
              className="w-40"
              onClick={() => navigate(-1)}
            />
            <CustomButton
              loading={loading}
              onClick={onArticleSubmit}
              title="Enregistrer"
              className="w-48"
            />
          </div>
        </div>
      </div>
    </div>
  );
}


export default ArticleAdd;