import { useNavigate } from 'react-router-dom';
import { Button, LinearProgress, Menu, MenuItem } from "@mui/material";
import {
    DataGrid,
    GridCallbackDetails,
    GridClasses,
    GridColDef,
    GridNoRowsOverlay,
    GridRowSelectionModel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import CustomButton from '../../../../components/CustomButton';
import { functions } from '../../../../App';
import { httpsCallable } from 'firebase/functions';


type Super = {
    id: number;
    name: string;
    surname: string;
    phone: string;
    email: string;
    country: string;
};

const columns: GridColDef<Super, any, any>[] = [
    { field: 'id', headerName: 'ID', minWidth: 50, flex: 1 },
    { field: 'name', headerName: 'Nom', minWidth: 110, flex: 1.5 },
    { field: 'surname', headerName: 'Prenom', minWidth: 110, flex: 1.5 },
    { field: 'phone', headerName: 'Telephone', minWidth: 110, flex: 1.5 },
    { field: 'email', headerName: 'Email', minWidth: 160, flex: 2 },
    { field: 'country', headerName: 'Pays', minWidth: 100, flex: 1.5 },
];

const BasicMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className='ml-auto'>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ paddingY: 2 }}
            >
                <BsThreeDotsVertical className='text-secondary-dark' size={20} />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}>Desactiver la selection</MenuItem>
            </Menu>
        </div>
    );
};

const CustomToolbar = () => {
    return (
        <GridToolbarContainer className='bg-secondary-light' sx={{ paddingX: 2 }}>
            <GridToolbarColumnsButton className='text-dark' sx={{ paddingY: 2, color: '#7F5032' }} />
            <GridToolbarFilterButton sx={{ paddingY: 2, color: '#7F5032' }} />
            <GridToolbarDensitySelector sx={{ paddingY: 2, color: '#7F5032' }} />
            <GridToolbarExport sx={{ paddingY: 2, color: '#7F5032' }} />
            <BasicMenu />
        </GridToolbarContainer>
    );
};

const Supers = () => {
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
    const [supers, setSupers] = useState<Super[]>([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();


    const handleRowSelection = (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
        //rowSelectionModel is an array containing checkboxed rows ids
        setSelectedRows(rowSelectionModel);
    };

    const gridClassNames: Partial<GridClasses> = {
        footerContainer: 'bg-secondary-light'
    }

    const getAndSetPlatformSupers = async () => {
        setLoading(true);
        try {
            const getAllPlatformSupers = httpsCallable(functions, 'getAllPlatformSupers');
            const resultData = (await getAllPlatformSupers()).data;
            setSupers(resultData as Super[]);
        } catch (error) {
            console.log(error)
        }
        setLoading(false);
    }


    useEffect(() => {
        getAndSetPlatformSupers()
    }, []);
    return (
        <div className='flex flex-1'>
            <div className='flex flex-1 flex-col max-w-7xl mx-auto px-2'>
                <h2 className="text-4xl font-medium mt-8 mb-4 self-start">Super Administrateurs</h2>
                <div className='max-h-[60vh] mb-2 lg:max-w-6xl xl:max-w-7xl'>
                    <DataGrid
                        classes={gridClassNames}
                        autoHeight
                        loading={loading}
                        checkboxSelection
                        rows={supers}
                        columns={columns}
                        onRowClick={(row) => navigate(row.id.toString(), { state: { userData: supers.find(superAdmin => superAdmin.id === row.id) } })}
                        onRowSelectionModelChange={handleRowSelection}
                        slots={{ toolbar: CustomToolbar, loadingOverlay: LinearProgress, noRowsOverlay: GridNoRowsOverlay }}
                        disableColumnMenu
                        disableRowSelectionOnClick
                    />
                </div>
                {/* <div className='flex justify-end my-12'>
                    <CustomButton
                        variant='btnSecondary'
                        title='Ajouter un nouveau'
                        className='w-72'
                        navigateTo='add'
                    />
                </div> */}
            </div>
        </div>
    );
};

export default Supers;
