import {createContext, useEffect, useState} from 'react';
import { AuthContextType, SetUserType, UserType } from '../global/types';
import { auth, functions } from '../App';
import { User, onAuthStateChanged, signOut } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';



export const AuthContext = createContext<AuthContextType | null>(null);

const AuthContextProvider:React.FC<React.PropsWithChildren> = ({children}) => {
    const storedUser = localStorage.getItem('storedUser') || null;
    const [user, setUser] = useState<UserType | null>(storedUser? JSON.parse(storedUser):null);

    const handleAuthUserExists = async (authUser:User) => {
        try {
            const getCurrentUser = httpsCallable(functions, 'getCurrentUser');
            const currentUser = (await getCurrentUser()).data as UserType;
            localStorage.setItem('storedUser', JSON.stringify(currentUser))
            // We are almost sure that by the time we get the user by email, setUser function will be initialized (non null)
            if(setUser) setUser(currentUser);
        } catch (error:any) {
            if(error.code == 'auth/user-not-found') signOut(auth); //signs out if the user doesn't exist in the db
        }
    };

    useEffect(() => {
        console.log('User: ', user);
    }, [user]);
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                unsubscribe();
                handleAuthUserExists(authUser);
            } else {
                /* Some unwanted behaviour is appearing here since on emulator, when reloading page onAuthStateChanged
                    always fire with a null authUser value. Hence, the locally stored users data is each time removed
                    from the context object  */
                localStorage.removeItem('storedUser')
                if(setUser) setUser(null); // Signed out
            }
        });
        
        return unsubscribe;
    }, []);
      
    return (
        <AuthContext.Provider
            value={{
                user:user,
                setUser:setUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;