const userProfile = {
    en:{
        labels:{
            name:'Name',
            surname:'Surname',
            phone:'Phone',
            email:'Email',
            occupation:'Occupation',
            country:'Country',
            description:'Description',
            accountState:'Account state: ',
            publicEmail:'Public Email: ',
            whatsappNumber:'WhatsApp number: ',
            telegramNumber:'Telegram number: ',
            linkedInProfileLink:'LinkedIn profile link',
            twitterProfileLink:'Twitter profile link',
            expertiseDomain:'Expertise domain',
            teachedCourses:'Teached courses',
            followingFormations:"Formation suivie"
        },
    },
    fr:{
        labels:{
            name:'Nom : ',
            surname:'Prénom : ',
            phone:'Téléphone (format international): ',
            email:'Email : ',
            occupation:'Occupation : ',
            country:'Pays : ',
            description:'Description : ',
            accountState:'Etat du compte : ',
            publicEmail:'Email publique : ',
            whatsappNumber:'Numéro WhatsApp : ',
            telegramNumber:'Numéro Telegram : ',
            linkedInProfileLink:'Lien profil LinkedIn : ',
            twitterProfileLink:'Lien profil Twitter : ',
            expertiseDomain:"Domaine d'expertise : ",
            teachedCourses:"Cours dispénses : ",
            followingFormations:"Formation(s) suivie(s) : "
        },
    }
};

export {userProfile};