// import MarksPerformance from "./graphs/MarksPerformance"
// import PillarsCompletion from "./graphs/PillarsCompletion"

const Journey = () => {
    return (
        <div className="flex flex-1 flex-col">
            {/* <PillarsCompletion/>
            <MarksPerformance/> */}
        </div>
    )
};

export default Journey;