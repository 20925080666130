import { useEffect, useState } from "react";
import { MCQQuestionType, MatchQuestionType, PretestQuestion, StructuralQuestionType } from "../../../../global/types";
import { MCQQuestion } from "../../ActivityAdd/components/MCQGenerator";
import { StructuralQuestion } from "../../ActivityAdd/components/StructuralGenerator";
import CustomButton from "../../../../components/CustomButton";
import { Button, ButtonGroup } from "@mui/material";
import { BiPlus } from "react-icons/bi";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../App";
import { useParams } from "react-router-dom";
import MatchQuestion from "./MatchQuestion";


const QuestionsEditor = () => {
  const [questions, setQuestions] = useState<PretestQuestion[]>([]);
  const [loading, setLoading] = useState(false);
  const {pretestId} = useParams();
  const [maxIndexes, setMaxIndexes] = useState({pseudo:0, meta:0});

  const handleAddQuestion = (type: 'mcq' | 'sq'|'match') => {
    if (type == 'sq') {
      const newQuestion: StructuralQuestionType = {
        pseudoIndex: maxIndexes.pseudo + 1,
        metaIndex: maxIndexes.meta + 1,
        question: '',
        weight: 1,
        answer: '',
        explanation: '',
        format: 'short',
        type: 'sq',
      };

      setQuestions([...questions, newQuestion]);
    } else if(type=='mcq') {
      const newQuestion: MCQQuestionType = {
        pseudoIndex: maxIndexes.pseudo + 1,
        metaIndex: maxIndexes.meta + 1,
        question: '',
        type: 'mcq',
        weight: 1,
        answers: [
          { text: '', isCorrect: true, weight: 1, },
          { text: '', isCorrect: false, weight: 0, },
        ],
        explanation: ''
      };

      setQuestions([...questions, newQuestion]);
    } else if(type=='match') {
      const newQuestion:MatchQuestionType = {
        pseudoIndex: maxIndexes.pseudo + 1,
        metaIndex: maxIndexes.meta + 1,
        type: 'match',
        label:'',
        options:[
          {metaIndex:0, part1:'', part2:''},
          {metaIndex:1, part1:'', part2:''}
        ],
        weight:1 // weight per choice
      }
      
      setQuestions([...questions, newQuestion]);
    }
  };

  const handleChangeQuestion = (qIndex: number, question: PretestQuestion) => {
    const newQuestions = [...questions];
    newQuestions[qIndex] = question;
    setQuestions(newQuestions);
  };

  const handleDeleteQuestion = (qIndex: number) => {
    const newQuestions = [...questions];
    newQuestions.splice(qIndex, 1);
    setQuestions(newQuestions);
  };

  const onSaveQuestions = async () => {
    if(loading) return;
    setLoading(true);

    try {
      const updatePretestQuestions = httpsCallable(functions, 'updatePretestQuestions');
      await updatePretestQuestions({ questions, pretestId });
    } catch (error) {
      console.log('An error occured while updating pretest questions: ', error);
    };

    setLoading(false);
  };


  const getAndSetPretestQuestions = async () => {
    if(loading) return;
    setLoading(true);

    try {
      const getPretestQuestions = httpsCallable(functions, 'getPretestQuestions');
      const result = (await getPretestQuestions({ includeCorrection:true, pretestId })).data as {questions:PretestQuestion[]};

      const dbQuestions = result.questions;
      setQuestions(dbQuestions);
    } catch (error) {
      console.log('An error occured while updating pretest questions: ', error);
    };

    setLoading(false);
  };

  
  
  useEffect(() => {
    setMaxIndexes({
      meta:Math.max(maxIndexes.meta, ...questions.map(q =>q.metaIndex)),
      pseudo:Math.max(maxIndexes.pseudo, ...questions.map(q =>q.pseudoIndex))
    })
  }, [questions.length]);
  useEffect(() => {
    getAndSetPretestQuestions()
  }, []);
  return (
    <div className="flex flex-1 flex-col">
      <div className="mt-4 mb-12 px-2 py-4 md:p-4 mx-auto rounded-2xl w-full flex flex-col max-w-7xl bg-secondary-light">
        <h3 className="text-xl lg:text-3xl font-semibold">Editeur de Questions</h3>
        <div className="flex flex-col gap-y-6 mt-4">
          {questions.map((question, qIndex) => question.type === 'mcq' ?
            <MCQQuestion
              key={qIndex}
              handleChangeQuestion={handleChangeQuestion}
              handleDeleteQuestion={handleDeleteQuestion}
              explanationsEnabled={false}
              qIndex={qIndex}
              question={question}
            />
            :
            question.type === 'sq' ?
            <StructuralQuestion
              key={qIndex}
              handleChangeQuestion={handleChangeQuestion}
              handleDeleteQuestion={handleDeleteQuestion}
              qIndex={qIndex}
              question={question}
            />
            :
            <MatchQuestion
              key={qIndex}
              handleChangeQuestion={handleChangeQuestion}
              handleDeleteQuestion={handleDeleteQuestion}
              qIndex={qIndex}
              question={question}
            />
          )}
        </div>
        <ButtonGroup className="mt-4 lg:mt-8">
          <Button
            title="Ajouter une question de type QCM"
            variant="contained"
            color="inherit"
            onClick={() => handleAddQuestion('mcq')}
          >
            <BiPlus size={16} className="mr-1" />
            QCM
          </Button>
          <Button
            title="Ajouter une question Ouverte"
            variant="contained"
            color="inherit"
            onClick={() => handleAddQuestion('sq')}
          >
            <BiPlus size={16} className="mr-1" />
            Question Ouverte
          </Button>
          <Button
            title="Ajouter une question de Correspondance"
            variant="contained"
            color="inherit"
            onClick={() => handleAddQuestion('match')}
          >
            <BiPlus size={16} className="mr-1" />
            Correspondance
          </Button>
        </ButtonGroup>
        <div className="ml-auto mt-4 lg:mt-6">
          <CustomButton
            loading={loading}
            title="Enregistrer les Questions"
            onClick={onSaveQuestions}
          />
        </div>
      </div>
    </div>
  )
};


export default QuestionsEditor;