import { useNavigate } from 'react-router-dom';
import { Box, Button, LinearProgress, Menu, MenuItem } from "@mui/material";
import {
    DataGrid,
    GridCallbackDetails,
    GridClasses,
    GridColDef,
    GridNoRowsOverlay,
    GridRowSelectionModel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useContext, useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import CustomButton from '../../../components/CustomButton';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../App';
import { AuthContext } from '../../../contexts/AuthContext';
import { GlobalFormationType } from '../../../global/types';
import moment from 'moment';
import 'moment/locale/fr'; // Import the French locale



type Formation = {
    id:string
    title:string
    description:string //textual description of the formation
    presentation?:any //rich text representation, in form of an html
    //string/document that will show on the formation catalog
    startDate:string;
    endDate:string;
    imageUrl?:string|null;
};

const columns:GridColDef<Formation, any, any>[] = [
    // { field: 'id', headerName: 'ID', minWidth: 50, flex:1 },
    { field: 'title', headerName: 'Nom', minWidth: 110, flex:1.5 },
    // { field: 'startDate', headerName: 'Date de debut', minWidth: 70, flex:1 },
    // { field: 'endDate', headerName: 'Date de fin', minWidth: 70, flex:1 },
    { field: 'description', headerName:'Description', minWidth:150, flex:2.5},
    { field: 'period', headerName:'Période', minWidth:100, flex:1,
      renderCell:(params) => <div>
        Du <span className='font-medium'>{moment(params.row.startDate).format('DD/MM/YYYY')}</span>
        <br />
        au <span className='font-medium'>{moment(params.row.endDate).format('DD/MM/YYYY')}</span>
        </div>
    },
    { field: 'students', headerName:'Apprenants', flex:0.75, headerAlign:'center',
      renderCell:(params) => <div className='bg-gray-100 hover:bg-gray-200 cursor-pointer rounded-lg px-1.5 py-2.5 text-center'>Voir les Apprenants</div>,
      sortable:true
    },
];

const BasicMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div className='ml-auto'>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{paddingY:2}}
        >
          <BsThreeDotsVertical className='text-secondary-dark' size={20}/>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableScrollLock
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose}>Desactiver la selection</MenuItem>
        </Menu>
      </div>
    );
};

const CustomToolbar = () => {
    return (
      <GridToolbarContainer className='bg-secondary-light' sx={{paddingX:2}}>
        <GridToolbarColumnsButton className='text-dark' sx={{paddingY:2, color:'#7F5032'}} />
        <GridToolbarFilterButton sx={{paddingY:2, color:'#7F5032'}}/>
        <GridToolbarDensitySelector sx={{paddingY:2, color:'#7F5032'}}/>
        <GridToolbarExport sx={{paddingY:2, color:'#7F5032'}}/>
        <BasicMenu/>
      </GridToolbarContainer>
    );
};

const Formations = () => {
    const user = useContext(AuthContext)?.user;

    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
    const [loading, setLoading] = useState(false);
    const [formations, setFormations] = useState<Formation[]>([]);

    const navigate = useNavigate();
    
    const handleRowSelection = (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
        //rowSelectionModel is an array containing checkboxed rows ids
        setSelectedRows(rowSelectionModel);
    };

    const gridClassNames:Partial<GridClasses> = {
        footerContainer:'bg-secondary-light'
    }

    const getAndSetFormations = async () => {
        setLoading(true);
        try {
            const getAllFormations = httpsCallable(functions, 'getAllFormations');
            const resultData = (await getAllFormations()).data as GlobalFormationType[];
            
            const tableFormations:Formation[] = resultData.map(formation => ({
              description:formation.description,
              id:formation.id,
              startDate:formation.startDate,
              endDate:formation.endDate,
              title:formation.title,
              imageUrl:formation.imageUrl,
              presentation:formation.longDescription
            }));

            setFormations(tableFormations);
        } catch (error) {
            console.log(error)
        }
        setLoading(false);
    }


    useEffect(() => {
        getAndSetFormations()
    }, []);
    
    return (
    <div className='flex flex-1'>
        <div className='flex flex-1 flex-col max-w-7xl mx-auto px-2'>
            <h2 className="text-4xl font-medium mt-8 mb-4 self-start">Formations</h2>
            <div className='mb-2 lg:max-w-6xl xl:max-w-7xl'>
                <DataGrid
                    classes={gridClassNames}
                    autoHeight
                    checkboxSelection
                    rows={formations}
                    columns={columns}
                    getRowClassName={() => 'cursor-default'}
                    onCellClick={(cell) => cell.field =='students'?navigate('/console/students?formationId='+cell.row.id.toString()):navigate(cell.row.id.toString())}    
                    onRowSelectionModelChange={handleRowSelection}
                    loading={loading}
                    slots={{ toolbar: CustomToolbar, loadingOverlay:LinearProgress, noRowsOverlay:GridNoRowsOverlay }}
                    disableColumnMenu
                    disableRowSelectionOnClick
                />
            </div>
            <div className='flex justify-end mt-6 mb-12'>
                {
                    [
                        'admin'
                    ].some((item:any) => user?.accountType.includes(item))
                    ?
                    <CustomButton
                        variant='btnSecondary'
                        title='Ajouter un nouveau'
                        className='w-72'
                        navigateTo='add'
                    />
                    :null
                }
            </div>
        </div>
    </div>
  );
};

export default Formations;
