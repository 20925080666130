import { IoCaretForwardCircle } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { textWithCutoff } from '../../../utils';
import { BlogArticle, GlobalFormationType } from '../../../global/types';

type CardPropsType = {
  article:BlogArticle;
  articles: BlogArticle[];
  replace?:boolean
};

export const Card = ({ article, articles, replace }: CardPropsType) => {
  return (
    <Link
      to={'/blog/articles?id=' + article.id}
      state={{ article, articles }}
      replace={replace}
      className='w-full shadow-2xl border border-primary-light border-opacity-40 flex flex-col my-3 rounded-3xl hover:scale-105 duration-300 cursor-pointer'
    >
      <img className=" m-1 rounded-t-3xl max-h-64 object-cover rounded-b-md border border-gray-200" alt="" src={article.imageUrl ? article.imageUrl : require('../assets/formation_placeholder.png')} />
      <div className="px-4 pb-4 pt-1">
        <div>
          <h6 className="font-medium text-lg">
            {article.title}
          </h6>
          {/* <p className="font-medium text-sm">Duree: {duration}</p> */}
        </div>
        <div className="text-sm pt-3">{textWithCutoff({ text: article.description, maxLength: 200 })}</div>
      </div>
    </Link>
  )
};

type FormationCardsPropsType = {
  articles: BlogArticle[]
};

const ArticlesCards: React.FC<FormationCardsPropsType> = ({ articles }) => {
  return (
    <div className='w-full pb-4 lg:pb-8 px-4'>
      <div className='max-w-7xl mx-auto'>
        <h2 className="text-2xl lg:text-3xl px-10 font-medium mb-4">Voir Aussi...</h2>
        <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 sm:gap-4 md:gap-6'>
          {
            articles.map(article => <Card article={article} key={article.id} articles={articles} />)
          }
          <Link
            state={{ articles }}
            className="seeAllCard" to='/blog/articles'
          >
            <p className='text-2xl font-semibold px-1'>Voir</p>
            <p className='text-2xl font-semibold px-1'>Tout</p>
            <IoCaretForwardCircle className='text-4xl my-4 mx-2' />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ArticlesCards;
