import { MatchQuestionType } from "../../../../global/types";


type MatchAnswerPropsType = {
  qIndex: number;
  question: MatchQuestionType;
};

const MatchAnswer = ({ qIndex, question }: MatchAnswerPropsType) => {
  return (
    <div
      style={{ display: "block" }}
      className='py-3 px-6 bg-white rounded-lg'
    >
      <p className="text-gray-700 font-medium">Question {qIndex + 1}: ({question.weight}*{question.options.length} crédits)</p>
      <div className="prose-sm" dangerouslySetInnerHTML={{ __html: question.label }} />
      
      <div className="flex flex-col gap-y-3">
        {
          question.options.map((option, optionIndex) => (
            <div className="flex flex-1 flex-col lg:flex-row gap-x-2">
              <input
                type="text"
                className="customInput !bg-gray-200"
                value={option.part1}
                disabled
              />
              <input
                type="text"
                className={"customInput !ring-2 ring-inset "+ (option.selected ===option.metaIndex?'!ring-green-300 !border-green-300' : '!ring-red-400 !border-red-400')}
                value={question.options.find(item => item.metaIndex ===option.selected)?.part2}
                disabled
              />
            </div>
          ))
        }
      </div>
      {/* <div className={'p-2 rounded-lg mt-1 prose-sm ' + tintColor}>
        <p className={"font-medium prose-sm " + (question.answers.every(answer => answer.isCorrect == answer.selected) ? 'text-gray-400' : 'text-gray-800')}>
          Reponse Juste:{'   '}{'   '}
          {
            question.answers.map((answer, index) =>
              answer.isCorrect ?
                <span className="text-gray-800 mx-2 font-bold">
                  {String.fromCharCode(index + 65)}. {answer.text}
                </span>
                :
                null
            )
          }
        </p>
        {
          question.explanation ?
            <div
              className="prose-sm prose-a:underline prose-a:text-blue-600 mt-2 pt-1 border-t"
              dangerouslySetInnerHTML={{ __html: question.explanation }}
            />
            :
            null
        }
      </div> */}

    </div>
  )
};

export default MatchAnswer;