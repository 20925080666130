import FormationStruct from "../../../components/FormationStruct"

const FormationAdd = () => {
    return (
        <FormationStruct
            init
        />
    )
};

export default FormationAdd;