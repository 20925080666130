import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { HiChatBubbleLeftEllipsis } from "react-icons/hi2";
import { UserType } from "../global/types";

type UserProfileContextualMenuPropsType = {
    user:UserType|undefined
}
const UserProfileContextualMenu:React.FC<UserProfileContextualMenuPropsType> = ({user}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOpenWhatsapp = () => {
        window.open('https://wa.me/'+user?.whatsAppNumber, '_blank')
        handleClose();
    };
    const handleOpenTelegram = () => {
        window.open('https://t.me/'+user?.telegramNumber, '_blank')
        handleClose();
    };
  
    return (
      <div style={user?undefined:{display:'none'}} className='ml-auto'>
        <button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <HiChatBubbleLeftEllipsis className='text-gray-700 p-2 hover:text-black cursor-pointer transition duration-200 hover:shadow-lg rounded-full' size={40} />
        </button>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            disableScrollLock
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {
                user?.whatsAppNumber?
                <MenuItem onClick={handleOpenWhatsapp}>Contacter via whatsapp</MenuItem>
                :null
            }
            {
                user?.telegramNumber?
                <MenuItem onClick={handleOpenTelegram}>Contacter via Telegram</MenuItem>
                :null
            }
        </Menu>
      </div>
    );
};

export default UserProfileContextualMenu;