import { Link } from "react-router-dom";
import { GlobalFormationType } from "../../../global/types";
import { BiChevronRight } from "react-icons/bi";
import { HiCalendar } from 'react-icons/hi2';
import moment from "moment";
import { Button } from "@mui/material";



type CardPropsType = {
  formation: Omit<GlobalFormationType, 'pillars'>;
  formations: Omit<GlobalFormationType, 'pillars'>[]
}

export const Card = ({ formation, formations }: CardPropsType) => {
  return (
    <Link
      to={'/catalog/formations/' + formation.id}
      className="group rounded-xl overflow-hidden"
      state={{ formation, formations }}
    >
      <div className="sm:flex">
        <div className="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
          <img
            className="group-hover:scale-105 transition-transform duration-500 ease-in-out size-full absolute top-0 start-0 object-cover rounded-xl"
            src={formation.imageUrl ? formation.imageUrl : require('../assets/formation_placeholder.png')}
            alt={"Illustration " + formation.title}
          />
        </div>

        <div className="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0 ml-2 lg:ml-3">
          <h3 className="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
            {formation.title}
          </h3>
          <p className="mt-3 text-gray-600 dark:text-gray-400">
            {formation.description}
          </p>
          <p className="text-sm flex items-center mt-2 gap-x-1">
            <HiCalendar className="inline mr-1" size={20} />
            Du <span className='font-medium'>{moment(formation.startDate).format('D MMM YYYY')}</span>
            au <span className='font-medium'>{moment(formation.endDate).format('D MMM YYYY')}</span>
          </p>
          <p className="mt-4 inline-flex items-center gap-x-1 text-secondary-dark decoration-2 hover:underline font-medium">
            Voir Plus
            <BiChevronRight size={24} />
          </p>
        </div>
      </div>
    </Link>
  )
};


type FormationCardsPropsType = {
  formations: Omit<GlobalFormationType, 'pillars'>[]
};

const FormationsCards = ({ formations }: FormationCardsPropsType) => {
  return (
    <div id='ourFormations' className="max-w-7xl w-full px-4 py-10 lg:mt-4 sm:px-3 lg:px-4 lg:py-7 mx-auto">
      <h2 className="text-3xl lg:text-4xl font-medium mb-3 lg:mb-6 cursor-default">
        Nos Formations{' '}
        {/* @ts-ignore */}
        <Button LinkComponent={Link} to="/catalog/formations" variant="text" color="primary" className="transform-none">
          <span style={{ textTransform: 'none' }} className="font-medium text-lg lg:text-xl" >Voir Tout</span>
          <BiChevronRight size={26} />
        </Button>
      </h2>
      <div className="grid lg:grid-cols-2 lg:gap-y-16 gap-10">
        {
          formations.map(formation => (
            <Card
              key={formation.id}
              formation={formation}
              formations={formations}
            />
          ))
        }
      </div>
    </div>
  )
};


export default FormationsCards;