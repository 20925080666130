import {
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

type RangePropsType = {
    title:string
    elements:{name:string, to:string}[]
};

const Range = ({title, elements}:RangePropsType) => {
    return (
        <div className='flex flex-1 flex-col px-2'>
            <h6 className='font-medium text-white pb-4'>{title}</h6>
            <ul>
                {elements.map(el => 
                    <li
                        key={el.name}
                    >
                        <Link
                            to={el.to}
                            className='text-sm transition ease-in-out hover:text-primary-light hover:font-medium cursor-pointer duration-200'
                        >
                            <p className='py-2'>{el.name}</p>
                        </Link>
                    </li>
                )}
            </ul>
        </div>
    )
}

const Footer = () => {
  return (
    <div className='py-10 lg:py-8 px-4 md:px-8 grid lg:grid-cols-3 gap-8 text-white bg-primary align-bottom'>
      <div>
        <h1 className='w-full text-3xl font-bold text-white'>CEAS e-learning</h1>
        <p className='py-4'>Gagnez en competence, gagnez de l'experience. Demandez une inscription des aujourd'hui</p>
        <div className='flex my-6 gap-4 '>
          <a href='https://www.facebook.com/centredexcellenceetdapplication'>
            <FaFacebookSquare className='transition ease-in-out hover:text-primary-light hover:scale-110 cursor-pointer' size={30} />
          </a>
          <a href='https://x.com/ceasgrne'>
            <FaTwitterSquare className='transition ease-in-out hover:text-primary-light hover:scale-110 cursor-pointer' size={30} />
          </a>
          <a href='https://www.linkedin.com/company/ceas-grne'>
            <FaLinkedin className='transition ease-in-out hover:text-primary-light hover:scale-110 cursor-pointer' size={30} />
          </a>
            {/* <FaInstagram size={30} /> */}
            {/* <FaGithubSquare size={30} /> */}
            {/* <FaDribbbleSquare size={30} /> */}
        </div>
      </div>
      <div className='lg:col-span-2 flex justify-between mt-6'>
        <Range
            title='Apprenants'
            elements={[{name:'Formations', to:'/catalog/formations'}, {name:'Carrières / Débouchées', to:'/about/careers'}]}
        />
        <Range
            title='Formateurs et experts'
            elements={[{name:'Voir', to:'/catalog/figures'}, {name:'Entrer en Contact', to:'/about/contact'}]}
        />
        <Range
            title='CEAS'
            elements={[{name:'A propos', to:'/about/ceas-grne'}, {name:'Blog', to:'/blog/articles'}, {name:'Connexion', to:'/signin'}]}
        />
        {/* <div>
            <h6 className='font-medium text-gray-400'>Legal</h6>
            <ul>
                <li className='py-2 text-sm'>Claim</li>
                <li className='py-2 text-sm'>Policy</li>
                <li className='py-2 text-sm'>Terms</li>
            </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
