import "../../../../assets/react-quill-snow.css";
import docx from '../../../../assets/docx.png';
import txt from '../../../../assets/txt.png';
import pptx from '../../../../assets/pptx.png';
import pdf from '../../../../assets/pdf.png';
import xlsx from '../../../../assets/xlsx.png';
import { Link } from "react-router-dom"
import { ChapterTaskType, UserType } from "../../../../global/types";
import CustomButton from "../../../../components/CustomButton";


type DocumentViewerPropsType = {
  user: UserType,
  task: ChapterTaskType
};

const DocumentViewer: React.FC<DocumentViewerPropsType> = ({ user, task }) => {
  const icons = {
    doc: docx,
    docx: docx,
    pdf: pdf,
    ppt: pptx,
    pptx: pptx,
    txt: txt,
    xls: xlsx,
    xlsx: xlsx,
  };

  const docTypes = {
    'pdf': 'PDF',
    'doc': 'Word',
    'docx': 'Word',
    'ppt': 'PowerPoint',
    'pptx': 'PowerPoint',
    'xls': 'Excel',
    'xlsx': 'Excel',
    'txt': 'Text'
  };
  return (
    <div className="flex bg-white rounded-xl p-4 mt-4">
      <img className="h-32 w-32 object-contain bg-slate-200 rounded-md" src={icons['pdf']} alt="" />
      <div className="flex flex-1 flex-col mx-4">
        <p className="text-xl mb-2">{task.fileName} </p>
        {/* <span className="text-gray-500">nam impedit nihil sed. <br/> Laborum harum accusantium, officiis doloribus saepe in unde maxime corrupti dolorum quasi voluptatibus! Nam quibusdam id animi.</span> */}
        <div className="flex flex-1 items-end justify-end border">
          <CustomButton
            navigateTo={task.fileUrl}
            title="Télécharger le document"
          />
        </div>
      </div>


    </div>
  )
};

export default DocumentViewer;