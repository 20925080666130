import { HiDocument } from "react-icons/hi2";
import { FiGlobe } from 'react-icons/fi';

const books = [
    {
        title:'Agroforesterie',
        description:"Un livre a propos de l'agriculture et des forets",
        link:'#',
    },
    {
        title:'Une Verdure cachee',
        description:'Decouvrir les plantes sous un tout autre aspect',
        link:'#',
    },
    {
        title:'Le reigne animal',
        description:"Une etude profonde sur les animaux et l'impact de l'etre humain",
        link:'#',
    },
    {
        title:'Tout en papier',
        description:"Vue sur nos forets et leur rajeunissement du a la surexploitation",
        link:'#',
    },
    {
        title:'Observation sous marine',
        description:"La vie de nos voisins de l'eau",
        link:'#',
    }
];

type MenuItemPropsType = {
    title:string
    description:string
    link:string //The link may be pointing to an external website
    index:number
};

const BookItem = ({title, description, link, index}:MenuItemPropsType) => {
    return (
        <div
            className="py-4 px-8 my-3 border rounded-lg flex w-full shadow-sm bg-secondary-light items-center cursor-pointer group"
        >
            <div className="flex flex-1 flex-col">
                <div className="font-semibold text-lg mb-1">
                    {title}
                </div>
                <div>
                    {description}
                </div>
            </div>
            {
                index%2?
                <HiDocument className='text-gray-500 group-hover:text-black group-hover:scale-[1.2] transition duration-200' size={24}/>
                :
                <FiGlobe className='text-gray-500 group-hover:text-black group-hover:scale-[1.2] transition duration-200' size={24}/>
            }
        </div>
    )
};

const Library = () => {
    return (
        <div className="container mx-auto px-4 py-6 mb-6">
            <h2 className="text-4xl font-medium mb-8 mt-4 self-start">Bibliotheque</h2>
            {books.map ((book, index) => {
                return (
                    <BookItem index={index} {...book} />
                )
            })}
        </div>
    )
};

export default Library;