import React from "react";
import MenuCard, { MenuItem } from "../../../../../components/MenuCard";

type StudentLogsPropsType = {
    visibleBoard:"journey" | "general" | "comments"|"history"|"tasks"
    setVisibleBoard:React.Dispatch<React.SetStateAction<"journey" | "general" | "comments"|"history"|"tasks">>
};

const TeacherLogs:React.FC<StudentLogsPropsType> = ({visibleBoard, setVisibleBoard}) => {
    return (
        <MenuCard
            title="Journal"
        >
            <MenuItem
                title="General"
                focused={visibleBoard==="general"}
                onNodeClick={() => setVisibleBoard('general')}
            />
            {/* <MenuItem
                title="Historique des activites"
                focused={visibleBoard==="history"}
                onNodeClick={() => setVisibleBoard('history')}
            />
            <MenuItem
                onNodeClick={() => setVisibleBoard('journey')}
                focused={visibleBoard==="journey"}
                title="Deroulement des cours"
            />
            <MenuItem
                title="Commentaires sur le formateur"
                focused={visibleBoard==="comments"}
                onNodeClick={() => setVisibleBoard('comments')}
            /> */}
            <MenuItem
                title="Tâches Associées"
                focused={visibleBoard==="tasks"}
                onNodeClick={() => setVisibleBoard('tasks')}
            />
        </MenuCard>
    )
};

export default TeacherLogs;