import { useEffect, useState } from "react";
import { BlogArticle } from "../../../global/types";
import { db, functions } from "../../../App";
import { httpsCallable } from "firebase/functions";
import { Card } from "../../Home/components/ArticlesCards";
import { useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";
import { collection, getDocs, query, where } from "firebase/firestore";


type BlogArticleCardProps = {
  article: BlogArticle;
};

const BlogArticleCard = ({ article }: BlogArticleCardProps) => {
  return (
    <div className="flex flex-col w-full max-w-6xl mx-auto bg-secondary-light rounded-2xl py-8 mb-4 lg:mb-8">
      <p className="text-3xl font-medium mb-6 mx-6 md:mx-12">
        
        {/* <span className="text-xl text-gray-500">
          {' '}Du{' '}{moment(formation.startDate).format('D MMM YYYY')}
          {' '}Au{' '}{moment(formation.endDate).format('D MMM YYYY')}
        </span> */}
      </p>
      <div className="flex flex-1 flex-col lg:flex-row">
        <div className="flex flex-1 mx-12">
          <img className="rounded-t-3xl rounded-b-lg border border-gray-200 aspect-[13/9] object-cover" alt={article.title} src={article.imageUrl ? article.imageUrl : require('../Formation/formation_placeholder.png')} />
        </div>
        <div className="flex flex-col lg:flex-[1.2] xl:flex-[1.5]">
          <p className="text-xl lg:text-2xl font-medium">
          {article.title}
          </p>
          
        <div className="mt-4 text-lg">
          {article.description}
        </div>
          <p className="mt-2 text-gray-700">
            <span className="font-medium">Période:</span> {article.period}
          </p>
        </div>
      </div>
      <div className="mx-6 md:mx-12">
        <div className="mt-8">
          {/* <p className="text-3xl mb-2">Presentation:</p> */}
          <div
            dangerouslySetInnerHTML={{ __html: article.rawHtml }}
            className="prose-a:text-blue-500 prose-a:underline prose-a:underline-offset-2"
          />
        </div>
      </div>
      {/* <div className="mx-10">
                    <CustomButton
                        title="S'enroler"
                        className="mt-10 w-full"
                        onClick={(e) => {
                            window.location.href = "mailto:cruzorbladex@gmail.com";
                            e.preventDefault();
                        }}
                    />
                </div> */}
    </div>

  )
};


const Articles = () => {

  const state: { articles: BlogArticle[] | undefined } | null = useLocation().state
  const [articles, setArticles] = useState<BlogArticle[]>(state?.articles ? state.articles : []);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const articleId = searchParams.get('id');
  const focusedArticle = articles.find(item => item.id === articleId);

  const getAndSetArticles = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const q = query(collection(db, 'articles'), where('options.public', '==', true));

      const articlesSnaps = await getDocs(q);
      const articles:BlogArticle[] = articlesSnaps.docs.map(snap => {
        const snapData = snap.data();
        return ({
          description:snapData.description,
          id:snap.id,
          period:snapData.period,
          options:snapData.options,
          rawHtml:snapData.rawHtml,
          title:snapData.title,
          imageUrl:snapData.imageUrl
        })
      })
      setArticles(articles)
    } catch (error) {
      console.log('Error while getting blog articles: ', error);
    }
    setLoading(false);
  };


  useEffect(() => {
    getAndSetArticles()
  }, []);

  return (
    <div className='w-full max-w-7xl  mx-auto py-12 sm:px-3 lg:px-4 lg:py-7 bg-white'>

      <div className='max-w-6xl mx-auto'>
      <h2 className="text-4xl font-medium mb-3 lg:mb-6">Articles</h2>
        {
          focusedArticle ?
            <BlogArticleCard article={focusedArticle} />
            : null
        }
        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:px-16  gap-2 sm:gap-4 md:gap-6">
          {
            articles.map((item, index) => (
              <Card
                key={item.title + index}
                article={item}
                articles={articles}
                replace
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default Articles;
