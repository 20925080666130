import CustomButton from "../../../../components/CustomButton";
import { useEffect, useState } from 'react';
import { functions } from "../../../../App";
import { useLocation, useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { ChatType, GlobalFormationType, UserType } from "../../../../global/types";
import CustomSelect from "../../../../components/CustomSelect";
import { notUndefined } from "../../../../utils";


const initialValues: ChatType = {
  title: "",
  description: "",
  createdAt: new Date(),
  guests: [],
  id: Date.now().toString(),
  attachedFormation: undefined
};


const ChatAdd = () => {

  const navigate = useNavigate();
  const state: { chatInfos: ChatType } | null = useLocation().state;

  const initialContent = state?.chatInfos ? { ...initialValues, ...state.chatInfos } : initialValues
  const [chatInfos, setChatInfos] = useState(initialContent);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [formations, setFormations] = useState<Omit<GlobalFormationType, 'pillars'>[]>([]);
  const [figures, setFigures] = useState<UserType[]>([]);

  const checkFormValid = () => {
    setFormError("");
    if (!chatInfos.title) setFormError("Vous spécifier un titre pour le chat");
    return Boolean(chatInfos.title);
  };


  const onSaveChatInfos = async () => {
    if (loading || !checkFormValid()) return;
    setLoading(true);


    try {
      const initChatObj = {
        title: chatInfos.title,
        description: chatInfos.description,
        attachedFormation: chatInfos.attachedFormation,
        guests: chatInfos.guests
      };

      if(state?.chatInfos ===undefined) {
        const initChat = httpsCallable(functions, 'initChat');
        await initChat(initChatObj)
      } else {
        const updateChat = httpsCallable(functions, 'updateChat');
        await updateChat({chatId:state.chatInfos.id, chat:initChatObj});
      };
      
      navigate(-1);
    } catch (error) {
      console.log('An error occured while initializing the chat')
    }
    setLoading(false);
  };

  const getAndSetGuests = async () => {
    setLoading(true);
    try {
      const getAllPlatformFigures = httpsCallable(functions, 'getAllPlatformFigures');
      const figuresData = (await getAllPlatformFigures()).data as UserType[];

      setFigures(figuresData);
      // console.log('Figures: ', figuresData)
    } catch (error) {
      console.log('error occured while getting guests: ', error);
    }
    setLoading(false);
  }


  const getAndSetFormations = async () => {
    try {
      const getAllFormations = httpsCallable(functions, 'getAllFormations');
      const resultData = (await getAllFormations()).data as Omit<GlobalFormationType, 'pillars'>[];
      setFormations(resultData || []);
    } catch (error) {
      console.log('Error while fetching list of formations', error)
    }
  };

  useEffect(() => {
    getAndSetFormations();
    getAndSetGuests();
  }, []);
  return (
    <div className="mt-4 mb-10 mx-auto flex flex-1 flex-col max-w-6xl ">
      <div className="p-3  lg:p-6 rounded-2xl flex flex-1 flex-col bg-secondary-light">
        <div className="w-full max-w-6xl mx-auto">
          <div className="text-2xl font-medium mb-4 self-start flex justify-between items-center">
            Initier une Discussion/Chat
          </div>
          <div className='mt-2 mb-1'>
            Titre de la discussion:
          </div>
          <input
            value={chatInfos.title}
            onChange={(e) => setChatInfos(infos => ({ ...infos, title: e.target.value }))}
            className='textInput'
            placeholder='Entrez quelque chose...'
          />
          <div className='mt-2 mb-1'>
            Description du du chat:
          </div>
          <textarea
            className="textInput"
            rows={4}
            value={chatInfos.description}
            onChange={(e) => setChatInfos(infos => ({ ...infos, description: e.target.value }))}
          />
          <div className='mt-2 mb-1'>
            Formation Attachée:
          </div>

          <CustomSelect
            containerClassName='col-span-2'
            name="followingFormations"
            onChange={(newValue: any) => setChatInfos(infos => ({ ...infos, attachedFormation: newValue.value == 'none' ? undefined : newValue.value }))}
            labelClassName='mb-0'
            value={chatInfos.attachedFormation && formations.some(item => item.id === chatInfos.attachedFormation) ?
              {
                label: formations.find(item => item.id === chatInfos.attachedFormation)?.title,
                value: chatInfos.attachedFormation
              } :
              { label: 'Aucune', value: undefined }
            }
            options={formations.map(formation => ({ label: formation.title, value: formation.id })).concat({ label: 'Aucune', value: 'none' })}
            placeholder='Aucune (Sélectionner une formation...)'
          />
          <div className='mt-2 mb-1'>
            Autres participant(s):
          </div>
          <CustomSelect
            isMulti
            value={chatInfos.guests.map(
              guestId => figures.find(figure => figure.id == guestId)
            ).filter(notUndefined)
              .map(figure => ({
                label: figure.name + ' ' + figure.surname,
                value: figure.id
              }))
            }
            options={figures.map(figure => ({
              label: figure.name + ' ' + figure.surname,
              value: figure.id
            }))}
            onChange={(newValue: any) =>
              setChatInfos((infos) => ({
                ...infos,
                guests: (newValue as { label: string, value: string }[])
                  .map((option) => option.value),
              }))
            }
          />

          {
            formError ?
              <div className="mt-4 text-red-600 font-medium">
                {formError}
              </div>
              :
              null
          }
          <div className="flex ml-auto justify-end px-8 gap-x-8 mt-6 max-w-lg">
            <CustomButton
              title="Annuler"
              variant="btnDanger"
              className="w-40"
              onClick={() => navigate(-1)}
            />
            <CustomButton
              loading={loading}
              onClick={onSaveChatInfos}
              title="Enregistrer"
              className="w-48"
            />
          </div>
        </div>
      </div>
    </div>
  );
}


export default ChatAdd;