import { Link } from "react-router-dom";
import { FormationChapterType, FormationCourseType, FormationModuleType, FormationPillarType, UserType } from "../../../../global/types";
import { BsGearFill } from "react-icons/bs";
import { BiPlus } from "react-icons/bi";



type ChapterCardPropsType = {
  chapterIndex: number;
  chapter: FormationChapterType;
  displaySettings: boolean;
  course:FormationCourseType;
};

const ChapterCard: React.FC<ChapterCardPropsType> = ({ chapter, course, chapterIndex, displaySettings }) => {
  return (
    <Link
      className="bg-secondary-dark w-80 mt-6 p-2 rounded cursor-pointer hover:shadow-inner hover:shadow-[#ffffff69] transition duration-200"
      to={'/console/chapters/' + chapter.id}
      state={{ chapter, course }}
    >
      <p className="text-lg text-white mb-2 flex items-center">
        Chapitre {chapterIndex + 1}: {chapter.title}
        <Link
          style={{ display: displaySettings ? 'block' : 'none' }}
          to={`manage/chapter`}
          state={{ nodeType: 'chapter', chapterInfos: chapter }}
        >
          <BsGearFill size={22} className="mx-2 hover:text-black hover:scale-125 transition" />
        </Link>
      </p>
      {/* <p className="text-sm text-gray-200">
                {description}
            </p> */}
    </Link>
  )
};

type PillarCardPropsType = {
  pillar: FormationPillarType;
  focusedNode: { pillar: string | null, module: string | null, course: string | null };
  user: UserType | null | undefined
};

const PillarCard: React.FC<PillarCardPropsType> = ({ pillar, focusedNode, user }) => {

  let focusedModule: FormationModuleType | null = null;
  let focusedCourse: FormationCourseType | null = null;

  if (focusedNode.module !== null) {
    focusedModule = pillar.modules.find(item => item.id === focusedNode.module)
      || pillar.modules.at(0)
      || null;
    // There could be cases where focusedNode.module != null but focusedModule == null (module not found)
    if (focusedModule && focusedNode.course !== null) {
      focusedCourse = focusedModule.courses.find(item => item.id === focusedNode.course)
        || focusedModule.courses.at(0)
        || null;
    }
  }


  return (
    <div className="rounded-md p-4 w-full">
      <div className="mx-2">
        <div className="text-lg font-medium flex justify-between mb-2">
          <div className="border-4 border-white px-3 py-2 w-full">
            <p className="mb-3">
              <span className="underline underline-offset-2"> Objectif du pillier</span>: {pillar.description}
            </p>
            {focusedModule ? (
              <p className="mb-3">
                <span className="underline underline-offset-2"> Objectif du module </span>: {focusedModule.description}
              </p>
            ) : null}
            {focusedCourse ? (
              <p className="mb-3">
                <span className="underline underline-offset-2"> Titre du cours</span> : {focusedCourse.title}
              </p>
            ) : null}
          </div>
        </div>
        {focusedCourse ? (
          <>
            <div className="p-3">
              <p className="text-gray-500 mb-3">
                Dispense par <span>{focusedCourse.teachers[0]?.name || "No"} {focusedCourse.teachers[0]?.surname || "Name"}</span>
              </p>
              <p className="text-gray-500 mb-3">
                Duree de cours: <span>{focusedCourse.courseDuration} Jours</span>
              </p>
              <p className="text-gray-500 mb-3">
                Duree de mise en oeuvre: <span>{focusedCourse.applicationDuration} j</span>
              </p>
            </div>
            <div className="">
              <div className="bg-slate-50 px-3 py-2 rounded-lg ">
                <p className="text-base font-medium text-black">Approche Pedagogique: </p>
                <div
                  className="prose prose-sm max-w-full text-black prose-a:text-blue-500"
                  dangerouslySetInnerHTML={{ __html: focusedCourse.pedagogicApproach }}
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="bg-slate-50 px-3 py-2 rounded-lg ">
                <p className="text-base font-medium text-black">Evaluation: </p>
                <div
                  className="prose prose-sm max-w-full text-black prose-a:text-blue-500"
                  dangerouslySetInnerHTML={{ __html: focusedCourse.evaluation }}
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="bg-slate-50 px-3 py-2 rounded-lg ">
                <p className="text-base font-medium text-black">Besoins: </p>
                <div
                  className="prose prose-sm max-w-full text-black prose-a:text-blue-500"
                  dangerouslySetInnerHTML={{ __html: focusedCourse.needs }}
                />
              </div>
            </div>
            <div className="mt-3">
              <div className="bg-slate-50 px-3 py-2 rounded-lg ">
                <p className="text-base font-medium text-black">Bibliographie: </p>
                <div
                  className="prose prose-sm max-w-full text-black prose-a:text-blue-500"
                  dangerouslySetInnerHTML={{ __html: focusedCourse.bibliography }}
                />
              </div>
            </div>
          </>
        ) : null}

      </div>
      <div>
        <div className="w-full flex flex-wrap justify-around m-2">
          {
            focusedCourse ?
              (<>
                {

                  focusedCourse.chapters.map((chapter, index) => (
                      <ChapterCard
                        displaySettings={(user && focusedCourse?.teachersIds.includes(user.id)) || false}
                        chapter={chapter}
                        chapterIndex={index}
                        course={focusedCourse as FormationCourseType}
                      />
                  )
                  )

                }
                <Link
                  to={'manage/chapter'}
                  style={{ display: (focusedCourse.teachers.length !== 0) && (focusedCourse.teachers.map(item => item.id).includes(user?.id || 'none')) ? 'block' : 'none' }}
                  className="bg-white border-secondary-dark border-4 w-80 mt-6 p-2 rounded cursor-pointer hover:shadow-inner hover:shadow-[#ffffff69] transition duration-200"
                  state={{ nodeType: 'chapter', initForm: true, parentId: focusedCourse.id }}
                >
                  <p className="text-lg text-secondary-dark flex items-center">
                    Ajouter Un Chapitre
                    <BiPlus size={24} className="mx-2" />
                  </p>
                </Link>
              </>)


              :
              null
          }
        </div>
      </div>
    </div>
  );
};

export default PillarCard;