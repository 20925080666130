import { BiChevronDownCircle } from "react-icons/bi";
import { FaBook } from "react-icons/fa";
import { RiBuilding2Fill } from "react-icons/ri";
import { TiDocumentText } from "react-icons/ti";
import MenuCard, { MenuItem } from "../../../../components/MenuCard"
import { GlobalFormationType } from "../../../../global/types";

type TreePropsType = {
    formation:GlobalFormationType
    focusedNode:{
        pillar: string | null;
        module: string | null;
        course: string | null;
    };
    setFocusedNode: (T:{pillar:string, module?:string, course?:string}) => void;
}

const Tree:React.FC<TreePropsType> = ({formation, focusedNode, setFocusedNode}) => {
    return (
        <MenuCard
            title="Vue d'Ensemble"
        >
            {
                formation.pillars.map((pillar, pillarIndex) => (
                    <MenuItem
                        focused={focusedNode.pillar === pillar.id}
                        onNodeClick={() => {
                            if(!(focusedNode.pillar === pillar.id)) {
                                setFocusedNode({
                                    pillar:pillar.id,
                                    module:pillar.modules.at(0)?.id||undefined,
                                    course:pillar.modules.at(0)?.courses.at(0)?.id||undefined
                            })}
                            }
                        }
                        suffixTogglable
                        title={'Pillar '+(pillarIndex+1)+': '+pillar.title}
                        icon={<RiBuilding2Fill className="mr-2"/>}
                        suffix={
                            <div className="px-2 py-1 hover:bg-white hover:bg-opacity-40 transition rounded-full">
                                <BiChevronDownCircle size={24} className="m-auto"/>
                            </div>
                        }
                    >
                        {
                            pillar.modules.map((module, moduleIndex) => (
                                <MenuItem
                                    focused={(focusedNode.module === module.id) && (focusedNode.pillar === pillar.id)}
                                    onNodeClick={() => {
                                        if(!(focusedNode.module === module.id)) {
                                            setFocusedNode({
                                            pillar:pillar.id,
                                            module:module.id,
                                            course:module.courses.at(0)?.id||undefined
                                        })}
                                        }
                                    }
                                    suffixTogglable
                                    icon={<FaBook className="mr-2" />}
                                    title={'Module '+(moduleIndex+1)+': '+module.title}
                                    suffix={
                                        <div className="px-2 py-1 text-gray-500 hover:bg-white hover:bg-opacity-40 transition rounded-full">
                                            <BiChevronDownCircle size={24} className="m-auto"/>
                                        </div>
                                    }
                                >
                                    {
                                        module.courses.map((course, courseIndex) => (
                                            <MenuItem
                                                focused={(focusedNode.course === course.id) && (focusedNode.module === module.id) && (focusedNode.pillar === pillar.id)}
                                                onNodeClick={() => {
                                                    if(!(focusedNode.course === course.id)) {
                                                        setFocusedNode({
                                                            pillar:pillar.id,
                                                            module:module.id,
                                                            course:course.id
                                                        })}
                                                    }
                                                }
                                                icon={<TiDocumentText size={20} className="mr-2" />}
                                                title={'Course '+(courseIndex+1)+': '+course.title}
                                            />
                                        ))
                                    }   
                                </MenuItem>
                            ))
                        }

                    </MenuItem>
                ))
            }
        </MenuCard>
    )
};

export default Tree;