import { Link } from 'react-router-dom';

type ButtonPropsType = {
    title: string;
    variant?: 'btnPrimary' | 'btnSecondary' | 'btnDanger';
    className?: string;
    navigateTo?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    btnType?: 'button' | 'submit' | 'reset';
    loading?:boolean;
    autoFocus?:boolean
};

const CustomButton: React.FC<React.PropsWithChildren<ButtonPropsType>> = ({
    className,
    title,
    variant = 'btnPrimary',
    btnType = 'button',
    onClick,
    navigateTo,
    children,
    loading,
    autoFocus
}) => {
    const buttonProps = {
        type: btnType,
        className: `customBtn ${variant} ${className} ${loading?'hover:cursor-progress':''}`,
        onClick,
    };

    if (navigateTo) {
        return (
            <Link to={navigateTo} >
                <div className={buttonProps.className}>
                    {title}
                    {children}
                    {
                        loading?
                        <div
                            className="inline-block h-6 w-6 ml-3  animate-spin rounded-full border-4 border-[#ffffff84] border-r-white align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                        />
                        :null
                    }
                </div>
            </Link>
        );
    }

    return (
        <button autoFocus={autoFocus} disabled={loading} {...buttonProps}>{title}{children}
            {
                loading?
                <div
                    className="inline-block h-6 w-6 ml-3  animate-spin rounded-full border-4 border-[#ffffff84] border-r-white align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                />
                :null
            }
        </button>
    );
};

export default CustomButton;
