import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { BiChevronDownCircle } from 'react-icons/bi'
import { Fragment, useContext, useEffect, useState } from "react";
import { db } from "../../App";
import { Timestamp, addDoc, collection, deleteDoc, doc, getDocs, query } from "firebase/firestore";
import CustomButton from "../../components/CustomButton";
import { AuthContext } from "../../contexts/AuthContext";
import { MdOutlineDelete } from "react-icons/md";
import { Button } from "@mui/material";


type Question = {
  title: string;
  answer: string;
};

const questions: Question[] = [
  {
    title: 'Comment Se Connecter A son compte?',
    answer:
      `Vous pouvez cliquer sur le menu "plus" dans la barre de navigation et de la, choisir l'onglet Connexion au compte.
        A defaut, vous pouvez utiliser le bouton "Connexion" situe dans le pied du site web.
        `,
  },
];

type FAQType = {
  id: string
  title: string;
  answer: string;
};

const FAQ = () => {
  const [questions, setQuestions] = useState<FAQType[]>([]);
  const [loading, setLoading] = useState(false);
  const [addFAQ, setAddFAQ] = useState<{ title: string; answer: string }>({ title: '', answer: '' })
  const [formError, setFormError] = useState('');

  const user = useContext(AuthContext)?.user;

  const getAndSetFAQs = async () => {
    try {
      const q = query(collection(db, 'faqs'));

      const faqsSnaps = await getDocs(q);
      const faqs: FAQType[] = faqsSnaps.docs.map(snap => {
        const snapData = snap.data();
        return ({
          id: snap.id,
          answer: snapData.answer,
          title: snapData.title
        })
      })
      setQuestions(faqs)
    } catch (error) {
      console.log('Error while getting frequently asked questions: ', error);
    }
  };

  const publishFAQ = async () => {
    if (!user) return;
    setFormError('');
    if (!addFAQ.title || !addFAQ.answer) {
      setFormError("La question ou la réponse ne peut pas être vide. Vérifiez vos entrées");
      return
    }
    setLoading(true);
    try {
      await addDoc(collection(db, 'faqs'), { title: addFAQ.title, answer: addFAQ.answer, createdAt: Timestamp.now(), author: user.id })
      getAndSetFAQs();
      setAddFAQ({ answer: '', title: '' });
    } catch (error) {
      console.log('An error occured while adding FAQ: ', error);
    };
    setLoading(false);
  }

  const deleteFAQ = async (id: string) => {
    try {
      await deleteDoc(doc(db, `faqs/${id}`));
      setQuestions(questions.filter(item => item.id != id));
    } catch (error) {
      console.log('An error occured while deleting FAQ: ', error);
    }
  };

  useEffect(() => {
    getAndSetFAQs()
  }, [])
  return (
    <div className="container mx-auto px-2 py-6 mb-6 max-w-7xl lg:px-8 xl:px-12">
      <h2 className="text-3xl lg:text-4xl font-medium mb-8 mt-4 self-start">Questions Fréquemment Posées</h2>
      {questions.map(question => {
        return (
          <Accordion defaultExpanded={false} className="!bg-secondary-light" style={{ overflow: 'hidden', marginTop: '1rem', borderRadius: '2rem' }}>
            <AccordionSummary expandIcon={<BiChevronDownCircle className="mx-1 lg:mx-2" size={20} color="#000" />}>
              <div className="font-bold text-lg mx-2 flex flex-1">
                {question.title}
              </div>
              <div>
                <Button onClick={() => deleteFAQ(question.id)}>
                  <MdOutlineDelete size={24} />
                </Button>
              </div>
            </AccordionSummary>
            <AccordionDetails className="bg-white">
              <div>
                {question.answer.split('\n').map((line, index) => (
                  <Fragment key={index}>
                    {line}
                    <br />
                  </Fragment>
                ))}
              </div >
            </AccordionDetails>
          </Accordion>
        )
      })}
      {
        ['admin'].some((item: any) => user?.accountType.includes(item)) ?
          <div className="mt-6 lg:mt-8">
            {
              formError ?
                <div className="mt-4 text-red-600 font-medium">
                  {formError}
                </div>
                :
                null
            }
            <div className="flex flex-1 gap-x-3 lg:gap-x-4 items-baseline">
              <div className="flex flex-col flex-1">
                <div className='mt-2 mb-1'>
                  Question:
                </div>
                <input
                  value={addFAQ.title}
                  onChange={(e) => setAddFAQ(value => ({ ...value, title: e.target.value }))}
                  className='textInput'
                  placeholder='Entrez quelque chose...'
                />
              </div>
              <CustomButton
                title="Ajouter"
                onClick={publishFAQ}
                className="self-end !px-6 lg:!px-10 xl:!px-12"
                loading={loading}
              />
            </div>
            <div>
              <div className='mt-2 mb-1'>
                Réponse/Indications:
              </div>
              <textarea
                value={addFAQ.answer}
                onChange={(e) => setAddFAQ(value => ({ ...value, answer: e.target.value }))}
                rows={4}
                className="textInput"
              />
            </div>
          </div>
          : null
      }
    </div>
  );
};

export default FAQ;