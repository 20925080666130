import { useState } from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const carousels = [
  require('../assets/carousel/1.jpg'),
  // require('../assets/carousel/2.jpg'),
  require('../assets/carousel/3.jpg'),
  require('../assets/carousel/4.jpg'),
  require('../assets/carousel/5.jpg'),
]

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <div className="lg:flex-row justify-center mx-auto gap-x-10">
      <div className="relative">
        <ResponsiveCarousel
          // dynamicHeight
          showThumbs={false}
          showIndicators={false}
          selectedItem={currentSlide}
          autoPlay
          infiniteLoop
          useKeyboardArrows
          axis='vertical'
          onChange={(index) => setCurrentSlide(index)}

          autoFocus={false}
          showArrows={false}
          swipeable
          emulateTouch
        >
          {carousels.map((item) => (
            <div className='aspect-square lg:aspect-video rounded-xl overflow-hidden flex items-center' key={item}>
              <img className='h-full w-full object-cover' src={item} alt="Carousel CEAS - GRNE learners" />
            </div>
          ))}
        </ResponsiveCarousel>
      </div>
    </div>
  );
};

const Hero = () => {
  const heroImg = require('../assets/heroImg.jpg')
  return (
    <div
      // style={{backgroundImage: `url(${heroImg})`}}
      className="relative bg-cover text-white overflow-hidden w-full"
    >
      <div className="flex flex-1 items-center justify-center backdrop-brightness-[30%]">
        <img
          src={heroImg}
          alt=""
          className="absolute w-full h-full object-cover brightness-50"
        />
        <div className='relative w-full h-[60vh] lg:h-[70vh] mx-auto flex items-center justify-center'>
          <Carousel />
          <div className='absolute my-auto mx-2 w-full flex flex-1 h-full items-center justify-center z-10 px-4 md:px-8 py-6 lg:px-24 text-2xl lg:text-4xl font-medium text-center text-gray-200 bg-black bg-opacity-60'>
            Bienvenue sur la plateforme d'apprentissage en ligne du Centre d'Excellence et d'Application
            pour le Suivi de la Gestion des Ressources Naturelles et de l'Environnement. Nous avons pour
            mission de former des praticiens qualifiés pour assurer un suivi de la qualité des ressources
            naturelles...
        </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
