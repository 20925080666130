import React from "react";
import MenuCard, { MenuItem } from "../../../../../components/MenuCard";

type StudentLogsPropsType = {
    visibleBoard:"general" | "comments"
    setVisibleBoard:React.Dispatch<React.SetStateAction<"general" | "comments">>
};

const TeacherLogs:React.FC<StudentLogsPropsType> = ({visibleBoard, setVisibleBoard}) => {
    return (
        <MenuCard
            title="Journal"
        >
            <MenuItem
                title="General"
                focused={visibleBoard==="general"}
                onNodeClick={() => setVisibleBoard('general')}
            />
            <MenuItem
                title="Commentaires sur l'administrateur"
                focused={visibleBoard==="comments"}
                onNodeClick={() => setVisibleBoard('comments')}
            />
        </MenuCard>
    )
};

export default TeacherLogs;