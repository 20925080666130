import { useEffect, useState } from "react";
import { ChapterTaskType, MCQQuestionType, MCQsAnswerType, UserType } from "../../../../global/types";
import CustomButton from "../../../../components/CustomButton";
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../App';
import { useNavigate, useParams } from 'react-router-dom';
import { notUndefined } from '../../../../utils';
import moment from 'moment';
import { BsPinAngleFill } from "react-icons/bs";
import { getScore } from "../../../../utils/pretest";


type MCQQuestionPropsType = {
  question: MCQQuestionType;
  handleQuestionChange: (qIndex:number, question: MCQQuestionType) => void;
  qIndex:number;
};

export const MCQQuestion: React.FC<MCQQuestionPropsType> = ({ question, handleQuestionChange, qIndex }) => {
  return (
    <div className="bg-slate-50 dark:bg-slate-200 p-2 lg:p-4 rounded-md">
      <div
        className="pb-2"
        dangerouslySetInnerHTML={{ __html: question.question+`(${question.weight} crédits)` }}
      />
      <div className="flex flex-col gap-y-3 dark:text-slate-100">
        {
          question.answers.map((answer, aIndex) => (
            <div
              onClick={() => {
                //toggle this answer selection state, and deselect all others options
                let newAnswers = question.answers.map((answer, index) => ({ ...answer, selected: index === aIndex ? !answer.selected : false }))
                handleQuestionChange(qIndex, { ...question, answers: newAnswers })
              }}
              className="px-3 py-2 bg-slate-200 dark:bg-gray-700 dark:hover:bg-gray-600 transition rounded flex justify-between"
            >
              <span>
                {answer.text}
              </span>
              <div className="text-primary-dark dark:text-slate-300">
                {
                  question.answers[aIndex].selected ?
                    <BsPinAngleFill size={22} />
                    : null
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}


type MCQQuestionsRendererPropsType = {
  questions: MCQQuestionType[]
  setQuestions: React.Dispatch<React.SetStateAction<MCQQuestionType[]>>
};

const MCQQuestionsRenderer: React.FC<MCQQuestionsRendererPropsType> = ({ questions, setQuestions }) => {
  const handleQuestionChange = (qIndex:number, question:MCQQuestionType) => {
    const newQuestions = [...questions];
    newQuestions[qIndex] = question;
    setQuestions(newQuestions);
  };

  return (
    <div className='space-y-4'>
      {
        questions.map((question, qIndex) =>
          <MCQQuestion
            key={question.pseudoIndex}
            question={question}
            qIndex={qIndex}
            handleQuestionChange={handleQuestionChange}
          />
        )
      }
    </div>
  )
};

type MCQAnswerProps = {
  choices?:number[]
  question: MCQQuestionType;
  questionIndex: number;
}
export const MCQAnswer: React.FC<MCQAnswerProps> = ({ question, choices, questionIndex }) => {
  const tintColor = !choices? 'bg-slate-100': choices.some(choice => question.answers[choice].isCorrect) ? 'bg-green-300' : 'bg-red-400'
  return (
    <>
      <div
        style={{ display: "block" }}
        className='py-3 px-6 bg-white rounded-lg'
      >
        <p className="text-gray-700 font-medium">Question {questionIndex+1}: ({question.weight} crédits)</p>
        <div className="prose-sm" dangerouslySetInnerHTML={{ __html: question.question }} />
        <div className={'p-2 rounded-lg mt-1 prose-sm ' + tintColor}>
          <p className={"font-medium prose-sm " + (question.answers.every(answer => answer.isCorrect == answer.selected) ? 'text-gray-400' : 'text-gray-800')}>
            Reponse Juste:{'   '}{'   '}
            {
              question.answers.map((answer, index) =>
                answer.isCorrect ?
                  <span className="text-gray-800 mx-2 font-bold">
                    {String.fromCharCode(index + 65)}. {answer.text}
                  </span>
                  :
                  null
              )
            }
          </p>
          {
            question.explanation?
            <div
              className="prose-sm prose-a:underline prose-a:text-blue-600 mt-2 pt-1 border-t"
              dangerouslySetInnerHTML={{ __html: question.explanation }}
            />
            :
            null
          }
        </div>

      </div>
    </>
  )
}


type MCQAnswersRendererPropsType = {
  questions: MCQQuestionType[];
  answers?: MCQsAnswerType[];
};

const MCQAnswersRenderer: React.FC<MCQAnswersRendererPropsType> = ({ answers, questions }) => {

  return (
    <div className='space-y-4'>
      {
        answers?
        <div className='mb-4 text-lg font-medium'>
          Votre score:{' '}
          <span className='underline underline-offset-2'>
            {getScore(questions, answers)} sur
            {' '}{questions.map(question => question.weight).reduce((prev, current) => (prev||0)+(current||0))}
          </span>
        </div>
        :null
      }
      {
        questions.map((question, index) =>
          <MCQAnswer
            key={question.pseudoIndex}
            questionIndex={index}
            question={question}
            choices={answers?.find(answer => answer.metaIndex ===question.metaIndex)?.choices}
          />
        )

      }
    </div>
  )
};


type MCQRendererPropsType = {
  hasUserDoneTask: boolean | undefined;
  user: UserType;
  task:ChapterTaskType;
};

const MCQRenderer: React.FC<MCQRendererPropsType> = ({ user, task, hasUserDoneTask }) => {
  const { answerUserId, taskId } = useParams();
  //Initialize answers as empty for each question. a choice of 0 here means not answered
  const [displayCorrection, setDisplayCorrection] = useState(hasUserDoneTask&&moment(task.endDate).isBefore(new Date()));
  const [submitEnabled, setSubmitEnabled] = useState(!answerUserId && ['student'].some((item: any) => user.accountType.includes(item)) && !hasUserDoneTask);
  const [loading, setLoading] = useState({submit:false, viewAnswers:false});
  const navigate = useNavigate();
  const [questions, setQuestions] = useState(task.MCQs||[]);

  const submitMCQAnswers = async () => {
    if (loading.submit) return;
    setLoading(value => ({...value, submit:true}));

    try {
      const submitMCQAnswers = httpsCallable(functions, 'submitMCQAnswers');
      const answers:MCQsAnswerType[] = questions.map(question => ({
        type:'mcq',
        metaIndex: question.metaIndex,
        choices: question.answers.map((answer, index) => answer.selected ? index : undefined).filter(notUndefined)
      }));

      const response = (await submitMCQAnswers({ taskId: taskId, answers: answers })).data;
      setSubmitEnabled(false);
    } catch (error) {
      console.log('Error while submitting MCQ answers: ', error);
    }
    
    setLoading(value => ({...value, submit:false}));
  };

  useEffect(() => {
    setQuestions(task.MCQs||[])
  }, [task.MCQs]);

  return (
    <div className="flex flex-1 w-full flex-col mt-4">
      {
        displayCorrection ?
          <MCQAnswersRenderer
            questions={questions}
            answers={task.MCQResults?.find(user => user.userId ===answerUserId)?.answers as MCQsAnswerType[]|undefined}
          />
          :
          <MCQQuestionsRenderer
            questions={questions}
            setQuestions={setQuestions}
          />
      }

      <div className='flex gap-x-3 lg:gap-x-6 mt-4 lg:mt-6 xl:mt-8 ml-auto'>
        {
          submitEnabled ?
            <CustomButton
              loading={loading.submit}
              onClick={submitMCQAnswers}
              className="w-max"
              title={"Soumettre les reponses"}
            />
            :
            null
        }
        {
          hasUserDoneTask
          ||
          ['teacher', 'admin', 'expert'].some((item: any) => user.accountType.includes(item)) ?
            <CustomButton
              loading={loading.viewAnswers}
              onClick={() => setDisplayCorrection(!displayCorrection)}
              className="w-max"
              title={displayCorrection ? "Voir les questions" : "Voir les réponses"}
            />
            :
            null
        }
      </div>
    </div>
  )
};


export default MCQRenderer;