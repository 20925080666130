import AppRoutes from "./AppRoutes"
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, createUserWithEmailAndPassword, getAuth, initializeAuth } from 'firebase/auth';
import { ThemeProvider, createTheme } from "@mui/material";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyANx4-LGA5moSU49Qu0dhlFtDGVSIBY9kw",
  authDomain: "ceas-elearning.firebaseapp.com",
  projectId: "ceas-elearning",
  storageBucket: "ceas-elearning.appspot.com",
  messagingSenderId: "1037658215567",
  appId: "1:1037658215567:web:c31078b39b42392e6c0cfa",
  measurementId: "G-L6ZM7HF4ZV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
// connectAuthEmulator(auth, 'http://localhost:9099');

const db = getFirestore(app);
// connectFirestoreEmulator(db, 'localhost', 8080);

const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "localhost", 5001);

const storage = getStorage(app);
// connectStorageEmulator(storage, 'localhost', 9199);
// createUserWithEmailAndPassword(auth, 'superadmin@gmail.com', 'superadmin');
// createUserWithEmailAndPassword(auth, 'simpleadmin@gmail.com', 'simpleadmin');
// createUserWithEmailAndPassword(auth, 'simpleteacher@gmail.com', 'simpleteacher');
// createUserWithEmailAndPassword(auth, 'simplestudent@gmail.com', 'simplestudent');
export { auth, db, functions, storage };


const theme = createTheme({
  palette: {
    primary:{
      main:'#475A23',
      light:'#A9BF16',
      dark:'#2E3B16'
    },
    secondary: {
      main: '#947C4A',
      light:'#FFFACE',
      dark:'#7F5032'
    }
  }
});
const App = () => {
    return (
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    )
};

export default App;