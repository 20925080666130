const dropdowns = {
    en: {
        countries:[
            { label: 'Angola', value: 'AGO' },
            { label: 'Cameroon', value: 'CMR' },
            { label: 'Central African Republic', value: 'CAF' },
            { label: 'Chad', value: 'TCD' },
            { label: 'Democratic Republic of the Congo', value: 'COD' },
            { label: 'Equatorial Guinea', value: 'GNQ' },
            { label: 'Gabon', value: 'GAB' },
            { label: 'Republic of the Congo', value: 'COG' }
        ],
        expertiseDomains: [
            {label:'Forestery Engineering', value:'forestEng'},
            {label:'Lawyer', value:'lawyer'}
        ]
    },
    fr: {
        expertiseDomain: [
            {label:'Ingenierie des forets', value:'forestEng'},
            {label:'Juriste', value:'lawyer'}
        ],
        countries:[
            { label: 'Angola', value: 'AGO' },
            { label: 'Cameroun', value: 'CMR' },
            { label: 'République centrafricaine', value: 'CAF' },
            { label: 'Tchad', value: 'TCD' },
            { label: 'République démocratique du Congo', value: 'COD' },
            { label: 'Guinée équatoriale', value: 'GNQ' },
            { label: 'Gabon', value: 'GAB' },
            { label: 'République du Congo', value: 'COG' }
        ]
    }
};

export {dropdowns};
