import { useNavigate } from 'react-router-dom';
import { Button, LinearProgress, Menu, MenuItem } from "@mui/material";
import {
    DataGrid,
    GridCallbackDetails,
    GridClasses,
    GridColDef,
    GridNoRowsOverlay,
    GridRowSelectionModel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useContext, useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import CustomButton from '../../../../components/CustomButton';
import { functions } from '../../../../App';
import { httpsCallable } from 'firebase/functions';
import { AuthContext } from '../../../../contexts/AuthContext';


type Admin = {
    id: number;
    name: string;
    surname: string;
    phone: string;
    email: string;
    country: string;
};

const columns:GridColDef<Admin, any, any>[] = [
    { field: 'id', headerName: 'ID', minWidth: 50, flex:1 },
    { field: 'name', headerName: 'Nom', minWidth: 110, flex:1.5 },
    { field: 'surname', headerName: 'Prenom', minWidth: 110, flex:1.5 },
    { field: 'phone', headerName: 'Telephone', minWidth: 110, flex:1.5 },
    { field: 'email', headerName: 'Email', minWidth: 160, flex:2 },
    { field: 'country', headerName: 'Pays', minWidth: 100, flex:1.5 },
];

const BasicMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div className='ml-auto'>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{paddingY:2}}
        >
          <BsThreeDotsVertical className='text-secondary-dark' size={20}/>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableScrollLock
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose}>Desactiver la selection</MenuItem>
        </Menu>
      </div>
    );
};

const CustomToolbar = () => {
    return (
      <GridToolbarContainer className='bg-secondary-light' sx={{paddingX:2}}>
        <GridToolbarColumnsButton className='text-dark' sx={{paddingY:2, color:'#7F5032'}} />
        <GridToolbarFilterButton sx={{paddingY:2, color:'#7F5032'}}/>
        <GridToolbarDensitySelector sx={{paddingY:2, color:'#7F5032'}}/>
        <GridToolbarExport sx={{paddingY:2, color:'#7F5032'}}/>
        <BasicMenu/>
      </GridToolbarContainer>
    );
};

const Admins = () => {
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
    const [admins, setAdmins] = useState<Admin[]>([]);
    const [loading, setLoading] = useState(false);
    const user = useContext(AuthContext)?.user;
    const navigate = useNavigate();
     

    const handleRowSelection = (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
        //rowSelectionModel is an array containing checkboxed rows ids
        setSelectedRows(rowSelectionModel);
    };

    const gridClassNames:Partial<GridClasses> = {
        footerContainer:'bg-secondary-light'
    }

    const getAndSetPlatformAdmins = async () => {
        setLoading(true);
        try {
            const getAllPlatformAdmins = httpsCallable(functions, 'getAllPlatformAdmins');
            const resultData = (await getAllPlatformAdmins()).data;
            setAdmins(resultData as Admin[]);
        } catch (error) {
            console.log(error)
        }
        setLoading(false);
    }


    useEffect(() => {
        getAndSetPlatformAdmins()
    }, []);
    return (
    <div className='flex flex-1'>
        <div className='flex flex-1 flex-col max-w-7xl mx-auto px-2'>
            <h2 className="text-4xl font-medium mt-8 mb-4 self-start">Administrateurs</h2>
            <div className='max-h-[60vh] mb-2 lg:max-w-6xl xl:max-w-7xl'>
                <DataGrid
                    classes={gridClassNames}
                    autoHeight
                    loading={loading}
                    checkboxSelection
                    rows={admins}
                    columns={columns}
                    onRowClick={(row) => navigate(row.id.toString(), {state:{userData:admins.find(admin => admin.id === row.id)}})}    
                    onRowSelectionModelChange={handleRowSelection}
                    slots={{ toolbar: CustomToolbar, loadingOverlay:LinearProgress, noRowsOverlay:GridNoRowsOverlay }}
                    disableColumnMenu
                    disableRowSelectionOnClick
                />
            </div>
            {
              ['super'].some((item:any) => user?.accountType.includes(item)) ?
                <CustomButton
                    variant='btnSecondary'
                    title='Ajouter un nouveau'
                    className='w-72 mt-4'
                    navigateTo='add'
                />
                :
                null
            }
        </div>
    </div>
  );
};

export default Admins;
