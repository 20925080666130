import { BiChevronRightCircle } from 'react-icons/bi';
import { Link } from 'react-router-dom';


type MenuItemPropsType = {
  title: string
  description: string
  to: string
};

const MenuItem = ({ title, description, to }: MenuItemPropsType) => {
  return (
    <Link
      className="container py-4 px-4 lg:px-8 rounded-lg flex w-full shadow-sm bg-secondary-light max-w-6xl my-3 items-center cursor-pointer group"
      to={to}
    >
      <div className="flex flex-1 flex-col">
        <div className="font-semibold text-lg mb-1">
          {title}
        </div>
        <div>
          {description}
        </div>
      </div>
      <BiChevronRightCircle className='ml-2 text-gray-500 group-hover:text-black group-hover:scale-[1.2] transition duration-200' size={24} />
    </Link>
  )
};


const More = () => {
  return (
    <div className="min-h-[50vh] flex flex-1 flex-col my-12 items-center max-w-7xl mx-4 md:mx-6 lg:mx-10">

      <MenuItem
        title="Bibliographies"
        description="Explorez des collections de livres et de ressources"
        to="/catalog/bibliographies"
      />
      <MenuItem
        title="Foire Aux Questions (FAQ)"
        description="Obtenez des reponses aux questions frequemment posees"
        to="/faq"
      />
      <MenuItem
        title="Connexion au Compte"
        description="Connectez vous a votre compte pour profiter pleinement de la plateforme"
        to="/signin"
      />
    </div>
  )
};

export default More;