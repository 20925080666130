import { useLocation, useNavigate } from "react-router-dom";
import { FormationPillarType } from "../../../global/types";
import CustomButton from "../../CustomButton";
import { useContext, useState } from "react";
import { FormationContext } from "../../../contexts/FormationContext";
import { updatePillar } from "../../../utils/formationTreeHandlers";
import { functions } from "../../../App";
import { httpsCallable } from "firebase/functions";
import CustomDialog from "../../CustomDialog";

const pillarInitTemplate:Omit<FormationPillarType, 'modules'> = {
    id:'',
    index:Date.now(),
    title:'',
    name: '',
    description:'',
};

const PillarInfos = () => {
    let state:{pillarInfos:FormationPillarType, initForm:boolean}|null = useLocation().state;
    const navigate = useNavigate();
    const { formationData, setFormationData } = useContext(FormationContext);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    
    if((!state?.pillarInfos) && (!state?.initForm)) {
        navigate('/console')
    }
    
    const initialContent = state?.pillarInfos? state.pillarInfos : pillarInitTemplate; 
    const [pillarInfos, setPillarInfos] = useState<Omit<FormationPillarType, 'modules'>>(initialContent)
    const [loading, setLoading] = useState(false);

    const onSave = async() => {
        if(loading) return;
        setLoading(true);
        if(pillarInfos.flag =='new') {
            try {
                const createFormationPillar = httpsCallable(functions, 'createFormationPillar');
                const createdPillar = (await createFormationPillar({formationId:formationData.id, pillar:pillarInfos})).data as unknown as Omit<FormationPillarType, "modules">;
                // since when creating a pillar in the formation, a new id is generated in the db different from the 
                //current one, we should provide the previous id to allow the pillar data to be retrieved and updated
                if(setFormationData) setFormationData(updatePillar(formationData, createdPillar, pillarInfos.id));
                navigate(-1);
            } catch (error) {
                console.log('Error while creating new pillar: ', error)
            }
        } else {
            try {
                const updateFormationPillar = httpsCallable(functions, 'updateFormationPillar');
                const updatedPillar = (await updateFormationPillar({pillar:pillarInfos})).data as unknown as Omit<FormationPillarType, "modules">;
                if(setFormationData) setFormationData(updatePillar(formationData, updatedPillar));
                navigate(-1);
            } catch (error) {
                console.log('Error while updating pillar: ', error)
            }
        }
        setLoading(false);
    };
    
    const onPillarDelete = async () => {
      const deleteFormationNode = httpsCallable(functions, 'deleteFormationNode');
      try {
        await deleteFormationNode({nodeId:pillarInfos.id, nodeType:'pillar'});
        navigate(-1)
      } catch (error) {
        console.log('Error while deleting the pillar: ', error);
      }
    }
    return (
        <div className="flex flex-1">
            <div className="mt-4 mb-12 mx-auto w-full flex flex-col max-w-7xl ">
                <h2 className="text-4xl font-medium mb-4 self-start">Informations sur le pillier</h2>
                <div className='bg-secondary-light rounded-2xl  px-4 py-8 h-full'>
                    <div className="px-8">
                        <div className='mt-2 mb-1'>
                            Titre du pillier:
                        </div>
                        <input
                            value={pillarInfos.title}
                            onChange={(e) => {
                                setPillarInfos(infos => {
                                    return {
                                        ...infos,
                                        title:e.target.value
                                    }
                                })
                            }}
                            className='textInput'
                            placeholder='Entrez quelque chose...'
                        />
                        <div className='mt-2 mb-1'>
                            Objectif du pillier:
                        </div>
                        <textarea
                            value={pillarInfos.description}
                            onChange={(e) => {
                                setPillarInfos(infos => {
                                    return {
                                        ...infos,
                                        description:e.target.value
                                    }
                                })
                            }}
                            placeholder='Entrez quelque chose...'
                            className="textInput"
                        />
                    </div>
                    <div className="px-8 flex gap-x-4 mt-10">
                        <CustomButton
                            onClick={() => navigate(-1)}
                            title="Annuler les modifications"
                            variant="btnSecondary"
                            className="min-w-max"
                        />
                        <CustomButton
                            onClick={onSave}
                            title={pillarInfos.flag ==='new'? "Créer le pillier": "Enregistrer"}
                            className="min-w-[10rem]"
                            loading={loading}
                        />
                        {
                          pillarInfos.flag !='new'?
                          <CustomButton
                              title="Supprimer"
                              variant="btnDanger"
                              onClick={() => setDeleteDialogOpen(true)}
                              className="w-40 ml-auto"
                          />
                          :null
                        }
                    </div>
                </div>
            </div>
            <CustomDialog
              confirmtButtonTitle="Procéder"
              cancelButtonTitle="Annuler"
              onConfirm={onPillarDelete}
              open={deleteDialogOpen}
              setOpen={setDeleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              dialogType="confirm"
              title="Êtes vous sûr de vouloir supprimer le pillier?"
              message="Si vous confirmez, les modules, cours, chapitres et taches sous ce pillier ne seront plus disponible aussi"
              cancelButton={{variant:'btnSecondary'}}
              confirmButton={{variant:'btnDanger'}}
            />
        </div>
    )
};

export default PillarInfos;