import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'User Time Spent on Platform',
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Date',
      },
    },
    y: {
      title: {
        display: true,
        text: 'Time Spent (hours)',
      },
    },
  },
};

const labels = {
  week: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  month: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
  year: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
};

const weekData = {
  labels: labels.week,
  datasets: [
    {
      label: 'Time Spent (hours)',
      data: [3, 4, 2.5, 5, 6, 7, 4.5],
      borderColor: '#475A23',
      backgroundColor: 'rgba(69, 107, 25, 0.5)',
      pointBackgroundColor: '#A9BF16',
      pointBorderColor: '#2E3B16',
      pointHoverBackgroundColor: '#2E3B16',
      pointHoverBorderColor: '#A9BF16',
    },
  ],
};

const monthData = {
  labels: labels.month,
  datasets: [
    {
      label: 'Time Spent (hours)',
      data: [60, 90, 75, 100],
      borderColor: '#475A23',
      backgroundColor: 'rgba(69, 107, 25, 0.5)',
      pointBackgroundColor: '#A9BF16',
      pointBorderColor: '#2E3B16',
      pointHoverBackgroundColor: '#2E3B16',
      pointHoverBorderColor: '#A9BF16',
    },
  ],
};

const yearData = {
  labels: labels.year,
  datasets: [
    {
      label: 'Time Spent (hours)',
      data: [200, 240, 250, 300, 350, 400, 420, 440, 480, 500, 520, 550],
      borderColor: '#475A23',
      backgroundColor: 'rgba(69, 107, 25, 0.5)',
      pointBackgroundColor: '#A9BF16',
      pointBorderColor: '#2E3B16',
      pointHoverBackgroundColor: '#2E3B16',
      pointHoverBorderColor: '#A9BF16',
    },
  ],
};

const TimeSpent = () => {
    const [view, setView] = useState<'week' | 'month' | 'year'>('week');
  
    let data;
    switch (view) {
      case 'week':
        data = weekData;
        break;
      case 'month':
        data = monthData;
        break;
      case 'year':
        data = yearData;
        break;
      default:
        data = weekData;
    }
  
    return (
      <div className="flex flex-1 flex-col items-center">
        <div className="my-4">
          <button
            className={`mr-2 ${
              view === 'week' ? 'bg-gray-300' : 'bg-gray-100'
            } px-4 py-2 rounded-md`}
            onClick={() => setView('week')}
          >
            Semaine
          </button>
          <button
            className={`mr-2 ${
              view === 'month' ? 'bg-gray-300' : 'bg-gray-100'
            } px-4 py-2 rounded-md`}
            onClick={() => setView('month')}
          >
            Mois
          </button>
          <button
            className={`${
              view === 'year' ? 'bg-gray-300' : 'bg-gray-100'
            } px-4 py-2 rounded-md`}
            onClick={() => setView('year')}
          >
            Annee
          </button>
        </div>
        <Line options={options} data={data} />
      </div>
    );
  }
  
  export default TimeSpent;