import { otherAccountData } from '../../../../../global/types';
import ProfileAddForm from '../../../../../components/ProfileAddForm';


type TeacherFormPropsType = {
    otherAccountData:otherAccountData
};

const TeacherForm:React.FC<TeacherFormPropsType> = ({otherAccountData}) => {
    return (
        <ProfileAddForm
            accountType={['teacher']}
            extraFields={{
                // expertiseDomain:true, teachedCourses:true
            }}
            otherAccountData={otherAccountData}
        />
    );
};


export default TeacherForm;