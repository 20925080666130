import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ScaleChartOptions,
  DatasetChartOptions,
  PluginChartOptions,
  ElementChartOptions,
  CoreChartOptions,
  BarControllerChartOptions,
  ChartDataset,
  LineElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { GlobalFormationType } from '../../../../../../global/types';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function generateColor(index: number): string {
  const natureColors = [
    '#228B22',  // Forest green
    '#8B4513',  // Saddle brown
    '#20B2AA',  // Light sea green
    '#2E8B57',  // Sea green
    '#CD853F',  // Peru
    '#6B8E23',  // Olive drab
    '#4682B4',  // Steel blue
    '#556B2F',  // Dark olive green
    '#008080',  // Teal
    '#8FBC8F',  // Dark sea green
  ];

  return natureColors[index % natureColors.length];
}

export type MarksPerformanceData = {
  pillarName: string;
  pillarTitle: string;
  pillarModules: {
    moduleName: string;
    moduleTitle: string;
    moduleChapters: {
      courseName: string;
      courseTitle: string;
      courseColor: string;
      obtainedMarks: number;
      totalMarks: number;
    }[];
  }[];
}[];

type MarksPerformanceProps = {
  formation: GlobalFormationType;
};

const MarksPerformance = ({ formation }: MarksPerformanceProps) => {
  const maxCoursesPerPillar = formation.pillars.map(pillar => pillar.modules.flatMap(module => module.courses))
    .map(courses => courses.length).concat(0).reduce((prev, current) => Math.max(prev, current));
  const maxCoursesPerModule = formation.pillars.flatMap(pillar => pillar.modules.map(module => module.courses))
    .map(flatModules => flatModules.length).concat(0).reduce((prev, current) => Math.max(prev, current));
  const maxModulesPerPillar = formation.pillars.map(pillar => pillar.modules)
    .map(modules => modules.length).concat(0).reduce((prev, current) => Math.max(prev, current));
  // const genAverageMarks = formation.pillars.map(
  //   pillar => pillar.modules.flatMap(module =>
  //     module.courses.flatMap(
  //       course => course.marks?.globalAvg || 0
  //     )
  //   ).concat(0).reduce((prev, current, index) => ((prev * index) + current) / (index + 1))
  // )


  const datasets: ChartDataset<"bar" | "line", number[]>[] = [];
  for (let moduleIndex = 0; moduleIndex < maxModulesPerPillar; moduleIndex++) {
    // we are adding one to the loop because we want to include an additionnal layer on the chart
    // that will display missed/unobtained marks by the student, under each module
    for (let courseIndex = 0; courseIndex < maxCoursesPerModule + 1; courseIndex++) {
      // Unobtained marks layer
      if (courseIndex === maxCoursesPerModule) {
        const data = formation.pillars.flatMap(
          pillar => pillar.modules.at(moduleIndex)?.courses.map(({ marks }) => marks ? (marks.maxMarks - marks.marks) : 0)
          // ensure the array is non empty before reducing
          .concat(0).reduce((prev, current) => prev + current)
        ).map(value => value!==undefined? value:0);
        
        datasets.push({
          data: data,
          borderColor: '#555555',
          backgroundColor: '#55555550',
          borderWidth: 2,
          stack: 'Module ' + (moduleIndex + 1),
        })
        continue;
      };

      const data = formation.pillars.flatMap(
        pillar => pillar.modules.at(moduleIndex)?.courses.at(courseIndex)?.marks?.marks || 0
      );
      datasets.push({
        data: data,
        borderColor: generateColor(moduleIndex), // courses under the same module have the same color
        backgroundColor: generateColor(moduleIndex) + '50',
        borderWidth: 2,
        stack: 'Module ' + (moduleIndex + 1),
      })
    }
  };
  
  // // add the averages to be represented as line
  // datasets.push({
  //   data: genAverageMarks,
  //   borderColor: '#475A23',
  //   backgroundColor: '#475A2350',
  //   order: 1,
  //   type: 'line',
  //   stack: 'Moyenne Générale du Pillier',
  //   pointRadius: 6,
  //   pointHoverRadius: 10,

  // });

  const data: ChartData<'bar' | 'line', number[], string> = {
    // each pillar should have its dataset and each module should have its own stack !!!. Hence, use an array
    // of modules courses instead of a array of plain numbers
    datasets: datasets,
    labels: formation.pillars.map(pillar => pillar.title),
  };

  const newOptions: _DeepPartialObject<CoreChartOptions<"bar">
    & ElementChartOptions<"bar"> & PluginChartOptions<"bar"> &
    DatasetChartOptions<"bar"> & ScaleChartOptions<'bar'> & BarControllerChartOptions> = {
    elements: {
      bar: {
        borderWidth: 2,
        borderColor: 'rgba(0,0,0,0)',
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Graphique des notes',
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (model) => {
            return model[0].dataset.stack;
          },
          label: (tooltipItem) => {
            const pillarIndex = tooltipItem.dataIndex;
            const courseIndex = tooltipItem.datasetIndex;
            const value = tooltipItem.parsed.y;
            const moduleCourses = formation.pillars[pillarIndex].modules.flatMap(module => module.courses);
            if (courseIndex === maxCoursesPerModule) return `Points manqués: ${value}`;

            const course = moduleCourses.find((item, index) => index === courseIndex);
            if (!course) return 'Non disponible';

            return `${course.title} : ${value + ' / ' + (course.marks?.maxMarks || value)}`;
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { stacked: true }
    }
  }

  return (
    <div className="flex flex-col flex-1 w-full">
      <Bar
        // @ts-ignore
        data={data}
        options={newOptions}
      />
      <div className="mx-auto mt-8">Nombre de points acquis par module</div>
    </div>
  );
};

export default MarksPerformance;