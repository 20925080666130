import { useState } from "react";
import CustomButton from "../../components/CustomButton";
import { functions } from "../../App";
import { httpsCallable } from "firebase/functions";
import { useLocation, useNavigate } from "react-router-dom";


const UpdatePassword = () => {
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState<string|null>(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tempCode = queryParams.get('code');
    const userId = queryParams.get('userId')

    const heroImg = require('../Home/assets/heroImg.jpg');

    const onUpdatePassword = async () => {
        if(!tempCode || !userId) return;
        if (loading) return;
        setError(null);

        setLoading(true);
        try {
            const updateUserPassword = httpsCallable(functions, 'updateUserPassword');
            const result = (await updateUserPassword({ uid: userId, tempCode, password })).data;
            console.log('Updated user id: ', result);
            navigate('/signin')
        } catch (error:any) {
            console.log('An error occured updating password: ', error);
            if(error.code ==='unavailable'||'failed-precondition') {
              setError(error.message)
            } else {
              setError('Une erreur est survenue')
            }
        }
        setLoading(false);
    };

    return (
        <div style={{ backgroundImage: `url(${heroImg})` }} className=" relative bg-cover flex flex-1">
            <div className="backdrop-brightness-[30%] flex flex-1 items-center justify-center">
                <div className="border border-primary-light shadow-inner w-92 h-72 rounded-[2rem] px-8 py-4 my-12 bg-white ">
                    <p className="text-2xl font-medium mb-4">
                        Mettre a jour le mot de passe
                    </p>
                    <div className="" >
                        <p>Entrez votre nouveau mot de passe</p>
                        <input
                            type="password"
                            className="textInput mt-4"
                            required
                            placeholder="mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className={"text-sm text-red-500 mt-8 "+ (!error&&' opacity-0')}>{error||'No Error'}</div>
                        <CustomButton
                            loading={loading}
                            onClick={onUpdatePassword}
                            title="Continuer"
                            className="w-full"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default UpdatePassword;