import moment from "moment";
import { GlobalFormationType } from "../global/types";


export function notUndefined<T>(value: T | undefined): value is T {
  return value !== undefined;
};

export const getFormationPeriod = (startDate: string, endDate: string) => {
  return `du ${moment(startDate).format('DD/MM/YYYY')} au ${moment(endDate).format('DD/MM/YYYY')}`
}

type textWithCutoffProps = {
  text: string;
  maxLength: number;
}

export const textWithCutoff = ({ text, maxLength }: textWithCutoffProps): string => {
  if (text.length > maxLength) {
    const visibleText = text.slice(0, maxLength);
    const lastSpaceIndex = visibleText.lastIndexOf(' ');
    const cutOffText = lastSpaceIndex === -1 ? visibleText : visibleText.slice(0, lastSpaceIndex);
    return `${cutOffText}...`;
  }
  return text;
}

export const sumArray: (arr: number[], index: number) => number = (arr, index) => {
  if (index === arr.length) {
    return 0;
  }
  return arr[index] + sumArray(arr, index + 1);
}

export const richTextToPlainText: (richText: string) => string = (richText) => {
  return richText.replace(/<[^>]+>/g, '');
}

function generateColor(index: number): string {
  const colors = [
    'rgb(255, 99, 132)',  // red
    'rgb(75, 192, 192)',  // green
    'rgb(255, 205, 86)',  // yellow
    'rgb(54, 162, 235)',  // blue
    'rgb(153, 102, 255)', // purple
    'rgb(255, 159, 64)',  // orange
  ];

  return colors[index % colors.length];
}

type getStudentGraphMarksPropsType = GlobalFormationType;

type getStudentGraphMarksReturnType = {
  pillarName: string;
  pillarTitle: string;
  pillarModules: {
    moduleName: string;
    moduleTitle: string;
    moduleChapters: {
      courseName: string;
      courseTitle: string;
      courseColor: string;
      obtainedMarks: number;
      totalMarks: number;
    }[];
  }[];
}[];

export const getStudentGraphMarks: (data: getStudentGraphMarksPropsType) => getStudentGraphMarksReturnType
  = (data) => {
    let spreadedObjArray = data.pillars.flatMap(pillar => 
      pillar.modules.flatMap((module, index) => ({
        pillarName: pillar.name,
        pillarTitle: pillar.title,
        pillarModules: module.courses.flatMap(course => ({
          moduleName: module.name,
          moduleTitle: module.title,
          moduleChapters: course.chapters.flatMap(chapter => ({
            courseName: course.name,
            courseTitle: course.title,
            courseColor: generateColor(index) || '#333333', // Default color if not specified
            obtainedMarks: course.marks?.marks || 0, // Default to 0 if obtainedMarks is not specified
            totalMarks: course.marks?.maxMarks || 100 // Default to 100 if totalMarks is not specified
          }))
        }))
      }))
    );

    // The following code section allows us to stack the chapters of a module instead of spreading them like previously.
    // This is achieved by transposing the chapters array between modules
    let stackedObjArray: getStudentGraphMarksReturnType = [];
    for (let chapterIndex = 0; chapterIndex < spreadedObjArray[0].pillarModules[0].moduleChapters.length; chapterIndex++) {
      const newModuleChapters = spreadedObjArray.map(spreadedModule => {
        const moduleChapters = spreadedModule.pillarModules.flatMap(pillarModule => {
          if (chapterIndex >= pillarModule.moduleChapters.length) {
            return {
              courseName: '',
              courseTitle: '',
              courseColor: '',
              obtainedMarks: 0,
              totalMarks: 0,
            };
          } else {
            return pillarModule.moduleChapters[chapterIndex];
          }
        });
        return {
          moduleName: spreadedModule.pillarModules[0].moduleName, // Assuming all modules have the same name
          moduleTitle: spreadedModule.pillarModules[0].moduleTitle, // Assuming all modules have the same title
          moduleChapters: moduleChapters,
        };
      });
      const newPillarModules = {
        pillarName: spreadedObjArray[0].pillarName, // Assuming all pillars have the same name
        pillarTitle: spreadedObjArray[0].pillarTitle, // Assuming all pillars have the same title
        pillarModules: newModuleChapters,
      };
      stackedObjArray.push(newPillarModules);
    }
    return stackedObjArray;
  };


type getStudentGraphCompletedChaptersPropsType = {
  name: string;
  title: string;
  modules: {
    name: string;
    title: string;
    courses: {
      name: string;
      title: string;
      color: string;
      chapters: {
        title: string;
        name: string;
        obtainedMarks: number;
        complete: boolean;
      }[];
    }[];
  }[];
}[]

type getStudentGraphCompletedChaptersReturnType = {
  pillarName: string;
  pillarTitle: string;
  pillarChapters: {
    moduleName: string;
    moduleTitle: string;
    courseName: string;
    courseTitle: string;
    courseColor: string;
    chapterName: string;
    chapterTitle: string;
    chapterComplete: boolean;
  }[];
}[];

export const getStudentGraphCompletedChapters = (data: getStudentGraphCompletedChaptersPropsType) => {
  const formattedData: getStudentGraphCompletedChaptersReturnType = data.map(pillar => {
    return {
      pillarName: pillar.name,
      pillarTitle: pillar.title,
      pillarChapters: pillar.modules.flatMap(module => module.courses.flatMap(course =>
        course.chapters.map(chapter => {
          return {
            moduleName: module.name, // add moduleName property
            moduleTitle: module.title, // add moduleTitle property
            courseName: course.name,
            courseTitle: course.title,
            courseColor: course.color,
            chapterName: chapter.name,
            chapterTitle: chapter.title,
            chapterComplete: chapter.complete
          }
        })
      ))
    }
  });
  return formattedData;
};


