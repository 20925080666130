import CustomButton from "../../../../components/CustomButton";
import { useEffect, useState } from 'react';
import CustomRichEditor from "../../../../components/CustomRichEditor";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import moment from "moment";
import { functions, storage } from "../../../../App";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Pretest } from "../../../../global/types";



const PretestAdd = () => {
  const navigate = useNavigate();
  const state: { pretestData: Pretest | undefined } | null = useLocation().state;

  
  const initialValues: Pretest = {
    title: "",
    description: "",
    startDate: moment().toDate(),
    endDate: moment().add({ days: 1 }).toDate(),
    duration: 60, // 60 minutes,
    id: Date.now().toString(),
    about: ''
  };

  const initialContent = state?.pretestData ? { ...initialValues, ...state.pretestData } : initialValues
  const [pretestInfos, setPretestInfos] = useState(initialContent);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState("");

  const checkFormValid = () => {
    setFormError("");
    if(!pretestInfos.title) setFormError("Vous spécifier un titre pour le pré-test");
    return Boolean(pretestInfos.title);
  };
   
  
  const onPretestInit = async () => {
    if (loading || !checkFormValid()) return;
    setLoading(true);


    try {
      const initPretest = httpsCallable(functions, 'initPretest');
      const pretest = (await initPretest({
        pretest:{
        ...pretestInfos,
        startDate: pretestInfos.startDate.toISOString(),
        endDate: pretestInfos.endDate.toISOString()
      }})).data as Pretest;
      
      navigate('/pre-tests/'+pretest.id, {state:{pretest}, replace:true});
    } catch (error) {
      console.log('An error occured while initializing the pretest')
    }
    setLoading(false);
  };

  return (
    <div className="mt-4 mb-10 mx-auto flex flex-1 flex-col max-w-7xl ">
      <h2 className="text-4xl font-medium ml-4 mb-6 self-start">Créer un Pré-Test</h2>
      <div className="p-3  lg:p-6 rounded-2xl flex flex-1 flex-col bg-secondary-light">
        <div className="w-full max-w-6xl mx-auto">
          <div className="text-2xl font-medium mb-4 self-start flex justify-between items-center">
            Général
          </div>
          <div className='mt-2 mb-1'>
            Titre du pré-test:
          </div>
          <input
            value={pretestInfos.title}
            onChange={(e) => setPretestInfos(infos => ({ ...infos, title: e.target.value }))}
            className='textInput'
            placeholder='Entrez quelque chose...'
          />
          <div className="flex flex-col lg:flex-row gap-x-8">
            <div className="w-full">
              <div className='mt-2 mb-1'>
                Date de début:
              </div>
              <CustomDatePicker
                value={pretestInfos.startDate}
                onChange={(date) => setPretestInfos(infos => ({ ...infos, startDate: date }))}
                initialValue={moment().toDate()}
              />
            </div>
            <div className="w-full">
              <div className='mt-2 mb-1'>
                Date de Fin:
              </div>
              <CustomDatePicker
                value={pretestInfos.endDate}
                onChange={(date) => setPretestInfos(infos => ({ ...infos, endDate: date }))}
                initialValue={moment().add({ days: 1 }).toDate()}
              />
              <div className='mt-2 mb-1'>
                Durée de l'évaluation (minutes):
              </div>
              <input
                value={pretestInfos.duration}
                onChange={(e) => setPretestInfos(infos => ({ ...infos, duration: Number(e.target.value) }))}
                className='textInput'
                type="number"
                placeholder='Entrez quelque chose...'
              />
            </div>

          </div>
            <div className='mt-2 mb-1'>
              À propos:
            </div>
            <textarea
              className="textInput"
              value={pretestInfos.about}
              onChange={(e) => setPretestInfos(infos => ({ ...infos, about: e.target.value }))}
            />
            <div className='mt-2 mb-1'>
              Description du pré-test:
            </div>
            <CustomRichEditor
              value={pretestInfos.description}
              onChange={(value) => setPretestInfos(infos => ({ ...infos, description: value }))}
            />


          {
            formError ?
              <div className="mt-4 text-red-600 font-medium">
                {formError}
              </div>
              :
              null
          }
          <div className="flex ml-auto justify-end px-8 gap-x-8 mt-6 max-w-lg">
            <CustomButton
              title="Annuler"
              variant="btnDanger"
              className="w-40"
              onClick={() => navigate(-1)}
            />
            <CustomButton
              loading={loading}
              onClick={onPretestInit}
              title="Enregistrer"
              className="w-48"
            />
          </div>
        </div>
      </div>
    </div>

  );
}


export default PretestAdd;