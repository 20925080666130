export const getFileUploadSource = (file: File):Promise<{
  type: string;
  extension: string;
  base64: string;
}> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            if (event.target) {
                const dataUrl = event.target.result;
                // @ts-ignore
                const base64 = dataUrl?.split(',')[1];
                // @ts-ignore
                const mimeType = dataUrl?.split(';')[0].split(':')[1];
                // Get the file extension from the file name
                const extension = file.name.split('.').pop();
                resolve({ type: mimeType, extension:extension||'', base64 });
            }
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
};
