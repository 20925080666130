import { Link, useNavigate } from "react-router-dom";
import { BibliographyType, UserType } from "../../../global/types";
import { useContext, useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "../../../App";
import { TbPencilMinus } from 'react-icons/tb'
import CustomButton from "../../../components/CustomButton";
import { AuthContext } from "../../../contexts/AuthContext";
import { collection, getDocs, query } from "firebase/firestore";
import { ImBooks } from "react-icons/im";



type BibliographyItemProps = {
  bibliography: BibliographyType;
  currentUser: UserType | null
};

const BibliographyItem = ({ bibliography, currentUser }: BibliographyItemProps) => {
  return (
    <Link
      className="container py-4 px-8 rounded-lg flex w-full shadow-sm bg-slate-50 max-w-6xl my-3 items-end cursor-pointer group"
      to={bibliography.id}
    >
      <div className="flex flex-1 flex-col">
        <div className="font-semibold text-lg mb-1">
          {bibliography.title}
        </div>
        <div
          className="prose prose-sm text-gray-900 prose-a:text-blue-500"
          dangerouslySetInnerHTML={{__html:bibliography.description}}
        />
      </div>

      {
        ['admin'].some((item: any) => currentUser?.accountType.includes(item)) ?
          <Link to={'add'} state={{ bibliography }} className="px-2 py-1 transition rounded-full hover:bg-secondary hover:bg-opacity-25">
            <TbPencilMinus size={24} />
          </Link>
          :
          null
      }
      <div className="px-2 py-1">
        <ImBooks className='text-gray-500 group-hover:text-black transition duration-200' size={24} />
      </div>
    </Link>
  )
};


// const testBibls: BibliographyType[] = [
//   {
//     createdAt: new Date().toISOString(),
//     description: ` Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae numquam, omnis quae voluptates repellendus`,
//     title: 'Corporis nesciunt suscipit consequatur enim deserunt',
//     id: 'sdihsdlaj'
//   },
//   {
//     createdAt: new Date().toISOString(),
//     description: 'Quo nemo maiores soluta, accusamus repellat, magni pariatur blanditiis perferendis.',
//     id: '93bdsaihap',
//     title: 'I am the Storm that is approaching'
//   },
//   {
//     createdAt: new Date().toISOString(),
//     description: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt provident assumenda, possimus et aperiam corrupti aliquid recusandae quibusdam quasi in voluptatem. ',
//     id: '09uesdnl',
//     title: 'eum perspiciatis odit laboriosam eveniet inventore explicabo maxime, incidunt'
//   },
//   {
//     createdAt: new Date().toISOString(),
//     description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dignissimos cupiditate unde dolor assumenda maxime quos aspernatur repellendus, pariatur animi natus consequatur! ',
//     id: '0ncso8LKndsn',
//     title: 'Dolorum repudiandae nulla necessitatibus, ratione voluptates'
//   }
// ];


const Bibliographies = () => {
  const navigate = useNavigate();

  const user = useContext(AuthContext)?.user;
  const [loading, setLoading] = useState(false);
  const [bibliographies, setBibliographies] = useState<BibliographyType[]>([]);


  // const getAndSetBibliographies = async () => {
  //   if (loading) return;
  //   setLoading(true);
  //   try {
  //     const getBibliographies = httpsCallable(functions, 'getBibliographies');
  //     const result = (await getBibliographies()).data as {bibliographies:BibliographyType[]};
  //     setBibliographies(result.bibliographies);
  //     console.log('Bibliographies: ', bibliographies);
  //   } catch (error) {
  //     console.log('Error while getting bibliographies: ', error);
  //   }
  //   setLoading(false);
  // };

  const getAndSetBibliographies = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const q = query(collection(db, 'bibliographies'));

      const bibliographiesSnaps = await getDocs(q);
      const bibliographies: BibliographyType[] = bibliographiesSnaps.docs.map(snap => {
        const snapData = snap.data();
        return ({
          id: snap.id,
          createdAt: snapData.createdAt,
          description: snapData.description,
          title: snapData.title
        })
      });

      setBibliographies(bibliographies);
    } catch (error) {
      console.log('Error while getting bibliographies: ', error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getAndSetBibliographies();
  }, []);

  return (
    <div className="flex flex-1">
      <div className="mt-4 mb-12 px-2 py-4 md:p-4 mx-auto rounded-2xl w-full flex flex-col max-w-6xl bg-secondary-light">
        <div className="flex flex-col gap-2">
          <h3 className="text-xl lg:text-3xl font-semibold">
            Bibliographies
          </h3>
          <div>
            {(!loading && bibliographies.length === 0) ?
              <div className="py-12 mx-12 flex items-center justify-center flex-col h-[54vh]">
                <h1 className="text-2xl lg:text-3xl xl:text-4xl my-8 font-bold text-center text-gray-400">
                  Aucune Bibliographie n'a été trouvée.
                </h1>
              </div>
              :
              bibliographies.map(bibliography => (
                <BibliographyItem
                  key={bibliography.id}
                  currentUser={user || null}
                  bibliography={bibliography}
                />
              ))
            }
          </div>
        </div>
        <div className='flex justify-end mt-8 mb-4 mx-2'>
          {
            ['admin'].some((item: any) => user?.accountType.includes(item))?
            <CustomButton
              variant='btnSecondary'
              title='Initier une Bibliographie'
              className='w-72'
              navigateTo='add'
            />
            :
            null
          }
        </div>
      </div>
    </div>
  )
};

export default Bibliographies;