import { UserType } from "../../../../global/types";

type YoutubeVideoPropsType = {
    videoId:string|null;
    user:UserType;
};

const YoutubeVideo:React.FC<YoutubeVideoPropsType> = ({videoId}) => {
    return (
        <div className="aspect-video my-2 shadow rounded-xl overflow-hidden">
            <iframe
                src={"https://www.youtube.com/embed/"+videoId}
                title="Underwater OnePlus 7 Pro Review"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="w-full aspect-video object-contain"
            >

            </iframe>
        </div>
    )
};

export default YoutubeVideo;