import ProfileViewForm from "../../../../../components/ProfileViewForm";
import { UserType, otherAccountData } from "../../../../../global/types";

type AdminFormPropsType = {
    user?:UserType
    otherAccountData:otherAccountData
};
;
const ExpertForm:React.FC<AdminFormPropsType> = ({user, otherAccountData}) => {
    return (
        <ProfileViewForm
            extraFields={{expertiseDomain:true}}
            accountType={["expert"]}
            user={user}
            otherAccountData={otherAccountData}
        />
    );
};


export default ExpertForm;