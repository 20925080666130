import { UserType } from "../../../../global/types";

type VideoPlayerPropsType = {
    videoFileLink?:string;
    user:UserType;
    hasUserDoneTask:boolean|undefined
};

const VideoPlayer:React.FC<VideoPlayerPropsType> = ({videoFileLink}) => {
  console.log('Video file link: ', videoFileLink)  
  return (
        <div className="max-h-[80vh] my-2">
            <video src={videoFileLink}  className="rounded-xl w-full h-full object-contain" controls autoPlay>
            </video>
        </div>
    )
};

export default VideoPlayer;