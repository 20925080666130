import StudentForm from './components/Form';
import { otherAccountData } from '../../../../global/types';
import { useState } from 'react';
import Administration from '../../../../components/Administration';

const AdminAdd = () => {
    const [otherAccountData, setOtherAccountData] = useState<otherAccountData>({accountOptions:{state:'enabled', emailVisibility:'public'}})
    
    return (
        <div className="flex flex-1 flex-col">
            <div className="mt-8 mb-12 mx-auto w-full flex flex-col max-w-7xl">
                <h2 className="text-4xl font-medium ml-4 mb-8 self-start">Ajouter administrateur</h2>
                    <div className='flex flex-col lg:flex-row gap-8'>
                    <div className='bg-secondary-light p-3 md:p-8 rounded-2xl flex flex-[5] h-min'>
                        <StudentForm
                            otherAccountData={otherAccountData}
                        />
                    </div>
                    <div className='flex flex-[2] flex-col gap-y-8'>
                        <Administration
                            otherAccountData={otherAccountData}
                            setOtherAccountData={setOtherAccountData}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminAdd;