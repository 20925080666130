import { createContext, useState } from "react";
import { GlobalFormationType } from "../global/types";
import moment from "moment";

let initialFormationContent:GlobalFormationType = {
    id:'formation' + Date.now().toString(),
    flag:'new',
    title:'Entrez le titre de la formation...',
    name:'',
    description:'',
    longDescription:'',
    pillars:[],
    imageFile:null,
    startDate:moment().format('YYYY-MM-DD'),
    endDate:moment().add({years:1}).format('YYYY-MM-DD')
};

type FormationContextConsumed = {
    formationData:GlobalFormationType;
    setFormationData:React.Dispatch<React.SetStateAction<GlobalFormationType>>|null;
    expandedKeys:React.Key[];
    setExpandedKeys:React.Dispatch<React.SetStateAction<React.Key[]>>|null;
}

export const FormationContext = createContext<FormationContextConsumed>
({formationData:initialFormationContent, setFormationData:null, expandedKeys:[], setExpandedKeys:null});


const FormationContextProvider:React.FC<React.PropsWithChildren> = ({children}) => {
    const [formationData, setFormationData] = useState(initialFormationContent);
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    
    return (
        <FormationContext.Provider
            value={{
                formationData,
                setFormationData,
                expandedKeys,
                setExpandedKeys
            }}
        >
            {children}
        </FormationContext.Provider>
    )
};

export default FormationContextProvider;