import { otherAccountData } from '../../../../../global/types';
import ProfileAddForm from '../../../../../components/ProfileAddForm';


type AdminFormPropsType = {
    otherAccountData:otherAccountData
};

const AdminForm:React.FC<AdminFormPropsType> = ({otherAccountData}) => {
    return (
        <ProfileAddForm
            accountType={['admin']}
            extraFields={{}}
            otherAccountData={otherAccountData}
        />
    );
};


export default AdminForm;