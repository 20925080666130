import { useContext, useEffect, useState } from 'react';
import PillarCard from './components/PillarCard';
import Tree from './components/Tree';
import { BsGearFill } from 'react-icons/bs';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../App';
import { GlobalFormationType } from '../../../global/types';
import { AuthContext } from '../../../contexts/AuthContext';
import { sortByIndexes } from '../../../utils/formationTreeHandlers';
import moment from 'moment';

const Formation = () => {
    const user = useContext(AuthContext)?.user;

    // const [focusedNode, setFocusedNode] = useState<FocusObj>({pillar:undefined, module:undefined, course:undefined});
    const [formation, setFormation] = useState<GlobalFormationType>(
      {
        description:'',
        id:'',
        longDescription:'',
        name:'',
        pillars:[],
        title:'Chargement...',
        startDate:moment().format('YYYY-MM-DD'),
        endDate:moment().add({years:1}).format('YYYY-MM-DD')
      }
    );

    const [loading, setLoading] = useState(false);

    const {formationId} = useParams();
    
    const [searchParams, setSearchParams] = useSearchParams();
    
    const focusedNode = {
        pillar:searchParams.get('pillar'),
        module:searchParams.get('module'),
        course:searchParams.get('course'),
    };
    
    const getFormationData = async () => {
        if(loading) return;
        setLoading(true);
        try {
            // console.log('Focus object: ', focusedNode);
            const getFormationTreeById = httpsCallable(functions, 'getFormationTreeById');
            const formationTree = (await getFormationTreeById({id:formationId})).data as GlobalFormationType;
            setFormation(sortByIndexes(formationTree));
            console.log('Formation: ', sortByIndexes(formationTree));
        } catch (error) {
            console.log("Error while getting formation by id", error)
        }
        setLoading(false);
    };

    // we can have focusedNode.pillar != null but have focusedPillar == null, if the pillar was not found.
    // So, it is better to check directly for focusedPillar value
    const focusedPillar = formation.pillars.find(item => item.id === focusedNode?.pillar)||formation.pillars.at(0)||null;
    useEffect(() => {
        getFormationData();
    }, []);
    return (
        <div className="flex flex-1 flex-col">
            <div className="mt-8 mb-12 mx-auto w-full flex flex-col max-w-7xl">
                <h2 className="flex gap-x-4 text-4xl font-medium mb-8 items-center px-4">
                    {formation.title}
                    <Link
                        state={{formation:loading?null:formation}}
                        to='manage'
                        style={{display:['admin'].some((item:any) => user?.accountType.includes(item))?'flex':'none'}}
                        title='Gerer la formation'
                        className='rounded-full border border-secondary bg-secondary-light p-1 cursor-pointer'
                    >
                        <BsGearFill size={24} />
                    </Link>
                </h2>
                <div className='flex flex-col-reverse lg:flex-row gap-6'>
                    <div className='bg-secondary-light md:px-2 py-4 rounded-2xl flex flex-[5] h-min'>
                        {
                            focusedPillar !=null ?
                                <PillarCard
                                    user={user}
                                    focusedNode={focusedNode}
                                    pillar={focusedPillar}
                                />
                                :
                                <div>
                                    {loading?"Chargement...":"Impossible d'afficher le pillier"}
                                </div>
                        }
                    </div>
                    <div className='flex flex-[3] flex-col gap-y-8'>
                        <div className='sticky top-28'>
                            <Tree
                                formation={formation}
                                focusedNode={focusedNode}
                                setFocusedNode={setSearchParams}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Formation;