import { IoCaretForwardCircle } from "react-icons/io5";
import { Link, useLocation, useParams } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import { GlobalFormationType } from "../../../global/types";
import { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "../../../App";
import moment from "moment";
import { collection, getDocs, query, where } from "firebase/firestore";

type PreviewCardPropsType = {
  title: string;
  description: string;
  imageUrl?: string | undefined;
  // duration: string;
};

const templateFormationDescriptionHTML = 
    `<span className="prose prose-base text-black">
        Une ­bonne mise en œuvre de l’observation indépendante des ressources forestières implique de disposer de ressources humaines qualifiées, issues des différents dispositifs de formation. Compte tenu de l’écart entre la demande de compétences professionnelles de qualité et l’offre de service des différents intervenants du secteur, il est nécessaire de mettre en place un programme de formation qui met en avant une approche centrée sur les compétences. Cela implique une bonne connaissance du métier d’observateur indépendant. L’élaboration d’un référentiel métier représente une approche pertinente pour y parvenir.
        Rappelons ici qu’un référentiel métier participe d’une démarche prospective et d’un questionnement sur la relation entre l’emploi et la formation. Cette démarche se fait à travers le recensement et l’analyse des activités constitutives des métiers (ou emplois-types), des compétences requises en situation de travail et des capacités à développer par les professionnels.
        Afin d’inventorier l’ensemble des compétences dont doit disposer un Observateur Indépendant des ressources forestières, les éléments ci-dessous ont servis de fil conducteur :
        <ul>
            <li> identification et description du métier visé par la formation ; </li>
            <li> identification et description des fonctions et activités du métier ; </li>
            <li> identification et description des compétences du métier.</li>
            Il est ainsi apparu qu’un Observateur Indépendant des ressources forestières doit être capable de:
            <li> décrire les ressources forestières, leur gestion et les enjeux forestiers du monde contemporain ;</li>
            <li> analyser les enjeux de l’application de la loi dans le cadre de la gestion des ressources forestières ; </li>
            <li> discuter les approches méthodologiques, les outils et les contours du suivi de la gestion des ressources forestières. </li>
            De ce qui précède, il a découlé que la formation des observateurs indépendants des ressources forestières en cours d’élaboration repose sur trois principaux piliers qui sont :
            <li> la foresterie de base ; </li>
            <li> la gouvernance, législation, responsabilités sociétale des entreprises du secteur forestier ; </li>
            <li> la pratique et l’éthique du suivi de la gestion des ressources forestières.</li>
        </ul>
        Pour chaque pilier, des modules de cours ont été identifiés. Chaque module est constitué d’un ensemble de cours. Pour chaque cours, un temps a été alloué à sa dispensation à distance et en présentiel. La durée de chaque cours est également répartie en cours théoriques, travaux pratiques et travaux personnels de l’apprenant.
        A la fin de la formation, l’apprenant est appelé rédiger et éventuellement à présenter un rapport de mission d’observation indépendante des ressources forestières. Ce rapport qui est un exercice de première importance pour la formation amène l’étudiant à retourner en entreprise et lui offre l’occasion de se pencher sur son avenir professionnel.
    </span>`


const PreviewCard = ({ imageUrl, title }: PreviewCardPropsType) => {
  return (
    <div className="bg-white p-2 my-2 lg:my-4 rounded-3xl border border-primary-light cursor-pointer">
      <img
        className=" rounded-t-3xl rounded-b-md border border-gray-200"
        alt=""
        src={imageUrl ? imageUrl : require('./formation_placeholder.png')}
      />
      <p className="mx-3 my-2 text-xl font-medium">{title}</p>
    </div>
  )
};
type FormationStateType = {
  formations: Omit<GlobalFormationType, 'pillars'>[] | undefined
} | null
const Formation = () => {
  let state: FormationStateType = useLocation().state;
  const { formationId } = useParams();
  const [formations, setFormations] = useState(state?.formations ? state.formations : []);
  const [loading, setLoading] = useState(false);


  const getAndSetFormations = async () => {
    setLoading(true);
    try {
      const q = query(collection(db, 'formations'), where('endDate', '>=', moment().format('YYYY-MM-DD')));

      const formationsSnaps = await getDocs(q);
      const formations:GlobalFormationType[] = formationsSnaps.docs.map(snap => {
        const snapData = snap.data();
        return ({
          id:snap.id,
          description:snapData.description,
          endDate:snapData.endDate,
          longDescription:snapData.longDescription,
          name:snapData.name,
          pillars:[],
          startDate:snapData.startDate,
          title:snapData.title,
          imageUrl:snapData.imageUrl
        })
      });
      
      setFormations(formations)
    } catch (error) {
      console.log('Error while fetching list of formations: ', error);
    }
    setLoading(false);
  };


  const formation = formations.filter(item => item.id === formationId)[0]

  useEffect(() => {
    getAndSetFormations()
  }, []);
  return (
    <div className="flex flex-1 flex-col w-1/3 py-8 px-2 md:px-6 lg:px-12">
      <div className="flex flex-col w-full max-w-6xl mx-auto bg-secondary-light rounded-2xl py-8">
        <p className="text-3xl font-medium mb-6 mx-6 md:mx-12">
          {formation.title}
          <span className="text-xl text-gray-500">
            {' '}Du{' '}{moment(formation.startDate).format('D MMM YYYY')}
            {' '}Au{' '}{moment(formation.endDate).format('D MMM YYYY')}
          </span>
        </p>
        {
          formation.imageUrl?
          <img className=" mx-6 md:mx-12 rounded-t-3xl rounded-b-lg border border-gray-200 aspect-video object-cover" alt={formation.title} src={formation.imageUrl} />
          :
          null
        }
        <div className="mx-6 md:mx-12">
          <div className="mt-4 text-lg">
            {formation.description}
          </div>
          {/* <div dangerouslySetInnerHTML={{__html:innerHTML}} /> */}
          <div className="mt-8">
            <p className="text-3xl mb-2">Presentation:</p>
            <div
              dangerouslySetInnerHTML={{ __html: formation.longDescription }}
              className="prose-a:text-blue-500 prose-a:underline prose-a:underline-offset-2"
            />
          </div>
        </div>
        {/* <div className="mx-10">
                    <CustomButton
                        title="S'enroler"
                        className="mt-10 w-full"
                        onClick={(e) => {
                            window.location.href = "mailto:cruzorbladex@gmail.com";
                            e.preventDefault();
                        }}
                    />
                </div> */}
      </div>
      
      {
        formations.filter(item => item.id !=formationId).length!=0?
        <div className="flex flex-1 min-w-[25%] max-w-6xl mx-auto flex-col lg:bg-background-light p-1 lg:p-4 mt-8 lg:mt-0">
          <div className="text-3xl mt-4" >
            Consultez Aussi...
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6">
            {
              formations.filter(item => item.id !=formationId).map(item => <PreviewCard key={item.title} {...item} />)
            }
          </div>
        </div>
        :null
      }
    </div>
  )
};

export default Formation;