import { ErrorMessage, Field } from "formik";
import { useState } from 'react';
import { HiOutlineUpload } from "react-icons/hi";

type CustomFileInputPropsType = {
    value?:string | number | readonly string[] | undefined
    accept?:string
    onChange?:React.ChangeEventHandler<HTMLInputElement>
    className?:string
    name?:string
    placeholder?:string
    label?:React.ReactNode;
    empty?:boolean
}

export const CustomFileInput:React.FC<CustomFileInputPropsType> = ({accept, empty, className, value, label, name, placeholder, onChange,}) => {
    const [filename, setFilename] = useState('')
    return (
        <label>
            {label}
            <div className={"textInput !h-[42px] !p-0.5 !flex items-center cursor-pointer "+className}>
                <div className=" mr-1 h-full aspect-video bg-slate-200 rounded-md">
                    <HiOutlineUpload className="m-auto" size={30} />
                </div>
                <span
                    className={"mx-2 "+ (filename?"text-gray-700":"text-gray-400")}
                >
                    {(filename && !empty)? filename: placeholder}
                </span>
            </div>
            <input
                value={value}
                accept={accept}
                style={{display:'none'}}
                onChange={(e) => {
                    setFilename(e.target.files?.item(0)?.name ||'');
                    if(onChange) onChange(e);
                }}
                name={name}
                placeholder={placeholder}
                type="file"
            />
        </label>
    )
};

type CustomInputPropsType = {
    label?:string
    type?:string
    name:string
    autocomplete?:"on"|"off"
    className?:string
    inputClassName?:string
    labelClassName?:string
    errorClassName?:string
    placeholder:string
    as?:string
    multiline?:boolean
    rows?:number
    disabled?:boolean
    hidden?:boolean
};

const CustomInput = ({
    name,
    type,
    label,
    className,
    autocomplete,
    inputClassName,
    labelClassName,
    errorClassName,
    placeholder,
    as,
    multiline,
    rows,
    disabled=false,
    hidden=false

}:CustomInputPropsType) => {
    return (
        <div className={className+" w-full"}>
            {label?<label className={labelClassName} htmlFor={name}>{label}</label>:null}
            
            <Field
                disabled={disabled}
                as={as}
                multiline={multiline}
                rows={rows}
                type={type}
                name={name}
                placeholder={placeholder}
                autocomplete={"off"}
                className={"customInput "+ (hidden? 'inputHidden ':'') +inputClassName}
            />
            <ErrorMessage
                component="div"
                name={name}
                className={"text-red-500 text-sm "+ errorClassName}
            />
          </div>
    )
};

export default CustomInput;