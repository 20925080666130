import { Fragment, useContext, useEffect, useState } from "react";
import { UserCommentType, UserType } from "../global/types";
import CustomButton from "./CustomButton";
import { httpsCallable } from "firebase/functions";
import { functions } from "../App";
import { AuthContext } from "../contexts/AuthContext";
import moment from "moment";

type UserCommentProps = {
  comment:UserCommentType;
  anonymous?:boolean;
};
const UserComment: React.FC<UserCommentProps> = ({comment:{author, createdAt, id, text, title}, anonymous}) => {
  return (
    <div className="bg-white rounded-lg p-4 shadow-md mb-4">
      {
        anonymous?
        <div className="flex items-center mb-2">
          <div
            className={`w-6 h-6 lg:h-8 lg:w-8 rounded-full mr-2 bg-secondary`}
          />
          <div className="font-medium text-gray-700">_____</div>
          <div className="ml-auto text-sm font-medium text-gray-600 mb-2">
            {moment(createdAt).format('D MMMM YYYY')}
          </div>
        </div>
        :
        <div className="flex items-center mb-2">
          <img
            src={author.profileImgUrl}
            alt={author.name+' '+author.surname}
            className={`w-6 h-6 lg:h-8 lg:w-8 rounded-full mr-2`}
          />
          <div className="font-medium text-gray-700">{author.name+' '+author.surname + '    (' + author.accountType + ')'}</div>
          <div className="ml-auto text-sm font-medium text-gray-600 mb-2">
            {moment(createdAt).format('D MMMM YYYY')}
          </div>
        </div>
        
      }
      <div className="text-lg font-medium text-gray-800 mb-2">{title}</div>
      <div className="text-sm text-gray-700">
        {text.split('\n').map((line, index) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ))}  
      </div>
    </div>
  );
};


type UserCommentsProps = {
  user:UserType|null;
  currentUser:UserType|null;
}
const UserComments: React.FC<UserCommentsProps> = ({ user, currentUser }) => {
  const [comments, setComments] = useState<UserCommentType[]>([]);
  const [commentData, setCommentData] = useState({ title: '', text: '' });
  const [addingComment, setAddingComment] = useState(false);
  const addCommentsEnabled = ['admin'].some((item: any) => currentUser?.accountType.includes(item))
  ||(user?.accountType.includes('student') && ['teacher', 'expert'].some((item: any) => currentUser?.accountType.includes(item)));
  
  const onCommentAdd = async () => {
    if (!user||!addCommentsEnabled) return;
    setAddingComment(true);

    const addUserComment = httpsCallable(functions, 'addUserComment');
    try {
      const addedUserComment = (await addUserComment({ id: user.id, commentData })).data as UserCommentType;
      // setComments([...comments, addedUserComment]);
      setCommentData({title:'', text:''});
      getAndSetUserComments();
    } catch (error) {
      console.log('Error while adding comment on user: ', error);
    };
    setAddingComment(false);
  }

  const getAndSetUserComments = async () => {
    if (!user) return;

    const getUserComments = httpsCallable(functions, 'getUserComments');
    try {
      const resultUserComments = (await getUserComments({ id: user.id })).data as UserCommentType[];
      console.log('Result user comments: ', resultUserComments);
      setComments(resultUserComments.map(comment => ({ ...comment, createdAt: new Date(comment.createdAt) })))
    } catch (error) {
      console.log('Error occured while fetching comments: ', error);
    }
  };

  useEffect(() => {
    getAndSetUserComments()
  }, [user]);
  return (
    <div className="flex flex-col gap-4 max-h-[100vh] overflow-auto w-full">
      {comments.map((comment) => (
        <UserComment comment={comment} key={comment.id} anonymous={!addCommentsEnabled} />
      ))}
      {
        addCommentsEnabled ?
          <div className="py-2">
            <p className="text-xl font-medium">Ajouter un commentaire</p>
            <input
              placeholder="Titre du commentaire"
              className="textInput !my-2"
              type="text"
              value={commentData.title}
              onChange={(e) => setCommentData(data => { return { ...data, title: e.target.value } })}
            />
            <textarea
              placeholder="Dites en plus..."
              className="textInput !my-2"
              value={commentData.text}
              onChange={(e) => setCommentData(data => { return { ...data, text: e.target.value } })}

            />
            <CustomButton
              loading={addingComment}
              title="Ajouter"
              className="min-w-[12rem]"
              onClick={onCommentAdd}
            />
          </div>
          :
          null
        // <button
        //     className="flex bg-green-400 hover:bg-green-600 rounded-lg p-2 shadow-md mb-4 justify-center"
        //     onClick={() => setShowAddComment(true)}    
        // >
        //     Ajouter Un Commentaire
        // </button>
      }
    </div>
  )
};

export default UserComments;