import CustomButton from "./CustomButton";
import moment from 'moment'
import 'moment/locale/fr'
import { useNavigate } from "react-router-dom";
import { ChapterTaskType } from "../global/types";

type TaskNotAvailablePropsType = {
    task:ChapterTaskType
};

const TaskNotAvailable: React.FC<TaskNotAvailablePropsType> = ({task}) => {
    const navigate = useNavigate();
    
    const notYetAvailableMessage=`Cette activite n'est pas disponible pour le moment.
        Revenez entre le ${moment(task.startDate).toLocaleString()} et le ${moment(task.endDate).toLocaleString()}
    `;
    const taskClosedMessage = `Cette activite n'est plus disponible depuis le ${moment(task.endDate).format('D MMM YYYY [à] HH[h] mm [mins]')}`
    const isTooEarly = moment().isBefore(task.startDate);
    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 relative">
            <h1 className="text-4xl font-bold mb-4 text-primary">Oops!</h1>
            <p className="text-lg text-gray-700 mb-8">
                {isTooEarly? notYetAvailableMessage:taskClosedMessage} 
            </p>
            <CustomButton
                title="Retour"
                onClick={() => navigate('..')}
                variant="btnPrimary"
            />
        </div>
    );
};

export default TaskNotAvailable;
