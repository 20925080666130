import { accountOptions, userProfile } from "../assets/languages";
import CustomSelect from "./CustomSelect";
import MenuCard, { MenuItem } from "./MenuCard";
import { otherAccountData } from "../global/types";

type AdministrationPropsType = {
    otherAccountData:otherAccountData,
    setOtherAccountData:React.Dispatch<React.SetStateAction<otherAccountData>>
};

const Administration:React.FC<AdministrationPropsType> = ({otherAccountData, setOtherAccountData}) => {
    return (
        <MenuCard
            title="Administration"
            containerClassName="flex-[1.5]"
        >
            <MenuItem
                title={userProfile.labels.accountState}
                suffix={(
                    <div className="ml-auto w-48 lg:w-56">
                        <CustomSelect
                            onChange={(newValue:any) => setOtherAccountData(data => {
                                return {...data, accountOptions:{...data.accountOptions, state:newValue.value}}
                            })}
                            value={accountOptions.state.find(option => option.value === otherAccountData.accountOptions.state)}
                            options={accountOptions.state}
                        />
                    </div>
                )}
            />
            <MenuItem
                title={userProfile.labels.publicEmail}
                suffix={(
                    <div className="ml-auto w-48 lg:w-56">
                        <CustomSelect
                            onChange={(newValue:any) => setOtherAccountData(data => {
                                return {...data, accountOptions:{...data.accountOptions, emailVisibility:newValue.value}}
                            })}
                            value={accountOptions.emailVisibility.find(option => option.value === otherAccountData.accountOptions.emailVisibility)}
                            options={accountOptions.emailVisibility}
                        />
                    </div>
                )}
            />
            <MenuItem
                title={userProfile.labels.whatsappNumber}
                suffix={(
                    <div className="ml-auto w-48 lg:w-56">
                        <input
                            value={otherAccountData.whatsAppNumber}
                            placeholder="(international)"
                            className="textInput"
                            onChange={(e) => setOtherAccountData(data => {return {...data, whatsAppNumber:e.target.value}})}
                        />
                    </div>
                )}
            />
            {/* <MenuItem
                title={userProfile.labels.telegramNumber}
                suffix={(
                    <div className="ml-auto w-48 lg:w-56">
                        <input
                            value={otherAccountData.telegramNumber}
                            placeholder="(international)"
                            className="textInput"
                            onChange={(e) => setOtherAccountData(data => {return {...data, telegramNumber:e.target.value}})}
                        />
                    </div>
                )}
            /> */}
            <MenuItem
                title={userProfile.labels.linkedInProfileLink}
                suffix={(
                    <div className="ml-auto w-48 lg:w-56">
                        <input
                            value={otherAccountData.linkedInLink}
                            placeholder="(international)"
                            className="textInput"
                            onChange={(e) => setOtherAccountData(data => {return {...data, linkedInLink:e.target.value}})}
                        />
                    </div>
                )}
            />
            <MenuItem
                title={userProfile.labels.twitterProfileLink}
                suffix={(
                    <div className="ml-auto w-48 lg:w-56">
                        <input
                            value={otherAccountData.linkedInLink}
                            placeholder="(international)"
                            className="textInput"
                            onChange={(e) => setOtherAccountData(data => {return {...data, linkedInLink:e.target.value}})}
                        />
                    </div>
                )}
            />
        </MenuCard>
    )
};

export default Administration;