import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { PretestAttendant, PretestQuestion, PretestResult, Pretest as PretestType } from "../../../../global/types";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../App";
import moment from "moment";
import Attendants from "./components/Attendants";
import CustomButton from "../../../../components/CustomButton";
import { getScore } from "../../../../utils/pretest";
import { AuthContext } from "../../../../contexts/AuthContext";


type UsePretestCoundownPropsType = {
  duration?:number;
  startAttendance?:string;
};

export const usePretestCoundown = (props:UsePretestCoundownPropsType) => {
  const [countDown, setCountDown] = useState({hours:0, minutes:0, seconds:0})
  const [config, setConfig] = useState({
    duration:props.duration||0,
    startAttendance:props.startAttendance||moment().subtract({minutes:props.duration})
  });
  const sessionEndDate = moment(config.startAttendance).add({minutes:config.duration});
                    
  const intervalId = setInterval(() => {
   const duration = moment.duration(sessionEndDate.diff(new Date()));
   
   const hours = Math.floor(duration.asHours());
   const minutes = Math.floor(duration.minutes());
   const seconds = Math.floor(duration.seconds());

   setCountDown({hours, minutes, seconds});
   if(duration.asSeconds() <=0) {
    clearInterval(intervalId);
   }
  }, 1000);
  return {countDown, sessionEndDate, setCountdownConfig:setConfig};
}

type SessionIndicatorProps = {
  startAttendance:string;
  duration:number
};

const SessionIndicator = ({duration, startAttendance}:SessionIndicatorProps) => {
  const {countDown, sessionEndDate} = usePretestCoundown({duration, startAttendance})
  
  if(sessionEndDate.isBefore(new Date())) {
    return (
      <div className="col-span-3">
        <span className="font-medium text-lg">La Session est terminée </span>
        <p>
          Depuis le: {moment(sessionEndDate).format('D MMM YYYY [:] HH[h] mm [mins]')}
        </p>
      </div>
    )
  } else {
    return (
      <div className="col-span-3">
        <span className="font-medium text-lg">Session en Cours: </span>
        <p>
          Se termine dans: {countDown.hours} heure(s) {countDown.minutes} minute(s) {countDown.seconds} seconde(s)
        </p>
      </div>
    )
  }
};

const Pretest = () => {
  const navigate = useNavigate();

  const { attendantId, pretestId } = useParams();
  const [loading, setLoading] = useState(false);
  const [fullResults, setFullResults] = useState<{questions:PretestQuestion[], results:PretestResult[]}>({questions:[], results:[]});
  const state: { pretest: PretestType } | null = useLocation().state;
  const [pretestData, setPretestData] = useState<PretestType & { attendants: PretestAttendant[] } | null>(
    state?.pretest ? { ...state.pretest, attendants: [] } : null
  );
  const [attendant, setAttendant] = useState<PretestAttendant|null>(null);
  const user = useContext(AuthContext)?.user;
  const isPretestAdmin = ['admin'].some((item: any) => user?.accountType.includes(item));
  const [startPretestLoading, setStartPretestLoading] = useState(false);

  const getAndSetPretestData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const getPretestInfos = httpsCallable(functions, 'getPretestInfos');
      const getPretestQuestions = httpsCallable(functions, 'getPretestQuestions');

      const dbPretestData = (await getPretestInfos({ pretestId, listAttendants:isPretestAdmin })).data as PretestType & { attendants: PretestAttendant[] };
      const pretestQuestions = (await getPretestQuestions({ pretestId, includeCorrection:isPretestAdmin, withResultsForAttendants:isPretestAdmin })).data as  { questions: PretestQuestion[], results:PretestResult[] };
      setPretestData({ ...dbPretestData, startDate: new Date(dbPretestData.startDate), endDate: new Date(dbPretestData.endDate) });
    
      setFullResults(pretestQuestions);
      
    } catch (error) {
      console.log('Error while getting pretest data: ', error);
    }

    setLoading(false);
  };

  const getAttendantScore = (attendant:PretestAttendant):number|undefined => {
    const attendantResults = fullResults.results.find(item => item.attendantId===attendant.id);
    if(!attendantResults) return undefined;
    return getScore(fullResults.questions, attendantResults.answers);
  };

  const getAndSetAttendant = async () => {
    if(!attendantId || !pretestId) return;
    try {
      const getPretestAttendantById = httpsCallable(functions, 'getPretestAttendantById');
      const result = (await getPretestAttendantById({attendantId, pretestId})).data as {attendant:PretestAttendant};
      setAttendant(result.attendant);
    } catch (error) {
      console.log('An error occured while getting the pretest attendant: ', error);
    }
  };

  const onPretestStart = async () => {
    if(!attendantId || startPretestLoading) return;
    setStartPretestLoading(true);

    try {
      const startPretest = httpsCallable(functions, 'startPretest');
      await startPretest({attendantId, pretestId});
      navigate('start');
    } catch (error) {
      console.log('Error occured while starting pretest: ', error);
    };

    setStartPretestLoading(false);
  };

  useEffect(() => {
    getAndSetPretestData();
    getAndSetAttendant();
  }, []);
  return (
    <div className="flex flex-1 w-screen">
      <div className="mt-4 mb-12 px-2 py-4 md:p-4 mx-auto rounded-2xl w-full flex flex-col max-w-7xl bg-secondary-light">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col flex-[2] my-2 md:m-2 gap-y-3 lg:gap-y-6">
            <h3 className="text-xl lg:text-3xl font-semibold">
              {pretestData?.title}
              {
                pretestData &&moment(pretestData.endDate).isBefore(new Date())?
                <h6 className="italic text-red-600 text-lg lg:text-xl">Evaluation Terminée</h6>
                :null
              }
            </h3>
            <div className="text-lg">
              <span className="font-medium text-lg">À propos:</span>
              <p>
                {pretestData?.about}
              </p>
            </div>
            <div>
              <span className="font-medium text-lg">Période du pré-test</span>
              <div className="flex gap-x-6">
                <span>
                  Début:{' '}
                  {moment(pretestData?.startDate).format('D MMM YYYY [:] HH[h] mm [mins]')}
                </span>
                <span>
                  Fin:{' '}
                  {moment(pretestData?.endDate).format('D MMM YYYY [:] HH[h] mm [mins]')}
                </span>
              </div>
            </div>
            <div>
              <span className="font-medium text-lg">Description:</span>
              <p
                className="px-2 lg:px-4 py-1 lg:py-3 rounded-lg border-2 "
                dangerouslySetInnerHTML={{__html:pretestData?.description||''}}
              />
            </div>
            <div>
              <span className="font-medium text-lg">
                Durée de l'évaluation:
                <span className="font-medium">
                  {' '+pretestData?.duration} minutes
                </span>
              </span>

            </div>
            {
              isPretestAdmin && pretestId?
              <div className="mt-6 lg:mt-8 space-y-2">
                <span className="font-medium text-lg">
                  Participants:
                </span>
                <Attendants
                  attendants={pretestData?.attendants||[]}
                  shortResults={pretestData?.attendants.map(item => ({attendantId:item.id, score:getAttendantScore(item)}))||[]}
                  pretestId={pretestId}
                  onAttendantResultsViewPress={(id) => navigate(`/pre-tests/${pretestId}/results?id=${id}`)}
                />
              </div>
              :null
            }
            {
              attendant?
              <div className="bg-secondary-dark bg-opacity-10 px-4 pt-2 pb-3 rounded-lg text-base grid lg:grid-cols-3" >
                <h5 className="text-xl lg:text-2xl font-semibold mb-2 col-span-3">
                  Informations sur le participant:
                </h5>
                <div>
                  <span className="font-medium text-lg">Nom:</span>
                  <p>
                    {attendant.name}
                  </p>
                </div>
                <div>
                  <span className="font-medium text-lg">Prénom:</span>
                  <p>
                    {attendant.surname}
                  </p>
                </div>
                <div>
                  <span className="font-medium text-lg">Addresse Email: </span>
                  <p>
                    {attendant.email}
                  </p>
                </div>
                {
                  attendant.startAttendance && pretestData? 
                  <SessionIndicator duration={pretestData.duration} startAttendance={attendant.startAttendance} />
                  :null
                }

                <div className="col-span-3 flex flex-1 items-end justify-end">
                  {
                    !pretestData ||moment(pretestData.endDate).isBefore(new Date())|| // the pretest is closed
                    moment(attendant.startAttendance).add({minutes:pretestData.duration}).isBefore(new Date())?
                    null
                    :
                    attendant.startAttendance?
                    <CustomButton
                      title={"Poursuivre la Session"}
                      onClick={() => navigate('start')}
                    />
                    :
                    <CustomButton
                      title={"Débuter l'Examen"}
                      onClick={onPretestStart}
                    />
                  }
                </div>
              </div>
              :null
            }
            <div className="flex gap-x-3 ml-auto">
              {
                isPretestAdmin?
                <CustomButton
                  title="Voir les Questions"
                  onClick={() => navigate(`/pre-tests/${pretestId}/questions`)}
                />
                :null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Pretest;