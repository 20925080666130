import CustomButton from "../../components/CustomButton";

const RequireAuth: React.FC = () => {
    return (
        <div className="flex flex-1">
            <div className="mt-4 mb-12 p-4 mx-auto rounded-2xl w-full flex flex-col items-center justify-center max-w-6xl h-[50vh] bg-secondary-light">
                <h1 className="text-4xl font-bold mb-4 text-primary">Authentification Obligatoire</h1>
                <p className="text-lg text-gray-700 mb-8">
                    Vous devez être connecté à un compte pour accéder à cette page.
                </p>
                <CustomButton
                    title="Connectez vous"
                    navigateTo="/signin"
                    variant="btnPrimary"
                // className="bg-red-500 text-white font-medium py-2 px-4 rounded-md hover:bg-red-600"
                />
            </div>
        </div>
    );
};

export default RequireAuth;
