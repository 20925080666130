import CustomButton from "../../components/CustomButton";

const ErrorOccured: React.FC = () => {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 relative">
        {/* <div className="absolute max-w-6xl mx-auto top-0 left-0 right-0 bottom-0 overflow-hidden">
          <div className="absolute w-80 h-80 rounded-full bg-primary-light opacity-30 top-0 left-0"></div>
          <div className="absolute w-60 h-60 rounded-full bg-yellow-300 opacity-30 bottom-0 right-0"></div>
          <div className="absolute w-40 h-40 rounded-full bg-purple-200 opacity-30 top-0 right-0"></div>
          <div className="absolute w-80 h-80 rounded-full bg-green-200 opacity-30 bottom-0 left-0"></div>
        </div> */}
        <h1 className="text-4xl font-bold mb-4 text-primary">Oops!</h1>
        <p className="text-lg text-gray-700 mb-8">
          An error occurred while processing your request.
        </p>
        <CustomButton
          title="Reessayer"
          onClick={() => window.location.reload()}
          variant="btnPrimary"
          // className="bg-red-500 text-white font-medium py-2 px-4 rounded-md hover:bg-red-600"
        />
      </div>
    );
  };
  
  export default ErrorOccured;
  