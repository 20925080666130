import { PretestAnswer, PretestQuestion } from "../global/types";


export const getScore = (questions:PretestQuestion[], answers:PretestAnswer[]) => {
  const marks = questions.map(question => {
    const answer = answers.find(answer => answer.metaIndex == question.metaIndex);
    if(answer ===undefined) return 0;
    if(answer.type==='mcq' && question.type==='mcq') {
      const mark= answer.choices.some(choice => question.answers[choice].isCorrect) ? (question.weight||1) : 0
      return mark;
    } else if (answer.type==='sq' && question.type==='sq') {
      return answer.earnedWeight||0;
    } else if(answer.type ==='match' && question.type =='match') {
      return answer.choices.map(choice => Number(choice.part1 ===choice.part2)*question.weight).concat(0).reduce((prev, current) => prev+current);
    } else {
      return 0; // the question type do not match, given the same meta index
    }
  });

  return marks.reduce((prev, current) => prev+current); 
};
