import { MdOutlineDelete } from "react-icons/md";
import { StructuralQuestionType } from "../../../../global/types";
import CustomRichEditor from "../../../../components/CustomRichEditor";
import CustomButton from "../../../../components/CustomButton";
import CustomSelect from "../../../../components/CustomSelect";
import { useEffect, useState } from "react";



type StructuralQuestionPropsType = {
  qIndex: number;
  question: StructuralQuestionType;
  handleDeleteQuestion: (qIndex: number) => void;
  handleChangeQuestion: (qIndex: number, value: StructuralQuestionType) => void;
};

export const StructuralQuestion: React.FC<StructuralQuestionPropsType> = ({
  qIndex,
  question,
  handleChangeQuestion,
  handleDeleteQuestion,
}) => {

  return (
    <div
      className="bg-slate-200 px-6 pt-4 rounded-2xl"
    >
      <div className="flex justify-between">
        <h3 className="text-lg font-bold text-gray-900">
          Question {qIndex + 1}
        </h3>
        <button
          className="flex items-center h-10 pl-2 pr-3 rounded-full bg-red-600 text-white hover:bg-red-800 transition duration-100"
          onClick={() => handleDeleteQuestion(qIndex)}
        >
          <MdOutlineDelete className='m-auto' size={24} />
          Question
        </button>
      </div>
      <div className="my-4">
        <div>
          <CustomRichEditor
            value={question.question}
            onChange={value => handleChangeQuestion(qIndex, {...question, question:value.toString()})}
          />
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Reponse:
          </label>
          <CustomRichEditor
            value={question.answer}
            onChange={(answer) => handleChangeQuestion(qIndex, {...question, answer:answer.toString()})}
          />
        </div>
        {/* <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Explication:
                    </label>
                    <CustomRichEditor
                        value={question.explanation}
                        onChange={(value) =>
                            handleChangeExplanation(qIndex, value.toString())
                        }
                    />
                </div> */}
        <div className="flex mt-4 gap-x-6">
          <label className="flex items-center gap-x-2">
            <p className="mb-1 text-sm font-medium text-gray-700">Crédits :</p>
            <input
              value={question.weight}
              type="number"
              min={0}
              onChange={(e) => handleChangeQuestion(qIndex, {...question, weight:parseInt(e.target.value)})}
              className="textInput !w-24"
            />
          </label>
          <div className="flex items-center gap-x-2">
            <p className="mb-1 text-sm font-medium text-gray-700">Format de question:</p>
            <CustomSelect
              containerClassName="w-44"
              value={[{label:'Long', value:'long'}, {label:'Court', value:'short'}].find(item => item.value ===question.format)}
              options={[{label:'Long', value:'long'}, {label:'Court', value:'short'}]}
              onChange={(newValue) => handleChangeQuestion(qIndex, {...question, format:(newValue as any).value})}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

type StructuralGeneratorPropsType = {
  questions: StructuralQuestionType[];
  setQuestions: React.Dispatch<React.SetStateAction<StructuralQuestionType[]>>
};

const StructuralGenerator: React.FC<StructuralGeneratorPropsType> = ({ questions, setQuestions }) => {
  const [maxIndexes, setMaxIndexes] = useState({pseudo:0, meta:0});

  const handleAddQuestion = () => {
    const newQuestion: StructuralQuestionType = {
      pseudoIndex: maxIndexes.pseudo+1,
      metaIndex:maxIndexes.meta+1,
      question: '',
      weight: 1,
      answer: '',
      explanation: '',
      format: 'short',
      type: 'sq',
    };
    setQuestions([...questions, newQuestion]);
  };

  const handleDeleteQuestion = (qIndex: number) => {
    const newQuestions = [...questions]; //destructure the "questions" array to clone it, and avoid rerendering issues
    newQuestions.splice(qIndex, 1);
    setQuestions(newQuestions);
  };


  const handleChangeQuestion = (qIndex: number, question: StructuralQuestionType,) => {
    let newQuestions = [...questions];
    newQuestions[qIndex] = question;
    setQuestions(newQuestions);
  };

  
  useEffect(() => {
    setMaxIndexes({
      meta:Math.max(maxIndexes.meta, ...questions.map(q =>q.metaIndex)),
      pseudo:Math.max(maxIndexes.pseudo, ...questions.map(q =>q.pseudoIndex))
    })
  }, [questions.length]);
  return (
    <div >
      <h2 className="text-2xl font-medium self-start flex justify-between items-center">Questions Ouvertes: </h2>
      <div className="flex flex-col gap-y-6 mt-4">
        {questions.map((q, qIndex) => (
          <StructuralQuestion
            key={qIndex}
            qIndex={qIndex}
            question={q}
            // handleChangeAnswer={handleChangeAnswer}
            // handleChangeQuestionWeight={handleChangeQuestionWeight}
            handleChangeQuestion={handleChangeQuestion}
            handleDeleteQuestion={handleDeleteQuestion}
          // handleChangeExplanation={handleChangeExplanation}
          />
        ))}
      </div>
      <CustomButton
        variant="btnSecondary"
        title="Ajouter une question"
        onClick={handleAddQuestion}
      />
    </div>
  )

};

export default StructuralGenerator;