import CustomButton from "../../../components/CustomButton";

const CallToAction = () => {
    const eformation = require('../assets/e-formation.png')
    return (
        <div className='w-full text-white bg-secondary-light py-6 px-4 mb-6 lg:mb-10'>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-5'>
                <img
                    className='col-span-2 w-64 mx-auto mt-4 mb-12 to-yellow-900 filter hue-rotate-180 saturate-200'
                    src={eformation} alt='/'
                />
                <div className='flex flex-col justify-center col-span-3'>
                    <p className='text-primary-dark font-bold '>UNE EXPERIENCE DE QUALITE</p>
                    <h1 className='text-2xl md:text-3xl font-medium py-1 text-dark'>Par Des Experts De Divers Horizons</h1>
                    <p className="text-dark">
                        Commencez a explorer les mysteres des forets et developpez vos competences
                        d'observateurs avec notre encadrement.
                        Devenez un detective hors pair grace a nos tele-cours interactifs.
                        Notre programme engageant est concu pour vous aider a affiner vos capacites et a
                        acquerir une comprehension approfondie des interactions complexes qui ont
                        lieu dans les ecosystemes forestiers.
                    </p>
                    <CustomButton
                        title="Consulter le catalogue des formateurs & experts"
                        navigateTo="catalog/figures"
                        className="mt-4 w-max !px-4"
                    />
                </div>
            </div>
        </div>
    );
};

export default CallToAction;