import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, LinearProgress, Menu, MenuItem } from "@mui/material";
import {
    DataGrid,
    GridCallbackDetails,
    GridClasses,
    GridColDef,
    GridNoRowsOverlay,
    GridRowSelectionModel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useContext, useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import CustomButton from '../../../../components/CustomButton';
import { AuthContext } from '../../../../contexts/AuthContext';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../App';
import { GlobalFormationType } from '../../../../global/types';
import { sortByIndexes } from '../../../../utils/formationTreeHandlers';
import { getFormationPeriod } from '../../../../utils';

type Student = {
    id: number;
    name: string;
    surname: string;
    birthdate: string;
    occupation: string;
    phone: string;
    email: string;
    country: string;
};

const columns: GridColDef<Student, any, any>[] = [
    { field: 'id', headerName: 'ID', minWidth: 50, flex: 1 },
    { field: 'name', headerName: 'Nom', minWidth: 110, flex: 1.5 },
    { field: 'surname', headerName: 'Prenom', minWidth: 110, flex: 1.5 },
    // { field: 'birthdate', headerName: 'Date de Naissance', minWidth: 75, flex: 1 },
    { field: 'occupation', headerName: 'Occupation', minWidth: 110, flex: 1.5 },
    { field: 'phone', headerName: 'Telephone', minWidth: 110, flex: 1.5 },
    { field: 'email', headerName: 'Email', minWidth: 160, flex: 2 },
    { field: 'country', headerName: 'Pays', minWidth: 100, flex: 1.5 },
];

const BasicMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className='ml-auto'>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ paddingY: 2 }}
            >
                <BsThreeDotsVertical className='text-secondary-dark' size={20} />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}>Desactiver la selection</MenuItem>
            </Menu>
        </div>
    );
};

const CustomToolbar = () => {
    return (
        <GridToolbarContainer className='bg-secondary-light' sx={{ paddingX: 2 }}>
            <GridToolbarColumnsButton className='text-dark' sx={{ paddingY: 2, color: '#7F5032' }} />
            <GridToolbarFilterButton sx={{ paddingY: 2, color: '#7F5032' }} />
            <GridToolbarDensitySelector sx={{ paddingY: 2, color: '#7F5032' }} />
            <GridToolbarExport sx={{ paddingY: 2, color: '#7F5032' }} />
            <BasicMenu />
        </GridToolbarContainer>
    );
};

const Students = () => {
    const user = useContext(AuthContext)?.user;

    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
    const [students, setStudents] = useState<Student[]>([]);
    const [loading, setLoading] = useState(false);
    const [formation, setFormation] = useState<GlobalFormationType|null>(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const formationId = searchParams.get('formationId');

    const navigate = useNavigate();

    const handleRowSelection = (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
        //rowSelectionModel is an array containing checkboxed rows ids
        setSelectedRows(rowSelectionModel);
    };

    const gridClassNames: Partial<GridClasses> = {
        footerContainer: 'bg-secondary-light'
    }

    const getAndSetPlatformStudents = async () => {
        setLoading(true);
        try {
            const getPlatformStudents = httpsCallable(functions, 'getPlatformStudents');
            const resultData = (await getPlatformStudents({formationId})).data;
            setStudents(resultData as Student[]);
        } catch (error) {
            console.log(error)
        }
        setLoading(false);
    }


    const getFormationData = async () => {
        try {
          const getFormationTreeById = httpsCallable(functions, 'getFormationTreeById');
          const formationTree = (await getFormationTreeById({id:formationId})).data as GlobalFormationType;
            setFormation(sortByIndexes(formationTree));
        } catch (error) {
            console.log("Error while getting formation by id", error)
        }
    };

    useEffect(() => {
        if(formationId) getFormationData();
        getAndSetPlatformStudents();
    }, []);

    return (
        <div className='flex flex-1'>
            <div className='flex flex-1 flex-col max-w-7xl mx-auto px-2'>
                <h2 className="text-4xl font-medium mt-8 self-start mb-2">Liste des apprenants</h2>
                {
                  formation?
                  <h4 className="text-lg font-bold mt-2 mb-2 self-start text-gray-500">
                    (Formation: {formation.title}.
                    <span className='italic text-base'> Période {getFormationPeriod(formation.startDate, formation.endDate)}</span>
                    )
                    </h4>
                  :
                  null
                }
                <div className='mb-2 lg:max-w-6xl xl:max-w-7xl'>
                    <DataGrid
                        classes={gridClassNames}
                        autoHeight
                        rows={students}
                        columns={columns}
                        loading={loading}
                        getRowClassName={() => 'cursor-default'}
                        onRowClick={(row) => navigate(row.id.toString(), {state:{userData:students.find(student => student.id === row.id)}})}
                        onRowSelectionModelChange={handleRowSelection}
                        slots={{ toolbar: CustomToolbar, loadingOverlay:LinearProgress, noRowsOverlay:() => <div className='flex flex-1 h-full items-center justify-center'>Aucun apprenant correspondant</div> }}
                        disableColumnMenu
                        disableRowSelectionOnClick
                    />
                </div>
                <div className='flex justify-end mb-10'>
                    {
                      ['admin', 'super'].some((item:any) => user?.accountType.includes(item)) ?
                        <CustomButton
                            variant='btnSecondary'
                            title='Ajouter un nouveau'
                            className='w-72 mt-4'
                            navigateTo='add'
                        />
                        :
                        null
                    }
                </div>
            </div>
        </div>
    );
};

export default Students;
