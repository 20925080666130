import axios from 'axios';
import CustomButton from '../../../../components/CustomButton';
import { BiWebcam } from 'react-icons/bi';
import { ChapterTaskType, UserType } from '../../../../global/types';
import { BsClipboard } from 'react-icons/bs';

type TeleClassPropsType = {
    hasUserDoneTask:boolean|undefined
    task:ChapterTaskType
    user:UserType
}

const TeleClass:React.FC<TeleClassPropsType> = ({task, user}) => {
    
    let userMeetingUrl='';
    if(!task.meeting) {
        userMeetingUrl= '#';
    } else if(['teacher', 'expert'].some((item:any) => user.accountType.includes(item))) {
        userMeetingUrl = task.meeting.start_url ||'#'
    } else {
        // "uname" property allows to specify the participant Name
        // "uname" does not work with start_url, the name is got when user clicks on the start link and signs in to Zoom
        userMeetingUrl = task.meeting.join_url+`&uname=${encodeURIComponent(user.name+' '+user.surname)}` ||'#';
    };

    return (
        <div className='flex gap-x-3'>
            <CustomButton
                title='Ouvrir la reunion'
                className='flex-row-reverse'
                navigateTo={userMeetingUrl}
            >
                <BiWebcam
                    size={26}
                    className='mr-2'
                />
            </CustomButton>
            <CustomButton
                title='Copier le lien'
                className='flex-row-reverse'
                onClick={() => navigator.clipboard.writeText(userMeetingUrl||'')}
            >
                <BsClipboard size={26} className='mr-2' />
            </CustomButton>
            
        </div>
    );
}

export default TeleClass;
