import { otherAccountData } from '../../../../../global/types';
import ProfileAddForm from '../../../../../components/ProfileAddForm';


type StudentFormPropsType = {
    otherAccountData:otherAccountData
};

const StudentForm:React.FC<StudentFormPropsType> = ({otherAccountData}) => {
    return (
        <ProfileAddForm
            accountType={['student']}
            extraFields={{
                // expertiseDomain:true,
                followingFormations:true
            }}
            otherAccountData={otherAccountData}
        />
    );
};


export default StudentForm;