import { useLocation, useNavigate, useParams } from "react-router-dom";
import MCQRenderer from "./components/MCQRenderer";
import { ChapterTaskType, MCQQuestionType, StructuralQuestionType, UserType } from "../../../global/types";
import ErrorOccured from "../../ErrorOccurred";
import DocumentViewer from "./components/DocumentViewer";
import Submission from "./components/Submission";
import VideoPlayer from "./components/VideoPlayer";
import YoutubeVideo from "./components/YoutubeVideo";
import StructuralRenderer from "./components/StructuralRenderer";
import WebDocumentViewer from "./components/WebDocumentViewer";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import TeleClass from "./components/TeleClass";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../App";
import moment from 'moment';
import TaskNotAvailable from "../../../components/TaskNotAvailable";



const taskSwitcher = ({task, user}:{task:ChapterTaskType, user:UserType}) => {
  if(!(moment().isBetween(task.startDate, task.endDate)) && !['admin', 'teacher', 'expert'].some((item:any) => user.accountType.includes(item))) {
      return <TaskNotAvailable task={task} />
  } else if(task.type === 'submission') {
      return <Submission task={task} user={user} />;
  } else if(task.type === 'MCQ') {
      return <MCQRenderer
                  hasUserDoneTask={task.hasUserDoneTask}
                  task={task}
                  user={user}
              />;
  } else if(task.type ==='documentFile') {
      return <DocumentViewer task={task} user={user} />;
  } else if(task.type === 'documentUrl') {
      return <WebDocumentViewer hasUserDoneTask={task.hasUserDoneTask} task={task} user={user} />;
  } else if(task.type === 'teleMeeting') {
      return <TeleClass user={user} task={task} hasUserDoneTask={task.hasUserDoneTask} />
  }else if(task.type === 'structural') {
      return <StructuralRenderer
                  hasUserDoneTask={task.hasUserDoneTask}
                  questions={task.structuralQuestions as unknown as StructuralQuestionType[]}
                  user={user}
              />
  } else if(task.type ==='videoFile') {
      return (
              <VideoPlayer
                  hasUserDoneTask={task.hasUserDoneTask}
                  user={user}
                  videoFileLink={task.fileUrl}
              />
          );
  } else if(task.type ==='youtubeVideo') {
      const extractVideoId = (url:string) => {
          // Extract the video ID from a YouTube URL
          var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
          var match = url.match(regExp);
          return (match&&match[7].length==11)? match[7] : null;
      };

      let youtubeVideoId = null;

      if(task.onlineUrl) youtubeVideoId= extractVideoId(task.onlineUrl);
      
      return <YoutubeVideo user={user} videoId={youtubeVideoId} />;
  } else {
      return <ErrorOccured/>;
  }
};


const ActivityRouter = () => {
    const user = useContext(AuthContext)?.user;
    const navigate = useNavigate();
    let state:{task:ChapterTaskType}|null = useLocation().state;
    const {taskId, answerUserId} = useParams();

    const [task, setTask] = useState<ChapterTaskType|undefined>(state?.task);

    const getAndSetTask = async() => {
        const getTaskData = httpsCallable(functions, 'getTaskData')
        const taskData = (await getTaskData({taskId:taskId, fullTask:true, withResultsForUsers:[answerUserId]})).data as unknown as ChapterTaskType;
        setTask(taskData);
        
    };

    useEffect(() => {
      getAndSetTask();
    },[]);

    if(!user) {
        navigate('/signin');
        return <div/>;
    }


    

    if(!task) return (
        <div className="flex flex-1">
            <div className="mt-4 mb-12 p-4 mx-auto rounded-2xl w-full flex flex-col max-w-6xl bg-secondary-light">
                
            </div>
        </div>
    );

    
    return (
        <div className="flex flex-1">
            <div className="mt-4 mb-12 p-3 lg:p-6 mx-auto rounded-2xl w-full flex flex-col max-w-6xl bg-secondary-light">
                <div className=" w-full max-w-4xl mx-auto my-2 ">
                    <div className="border-4 border-white p-4 mb-12">
                        <div className="text-xl text-secondary-dark font-semibold">
                            Description de l'activite:
                        </div>
                        <div
                            dangerouslySetInnerHTML={{__html:task.description}}
                            className="prose-base"
                        />
                    </div>
                    {taskSwitcher({task, user})}
                </div>
            </div>
        </div>
    );  
       
    
};

export default ActivityRouter;