import { useNavigate } from 'react-router-dom';
import { Button, LinearProgress, Menu, MenuItem } from "@mui/material";
import {
  DataGrid,
  GridCallbackDetails,
  GridClasses,
  GridColDef,
  GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useContext, useEffect, useState } from 'react';
import CustomButton from '../../../../components/CustomButton';
import { AuthContext } from '../../../../contexts/AuthContext';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../App';
import moment from 'moment';
import { Pretest } from '../../../../global/types';


const columns: GridColDef<Pretest, any, any>[] = [
  // { field: 'id', headerName: 'ID', minWidth: 50, flex: 1 },
  { field: 'title', headerName: 'Titre', minWidth: 110, flex: 1.5 },
  { field: 'about', headerName: 'À propos', minWidth: 110, flex: 2 },
  // { field: 'birthdate', headerName: 'Date de Naissance', minWidth: 75, flex: 1 },
  { field: 'startDate', headerName: 'Date de début', minWidth: 110, flex: 1,
    renderCell:(params) => <span>{moment(params.row.startDate).format('D MMM YYYY [:] HH[h] mm [mins]')}</span>,
  },
  { field: 'endDate', headerName: 'Date de fin', minWidth: 110, flex: 1,
    renderCell:(params) => <span>{moment(params.row.endDate).format('D MMM YYYY [:] HH[h] mm [mins]')}</span>
  },
  { field:'duration', headerName:'Durée (mins)', flex:1}
];

const CustomToolbar = () => {
  return (
    <GridToolbarContainer className='bg-secondary-light' sx={{ paddingX: 2 }}>
      <GridToolbarColumnsButton className='text-dark' sx={{ paddingY: 2, color: '#7F5032' }} />
      <GridToolbarFilterButton sx={{ paddingY: 2, color: '#7F5032' }} />
      <GridToolbarDensitySelector sx={{ paddingY: 2, color: '#7F5032' }} />
      <GridToolbarExport sx={{ paddingY: 2, color: '#7F5032' }} />
    </GridToolbarContainer>
  );
};

const Pretests = () => {
  const user = useContext(AuthContext)?.user;

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [pretests, setPretests] = useState<Pretest[]>([]);
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();

  const handleRowSelection = (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
    //rowSelectionModel is an array containing checkboxed rows ids
    setSelectedRows(rowSelectionModel);
  };

  const gridClassNames: Partial<GridClasses> = {
    footerContainer: 'bg-secondary-light'
  }

  const getAndSetPretests = async () => {
    setLoading(true);
    try {
      const getPretestsList = httpsCallable(functions, 'getPretestsList');
      const resultData = (await getPretestsList()).data as Pretest[];
      console.log('Pretests: ', resultData)
      setPretests(resultData.map(pretest => ({
        ...pretest,
        startDate:new Date(pretest.startDate),
        endDate:new Date(pretest.endDate)
      })));
    } catch (error) {
      console.log(error)
    }
    setLoading(false);
  }


  useEffect(() => {
    getAndSetPretests();
  }, []);

  return (
    <div className='flex flex-1'>
      <div className='flex flex-1 flex-col max-w-7xl mx-auto px-2'>
        <h2 className="text-4xl font-medium mt-8 self-start mb-2">Voir les Pré-Tests</h2>
        <div className='mb-2 lg:max-w-6xl xl:max-w-7xl'>
          <DataGrid
            classes={gridClassNames}
            autoHeight
            rows={pretests}
            columns={columns}
            loading={loading}
            getRowClassName={() => 'cursor-default'}
            onRowClick={(row) => navigate(row.id.toString(), { state: { pretestData: pretests.find(pretest => pretest.id === row.id) } })}
            onRowSelectionModelChange={handleRowSelection}
            slots={{ toolbar: CustomToolbar, loadingOverlay: LinearProgress, noRowsOverlay: () => <div className='flex flex-1 h-full items-center justify-center'>Aucun prétest n'a été trouvé</div> }}
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </div>
        <div className='flex justify-end mb-10'>
          {
            ['admin', 'super'].some((item: any) => user?.accountType.includes(item)) ?
              <CustomButton
                variant='btnSecondary'
                title='Ajouter un nouveau'
                className='w-72 mt-4'
                navigateTo='add'
              />
              :
              null
          }
        </div>
      </div>
    </div>
  );
};

export default Pretests;
