import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButton from './CustomButton';

type CustomDialogPropsType = {
    open:boolean;
    setOpen:React.Dispatch<React.SetStateAction<boolean>>;
    confirmtButtonTitle:string;
    cancelButtonTitle?:string;
    onConfirm?:() => void;
    onCancel?:() => void;
    onClose?:() => void;
    message?:string
    title?:string
    dialogType?:'confirm'|'alert'
    cancelButton?:{
      className?:string;
      variant?:"btnPrimary" | "btnSecondary" | "btnDanger";
    }
    confirmButton?:{
      className?:string;
      variant?:"btnPrimary" | "btnSecondary" | "btnDanger";
    }
};

const CustomDialog:React.FC<CustomDialogPropsType> = ({
    open,
    setOpen,
    message,
    confirmtButtonTitle,
    cancelButtonTitle,
    dialogType='alert',
    onConfirm,
    onCancel,
    onClose,
    title,
    ...props
}) => {

    const handleClose = () => {
        if(onClose) onClose();
    };

    const handleCancel = () => {
        setOpen(false);
        if(onClose) onClose();
        if(onCancel) onCancel();
    };

    const handleConfirm = () => {
        setOpen(false);
        if(onClose) onClose();
        if(onConfirm) onConfirm()
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableScrollLock
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {dialogType === 'alert' ?
                    null 
                    :
                    <CustomButton
                        title={cancelButtonTitle||''}
                        onClick={handleCancel}
                        variant={props.cancelButton?.variant}
                        className={'flex-1 '+props.cancelButton?.className}
                    />
                }
                <CustomButton
                    title={confirmtButtonTitle}
                    onClick={handleConfirm}
                    autoFocus
                    variant={props.confirmButton?.variant}
                    className={'flex-1 '+props.confirmButton?.className}
                />
            </DialogActions>
        </Dialog>
    
    );
}

export default CustomDialog;