import React, { useEffect, useState } from "react";
import AnimateHeight from 'react-animate-height';

type LogsItemPropsType = {
    title:string
    onNodeClick?: () => void
    onSuffixClick?: () => void
    focused?:boolean
    togglable?:boolean
    /**If true, the node can be toggled by pressing its suffix icon */
    suffixTogglable?:boolean
    icon?:React.ReactNode
    suffix?:React.ReactNode
};

export const MenuItem:React.FC<React.PropsWithChildren<LogsItemPropsType>> = ({
    icon,
    title,
    focused,
    children,
    suffix,
    togglable=false,
    suffixTogglable=false,
    onNodeClick
}) => {
    const [toggled, setToggled] = useState(true);
    
    const onClick = () => {
        if(togglable) {
            setToggled(!toggled)
        }
        if(onNodeClick) onNodeClick();
    }
    
    const onSuffixClick = () => {
        if(suffixTogglable) {
            setToggled(!toggled)
        }
    }
    // useEffect(() => {
    //     console.log(toggled)
    // }, [toggled]);
    
    return (
        <div>
            <div
                onClick={onClick}
                className={"bg-black hover:bg-opacity-5 cursor-pointer px-4 my-0.5 flex items-center rounded-full text-sm "+ (focused?"bg-opacity-10 shadow-inner":"bg-opacity-0")}
            >   
                <div className="py-2 flex items-center">
                    {icon}
                    <span>{title}</span>
                </div>
                <div className={"ml-auto "+ (toggled?'':'rotate-180')} onClick={onSuffixClick} >
                    {suffix}
                </div>   
            </div>
            <AnimateHeight
                duration={300}
                height={toggled ? 0 : 'auto'}
                animateOpacity
                className="mx-6"
            >
                {children}   
            </AnimateHeight>
        </div>
    )
};


type MenuCardPropsType = {
    title:string;
    containerClassName?:string;
};

const MenuCard:React.FC<React.PropsWithChildren<MenuCardPropsType>> = ({children, containerClassName, title}) => {
    return (
        <div className={"bg-secondary-light py-4 px-3 rounded-2xl h-min flex flex-col flex-1 "+containerClassName}>
            <h2 className="text-2xl font-medium ml-2 mb-4 self-start">{title}</h2>
            {children}
        </div>
    )
};

export default MenuCard;