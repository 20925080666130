import "../../../assets/react-quill-snow.css";
import CustomButton from "../../CustomButton";
import CustomSelect from "../../CustomSelect";
import CustomRichEditor from "../../CustomRichEditor";
import CustomDatePicker from "../../CustomDatePicker";
import { FormationCourseType, UserType } from "../../../global/types";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { FormationContext } from "../../../contexts/FormationContext";
import { getParentNodeId, updateCourse } from "../../../utils/formationTreeHandlers";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../App";
import CustomDialog from "../../CustomDialog";

const courseInitTemplate:Omit<FormationCourseType, 'chapters'> = {
    id:'',
    index:Date.now(),
    title:'',
    name: '',
    description:'',
    flag:'new' as 'new',
    teachers: [],
    startDate: moment().toDate(),
    endDate: moment().add({days:1}).toDate(),
    prerequisites:[],
    prerequisitesDescription:'',
    courseDuration:1,
    applicationDuration:3,
    pedagogicApproach:'', //rich text
    evaluation:'', //rich text
    needs:'', //rich text
    bibliography:'', //rich text
    teachersIds:[]
};

const CourseInfos= () => {
    let state:{courseInfos:FormationCourseType, initForm:boolean}|null = useLocation().state;
    const navigate = useNavigate();
    const {formationData, setFormationData} = useContext(FormationContext);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    
    
    const initialContent = state?.courseInfos? state.courseInfos : courseInitTemplate; 
    const [courseInfos, setCourseInfos] = useState<Omit<FormationCourseType, 'chapters'>>(initialContent)
    const [loading, setLoading] = useState(false);
    const [formDynamicInfos, setFormDynamicInfos] = useState<{teachers:UserType[]}>({teachers:courseInfos.teachers})

    const onSave = async() => {
      if(loading) return;
      setLoading(true);
      courseInfos.teachersIds = courseInfos.teachers.map(item => item.id);
      courseInfos.teachers = [];
      if(courseInfos.flag =='new') {
        const parentId = getParentNodeId(formationData, 'course', courseInfos.id)
            try {
                const createFormationCourse = httpsCallable(functions, 'createFormationCourse');
                const createdCourse = (await createFormationCourse({moduleId:parentId, course:{...courseInfos, startDate:courseInfos.startDate.toISOString(), endDate:courseInfos.endDate.toISOString()}})).data as unknown as Omit<FormationCourseType, "chapters">;
                // since when creating a course in the formation, a new id is generated in the db different from the 
                // current one, we should provide the previous id to allow the course data to be retrieved and updated
                console.log('Course Dates: ', createdCourse.startDate, createdCourse.endDate)
                if(setFormationData) setFormationData(updateCourse(formationData, createdCourse, courseInfos.id));
                navigate(-1);
            } catch (error) {
                console.log('Error while creating new course: ', error)
            }
        } else {
            try {
                const updateFormationCourse = httpsCallable(functions, 'updateFormationCourse');
                const updatedCourse = (await updateFormationCourse({course:{...courseInfos, startDate:courseInfos.startDate.toISOString(), endDate:courseInfos.endDate.toISOString()}})).data as unknown as Omit<FormationCourseType, "chapters">;
                if(setFormationData) setFormationData(updateCourse(formationData, updatedCourse));
                navigate(-1);
            } catch (error) {
                console.log('Error while updating course: ', error)
            }
        }
        setLoading(false);
    };
    
    const getAndSetDynamicFormInfos = async() => {
        try {
            const getAllPlatformTeachers = httpsCallable(functions, 'getAllPlatformTeachers');
            const teachersData = (await getAllPlatformTeachers()).data  as UserType[];
            setFormDynamicInfos(infos => ({...infos, teachers:teachersData}));
        } catch (error) {
            console.log('Error while getting teachers data: ', error);
        }
    };

    const onCourseDelete = async () => {
      const deleteFormationNode = httpsCallable(functions, 'deleteFormationNode');
      try {
        await deleteFormationNode({nodeId:courseInfos.id, nodeType:'course'});
        navigate(-1)
      } catch (error) {
        console.log('Error while deleting the course: ', error);
      }
    };

    useEffect(() => {
        if((!state?.courseInfos) && (!state?.initForm)) {
            navigate('/console')
        }
        getAndSetDynamicFormInfos();
    }, []);
    return (
        <div className="flex flex-1">
            <div className="mt-4 mb-12 mx-auto w-full flex flex-col max-w-7xl ">
                <h2 className="text-4xl font-medium mb-4 self-start">Informations sur le cours</h2>
                <div className='bg-secondary-light rounded-2xl  px-4 py-8 h-full'>
                    <div className="px-8">
                        <>
                            <div className='mt-2 mb-1'>
                                Titre du cours:
                            </div>
                            <input
                                value={courseInfos.title}
                                onChange={(e) => setCourseInfos(infos => {
                                    return {
                                        ...infos,
                                        title:e.target.value
                                    }
                                })}
                                className='textInput'
                                placeholder='Entrez quelque chose...'
                            />
                        </>
                        <>
                            <div className='mt-2 mb-1'>
                                Objectif du cours:
                            </div>
                            <textarea
                                value={courseInfos.description}
                                onChange={(e) => setCourseInfos(infos => {
                                    return {
                                        ...infos,
                                        description:e.target.value
                                    }
                                })}
                                placeholder='Entrez quelque chose...'
                                className="textInput"
                            />
                        </>
                        <>
                            <div className='mt-2 mb-1'>
                                Formateur en charge:
                            </div>
                            <CustomSelect
                                isMulti
                                value={courseInfos.teachers.map(teacher => ({label:teacher.name+ ' '+teacher.surname, value:teacher.id}))}
                                options={formDynamicInfos.teachers.map(teacher => ({
                                    label:teacher.name+ ' '+teacher.surname,
                                    value:teacher.id
                                }))}
                                onChange={(newValue: any) =>
                                    setCourseInfos((infos) => ({
                                      ...infos,
                                      teachers: newValue
                                        .map((option: any) =>
                                          formDynamicInfos.teachers.find((item) => item.id === option.value)
                                        )
                                        .filter((teacher:any) => teacher !== undefined),
                                    }))
                                }
                            />
                        </>
                        <div className="flex gap-x-8">
                            <div className="w-full">
                                <div className='mt-2 mb-1'>
                                    Date de debut:
                                </div>
                                <CustomDatePicker
                                    value={courseInfos.startDate}
                                    onChange={(value) => {
                                        setCourseInfos(infos => ({
                                                ...infos,
                                                startDate:value
                                            })
                                        )
                                    }}
                                />
                            </div>
                            <div className="w-full">
                                <div className='mt-2 mb-1'>
                                    Date de Fin:
                                </div>
                                <CustomDatePicker
                                    value={courseInfos.endDate}
                                    onChange={(value) => {
                                        setCourseInfos(infos => ({
                                                ...infos,
                                                endDate:value
                                        }))
                                    }}
                                />
                            </div>
                        </div>

                        {/* <>
                            <div className='mt-2 mb-1'>
                                Cours prerequis:
                            </div>
                            <CustomSelect
                            />
                        </> */}
                        <>
                            <div className='mt-2 mb-1'>
                                Description des connaissances prerequises:
                            </div>
                            <textarea
                                value={courseInfos.prerequisitesDescription}
                                onChange={(e) => setCourseInfos(infos => {
                                    return {
                                        ...infos,
                                        prerequisitesDescription:e.target.value
                                    }
                                })}
                                className='textInput'
                                placeholder='Entrez quelque chose...'
                            />
                        </>

                        <div className="flex gap-x-8">
                            <div className="w-full">
                                <div className='mt-2 mb-1'>
                                    Duree d'application:
                                </div>
                                <CustomSelect
                                    value={{label:courseInfos.applicationDuration, value:courseInfos.applicationDuration}}
                                    options={Array(20).fill(0).map((number, index) => {return {label:index/2, value:index/2}})}
                                    onChange={(newValue:any ) => setCourseInfos(infos => {
                                        return {
                                            ...infos,
                                            applicationDuration:newValue.value
                                        }
                                    })}
                                />
                            </div>
                            <div className="w-full">
                                <div className='mt-2 mb-1'>
                                    Duree du cours:
                                </div>
                                <CustomSelect
                                    value={{label:courseInfos.courseDuration, value:courseInfos.courseDuration}}
                                    options={Array(20).fill(0).map((number, index) => {return {label:index/2, value:index/2}})}
                                    onChange={(newValue:any ) => setCourseInfos(infos => {
                                        return {
                                            ...infos,
                                            courseDuration:newValue.value
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <>
                            <div className='mt-2 mb-1'>
                                Approche pedagogique:
                            </div>
                            <CustomRichEditor
                                value={courseInfos.pedagogicApproach}
                                onChange={(e) => setCourseInfos(infos => {
                                    return {
                                        ...infos,
                                        pedagogicApproach:e
                                    }
                                })}
                            />
                        </>
                        <>
                            <div className='mt-2 mb-1'>
                                Evaluation:
                            </div>
                            <CustomRichEditor
                                value={courseInfos.evaluation}
                                onChange={(e) => setCourseInfos(infos => {
                                    return {
                                        ...infos,
                                        evaluation:e
                                    }
                                })}
                            />
                        </>
                        <>
                            <div className='mt-2 mb-1'>
                                Besoins:
                            </div>
                            <CustomRichEditor
                                value={courseInfos.needs}
                                onChange={(e) => setCourseInfos(infos => {
                                    return {
                                        ...infos,
                                        needs:e
                                    }
                                })}
                            />
                        </>
                        <>
                            <div className='mt-2 mb-1'>
                                Bibliographie:
                            </div>
                            <CustomRichEditor
                                value={courseInfos.bibliography}
                                onChange={(e) => setCourseInfos(infos => {
                                    return {
                                        ...infos,
                                        bibliography:e
                                    }
                                })}
                            />
                        </>
                    </div>
                    <div className="flex ml-auto px-8 gap-x-4 mt-10">
                        <CustomButton
                            onClick={() => navigate(-1)}
                            title="Annuler les modifications"
                            variant="btnSecondary"
                            className="min-w-max"
                        />
                        <CustomButton
                            onClick={onSave}
                            title={courseInfos.flag ==='new'? "Creer le cours": "Enregistrer"}
                            className="min-w-[10rem]"
                            loading={loading}
                        />
                        {
                          courseInfos.flag !='new'?
                          <CustomButton
                              title="Supprimer"
                              variant="btnDanger"
                              onClick={() => setDeleteDialogOpen(true)}
                              className="w-40 ml-auto"
                          />
                          :null
                        }
                    </div>
                </div>
            </div>
            <CustomDialog
              confirmtButtonTitle="Procéder"
              cancelButtonTitle="Annuler"
              onConfirm={onCourseDelete}
              open={deleteDialogOpen}
              setOpen={setDeleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              dialogType="confirm"
              title="Êtes vous sûr de vouloir supprimer le pillier?"
              message="Si vous confirmez, les chapitres et tâches sous ce pillier ne seront plus disponible aussi"
              cancelButton={{variant:'btnSecondary'}}
              confirmButton={{variant:'btnDanger'}}
            />
        </div>
    )
};

export default CourseInfos;