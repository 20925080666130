import CustomButton from "../../../components/CustomButton";
import { useState } from 'react';
import { functions } from "../../../App";
import { useLocation, useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { BibliographyType } from "../../../global/types";
import CustomRichEditor from "../../../components/CustomRichEditor";
import CustomDialog from "../../../components/CustomDialog";


const initialValues: BibliographyType = {
  id: Date.now().toString(),
  title: "",
  description: "",
};


const BibliographyAdd = () => {

  const navigate = useNavigate();
  const state: { bibliography: BibliographyType } | null = useLocation().state;

  const initialContent = state?.bibliography ? { ...initialValues, ...state.bibliography } : initialValues
  const [bibliography, setBibliography] = useState(initialContent);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const checkFormValid = () => {
    setFormError("");
    if (!bibliography.title) setFormError("Vous spécifier un titre pour la bibliographie");
    return Boolean(bibliography.title);
  };


  const onSaveBibliography = async () => {
    if (loading || !checkFormValid()) return;
    setLoading(true);


    try {
      const initObj:Partial<BibliographyType> = {
        title: bibliography.title,
        description: bibliography.description,
      };

      if(state?.bibliography ===undefined) {
        const initBibliography = httpsCallable(functions, 'initBibliography');
        await initBibliography(initObj)
      } else {
        const updateBibliography = httpsCallable(functions, 'updateBibliography');
        await updateBibliography({bibliographyId:state.bibliography.id, bibliography:initObj});
      };
      
      navigate(-1);
    } catch (error) {
      console.log('An error occured while initializing the bibliography', error)
    }
    setLoading(false);
  };

  const onBibliographyDelete = async () => {
    if(loading||deleteLoading||!state?.bibliography) return;
    setDeleteLoading(true);
    try {
      const deleteBibliography = httpsCallable(functions, 'deleteBibliography');
      await deleteBibliography({bibliographyId:state.bibliography.id});
      navigate(-1);
    } catch (error) {
      console.log('An error occured while deleting bibliography: ', error);
    };
    setDeleteLoading(false);
  }
  return (
    <div className="mt-4 mb-10 mx-auto flex flex-1 flex-col max-w-6xl ">
      <div className="p-3  lg:p-6 rounded-2xl flex flex-1 flex-col bg-secondary-light">
        <div className="w-full max-w-6xl mx-auto">
          <div className="text-2xl font-medium mb-4 self-start flex justify-between items-center">
            {
              state?.bibliography?
              'Modifier la bibliographie'
              :
              'Initialiser une bibliographie (collection de documents)'
            }
          </div>
          <div className='mt-2 mb-1'>
            Titre de la bibliographie:
          </div>
          <input
            value={bibliography.title}
            onChange={(e) => setBibliography(infos => ({ ...infos, title: e.target.value }))}
            className='textInput'
            placeholder='Entrez quelque chose...'
          />
          <div className='mt-2 mb-1'>
            Description:
          </div>
          <CustomRichEditor
            value={bibliography.description}
            onChange={value => setBibliography(infos => ({ ...infos, description: value }))}
          />
          {
            formError ?
              <div className="mt-4 text-red-600 font-medium">
                {formError}
              </div>
              :
              null
          }
          <div className="flex flex-1 justify-between mt-8 lg:mt-12">
            {
              state?.bibliography?<CustomButton
                title="Supprimer"
                variant="btnDanger"
                className="w-40"
                loading={deleteLoading}
                onClick={() => setDeleteDialogOpen(true)}
              />
              :
              <div/>
            }
            <div className="self-end flex gap-x-4">
              <CustomButton
                title="Annuler"
                variant="btnSecondary"
                className="w-40"
                onClick={() => navigate(-1)}
              />
              <CustomButton
                loading={loading}
                onClick={onSaveBibliography}
                title="Enregistrer"
                className="w-48"
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        onConfirm={onBibliographyDelete}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        dialogType="confirm"
        title="Êtes vous sûr de vouloir supprimer la bibliographie?"
        message="Si vous confirmez, la bibliographie ainsi que tous les documents qui s'y trouvent seront supprimés de la plateforme"
        confirmtButtonTitle="Procéder"
        cancelButtonTitle="Annuler"
        cancelButton={{variant:'btnSecondary'}}
        confirmButton={{variant:'btnDanger'}}
      />
    </div>
  );
}


export default BibliographyAdd;