import CustomButton from "../../../components/CustomButton";
import { useEffect, useState } from 'react';
import CustomSelect from "../../../components/CustomSelect";
import MCQGenerator from "./components/MCQGenerator";
import StructuralGenerator from "./components/StructuralGenerator";
import { ChapterTaskType, MCQQuestionType, StructuralQuestionType, activitiesType } from "../../../global/types";
import { CustomFileInput } from "../../../components/CustomInput";
import CustomRichEditor from "../../../components/CustomRichEditor";
import CustomDatePicker from "../../../components/CustomDatePicker";
import moment from "moment";
import { mockStructuralQuestions } from "../../../assets/formations";
import { httpsCallable } from "firebase/functions";
import { functions, storage } from "../../../App";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ref, uploadBytes } from "firebase/storage";



type FormValues = {
  title: string;
  description?: string //HTML template representing more detailed description about a submission|mcq|structural 
  type: activitiesType | "";
  startDate: Date;
  endDate: Date;
  timeUnbounded?: boolean
  youtubeVideoLink?: string; // Gives a link to a youtube video
  videoFile?: File | null;  //Uploads a video to the platform
  documentUrl?: string;
  documentFile?: File | null; // Uploads notes
  teleMeetingStartDate?: Date
};

const activitiesTypes: { label: string, value: activitiesType }[] = [
  { label: 'Charger une video', value: 'videoFile' },
  { label: 'Video Youtube', value: 'youtubeVideo' },
  { label: 'Charger un document', value: 'documentFile' },
  { label: 'Lien vers un document / Site web', value: 'documentUrl' },
  { label: 'QCM', value: 'MCQ' },
  // { label: 'Questions Ouvertes', value: 'structural' },
  { label: 'Devoir a soumettre', value: 'submission' },
  { label: 'Reunion en ligne', value: "teleMeeting" }
];

const initialValues: FormValues = {
  title: "",
  description: "",
  type: "",
  youtubeVideoLink: "",
  documentFile: null,
  videoFile: null,
  documentUrl: "",
  startDate: moment().toDate(),
  endDate: moment().add({ days: 1 }).toDate(),
  teleMeetingStartDate:moment().toDate(),
};


const initMCQQuestion: MCQQuestionType = {
  question: '',
  pseudoIndex: Date.now(),
  metaIndex: Date.now(),
  weight: 1,
  explanation: '',
  answers: [{ isCorrect: true, text: '', weight: 0 }, { isCorrect: false, text: '', weight: 0 }],
  type:'mcq'
};

const ActivityAdd = () => {
  type ActivitiesMap = {
    [key in activitiesType]?: boolean;
  };

  const navigate = useNavigate();
  const state: { taskData: ChapterTaskType | undefined } | null = useLocation().state;

  const { chapterId } = useParams();

  // the "initialValues" object is destructured to initialize the values that are not inheritent to the 
  // taskData state (for example documentFile property), then, state.taskData is destructured to update the
  // fields where taskData state has already defined their values
  const initialTaskContent = state?.taskData ? { ...initialValues, ...state.taskData } : initialValues
  const [activityInfos, setActivityInfos] = useState(initialTaskContent);
  const [inputsVisible, setInputsVisible] = useState<ActivitiesMap>({});

  const [structuralQuestions, setStructuralQuestions] = useState<StructuralQuestionType[]>(mockStructuralQuestions);
  const [MCQs, setMCQs] = useState<MCQQuestionType[]>([initMCQQuestion]);

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState("");

  const handleTypeChange = (option: { label: string, value: activitiesType } | undefined) => {
    if (!option) return; // return if "option" is set to undefined

    if (option.value === "youtubeVideo") {
      setInputsVisible({ youtubeVideo: true })
    } else if (option.value === "videoFile") {
      setInputsVisible({ videoFile: true });
    } else if (option.value === "documentFile") {
      setInputsVisible({ documentFile: true });
    } else if (option.value === "documentUrl") {
      setInputsVisible({ documentUrl: true });
    } else if (option.value === "MCQ") {
      setInputsVisible({ MCQ: true });
    } else if (option.value === "structural") {
      setInputsVisible({ structural: true });
    } else if (option.value === "submission") {
      setInputsVisible({ submission: true }); //reset the visible fields
    } else if (option.value === "teleMeeting") {
      setInputsVisible({ teleMeeting: true }); //reset the visible fields
    }

    if (formError) setFormError("");
    setActivityInfos(formValues => { return { ...formValues, type: option.value } });
  };

  const checkFormValid = (values: FormValues) => {
    setFormError("");

    if (values.type === 'MCQ' && MCQs.length === 0) {
      setFormError("Une épreuve de type QCM ne peut pas être vide.");
      return false;
    } else if (values.type === 'documentFile' && !values.documentFile) {
      setFormError("Veuillez sélectionner un document s'il vous plaît.");
      return false;
    } else if (values.type === 'documentUrl' && !values.documentUrl) {
      setFormError("Veuilliez entrez le lien vers le document à référencer");
      return false;
    } else if (values.type === 'structural' && structuralQuestions.length === 0) {
      setFormError("Une épreuve de type structurelle ne peut pas être vide.");
      return false;
    } else if (values.type === 'submission') {

    } else if (values.type === 'teleMeeting' && !values.teleMeetingStartDate) {
      setFormError("Veuillez choisir une heure pour le début du cours en ligne");
      return false;
    } else if (values.type === 'videoFile' && !values.videoFile) {
      setFormError("Veuillez sélectionner une vidéo à partager s'il vous plaît");
      return false;
    } else if (values.type === 'youtubeVideo' && !values.youtubeVideoLink) {
      setFormError("Entrez le lien vers la vidéo YouTube s'il vou plait");
      return false
    };

    return true;
  };

  const onTaskSubmit = async () => {
    if (loading || !activityInfos.type) return;

    const formValid = checkFormValid(activityInfos);
    if (!formValid) return;

    setLoading(true);
    try {
      let fileName = null;
      if (activityInfos.videoFile && activityInfos.type === 'videoFile')
        fileName = activityInfos.videoFile.name.split('/').at(-1);
      if (activityInfos.documentFile && ['submission', 'documentFile'].includes(activityInfos.type))
        fileName = activityInfos.documentFile.name.split('/').at(-1);

      const submitObj = {
        chapterId,
        task: {
          ...activityInfos,
          documentFile: null,
          videoFile: null,
          fileName,
          structuralQuestions,
          MCQs,
          startDate: activityInfos.startDate.toISOString(),
          endDate: activityInfos.endDate.toISOString(),
        }
      };

      let resultTask;
      if (state?.taskData) {
        const updateChapterTask = httpsCallable(functions, 'updateChapterTask')
        resultTask = (await updateChapterTask({ ...submitObj, taskId: state.taskData.id })).data as ChapterTaskType;
      } else {
        const createChapterTask = httpsCallable(functions, 'createChapterTask');
        resultTask = (await createChapterTask(submitObj)).data as ChapterTaskType;
      }

      if (activityInfos.videoFile && activityInfos.type === 'videoFile') {
        const filePath = `tasks/${resultTask.id}.${fileName?.split('.').at(-1)}`;
        const storageRef = ref(storage, filePath);
        await uploadBytes(storageRef, activityInfos.videoFile, { contentDisposition: fileName ? `attachment; filename="${fileName}"` : undefined });
      } else if (activityInfos.documentFile && ['submission', 'documentFile'].includes(activityInfos.type)) {
        console.log('Result task: ', resultTask)
        const filePath = `tasks/${resultTask.id}.${fileName?.split('.').at(-1)}`;
        const storageRef = ref(storage, filePath);
        await uploadBytes(storageRef, activityInfos.documentFile, { contentDisposition: fileName ? `attachment; filename="${fileName}"` : undefined });
      };
      // setActivityInfos(initialValues);
      navigate(-1);
    } catch (error) {
      console.log('Error while creating/updating new chapter task:', error);
    }
    setLoading(false);
  };

  const getAndSetTask = async () => {
    if(!state?.taskData?.id) return;
    try {
      const getTaskData = httpsCallable(functions, 'getTaskData')
      const taskData = (await getTaskData({ taskId: state.taskData.id, fullTask:true })).data as unknown as ChapterTaskType;
      setActivityInfos({ ...initialValues, ...taskData, endDate:new Date(taskData.endDate), startDate:new Date(taskData.startDate) });

      handleTypeChange(activitiesTypes.find(item => item.value == taskData.type));
      if (taskData.type === 'MCQ') {
        setMCQs(taskData.MCQs as MCQQuestionType[]);
      } else if (taskData.type === 'structural') {
        setStructuralQuestions(taskData.structuralQuestions as StructuralQuestionType[]);
      }
    } catch (error) {
      console.log('Error while getting task data: ', error);
    }
  };

  useEffect(() => {
    const taskData = state?.taskData;
    if (taskData) getAndSetTask();
  }, [])
  return (

    <div className="mt-4 mb-10 mx-auto flex flex-1 flex-col max-w-7xl ">
      <h2 className="text-4xl font-medium ml-4 mb-6 self-start">Ajouter une activite</h2>
      <div className="p-3  lg:p-6 rounded-2xl flex flex-1 flex-col bg-secondary-light">
        <div className="w-full max-w-6xl mx-auto">
          <div className="text-2xl font-medium mb-4 self-start flex justify-between items-center">
            General
          </div>
          <div className='mt-2 mb-1'>
            Titre de l'activité:
          </div>
          <input
            value={activityInfos.title}
            onChange={(e) => setActivityInfos(infos => ({ ...infos, title: e.target.value }))}
            className='textInput'
            placeholder='Entrez quelque chose...'
          />
          <div className="flex gap-x-8">
            <div className="w-full">
              <div className='mt-2 mb-1'>
                Date de début:
              </div>
              <CustomDatePicker
                value={activityInfos.startDate}
                onChange={(date) => setActivityInfos(infos => ({ ...infos, startDate: date }))}
                initialValue={moment().toDate()}
              />
            </div>
            <div className="w-full">
              <div className='mt-2 mb-1'>
                Date de Fin:
              </div>
              <CustomDatePicker
                value={activityInfos.endDate}
                onChange={(date) => setActivityInfos(infos => ({ ...infos, endDate: date }))}
                initialValue={moment().add({ days: 1 }).toDate()}
              />
            </div>
          </div>

          <div className='mt-2 mb-1'>
            Type d'activité:
          </div>
          <CustomSelect
            name="type"
            value={activitiesTypes.find(item => item.value === activityInfos.type)}
            options={activitiesTypes}
            onChange={(el) => handleTypeChange(el as { label: string, value: activitiesType })}
          />

          <div className='mt-2 mb-1'>
            Description de l'activité:
          </div>
          <CustomRichEditor
            value={activityInfos.description}
            onChange={(value) => setActivityInfos(infos => ({ ...infos, description: value }))}
          />
          {inputsVisible.youtubeVideo ? (
            <>
              <div className='mt-2 mb-1'>
                Entrez le lien de la video Youtube:
              </div>
              <input
                value={activityInfos.youtubeVideoLink}
                className='textInput'
                onChange={(e) => setActivityInfos(infos => ({ ...infos, youtubeVideoLink: e.target.value }))}
                placeholder="https://youtu.be/..."
              />
            </>
          ) : null
          }

          {inputsVisible.videoFile ? (
            <>
              <div className='mt-2 mb-1'>
                Choisir le fichier vidéo:
              </div>

              <CustomFileInput
                onChange={(e) => setActivityInfos(value => { return { ...value, videoFile: e.target.files?.item(0) } })}
                name="videoFile"
                accept="video/*"
                placeholder="Choisir un fichier vidéo"
              />
            </>
          ) : null
          }

          {
            inputsVisible.documentUrl ? (
              <>
                <div className='mt-2 mb-1'>
                  Entrez le lien vers le site web ou document:
                </div>

                <input
                  value={activityInfos.documentUrl}
                  onChange={(e) => setActivityInfos(value => { return { ...value, documentUrl: e.target.value } })}
                  className='textInput'
                  name="documentUrl"
                  placeholder="Addresse url vers le contenu"
                />
              </>
            ) : null
          }

          {
            inputsVisible.documentFile ? (
              <>
                <div className='mt-2 mb-1'>
                  Choisir le document:
                </div>
                <CustomFileInput
                  onChange={(e) => setActivityInfos(value => { return { ...value, documentFile: e.target.files?.item(0) } })}
                  name="documentFile"
                  placeholder="Document :"
                />
              </>
            ) : null
          }

          {
            inputsVisible.MCQ ? (
              <div className="mt-8">
                <MCQGenerator
                  questions={MCQs}
                  setQuestions={setMCQs}
                />
              </div>
            ) : null
          }

          {
            inputsVisible.structural ? (
              <div className="mt-8">
                <StructuralGenerator
                  questions={structuralQuestions}
                  setQuestions={setStructuralQuestions}
                />
              </div>
            ) : null
          }
          {
            inputsVisible.teleMeeting ? (
              <>
                <div className='mt-2 mb-1'>
                  Heure du début du cours:
                </div>
                <CustomDatePicker
                  value={activityInfos.teleMeetingStartDate}
                  onChange={(date) => setActivityInfos(infos => ({ ...infos, teleMeetingStartDate: date }))}
                />
              </>
            ) : null
          }
          {
            formError ?
              <div className="mt-4 text-red-600 font-medium">
                {formError}
              </div>
              :
              null
          }
          <div className="flex ml-auto justify-end px-8 gap-x-8 mt-6 max-w-lg">
            <CustomButton
              title="Annuler"
              variant="btnDanger"
              className="w-40"
              onClick={() => navigate(-1)}
            />
            <CustomButton
              loading={loading}
              onClick={onTaskSubmit}
              title="Enregistrer"
              className="w-48"
            />
          </div>
        </div>
      </div>
    </div>

  );
}


export default ActivityAdd;