import "../../../../assets/react-quill-snow.css";
import docx from '../../../../assets/docx.png';
import txt from '../../../../assets/txt.png';
import otherdoc from '../../../../assets/otherdoc.png';
import pptx from '../../../../assets/pptx.png';
import pdf from '../../../../assets/pdf.png';
import xlsx from '../../../../assets/xlsx.png';
import { Link, useNavigate, useParams } from "react-router-dom"
import CustomButton from "../../../../components/CustomButton";
import { CustomFileInput } from "../../../../components/CustomInput";
import { ChapterTaskType, UserType } from "../../../../global/types";
import { Dictionary } from "../../../../global/types";
import { useContext, useEffect, useState } from "react";
import { ref, uploadBytes } from "firebase/storage";
import { functions, storage } from "../../../../App";
import { httpsCallable } from "firebase/functions";
import { AuthContext } from "../../../../contexts/AuthContext";


const getIcon = (extension: string) => {
  const icons: Dictionary = {
    doc: docx,
    docx: docx,
    pdf: pdf,
    ppt: pptx,
    pptx: pptx,
    txt: txt,
    xls: xlsx,
    xlsx: xlsx,
  };
  if (Object.hasOwn(icons, extension)) {
    return icons[extension]
  } else {
    return otherdoc
  }
}


const docTypes = {
  'pdf': 'PDF',
  'doc': 'Word',
  'docx': 'Word',
  'ppt': 'PowerPoint',
  'pptx': 'PowerPoint',
  'xls': 'Excel',
  'xlsx': 'Excel',
  'txt': 'Text'
};

type SubmissionPropsType = {
  task: ChapterTaskType
  user: UserType,
};

const Submission: React.FC<SubmissionPropsType> = ({ task, user }) => {
  type submissionInfosType = { documentFile: File | null, additionnalComments: string };
  const { taskId, answerUserId } = useParams();
  const [submissionInfos, setSubmissionInfos] = useState<submissionInfosType>({ documentFile: null, additionnalComments: '' })
  const [loading, setLoading] = useState(false);
  const [taskData, setTaskData] = useState(task);
  const [formError, setFormError] = useState("");
  const currentUser = useContext(AuthContext)?.user
  const navigate = useNavigate();

  const onSubmitFile = async () => {
    if (loading) return;
    setFormError("")
    if (!submissionInfos.documentFile) {
      setFormError("Veuillez sélectionner un fichier s'il vous plaît.");
      return
    };

    setLoading(true);
    try {
      const submitDocumentAnswer = httpsCallable(functions, 'submitDocumentAnswer');
      const submitObj = { taskId: taskId, additionnalComments: '', fileName:submissionInfos.documentFile.name };

      const response = (await submitDocumentAnswer(submitObj)).data;
      let fileName = null;
      fileName = submissionInfos.documentFile.name.split('/').at(-1);
      const filePath = `tasks/${taskId}/results/${user.id}.${fileName?.split('.').at(-1)}`;
      const storageRef = ref(storage, filePath);
      await uploadBytes(storageRef, submissionInfos.documentFile, { contentDisposition: fileName ? `attachment; filename="${fileName}"` : undefined });
    
      getAndSetSubmissionData(); // to refresh the submission state
    } catch (error) {
      console.log('Error while submitting file: ', error)
    };
    setLoading(false)
  };

  function downloadZip(url: string, filename: string) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
      });
  };

  const onGetDownloadAnswers = async () => {
    if (loading) return;
    setLoading(true)
    const getSubmissionAnswersZip = httpsCallable(functions, 'getSubmissionAnswersZip');
    try {
      const zipAnswersLink = (await getSubmissionAnswersZip({ taskId: taskId })).data;
      downloadZip(zipAnswersLink as string, `${taskData.title}.zip`)
    } catch (error) {
      console.log('Error while getting submission results zip: ', error);
    };
    setLoading(false);
  };


  const getAndSetSubmissionData = async () => {
    try {
      const getTaskData = httpsCallable(functions, 'getTaskData');
      const submissionData = (await getTaskData({ taskId: taskId, fullTask: true, withResultsForUsers: answerUserId ? [answerUserId] : [user.id] })).data as unknown as ChapterTaskType;
      setTaskData({
        ...taskData,
        ...submissionData,
        fileUrl:submissionData.submissionResults?.at(0)?.fileUrl,
        fileName:submissionData.submissionResults?.at(0)?.fileName,
      });
      console.log('Task data: ', taskData);
    } catch (error) {
      console.log('Error while getting user submission data: ', error);
    }
  };

  useEffect(() => {
      getAndSetSubmissionData();
  }, []);
  return (
    <div>
      {/* <Link
        to={taskData.fileUrl || '#'}
        className="flex bg-white rounded-xl p-4 mt-4"
      >
        {
          taskData.fileUrl ?
            <img
              className="h-32 w-32 object-contain bg-slate-200 rounded-md"
              src={getIcon(taskData.fileUrl.split('.').at(-1) || 'otherdoc')}
              alt=""
            />
            :
            null
        }
        <div className="mx-4">
          <p className="text-xl mb-2">{taskData.fileName} </p>
        </div>
      </Link> */}
      <div className="mt-6">
        {
          (answerUserId && ['admin', 'teacher', 'expert'].some((item: any) => user.accountType.includes(item)))
          ||taskData.hasUserDoneTask&&taskData.fileUrl ?
            <>
              {!taskData.hasUserDoneTask?"Soumission de l'apprenant":"Votre soumission:"} 
              <Link
                to={taskData.fileUrl || '#'}
                className="flex bg-white rounded-xl p-4 mt-4 flex-row-reverse justify-between"
              >
                {
                  taskData.fileUrl ?
                    <img
                      className="h-16 w-16 lg:h-20 lg:w-20 object-contain bg-slate-200 rounded-md"
                      src={getIcon(taskData.fileUrl?.split('.').at(-1) || 'otherdoc')}
                      alt=""
                    />
                    :
                    null
                }
                <div className="mx-4">
                  <p className="text-xl mb-2">Votre soumission: </p>
                  <div className="mx-4">
                  <p className="text-xl mb-2">{taskData.fileName} </p>
                </div>
                </div>

              </Link>
            </>
            :
            null
        }

        {
          !answerUserId && ['student'].some((item: any) => user.accountType.includes(item)) ?
            taskData.hasUserDoneTask ?
              // if it is a student and he already submitted
              // <div>Vous avez deja soumis votre devoir</div>
              null
              :
              // if it is a student and he has not yet submitted
              <>
                {/* <p className="p-1 mb-2">Votre reponse:</p>
                        <textarea
                            onChange={(e) => setSubmissionInfos(infos => ({...infos, additionnalComments:e.target.value}))}
                            value={submissionInfos.additionnalComments}
                            className="textInput"
                        /> */}
                <span>Votre devoir: </span>
                <CustomFileInput
                  onChange={e => setSubmissionInfos(infos => ({ ...infos, documentFile: e.target.files?.item(0) || null }))}
                  className="mt-2"
                  accept=".pdf,.ppt,.pptx,.txt,.doc,.docx"
                  placeholder="Choisir un fichier"
                />
                {
                  formError ?
                    <div className="mt-4 text-center text-red-600 font-medium">
                      {formError}
                    </div>
                    :
                    null
                }
                <CustomButton
                  loading={loading}
                  onClick={onSubmitFile}
                  className="w-4/5 mx-auto mt-4"
                  title="Soumettre le devoir"
                />
              </>
            :
            // If it is not a student
            <CustomButton
              loading={loading}
              onClick={onGetDownloadAnswers}
              className="w-4/5 mx-auto mt-4"
              title="Telecharger toutes les reponses"
            />
        }
      </div>

    </div>
  )
};

export default Submission;