import { LinearProgress } from '@mui/material';
import { DataGrid, GridClasses, GridColDef, GridNoRowsOverlay, GridRowParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import 'moment/locale/fr';
import { ChapterTaskUserRecord } from '../../../../../global/types';


const columns: GridColDef[] = [
  // { field: 'id', headerName: 'ID', minWidth: 50, flex:1 },
  { field: 'title', headerName: "Titre de la tâche", minWidth: 240, flex: 3 },
  { field: 'type', headerName: "Type de tâche", minWidth: 75, flex: 2 },
  { field: 'chapter', headerName: 'Chapitre', minWidth: 75, flex: 2 },
  { field: 'course', headerName: 'Cours', minWidth: 75, flex: 2 },
  // { field: 'maxMarks', headerName: 'Maximum', minWidth: 50, flex: 1 },
  // { field: 'status', headerName: 'Statut', minWidth: 50, flex:1 },
  { field: 'assignedOn', headerName: 'Date Assignée', minWidth: 50, flex: 2 },
];

const CustomToolbar = () => {
  return (
    <GridToolbarContainer className='bg-secondary-light' sx={{ paddingX: 2 }}>
      <GridToolbarColumnsButton className='text-dark' sx={{ paddingY: 2, color: '#7F5032' }} />
      <GridToolbarFilterButton sx={{ paddingY: 2, color: '#7F5032' }} />
      <GridToolbarDensitySelector sx={{ paddingY: 2, color: '#7F5032' }} />
      <GridToolbarExport sx={{ paddingY: 2, color: '#7F5032' }} />
    </GridToolbarContainer>
  );
};

type MarksGridPropsType = {
  isLoading: boolean
  taskData: ChapterTaskUserRecord[]
};

const TasksGrid: React.FC<MarksGridPropsType> = ({ isLoading, taskData }) => {
  const navigate = useNavigate();

  const rows = taskData.map((data) => ({
    id: data.id.toString(),
    title: data.title,
    type: data.type,
    teacher: data.author.name + ' ' + data.author.surname,
    // obtainedMarks: 0,
    // maxMarks: 100,
    status: data.status,
    chapter: data.taskChapter.title,
    course: data.taskCourse.title,
    assignedOn: moment(data.createdAt).locale('fr').format('DD MMMM YYYY HH:mm')
  }));


  const gridClassNames: Partial<GridClasses> = {
    footerContainer: 'bg-secondary-light'
  }

  const onRowClick = (row: GridRowParams<any>) => {
    const task = taskData.find(item => item.id === row.id);
    if (!task) return;
    const path = `/console/tasks/${task.id}`

    navigate(path, { state: { task: task } });
  };
  return (
    <div className='flex flex-1 flex-col'>
      <div className='font-medium text-3xl my-4'>Grille d'activites</div>
      <div className='h-[80vh] w-full mb-8'>
        <DataGrid
          slots={{ toolbar: CustomToolbar, loadingOverlay: LinearProgress, noRowsOverlay: GridNoRowsOverlay }}
          classes={gridClassNames}
          autoPageSize
          rows={rows}
          columns={columns}
          onRowDoubleClick={onRowClick}
        />
      </div>
    </div>
  )
};

export default TasksGrid;