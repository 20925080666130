import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { ChatMessage, ChatType, UserType } from "../../../../global/types";
import { AuthContext } from "../../../../contexts/AuthContext";
import { FaPaperPlane } from "react-icons/fa";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { query, collection, doc, orderBy, getDocs, addDoc, onSnapshot, DocumentSnapshot} from 'firebase/firestore';
import { db, functions } from "../../../../App";
import { httpsCallable } from "firebase/functions";
import { notUndefined } from "../../../../utils";
import CustomButton from "../../../../components/CustomButton";


type ChatBublePropsType = {
  opposite:boolean;
  message: ChatMessage
};

const ChatBuble = ({ message, opposite }: ChatBublePropsType) => {
  return (
    <li className={`flex gap-x-2 sm:gap-x-4 max-w-[80%] ${(!opposite?'self-end flex-row-reverse':'')}`}>

      <img
        className="flex-shrink-0 w-[2.375rem] h-[2.375rem] rounded-full"
        src={message.author?.profileImgUrl || require('../../../Home/assets/formation_placeholder.png')}
        alt={message.author?.name}
      />
      <div className="grow max-w-[90%] md:max-w-2xl w-full space-y-3">
        <div className={"border-gray-200 rounded-lg p-2 lg:px-4 "+(opposite ?'border border-secondary-dark':'bg-secondary-dark')}>
          <p className={"text-sm text-gray-800 dark:text-white mt-1 "+(opposite?'':'text-white')}>
          {message.content.split('\n').map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
          </p>
          <div className="mt-1">
            <span className={"text-sm font-light "+ (opposite?'text-gray-600':'text-gray-200')}>
              {message.author?.name} {message.author?.surname}
              , {moment(message.sentAt).format('D MMM YYYY [:] HH[h] mm [mins]')}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};



const Chat = () => {
  const currentUser = useContext(AuthContext)?.user;
  const [chat, setChat] = useState<ChatType | null>(null);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [textContent, setTextContent] = useState('');
  const [sendingComment, setSendingComment] = useState(false);
  const [isAllowed, setIsAllowed] = useState(true);
  const bottomRef:React.LegacyRef<HTMLUListElement> = useRef(null);
  const {chatId} = useParams();
  const navigate = useNavigate();

  const chatMessagesRef = query(
    collection(db, `chats/${chatId}/messages`),
    orderBy('sentAt', 'asc')
  );

  const getAndSetChat = async () => {
    try {
      const getChatById = httpsCallable(functions, 'getChatById');
      const result = (await getChatById({chatId})).data as {chat:ChatType};
      setChat(result.chat)
    } catch (error:any) {
      if(error.code ==='permission-denied') setIsAllowed(false);
      console.log('Error while getting the chat general infos from backend: ', error)
    }
  };


  const getMessagesFromSnaps = async (...snaps:DocumentSnapshot[]):Promise<ChatMessage[]> => {
    const messages:ChatMessage[]= snaps.map((doc) =>{
      const docData = doc.data();
      if(!docData) return;
      return ({
        id:doc.id,
        authorId:docData.authorId,
        content:docData.content,
        sentAt:new Date(docData.sentAt),
      })
    }).filter(notUndefined);

    const authorIds = messages.map(item => item.authorId);
    const dedupedIds = authorIds.filter((item, index, authorIds) => index == authorIds.indexOf(item));
    
    const getUsersByUids = httpsCallable(functions, 'getUsersByUids');
    const users = (await getUsersByUids({uids:dedupedIds})).data as UserType[];
    messages.forEach(message => {
      const author = users.find(item => item.id === message.authorId);
      message.author = author;
    });

    return messages;
  };

  const getAndSetChats = async () => {
    const querySnap = await getDocs(chatMessagesRef)
    const messages = await getMessagesFromSnaps(...querySnap.docs)
    setMessages(messages);
    bottomRef.current?.scrollIntoView({behavior:'smooth', block:'end', inline:'end'});
  };

  const onSendClick = async () => {
    if(sendingComment || !textContent.trim()) return;
    setSendingComment(true);

    try {
      const messageObj = {
        content:textContent.trim(),
        sentAt: new Date().toISOString(),
        authorId:currentUser?.id
      };
  
      const result = await addDoc(collection(db, `chats/${chatId}/messages`), messageObj)
      const newMessages = [...messages];
      newMessages.push({
        authorId:currentUser?.id as string,
        content:messageObj.content,
        id:result.id,
        sentAt:new Date(messageObj.sentAt),
        author:currentUser||undefined
      });

      setMessages(newMessages);
      setTextContent('');
      bottomRef.current?.scrollIntoView({behavior:'smooth', block:'end', inline:'end'});
    } catch (error) {
      console.log('An error happened while submitting the message: ', error);
    };

    setSendingComment(false);
  };


  useEffect(() => {
    getAndSetChat();
    getAndSetChats();
    // Subscribe to real-time updates using onSnapshot
    const unsubscribe = onSnapshot(chatMessagesRef, async (snapshot) => {
      const messages = await getMessagesFromSnaps(...snapshot.docs);
      setMessages(messages);
      // bottomRef.current?.scrollIntoView({behavior:'smooth', block:'end', inline:'end'});
    });
    
    // Clean up the listener when the component unmounts
    return () => unsubscribe();

  }, []);
  return (
    <div className="flex flex-1">
      <div className="mt-4 mb-12 px-1 py-4 md:p-4 mx-auto rounded-2xl w-full flex flex-col max-w-6xl bg-secondary-light">
        <div className="flex flex-col gap-2 overflow-y-scroll">

          {
            !isAllowed?
            <div className="py-12 mx-12 flex items-center justify-center flex-col h-[68vh]">
              <h1 className="text-2xl lg:text-3xl xl:text-4xl my-8 font-bold text-center">
                Désolé, mais vous n'avez pas acces à cette conversation.
                Veuillez contacter votre enseignant
              </h1>
              <CustomButton
                className="mx-auto mt-6"
                title="Retourner à la page d'Acceuil"
                onClick={() => navigate('/', {replace:true})}
              />
            </div>
          :
            <div className="flex flex-col lg:mx-12 xl:mx-16 flex-1">
              <div className=" sm:px-2 lg:px-4 lg:py-6">
                <div className="px-4 py-4 text-center">
                  <h1 className="text-xl lg:text-2xl font-medium text-gray-800 sm:text-4xl dark:text-white">
                    {chat?.title}
                  </h1>
                  <p className="mt-3 text-gray-600 dark:text-gray-400">
                    {chat?.description}
                  </p>
                </div>

                <ul className="mt-8 flex flex-1 flex-col gap-y-5 min-h-[80vh]">
                  {
                    chat && messages.map((message, index) => (
                      <ChatBuble
                        key={message.id + '_' + index}
                        opposite={message.authorId !== currentUser?.id}
                        message={message}
                      />
                    ))
                  }
                  <ul className="w-full" ref={bottomRef} />
                </ul>
              </div>

              <div className="sticky bottom-0 z-10 flex items-baseline justify-end px-3 lg:px-6 gap-x-2">
                <textarea
                  className="customInput"
                  rows={3}
                  value={textContent}
                  onChange={e => setTextContent(e.target.value)}
                />
                <button onClick={onSendClick} type="button" className="mt-auto mb-2 inline-flex flex-shrink-0 justify-center items-center w-9 h-9 rounded-lg text-white bg-blue-600 hover:bg-blue-500 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-500">
                  <FaPaperPlane size={16} className="" />
                </button>
              </div>
            </div>
          }

        </div>
      </div>
    </div>
  )
};


export default Chat;