import React, { useContext, useEffect, useState } from 'react';
import '../../assets/rc-tree-theme.css';
import Tree from 'rc-tree';
import { DataNode, EventDataNode, Key } from 'rc-tree/lib/interface';
import { getFormationDataForTree } from '../../utils/transformers';
import { BiChevronDownCircle, BiChevronUpCircle } from 'react-icons/bi';
import { NodeDragEventParams } from 'rc-tree/lib/contextTypes';
import { AllowDropOptions } from 'rc-tree/lib/Tree';
import { useNavigate, useParams } from 'react-router-dom';
import { FiPlusCircle } from 'react-icons/fi';
import { Dictionary, FormationChapterType, FormationCourseType, FormationModuleType, FormationPillarType, GlobalFormationType } from '../../global/types';
import { createChildNode, getChaptersFromPillars, getCoursesFromPillars, getModulesFromPillars, sortByIndexes } from '../../utils/formationTreeHandlers';
import { FormationContext } from '../../contexts/FormationContext';
import CustomButton from '../CustomButton';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../App';


type FormationStructPropsType = {
    formation?:GlobalFormationType;
    init?:boolean;
    loading?:boolean
}

const FormationStruct:React.FC<FormationStructPropsType> = ({formation, init}) => {
    const navigate = useNavigate();
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [indexesLoading, setIndexesLoading] = useState(false);
    
    const [loading, setLoading] = useState(false);
    const {formationId} = useParams();
    
    /*
    "formationData" includes all the informations from treeData, as well as supplementary informations
    concerning the formation. On the other hand, "treeData" contain only the strict minimum
    to represent and manipulate the formation tree
    */
   
    const {formationData, setFormationData, expandedKeys, setExpandedKeys} = useContext(FormationContext);
    // const [treeData, setTreeData] = useState<DataNode[]>(getFormationDataForTree({ formation: formationData }));

    const nodeChildren:Dictionary = {
        pillar:'module',
        module:'course',
        course:'chapter',
        chapter:null
    };

    const onDragStart = (info: any) => {
        console.log('start', info);
    };

    const onDragEnter = () => {
        console.log('enter');
    };

    const allowDrop = (options: AllowDropOptions<DataNode>) => {
        const dragKey = options.dragNode.key;
        const dropKey = options.dropNode.key;

        // Do not allow same hierarchy level elements to be embedden in one another.
        // For example, a module cannot be conterted into a course or a chapter
        if(options.dropPosition === 0) return false;
        
        // If the two nodes have the same prefix before the "_" (underscore),
        // then they are at the same hierarchical level
        if (dragKey.toString().split('_')[0]
            === dropKey.toString().split('_')[0]) {
            return true;
        } else {
            return false;
        }
    };

    type onDropPropsType = NodeDragEventParams<DataNode> & {
        dragNode: EventDataNode<DataNode>;
        dragNodesKeys: Key[];
        dropPosition: number;
        dropToGap: boolean;
    };
    
    const onDrop = (info: onDropPropsType) => {
        console.log('drop', info);
        const dataDropKey = info.node.key.toString().split('_')[1];
        const dataDragKey = info.dragNode.key.toString().split('_')[1];
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        // Find the drag object in the formation data
        let dragObj: FormationChapterType | FormationCourseType | FormationModuleType | FormationPillarType | undefined;
        let dragObjParent: FormationCourseType | FormationModuleType | FormationPillarType | GlobalFormationType | undefined;
        let dragObjIndex: number | undefined;

        const findDragObj = (dataNode: FormationChapterType | FormationCourseType | FormationModuleType | FormationPillarType, parent: FormationCourseType | FormationModuleType | FormationPillarType | GlobalFormationType) => {
            if (dataNode.id === dataDragKey) {
                dragObj = dataNode;
                dragObjParent = parent;
                if ('chapters' in parent) {
                    dragObjIndex = parent.chapters.findIndex(chapter => chapter.id === dataDragKey);
                } else if ('courses' in parent) {
                    dragObjIndex = parent.courses.findIndex(course => course.id === dataDragKey);
                } else if ('modules' in parent) {
                    dragObjIndex = parent.modules.findIndex(module => module.id === dataDragKey);
                } else if ('pillars' in parent) {
                    dragObjIndex = parent.pillars.findIndex(pillar => pillar.id === dataDragKey);
                }
            } else if ('chapters' in dataNode) {
                dataNode.chapters.forEach(chapter => findDragObj(chapter, dataNode));
            } else if ('courses' in dataNode) {
                dataNode.courses.forEach(course => findDragObj(course, dataNode));
            } else if ('modules' in dataNode) {
                dataNode.modules.forEach(module => findDragObj(module, dataNode));
            }
        }

        formationData.pillars.forEach(pillar => findDragObj(pillar, formationData));

        if (!dragObj || !dragObjParent || dragObjIndex === undefined) {
            return;
        }

        // Remove the drag object from its original position
        if ('chapters' in dragObjParent) {
            dragObjParent.chapters.splice(dragObjIndex, 1);
        } else if ('courses' in dragObjParent) {
            dragObjParent.courses.splice(dragObjIndex, 1);
        } else if ('modules' in dragObjParent) {
            dragObjParent.modules.splice(dragObjIndex, 1);
        } else if ('pillars' in dragObjParent) {
            dragObjParent.pillars.splice(dragObjIndex, 1);
        }

        // Find the drop object and its parent in the formation data
        let dropObj: FormationChapterType | FormationCourseType | FormationModuleType | FormationPillarType | undefined;
        let dropObjParent: FormationCourseType | FormationModuleType | FormationPillarType | GlobalFormationType | undefined;

        const findDropObj = (dataNode: FormationChapterType | FormationCourseType | FormationModuleType | FormationPillarType, parent: FormationCourseType | FormationModuleType | FormationPillarType | GlobalFormationType) => {
            if (dataNode.id === dataDropKey) {
                dropObj = dataNode;
                dropObjParent = parent;
            } else if ('chapters' in dataNode) {
                dataNode.chapters.forEach(chapter => findDropObj(chapter, dataNode));
            } else if ('courses' in dataNode) {
                dataNode.courses.forEach(course => findDropObj(course, dataNode));
            } else if ('modules' in dataNode) {
                dataNode.modules.forEach(module => findDropObj(module, dataNode));
            }
        }

        formationData.pillars.forEach(pillar => findDropObj(pillar, formationData));

        if (!dropObj || !dropObjParent) {
            return;
        }
        // Insert the drag object at its new position
        if (dropPosition === 0) {
            // Drop on the content
            if ('chapters' in dropObj) {
                dropObj.chapters.unshift(dragObj as FormationChapterType);
            } else if ('courses' in dropObj) {
                dropObj.courses.unshift(dragObj as FormationCourseType);
            } else if ('modules' in dropObj) {
                dropObj.modules.unshift(dragObj as FormationModuleType);
            } else if ('pillars' in dropObj) {
                // dropObj.pillars.unshift(dragObj as FormationPillarType);
                return // drag and drop does not exist for the Formation Global
            }
        } else {
            // Drop on the gap (insert before or insert after)
            let ar: (FormationChapterType | FormationCourseType | FormationModuleType | FormationPillarType)[] | undefined;
            let i: number | undefined;
            if ('chapters' in dropObjParent) {
                ar = dropObjParent.chapters;
                i = ar.findIndex(chapter => chapter.id === dataDropKey);
            } else if ('courses' in dropObjParent) {
                ar = dropObjParent.courses;
                i = ar.findIndex(course => course.id === dataDropKey);
            } else if ('modules' in dropObjParent) {
                ar = dropObjParent.modules;
                i = ar.findIndex(module => module.id === dataDropKey);
            } else if ('pillars' in dropObjParent) {
                ar = dropObjParent.pillars;
                i = ar.findIndex(pillar => pillar.id === dataDropKey);
            }
            if (!ar || i === undefined) {
                return;
            }
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        }

        if (setFormationData) setFormationData({ ...formationData });
    };

    const onExpand = (expandedKeys: React.Key[]) => {
      console.log('Key: ', expandedKeys);
        if(setExpandedKeys) setExpandedKeys(expandedKeys);
        setAutoExpandParent(false);
    };

    const levelToIcon: { [key: number]: string } = {
        0: "🌳",
        1: "🌴",
        2: "🍁",
        3: "🌿",
    };

    const addLevelToTreeData = (data: DataNode[], level = 0) => {
        return data.map((item) => {
            const newItem = { ...item, level };
            if (item.children) {
                newItem.children = addLevelToTreeData(item.children, level + 1);
            }
            return newItem;
        });
    };

    const titleRender = (node: DataNode): JSX.Element => {
        // @ts-ignore
        const icon = levelToIcon[node.level];
        
        // the type of the node can either be pillar, module, course or chapter
        const nodeType = node.key.toString().split('_')[0] as 'formation'|'pillar'|'module'|'course'|'chapter';
        const dataNodeId = node.key.toString().split('_').slice(1).join('_');
        
        
        const onTitleClick = () => {
            let navigateState:{
                nodeType:'formation'|'pillar'|'module'|'course'|'chapter';
                formationInfos?:Omit<GlobalFormationType, 'pillars'>;
                pillarInfos?:Omit<FormationPillarType, 'modules'>;
                moduleInfos?:Omit<FormationModuleType, 'courses'>;
                courseInfos?:Omit<FormationCourseType, 'chapters'>;
                chapterInfos?:FormationChapterType;
            } = {nodeType};

            if(nodeType ==='formation') {
                let clickedFormation:  (GlobalFormationType &{pillars:any})|undefined
                    = formationData;
                if(formationData) {
                    let cloneObj = {...clickedFormation};
                    delete cloneObj.pillars;
                    navigateState.formationInfos = cloneObj;
                }
            } else if(nodeType ==='pillar') {
                let clickedPillar:  (FormationPillarType &{modules:any})|undefined
                    = formationData.pillars.find(pillar => pillar.id == dataNodeId);
                if(clickedPillar) {
                    let cloneObj = {...clickedPillar};
                    delete cloneObj.modules;
                    navigateState.pillarInfos = cloneObj;
                }
            } else if(nodeType ==='module') {
                let clickedModule:  (FormationModuleType &{courses:any})|undefined
                    = getModulesFromPillars(formationData.pillars)
                        .find(module => module.id == dataNodeId);
                if(clickedModule) {
                    let cloneObj = {...clickedModule};
                    delete cloneObj.courses;
                    navigateState.moduleInfos = cloneObj;
                }
            } else if(nodeType ==='course') {
                let clickedCourse:  (FormationCourseType &{chapters:any})|undefined
                    = getCoursesFromPillars(formationData.pillars)
                        .find(course => course.id == dataNodeId);
                    if(clickedCourse) {
                        let cloneObj = {...clickedCourse};
                        delete cloneObj.chapters;
                        navigateState.courseInfos = cloneObj;
                    }
            } else if(nodeType === 'chapter') {
                let clickedChapter:  (FormationChapterType)|undefined
                    = getChaptersFromPillars(formationData.pillars)
                        .find(chapter => chapter.id == dataNodeId);
                    if(clickedChapter) {
                        let cloneObj = {...clickedChapter};
                        navigateState.chapterInfos = cloneObj;
                    }
            } else {
                return; // If the nodeType is "chapter" nothing will occur on click
            };

            
            navigate(nodeType ==='formation'?'general':nodeType, {
                state:navigateState
            });
        }

        const onSubNodeCreate = () => {
            if (nodeType ==='chapter') return;
            if (setFormationData) setFormationData(createChildNode(nodeType, dataNodeId, formationData));
            if (!expandedKeys.includes(node.key) && setExpandedKeys) setExpandedKeys(keys => [...keys, node.key])
        };
        
        return (
            <div className='flex items-center gap-x-3'>
                <button
                    className={'bg-slate-100 px-3 py-1 my-1 rounded-full text-lg '+(nodeType=='formation'?'mb-3 font-medium px-6 py-2 rounded-xl border-2 ':'')}
                    onClick={onTitleClick}
                >
                    <span>{icon}</span>
                    <span className=''>{node.title as string}</span>
                </button>
                {
                    // The "+" button should not appear on course nodes because it is the teacher who creates chapters
                    // and doesn't appear on chapter nodes because they have no childs
                    !['chapter', 'course'].includes(nodeType)?
                    <button
                        onClick={onSubNodeCreate}
                        title={'Ajouter un '+ nodeChildren[nodeType] +' à ce '+nodeType}
                        className='bg-slate-100 px-2 py-1 my-1 rounded-full'
                    >
                        <FiPlusCircle size={24} />
                    </button>
                    :null
                }
            </div>
        );
    };

    const onSave = async () => {
        if(indexesLoading) return;
        setIndexesLoading(true);

        const updateFormationNodesIndexes = httpsCallable(functions, 'updateFormationNodesIndexes');
        const indexesReducedArr = formationData.pillars.map(pillar => ({
            pillarId:pillar.id,
            modules:pillar.modules.map(module => ({
                moduleId:module.id,
                courses:module.courses.map(course => ({
                    courseId:course.id,
                    chapters:course.chapters.map(chapter => ({chapterId:chapter.id}))
                }))
            }))
        }));
        
        try {
            const result = await updateFormationNodesIndexes({indexes:indexesReducedArr});
            // console.log('Result: ', result)
        } catch (error) {
            console.log('Error while reorganizing the formation structure: ', error);
        };
        setIndexesLoading(false);
    };

    // useEffect(() => {
    //     // if (formation && setFormationData) {
    //     //     setFormationData(formation);
    //     // }
    // }, [loading]);
    
    
    const getFormationData = async () => {
        if(loading) return;
        setLoading(true);
        try {
            const getFormationTreeById = httpsCallable(functions, 'getFormationTreeById');
            const formationTree = (await getFormationTreeById({id:formationId})).data as GlobalFormationType;
            if(setFormationData) setFormationData(sortByIndexes(formationTree));
            if(setExpandedKeys && !expandedKeys.length) setExpandedKeys(['formation_'+formationTree.id]);
        } catch (error) {
            console.log('Error while getting formation data: ', error)
        }
        setLoading(false);
    };


    useEffect(() =>{
        getFormationData();
    }, []);
    return (
        <div className="flex flex-1">
            <div className="mt-4 mb-12 mx-auto w-full flex flex-col max-w-7xl ">
                <h2 className="text-4xl font-medium mb-4 self-start">Definition de la formation</h2>
                <div className='bg-secondary-light rounded-2xl  px-4 py-8 h-full'>
                    <div className="text-2xl font-medium mb-2 px-2 self-start flex justify-between items-center">
                        Hierarchie:
                    </div>
                    <div className="bg-primary max-h-[75vh] lg:max-h-[100vh] overflow-auto p-6 rounded-xl">
                        <Tree
                            expandedKeys={expandedKeys}
                            onExpand={onExpand}
                            draggable
                            autoExpandParent={autoExpandParent}
                            onDragStart={onDragStart}
                            onDragEnter={onDragEnter}
                            onDrop={onDrop}
                            treeData={getFormationDataForTree({ formation: formationData })}
                            allowDrop={allowDrop}
                            // Virtual
                            switcherIcon={(node) => node.isLeaf? <div style={{width:30}}/>: node.expanded ? <BiChevronUpCircle size={24} /> : <BiChevronDownCircle size={24} />}
                            virtual={false}
                            titleRender={titleRender}
                        />
                    </div>
                    <div className="px-8 flex gap-x-8 mt-10 max-w-lg">
                        {/* <CustomButton
                            onClick={() => navigate('..')}
                            title="Annuler les modifications"
                            variant="btnSecondary"
                            className="w-full"
                        /> */}
                        <CustomButton
                            loading={indexesLoading}
                            onClick={onSave}
                            title="Reorganiser la formation"
                            className="max-w-max"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormationStruct;