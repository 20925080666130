import { useEffect, useState } from "react";
import { GlobalFormationType } from "../../../global/types";
import { db, functions } from "../../../App";
import { httpsCallable } from "firebase/functions";
import { Card } from "../../Home/components/FormationsCards";
import { useLocation } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from "moment";

const Formations = () => {

  const state: { formations: Omit<GlobalFormationType, 'pillars'>[] | undefined } | null = useLocation().state
  const [formations, setFormations] = useState<Omit<GlobalFormationType, 'pillars'>[]>(state?.formations ? state.formations : []);
  const [loading, setLoading] = useState(false);

  const getAndSetFormations = async () => {
    setLoading(true);

    try {
      const q = query(collection(db, 'formations'), where('endDate', '>=', moment().format('YYYY-MM-DD')));

      const formationsSnaps = await getDocs(q);
      const formations: GlobalFormationType[] = formationsSnaps.docs.map(snap => {
        const snapData = snap.data();
        return ({
          id: snap.id,
          description: snapData.description,
          endDate: snapData.endDate,
          longDescription: snapData.longDescription,
          name: snapData.name,
          pillars: [],
          startDate: snapData.startDate,
          title: snapData.title,
          imageUrl: snapData.imageUrl
        })
      });

      setFormations(formations)
    } catch (error) {
      console.log('Error while fetching list of formations: ', error);
    }
    setLoading(false);
  };



  useEffect(() => {
    getAndSetFormations()
  }, []);

  return (
    <div className='w-full max-w-7xl  mx-auto py-12 sm:px-3 lg:px-4 lg:py-7 bg-white'>
      <h2 className="text-3xl lg:text-4xl px-4 font-medium mb-3 lg:mb-6">Catalogue de Formations</h2>
      <div className="grid lg:grid-cols-2 lg:gap-y-16 gap-10">
        {
          formations.map((item, index) => <Card key={item.title + index} formation={item} formations={formations} />)
        }
      </div>
    </div>
  );
};

export default Formations;
