import { MdOutlineDelete } from "react-icons/md";
import { MatchQuestionOptionType, MatchQuestionType } from "../../../../global/types";
import CustomRichEditor from "../../../../components/CustomRichEditor";

type MatchQuestionPropsType  = {
  handleChangeQuestion:(qIndex:number, value:MatchQuestionType) => void;
  handleDeleteQuestion:(qIndex:number) => void;
  qIndex:number;
  question:MatchQuestionType;
};

const MatchQuestion = ({handleChangeQuestion, handleDeleteQuestion, qIndex, question}:MatchQuestionPropsType) => {
  
  const handleAddOption = () => {
    const newOption:MatchQuestionOptionType = {
      metaIndex:1+Math.max(0, ...question.options.map(option => option.metaIndex)),
      part1:'',
      part2:''
    };
    handleChangeQuestion(qIndex, {...question, options:[...question.options, newOption]});
  };


  const handleDeleteOption = (optionIndex:number) => {
    const newOptions = [...question.options];
    newOptions.splice(optionIndex, 1);
    handleChangeQuestion(qIndex, {...question, options:newOptions});
  };

  const handleOptionChange = (optionIndex:number, option:MatchQuestionOptionType) => {
    const newOptions = [...question.options];
    newOptions[optionIndex]= option;
    handleChangeQuestion(qIndex, {...question, options:newOptions});
  };
  
  return (
    <div
      className="bg-slate-200 px-6 pt-4 rounded-2xl"
    >
      <div className="flex justify-between">
        <h3 className="text-lg font-bold text-gray-900">
          Question {qIndex + 1}
        </h3>
        <button
          className="flex items-center h-10 pl-2 pr-3 rounded-full bg-red-600 text-white hover:bg-red-800 transition duration-100"
          onClick={() => handleDeleteQuestion(qIndex)}
        >
          <MdOutlineDelete className='m-auto' size={24} />
          Question
        </button>
      </div>
      <div className="my-4">
        <div>
          <CustomRichEditor
            value={question.label}
            onChange={value => handleChangeQuestion(qIndex, {...question, label:value.toString()})}
          />
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Options:
          </label>
          {
            question.options.map((option, index) => (
              <div key={index}>
                <div className="text-sm mb-0.5 font-medium text-gray-700">
                  Option {index +1}:
                </div>
                <div className="flex flex-1 flex-col lg:flex-row gap-x-2">
                  <input
                    type="text"
                    className="customInput"
                    value={option.part1}
                    onChange={(e) => handleOptionChange(index, {...option, part1:e.target.value})}
                  />
                  <div className="flex w-full gap-x-2">
                    <input
                      type="text"
                      className="customInput"
                      value={option.part2}
                      onChange={(e) => handleOptionChange(index, {...option, part2:e.target.value})}
                    />
                    <button
                      className="h-10 w-10 aspect-square rounded-md text-red-600 bg-slate-50 hover:bg-slate-200 hover:shadow"
                      onClick={() => handleDeleteOption(index)}
                    >
                      <MdOutlineDelete className='m-auto' size={24} />
                    </button>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className="flex mt-4 gap-x-6">
          <label className="flex items-center gap-x-2">
            <p className="mb-1 text-sm font-medium text-gray-700">Crédits individuels :</p>
            <input
              value={question.weight}
              type="number"
              min={0}
              onChange={(e) => handleChangeQuestion(qIndex, {...question, weight:parseInt(e.target.value)})}
              className="textInput !w-24"
            />
          </label>
          <button
            className="px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-700"
            onClick={handleAddOption}
          >
            Ajouter une option
          </button>
        </div>
      </div>
    </div>
  )
};

export default MatchQuestion;