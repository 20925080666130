import { Link, useNavigate } from "react-router-dom";
import { ChatType, UserType } from "../../../../global/types";
import { useContext, useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../App";
import { HiChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import {TbPencilMinus} from 'react-icons/tb'
import CustomButton from "../../../../components/CustomButton";
import { AuthContext } from "../../../../contexts/AuthContext";



type ChatItemProps = {
  chat: ChatType;
  currentUser:UserType|null
};

const ChatItem = ({ chat, currentUser }: ChatItemProps) => {
  return (
    <Link
      className="container py-4 px-8 rounded-lg flex w-full shadow-sm bg-slate-50 max-w-6xl my-3 items-center cursor-pointer group"
      to={chat.id}
    >
      <div className="flex flex-1 flex-col">
        <div className="font-semibold text-lg mb-1">
          {chat.title}
        </div>
        <div>
          {chat.description}
        </div>
      </div>

      {
        ['teacher'].some((item: any) => currentUser?.accountType.includes(item))?
        <Link to={'add'} state={{chatInfos:chat}} className="px-2 py-1 transition rounded-full hover:bg-secondary hover:bg-opacity-25">
          <TbPencilMinus size={24} />
        </Link>
        :
        null
      }
      <div className="px-2 py-1">
        <HiChatBubbleOvalLeftEllipsis className='text-gray-500 group-hover:text-black transition duration-200' size={24} />
      </div>
    </Link>
  )
};


const Chats = () => {
  const navigate = useNavigate();

  const user = useContext(AuthContext)?.user;
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState<ChatType[]>([]);


  const getAndSetChats = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const getChats = httpsCallable(functions, 'getChats');
      const result = (await getChats()).data as {chats:ChatType[]};
      setChats(result.chats);
      console.log('Chats: ', chats);
    } catch (error) {
      console.log('Error while getting chats: ', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAndSetChats();
  }, []);

  return (
    <div className="flex flex-1">
      <div className="mt-4 mb-12 px-2 py-4 md:p-4 mx-auto rounded-2xl w-full flex flex-col max-w-6xl bg-secondary-light">
        <div className="flex flex-col gap-2">
            <h3 className="text-xl lg:text-3xl font-semibold">
              Chats
            </h3>
            <div>
              {chats.map(chat => (
                <ChatItem
                  key={chat.id}
                  currentUser={user||null}
                  chat={chat}
                />
              ))}
            </div>
        </div>
        <div className='flex justify-end my-12'>
          <CustomButton
              variant='btnSecondary'
              title='Initier un Chat'
              className='w-72'
              navigateTo='add'
          />
      </div>
      </div>
    </div>
  )
};

export default Chats;