import TasksGrid from './components/TasksGrid';
import StudentForm from './components/Form';
import StudentLogs from "./components/Logs";
import { useContext, useEffect, useState } from 'react';
import History from './components/History';
import Journey from './components/Journey';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import Courses from './components/Courses';
import UserComments from '../../../../components/UserComments';
import { ChapterTaskType, ChapterTaskUserRecord, UserType, otherAccountData } from '../../../../global/types';
import { functions } from '../../../../App';
import { httpsCallable } from 'firebase/functions';
import User from '../../../../components/User';
import Administration from '../../../../components/Administration';
import MarksGrid from './components/MarksGrid';


type StudentBoardPropsType = {
  taskData: ChapterTaskUserRecord[];
  isLoading: boolean
};

const StudentBoard: React.FC<StudentBoardPropsType> = ({ taskData, isLoading }) => {
  return (
    <div className='w-full mt-4'>
      <TasksGrid isLoading={isLoading} taskData={taskData} />
    </div>
  )
};

export type studentBoard = 'journey' | 'general' | 'comments' | 'history' | 'tasks'|'marks';
const Student = () => {
  const user = useContext(AuthContext)?.user;

  // const [visibleBoard, setVisibleBoard] = useState<>('general');
  let state: { userData: UserType } | null = useLocation().state;
  const { studentId } = useParams();
  const [userData, setUserData] = useState<UserType | undefined>(state?.userData);
  const [otherAccountData, setOtherAccountData] = useState<otherAccountData>({ accountOptions: { state: 'enabled', emailVisibility: 'public' } })
  const [tasksLoading, setTasksLoading] = useState(false);
  const [tasksRecord, setTasksRecord] = useState<ChapterTaskUserRecord[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const visibleBoard:studentBoard = (searchParams.get('board') as studentBoard|undefined)||'general';;

  const getAndSetUserData = async () => {
    try {
      const getUsersByUids = httpsCallable(functions, 'getUsersByUids');
      const [adminUserData] = (await getUsersByUids({ uids: [studentId] })).data as UserType[];
      console.log('student user data: ', adminUserData);
      setUserData(adminUserData as UserType)

      const { options: accountOptions, whatsAppNumber, telegramNumber } = adminUserData;
      setOtherAccountData({ accountOptions, whatsAppNumber, telegramNumber });
    } catch (error) {
      console.log('Error while getting user data: ', error)
    }
  }


  const boards = {
    general: <StudentForm otherAccountData={otherAccountData} user={userData} />,
    comments: <UserComments currentUser={user||null} user={userData||null} />,
    journey: studentId?<Journey studentId={studentId} />:null,
    history: <History />,
    marks: studentId? <MarksGrid editable={['admin', 'teacher'].some((item: any) => user?.accountType.includes(item))} studentId={studentId} />:null,
    tasks: <TasksGrid isLoading={tasksLoading} taskData={tasksRecord} />
  };

  const getAndSetStudentTasksRecordByUid = async () => {
    if (tasksLoading || userData?.followingFormations == undefined) return
    setTasksLoading(true)
    try {
      const getStudentTasksRecordByUid = httpsCallable(functions, 'getStudentTasksRecordByUid');
      const userTasksRecord = (await getStudentTasksRecordByUid({ formationIds: userData?.followingFormations||[], uid: studentId })).data as unknown as ChapterTaskUserRecord[];
      // console.log('User tasks record: ', userTasksRecord)
      setTasksRecord(userTasksRecord);
    } catch (error) {
      console.log('Error while getting student tasks record data: ', error)
    };
    setTasksLoading(false);
  }
  useEffect(() => {
    getAndSetStudentTasksRecordByUid();
  }, [userData]);

  useEffect(() => {
    if ((!state?.userData) && (!userData)) { //The last condition one prevent infinite rerenders
      getAndSetUserData()
    }
  }, []);
  return (
    <div className="flex flex-1 flex-col">
      <div className="mt-8 mb-12 mx-auto w-full flex flex-col max-w-7xl">
        <h2 className="text-4xl font-medium ml-4 mb-8 self-start">Profil de l'apprenant</h2>
        <div className='flex flex-col gap-8'>
          <div className='flex flex-1 flex-col lg:flex-row flex-wrap gap-x-4 lg:gap-x-8 justify-around px-4 lg:px-8 gap-y-4'>
            <StudentLogs
              visibleBoard={visibleBoard}
              setVisibleBoard={(board) => setSearchParams({board}, {replace:true})}
            />
            {
              ['admin'].some((item: any) => user?.accountType.includes(item)) ?
                <Administration
                  otherAccountData={otherAccountData}
                  setOtherAccountData={setOtherAccountData}
                />
                :
                null
            }
            {/* <Courses/> */}
            <User
              otherAccountData={otherAccountData}
              setOtherAccountData={setOtherAccountData}
              user={userData}
              currentUser={user}
            />
          </div>
          <div className='bg-secondary-light p-3 md:p-8 rounded-2xl flex flex-[5] h-min'>
            {boards[visibleBoard]}
          </div>
        </div>
        {/* <p className='text-xl'>Formation suivie: Agroforesterie</p> Display enrolled formation */}

        {/*The student board should list the courses available under a particular formation, 
                as well as more details about student completion status of the courses, teachers, assignments, completed assignments...
            */}

        {/*There should also be a way to view course content, meaning a redirect to a course presentation*/}


        {/* <StudentBoard
          isLoading={tasksLoading}
          taskData={tasksRecord}
        /> */}
      </div>
    </div>
  );
};

export default Student;