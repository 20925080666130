import { useContext, useEffect, useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import { AuthContext } from "../../../../contexts/AuthContext";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { MdOutlinePublic, MdOutlinePublicOff } from "react-icons/md";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../App";
import { BlogArticle } from "../../../../global/types";
import { LinearProgress, ToggleButton, ToggleButtonGroup } from "@mui/material";



type ArticleGridItem = {
  id: string;
  title: string;
  period: string;
  description: string;
  public: boolean;
  imageUrl?: string;
}

export const predefinedArticles:ArticleGridItem[] = [
  {
    id:'careers',
    title:'Carrières / Débouchées',
    description:'Carrières et ou Débouchées potentielles pour un apprenant du CEAS GRNE',
    period:'-/',
    public:true,
  },
  {
    id:'contact',
    title:'Entrer en Contact',
    description:'Petite présentation et contacts utiles pour les visiteurs',
    period:'-/',
    public:true,
  },
  {
    id:'ceas-grne',
    title:'À propos',
    description:'À propos du CEAS GRNE',
    period:'-/',
    public:true,
  },
  
]

const Articles = () => {
  const user = useContext(AuthContext)?.user;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [articlesType, setArticlesType] = useState<'predefined'|'others'>('predefined')
  const [articles, setArticles] = useState<BlogArticle[]>([]);

  const getAndSetArticles = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const getBlogArticles = httpsCallable(functions, 'getBlogArticles');
      const result = (await getBlogArticles()).data as { articles: BlogArticle[] };
      setArticles(result.articles.filter(article => !predefinedArticles.some(predefined => predefined.id ===article.id )))
    } catch (error) {
      console.log('Error while getting blog articles: ', error);
    }
    setLoading(false);
  };

  const columns: GridColDef<ArticleGridItem, any, any>[] = [
    // { field: 'id', headerName: 'ID', minWidth: 50, flex:1 },
    { field: 'title', headerName: 'Titre', minWidth: 220, flex: 1.5 },
    { field: 'description', headerName: 'Description', minWidth: 300, flex: 2.5 },
    { field: 'period', headerName: 'Période', minWidth: 100, flex: 1 },
    {
      field: 'public', headerName: 'Visibilité', width:100, flex: 0.75, headerAlign: 'center',
      renderCell: ({row}) => (
        <button onClick={() =>
          navigate(`/console/articles/${row.id}`, {state:{articleData:articles.find(item => item.id === row.id)}})}
          className='flex gap-x-2 bg-gray-100 hover:bg-gray-200 cursor-pointer rounded-lg pl-1.5 pr-2.5 py-2.5 text-center'
        >
          {row.public ?
            <MdOutlinePublic size={18} className="text-gray-700" />
            : <MdOutlinePublicOff size={18} className="text-gray-600" />
          } Voir l'article
        </button>
      ),
      sortable: true
    },
  ];
  
  const rows: ArticleGridItem[] = (articlesType==='predefined')?predefinedArticles: articles.map(article => ({
    description: article.description,
    id: article.id,
    period: article.period,
    public: article.options.public,
    title: article.title,
    imageUrl: article.imageUrl
  }));

  useEffect(() => {
    if(articlesType==='others') {
      getAndSetArticles()
    };
  }, [articlesType]);
  return (
    <div className="flex flex-1">
      <div className="mt-4 mb-12 p-3 lg:p-6 mx-auto rounded-2xl w-full flex flex-col max-w-7xl bg-secondary-light">
        <div className=" w-full mx-auto my-2 ">
          <h2 className="text-4xl font-medium ml-4 mb-8 self-start">Articles/Blog</h2>

          <div className='mb-2 lg:max-w-6xl xl:max-w-7xl'>
            <div>
              <ToggleButtonGroup
                exclusive
                value={articlesType}
                onChange={(event, value) => setArticlesType(value)}
              >
                <ToggleButton value={'predefined'}>
                  Prédéfinis
                </ToggleButton>
                <ToggleButton value={'others'}>
                  Autres
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <DataGrid
              autoHeight
              pageSizeOptions={[10]}
              rows={rows}
              columns={columns}
              getRowClassName={() => 'cursor-default'}
              slots={{ loadingOverlay: LinearProgress, noRowsOverlay: () => <div className='flex flex-1 h-full items-center justify-center'>Aucun article n'a été trouvé</div> }}
              loading={loading}
              disableColumnMenu
              disableRowSelectionOnClick
            />
          </div>
          <div className='flex justify-end mt-6 mb-12'>
            {
              [
                'admin'
              ].some((item: any) => user?.accountType.includes(item))
                ?
                <CustomButton
                  variant='btnSecondary'
                  title='Ajouter un nouveau'
                  className='w-60'
                  navigateTo='add'
                />
                : null
            }
          </div>
        </div>
      </div>
    </div>
  )
};


export default Articles;