import { useContext } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import SideBar from './components/SideBar';
import TopBar from './components/TopBar';
import Activities from './pages/console/Activities';
import Admin from './pages/console/users/Admin';
import AdminAdd from './pages/console/users/AdminAdd';
import Admins from './pages/console/users/Admins';
import Dashboard from './pages/console/Dashboard';
import Expert from './pages/console/users/Expert';
import ExpertAdd from './pages/console/users/ExpertAdd';
import Experts from './pages/console/users/Experts';
import FAQ from './pages/FAQ';
import Formation from './pages/console/Formation';
import FormationAdd from './pages/console/FormationAdd';
import FormationManage from './pages/console/FormationManage';
import Formations from './pages/console/Formations';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Student from './pages/console/users/Student';
import StudentAdd from './pages/console/users/StudentAdd';
import Students from './pages/console/users/Students';
import Super from './pages/console/users/Super';
import Supers from './pages/console/users/Supers';
import ConsoleTeacher from './pages/console/users/Teacher';
import TeacherAdd from './pages/console/users/TeacherAdd';
import ConsoleTeachers from './pages/console/users/Teachers';

import Figures from './pages/catalog/Figures';
import Figure from './pages/catalog/Figure';
import BlogArticles from './pages/catalog/Articles';
import CatalogFormations from './pages/catalog/Formations';
import CatalogFormation from './pages/catalog/Formation';
import More from './pages/More';
import Library from './pages/Library';
import SignIn from './pages/SignIn';
import RequireAuth from './pages/RequireAuth';
import Chapter from './pages/console/Chapter';
import ActivityAdd from './pages/console/ActivityAdd';
import ActivityRouter from './pages/console/Activity';
import PillarInfos from './components/FormationStruct/components/PillarInfos';
import ModuleInfos from './components/FormationStruct/components/ModuleInfos';
import CourseInfos from './components/FormationStruct/components/CourseInfos';
import GeneralInfos from './components/FormationStruct/components/GeneralInfos';
import ChapterInfos from './components/FormationStruct/components/ChapterInfos';
import FormationContextProvider from './contexts/FormationContext';
import UpdatePassword from './pages/UpdatePassword';
import { AuthContext } from './contexts/AuthContext';
import RapidAccess from './pages/console/RapidAccess';
import Pretests from './pages/console/pretests/Pretests';
import PretestAdd from './pages/console/pretests/PretestAdd';
import Pretest from './pages/console/pretests/Pretest';
import QuestionsEditor from './pages/console/pretests/QuestionsEditor';
import QuestionsRenderer from './pages/console/pretests/QuestionsRenderer';
import ResultsRenderer from './pages/console/pretests/ResultsRenderer';
import Articles from './pages/console/blog/Articles';
import ArticleAdd from './pages/console/blog/ArticleAdd';
import Chat from './pages/console/messaging/Chat';
import Chats from './pages/console/messaging/Chats';
import ChatAdd from './pages/console/messaging/ChatAdd';
import Bibliographies from './pages/catalog/Bibliographies';
import BibliographyAdd from './pages/catalog/BibliographyAdd';
import Bibliography from './pages/catalog/Bibliography';
import About from './pages/about';


const ConsoleContent = () => {
  const user = useContext(AuthContext)?.user;
  if (user) {
    return (
      <>
        <SideBar />
        <div
          // style={{marginLeft:60}}
          className='w-full'
        >
          <Outlet />
        </div>
      </>
    )
  } else return <RequireAuth />
};

function AppRoutes() {
  return (
    <>
      <div className="flex relative flex-1 flex-col">
        <TopBar />
        <div className='flex flex-row'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/more" element={<More />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/library" element={<Library />} />
            <Route path='/signin' element={<SignIn />} />
            <Route path='/update-password' element={<UpdatePassword />} />

            <Route path="/about/:aboutId" element={<About />} />
            <Route path="/blog/articles" element={<BlogArticles />} />
            <Route path='/catalog/'>
              <Route path="" element={<Navigate to="teachers" replace />} />
              <Route path="figures" element={<Figures />} />
              <Route path="figures/:figureId" element={<Figure />} />
              <Route path="formations" element={<CatalogFormations />} />
              <Route path="formations/:formationId" element={<CatalogFormation />} />
              <Route path="bibliographies" element={<Bibliographies />} />
              <Route path="bibliographies/add" element={<BibliographyAdd />} />
              <Route path="bibliographies/:bibliographyId" element={<Bibliography />} />
            </Route>
            <Route path='/console/' element={<ConsoleContent />}>
              {/* Redirect to Dashboard if console/ is typed in the address bar */}
              <Route path="" element={<Navigate to="dashboard" replace />} />

              <Route index={true} path="dashboard" element={<Dashboard />} />
              <Route path="articles" element={<Articles />} />
              <Route path="articles/add" element={<ArticleAdd />} />
              <Route path="articles/:articleId" element={<ArticleAdd />} />
              <Route path="messaging/chats/:chatId" element={<Chat />} />
              <Route path="messaging/chats" element={<Chats />} />
              <Route path="messaging/chats/add" element={<ChatAdd />} />
              <Route path="admins" element={<Admins />} />
              <Route path="rapid-access" element={<RapidAccess />} />
              <Route path="tasks" element={<Activities />} />
              <Route path="admins/:adminId" element={<Admin />} />
              <Route path="admins/add" element={<AdminAdd />} />
              <Route path="experts/:expertId" element={<Expert />} />
              <Route path="experts/add" element={<ExpertAdd />} />
              <Route path="experts" element={<Experts />} />
              <Route path="students/:studentId" element={<Student />} />
              <Route path="students/add" element={<StudentAdd />} />
              <Route path="students" element={<Students />} />
              <Route path="supers/:superId" element={<Super />} />
              <Route path="supers" element={<Supers />} />
              <Route path="teachers/:teacherId" element={<ConsoleTeacher />} />
              <Route path="teachers/add" element={<TeacherAdd />} />
              <Route path="teachers" element={<ConsoleTeachers />} />
              <Route path='chapters/:chapterId/*'>
                <Route path="" element={<Chapter />} />
                <Route path="addTask" element={<ActivityAdd />} />
                <Route path="editTask" element={<ActivityAdd />} />
              </Route>
              <Route path="tasks/:taskId" element={<ActivityRouter />} />
              <Route path="tasks/:taskId/:answerUserId" element={<ActivityRouter />} />
              <Route path='formations/'>
                <Route path="" element={<Formations />} />
                <Route
                  path="add/*"
                  element={
                    <FormationContextProvider>
                      <Routes>
                        <Route index path="" element={<FormationAdd />} />
                        <Route path="general" element={<GeneralInfos />} />
                        <Route path="pillar" element={<PillarInfos />} />
                        <Route path="module" element={<ModuleInfos />} />
                        <Route path="course" element={<CourseInfos />} />
                      </Routes>
                    </FormationContextProvider>
                  }
                />
                <Route
                  path=":formationId/*"
                  element={
                    <FormationContextProvider>
                      <Routes>
                        <Route index path="" element={<Formation />} />
                        <Route path="manage" element={<FormationManage />} />
                        <Route path="manage/general" element={<GeneralInfos />} />
                        <Route path="manage/pillar" element={<PillarInfos />} />
                        <Route path="manage/module" element={<ModuleInfos />} />
                        <Route path="manage/course" element={<CourseInfos />} />
                        <Route path="manage/chapter" element={<ChapterInfos />} />
                      </Routes>
                    </FormationContextProvider>
                  }
                />
              </Route>
            </Route>
            <Route path='/pre-tests'>
              <Route path="" element={<Pretests />} />
              <Route path="add" element={<PretestAdd />} />
              <Route path=":pretestId" element={<Pretest />} />
              <Route path=":pretestId/:attendantId" element={<Pretest />} />
              <Route path=":pretestId/questions" element={<QuestionsEditor />} />
              <Route path=":pretestId/overview" element={<QuestionsRenderer />} />
              <Route path=":pretestId/:attendantId/start" element={<QuestionsRenderer />} />
              <Route path=":pretestId/results" element={<ResultsRenderer />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
      {/* <RequireAuth/> */}
    </>
  );
}

export default AppRoutes;
