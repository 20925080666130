import { useEffect, useState } from "react";
import CallToAction from "./components/CallToAction";
import FormationsCards from "./components/FormationsCards";
import Hero from "./components/Hero";
import { BlogArticle, GlobalFormationType } from "../../global/types";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "../../App";
import ArticlesCards from "./components/ArticlesCards";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import moment from "moment";
import { predefinedArticles } from "../console/blog/Articles";


const Home = () => {
  const [formations, setFormations] = useState<Omit<GlobalFormationType, 'pillars'>[]>([]);
  const [articles, setArticles] = useState<BlogArticle[]>([]);
  const [loading, setLoading] = useState(false);


  const getAndSetArticles = async () => {
    setLoading(true);

    try {
      const q = query(collection(db, 'articles'), where('options.public', '==', true), limit(3));

      const articlesSnaps = await getDocs(q);
      const articles:BlogArticle[] = articlesSnaps.docs.map(snap => {
        const snapData = snap.data();
        return ({
          description:snapData.description,
          id:snap.id,
          period:snapData.period,
          options:snapData.options,
          rawHtml:snapData.rawHtml,
          title:snapData.title,
          imageUrl:snapData.imageUrl
        })
      }).filter(article => !predefinedArticles.some(item => item.id ==article.id))
      setArticles(articles)
    } catch (error) {
      console.log('Error while getting blog articles: ', error);
    }
    setLoading(false);
  };

  const getAndSetFormations = async () => {
    setLoading(true);

    try {
      const q = query(collection(db, 'formations'), where('endDate', '>=', moment().format('YYYY-MM-DD')), limit(4));

      const formationsSnaps = await getDocs(q);
      const formations:GlobalFormationType[] = formationsSnaps.docs.map(snap => {
        const snapData = snap.data();
        return ({
          id:snap.id,
          description:snapData.description,
          endDate:snapData.endDate,
          longDescription:snapData.longDescription,
          name:snapData.name,
          pillars:[],
          startDate:snapData.startDate,
          title:snapData.title,
          imageUrl:snapData.imageUrl
        })
      });
      
      setFormations(formations)
    } catch (error) {
      console.log('Error while fetching list of formations: ', error);
    }
    setLoading(false);
  };


  useEffect(() => {
    getAndSetFormations();
    getAndSetArticles()
  }, []);
  return (
    <div className='flex flex-1 flex-col justify-center'>
      <Hero />
      <FormationsCards
        formations={formations}
      />
      <CallToAction />
      <ArticlesCards
        articles={articles}
      />
    </div>
  )
};

export default Home;