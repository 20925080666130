import ProfileViewForm from "../../../../../components/ProfileViewForm";
import { UserType, otherAccountData } from "../../../../../global/types";

type AdminFormPropsType = {
    user?:UserType
    otherAccountData:otherAccountData
};

const AdminForm:React.FC<AdminFormPropsType> = ({user, otherAccountData}) => {
    return (
        <ProfileViewForm
            extraFields={{
                followingFormations:true
            }}
            accountType={["student"]}
            user={user}
            otherAccountData={otherAccountData}
        />
    );
};


export default AdminForm;