import { useEffect, useState } from "react";
import MarksPerformance, { MarksPerformanceData } from "./graphs/MarksPerformance"
import { FormationCourseType, GlobalFormationType } from "../../../../../global/types";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../App";
import CustomSelect from "../../../../../components/CustomSelect";
import { sortByIndexes } from "../../../../../utils/formationTreeHandlers";


type JourneyProps = {
  studentId: string;
};

const Journey = ({studentId}:JourneyProps) => {
  const [markedCourses, setMarkedCourses] = useState<FormationCourseType[]>([]);
  const [formations, setFormations] = useState<GlobalFormationType[]>([]);
  const [focusedFormation, setFocusedFormation] = useState<string|null>(null);
  const [loading, setLoading] = useState(false);
  
  const getAndSetConcernedCoursesByUid = async () => {
    if (loading) return;
    setLoading(true);
    const getConcernedCoursesByUid = httpsCallable(functions, 'getConcernedCoursesByUid');
    try {
      const dbConcernedCourses = (await getConcernedCoursesByUid({
        asRole:'student',
        uid: studentId,
        includeMarks: true,
        coursesDepht:true
      })).data as { formations: GlobalFormationType[], courses: (FormationCourseType & { formationId: string })[] };
      const concernedCourses = dbConcernedCourses.courses;

      const sortedFormations = dbConcernedCourses.formations.map(formation => sortByIndexes(formation));
      sortedFormations.forEach((formation, formationIndex) => 
        formation.pillars.map((pillar, pillarIndex) =>
          pillar.modules.map((module, moduleIndex) =>
            module.courses.map((course, courseIndex) => {
              sortedFormations[formationIndex]
                .pillars[pillarIndex]
                .modules[moduleIndex]
                .courses[courseIndex]
                .marks = concernedCourses.find(cCourse => cCourse.id == course.id)?.marks;
            })
          )
        )
      );
            
      setMarkedCourses(concernedCourses);
      setFormations(sortedFormations);
    } catch (error) {
      console.log('Error while getting concerned courses by uid: ', error);
    }
    setLoading(false);
  };
  
  const focusedFormationContent = formations.find(item => item.id ==focusedFormation);
  useEffect(() => {
    getAndSetConcernedCoursesByUid();
  }, []);
  return (
    <div className="flex flex-1 flex-col">
      <CustomSelect
        containerClassName='col-span-2 max-w-xl my-2'
        label={"Choisir la formation"}
        onChange={(newValue: any) => setFocusedFormation(newValue.value)}
        labelClassName='mb-0'
        value={!focusedFormation?undefined:{label:formations.find(item => item.id ===focusedFormation)?.title, value:focusedFormation}}
        options={formations.map(formation => ({ label: formation.title, value: formation.id }))}
        placeholder='Aucune (Spécifier une formation...)'
      />
      {/* <PillarsCompletion /> */}
      
      {
        focusedFormationContent?
        <MarksPerformance 
          formation={focusedFormationContent}
        />
        :null
      }
      
    </div>
  )
};

export default Journey;