import TasksGrid from './components/TasksGrid';
import TeacherLogs from "./components/Logs";
import { ChapterTaskUserRecord, UserType, otherAccountData } from '../../../../global/types';
import { useContext, useEffect, useState } from 'react';
import History from './components/History';
import Journey from './components/Journey';
import UserComments from '../../../../components/UserComments';
import { useLocation, useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../App';
import Administration from '../../../../components/Administration';
import User from '../../../../components/User';
import TeacherForm from './components/Form';
import { AuthContext } from '../../../../contexts/AuthContext';



const Teacher = () => {
  const user = useContext(AuthContext)?.user;

  const [visibleBoard, setVisibleBoard] = useState<'journey' | 'general' | 'comments' | 'history'|'tasks'>('general');
  let state: { userData: UserType } | null = useLocation().state;
  const { teacherId } = useParams();
  const [userData, setUserData] = useState<UserType | undefined>(state?.userData);
  const [otherAccountData, setOtherAccountData] = useState<otherAccountData>({ accountOptions: { state: 'enabled', emailVisibility: 'public' } })
  const [tasksLoading, setTasksLoading] = useState(false);
  const [tasksRecord, setTasksRecord] = useState<ChapterTaskUserRecord[]>([]);

  const getAndSetUserData = async () => {
    const getUsersByUids = httpsCallable(functions, 'getUsersByUids');

    try {
      const [teacherUserData] = (await getUsersByUids({ uid: [teacherId] })).data as UserType[];
      setUserData(teacherUserData as UserType)

      const { options: accountOptions, whatsAppNumber, telegramNumber } = teacherUserData;
      setOtherAccountData({ accountOptions, whatsAppNumber, telegramNumber });
    } catch (error) {
      console.log('Error while getting user data: ', error)
    }
  };

  const getAndSetTeacherTasksRecordByUid = async () => {
    if (tasksLoading) return
    setTasksLoading(true)
    try {
      const getTeacherTasksRecordByUid = httpsCallable(functions, 'getTeacherTasksRecordByUid');
      const userTasksRecord = (await getTeacherTasksRecordByUid({ uid: teacherId })).data as unknown as ChapterTaskUserRecord[];
      
      setTasksRecord(userTasksRecord.map(item => ({
        ...item,
        createdAt: new Date(item.createdAt),
        startDate:new Date(item.startDate),
        endDate: new Date(item.endDate)
      })));
    } catch (error) {
      console.log('Error while getting teacher tasks record data: ', error)
    };
    setTasksLoading(false);
  }

  useEffect(() => {
    getAndSetTeacherTasksRecordByUid()
  }, [userData]);

  useEffect(() => {
    if ((!state?.userData) && (!userData)) { //The last condition one prevent infinite rerenders
      getAndSetUserData()
    };
  }, []);

  const boards = {
    general: <TeacherForm otherAccountData={otherAccountData} user={userData} />,
    comments: <UserComments currentUser={user||null} user={userData||null} />,
    journey: <Journey />,
    history: <History />,
    tasks: <TasksGrid isLoading={tasksLoading} taskData={tasksRecord} />
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="mt-8 mb-12 mx-auto w-full flex flex-col max-w-7xl">
        <h2 className="text-4xl font-medium ml-4 mb-8 self-start">Profil du formateur</h2>
        <div className='flex flex-col gap-8'>
          <div className='flex flex-1 flex-col lg:flex-row flex-wrap gap-x-4 lg:gap-x-8 justify-around px-4 lg:px-8 gap-y-4'>
            <TeacherLogs
              visibleBoard={visibleBoard}
              setVisibleBoard={setVisibleBoard}
            />
            <Administration
              otherAccountData={otherAccountData}
              setOtherAccountData={setOtherAccountData}
            />
            <User
              user={userData}
              currentUser={user}
              otherAccountData={otherAccountData}
              setOtherAccountData={setOtherAccountData}
            />
          </div>
          <div className='bg-secondary-light p-3 md:p-8 rounded-2xl flex flex-[5] h-min'>
            {boards[visibleBoard]}
          </div>
        </div>
        {/* <p className='text-xl'>Formation suivie: Agroforesterie</p> Display enrolled formation */}

        {/*The student board should list the courses available under a particular formation, 
                as well as more details about student completion status of the courses, teachers, assignments, completed assignments...
            */}
      </div>
    </div>
  );
};

export default Teacher;