import { useNavigate, useParams } from 'react-router-dom';
import { Button, LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridClasses,
  GridColDef,
  GridNoRowsOverlay,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useContext, useEffect, useState } from 'react';
import CustomButton from '../../../components/CustomButton';
import { db, functions, storage } from '../../../App';
import { collection, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore';
import moment from 'moment';
import { CustomFileInput } from '../../../components/CustomInput';
import { BibliographyType } from '../../../global/types';
import { AuthContext } from '../../../contexts/AuthContext';
import { ref, uploadBytes } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';
import { MdOutlineDelete } from 'react-icons/md';
import CustomDialog from '../../../components/CustomDialog';

export type Document = {
  id: string;
  title: string;
  createdAt:string;
  fileUrl: string;
  documentFile?: File | null
};
type CustomToolbarPropsType = {
  searchTitle:string;
  setSearchTitle:React.Dispatch<React.SetStateAction<string>>;
}
const CustomToolbar = ({searchTitle, setSearchTitle}:CustomToolbarPropsType) => {
  return (
    <GridToolbarContainer className='bg-secondary-light' sx={{ paddingX: 2 }}>
      <input
        type="text"
        className='customInput'
        value={searchTitle}
        onChange={(e) => setSearchTitle(e.target.value)}
        placeholder='Entrez du texte pour filtrer via le nom'
      />
    </GridToolbarContainer>
  );
};


const Bibliography = () => {
  const [bibliography, setBibliography] = useState<BibliographyType | null>(null);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [documentAdd, setDocumentAdd] = useState<Partial<Document>|null>(null);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const { bibliographyId } = useParams();
  const [deleteDocLoading, setDeleteDocLoading] = useState(false);
  const [deleteDocId, setDeleteDocId] = useState<string|null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [searchTitle, setSearchTitle] = useState('');
  const navigate = useNavigate();

  const currentUser = useContext(AuthContext)?.user;
  
  const gridClassNames: Partial<GridClasses> = {
    footerContainer: 'bg-secondary-light'
  }

  const columns: GridColDef<Document, any, any>[] = [
    // { field: 'id', headerName: 'ID', minWidth: 50, flex: 1 },
    { field: 'title', headerName: 'Nom du document', minWidth: 110, flex: 4,
      renderCell:({row}) => <div className='flex flex-1 items-center justify-between'>
        <span>
          {row.title}
        </span>
        {
          ['admin'].some((item: any) => currentUser?.accountType.includes(item)) &&
          deleteDocLoading && deleteDocId ==row.id?
          <div
              className="inline-block h-5 w-5 m-2  animate-spin rounded-full border-[3px] border-red-600 border-r-transparent align-[-0.0625em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
          />
          :
          <button
              className="flex items-center h-10 p-2 rounded-full bg-red-600 bg-opacity-0 hover:bg-opacity-20 transition duration-100"
              onClick={() => {
                setDeleteDocId(row.id);
                setDeleteDialogOpen(true);
              }}
            >
              <MdOutlineDelete className='m-auto text-red-600' size={24} />
            </button>
        }
      </div>
    },
    { field: 'createdAt', headerName: "Date de D'Ajout", minWidth: 110, flex: 1 },
    { field: '', headerName: '', minWidth: 20, align:'center', renderCell:({row}) => (
      <Button href={row.fileUrl}><span className='lowercase'>Ouvrir</span></Button>
    ) },
  ];
  
  const getAndSetBibliography = async () => {
    setLoading(true);

    try {
      const docSnap = await getDoc(doc(db, `bibliographies/${bibliographyId}`));
      const docData = docSnap.data();
      if (docData == undefined) {
        setLoading(false);
        return
      };
      const bibliography: BibliographyType = {
        id: docSnap.id,
        description: docData.description,
        title: docData.title
      };

      const q = query(collection(db, `bibliographies/${bibliographyId}/documents`), orderBy('title', 'asc'));

      const documentsSnaps = await getDocs(q);
      const documents: Document[] = documentsSnaps.docs.map(snap => {
        const snapData = snap.data();
        return ({
          id: snap.id,
          createdAt:moment(snapData.createdAt.toDate()).format('D MMM YYYY [:] HH[h] mm [mins]'),
          fileUrl: snapData.fileUrl,
          title: snapData.title,
        })
      });

      setBibliography(bibliography);
      setDocuments(documents);
    } catch (error) {
      console.log('Error while fetching list of formations: ', error);
    }
    setLoading(false);
  };

  const onAddDocument = async () => {
    if(addLoading) return;
    setFormError("")
    if(!documentAdd?.documentFile) {
      setFormError("S'il vous plaît, veuillez choisir un fichier");
      return;
    }
    setAddLoading(true)

    try {
      const nameDotSplit = documentAdd.documentFile.name.split('.')
      const fileName = documentAdd.title? documentAdd.title+new Date().toISOString() +'.'+ nameDotSplit.slice(1).join('.')
      : nameDotSplit[0]+new Date().toISOString()+'.'+ nameDotSplit.slice(1).join('.')
      const filePath = `bibliographies/${bibliographyId}/${fileName}`;
      const storageRef = ref(storage, filePath);
      await uploadBytes(storageRef, documentAdd.documentFile, { contentDisposition: fileName ? `attachment; filename="${fileName}"` : undefined });
      
      const addBibliographyDoc = httpsCallable(functions, 'addBibliographyDoc');
      await addBibliographyDoc({
        title:documentAdd.title?documentAdd.title+'.'+ nameDotSplit.slice(1).join('.'):documentAdd.documentFile.name,
        path:filePath,
        bibliographyId
      });
      setDocumentAdd(null);
      getAndSetBibliography();
    } catch (error) {
      console.log('Error occured while uploading document: ', error);
    }

    setAddLoading(false);
  };

  
  const onDeleteDocument = async () => {
    if(deleteDocLoading) return;
    setDeleteDocLoading(true);

    try {
      const deleteBibliographyDoc = httpsCallable(functions, 'deleteBibliographyDoc');
      await deleteBibliographyDoc({docId:deleteDocId, bibliographyId})
      getAndSetBibliography();
    } catch (error) {
      console.log('Error while deleting document: ', error);      
    }
    setDeleteDocId(null);
    setDeleteDocLoading(false);
  };

  useEffect(() => {
    getAndSetBibliography();
  }, []);
  return (
    <div className="flex flex-1">
      <div className="mt-4 mb-12 px-2 py-4 lg:px-6 mx-auto rounded-2xl w-full flex flex-col max-w-6xl bg-secondary-light">
        <h2 className="text-xl lg:text-2xl font-medium my-4 self-start">
          Bibliographie <span className='italic'>{bibliography?.title}</span>
        </h2>
        <div className='mb-2 '>
          <DataGrid
            classes={gridClassNames}
            autoHeight
            rows={documents.filter(item => item.title.includes(searchTitle))}
            columns={columns}
            loading={loading}
            slots={{ toolbar: () => <div/>, loadingOverlay: LinearProgress, noRowsOverlay: GridNoRowsOverlay }}
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </div>

        {
          ['admin'].some((item: any) => currentUser?.accountType.includes(item)) ?
          <div className='mt-12 mb-6 pt-2 border-t-4 border-t-slate-300'>
            <div className='mb-2 text-lg lg:text-xl font-medium'>
              Ajouter un document:
            </div>
            <div className='xl:grid xl:grid-cols-2 xl:gap-x-4'>
              <div>
                <div className='mt-1 lg:mt-2 mb-1'>
                  Titre du document:
                </div>
                <input
                  type="text"
                  className='customInput'
                  placeholder='Entrez un titre ici (facultatif)'
                  value={documentAdd?.title||''}
                  onChange={e => setDocumentAdd(value => ({...value, title:e.target.value}))}
                />
              </div>
              <div>
                <div className='mt-1 lg:mt-2 mb-1'>
                  Veuillez choisir un fichier:
                </div>
                <CustomFileInput
                  empty={!Boolean(documentAdd?.documentFile)}
                  onChange={e => setDocumentAdd(value => ({...value, documentFile:e.target.files?.item(0)||undefined}))}
                />
              </div>
            </div>
            {
            formError ?
              <div className="mt-2 text-red-600 font-medium">
                {formError}
              </div>
              :
              null
          }
            <div className='flex justify-end mt-2'>
              <CustomButton
                variant='btnSecondary'
                title='Ajouter un nouveau'
                className='w-72'
                loading={addLoading}
                onClick={onAddDocument}
              />
            </div>
          </div>
          :null
        }
      </div>
      <CustomDialog
        onConfirm={onDeleteDocument}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        dialogType="confirm"
        title="Voulez vous supprimer le document?"
        message={`Le document ${documents.find(item => item.id == deleteDocId)?.title} sera supprimé de la bibliographie`}
        confirmtButtonTitle="Procéder"
        cancelButtonTitle="Annuler"
        cancelButton={{variant:'btnSecondary'}}
        confirmButton={{variant:'btnDanger'}}
      />
    </div>
  );
};

export default Bibliography;
