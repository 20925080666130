import { useContext, useEffect, useRef, useState } from "react";
import FullCalendar, { CustomContentGenerator, EventClickArg, EventContentArg, EventSourceInput } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import frLocale from "@fullcalendar/core/locales/fr";
import enLocale from "@fullcalendar/core/locales/en-au";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../App";
import { ChapterTaskUserRecord } from "../../../global/types";
import { AuthContext } from "../../../contexts/AuthContext";
import moment from "moment";


const Activities = () => {
  const navigate = useNavigate()
  const user = useContext(AuthContext)?.user;
  const [tasksLoading, setTasksLoading] = useState(false);
  const [tasksRecord, setTasksRecord] = useState<ChapterTaskUserRecord[]>([]);

  const initialEvents: EventSourceInput = tasksRecord.map(task => {
    return {
      title: task.title,
      start: moment(task.startDate).isBefore(new Date())?new Date():new Date(task.startDate),
      allDay:false,
      end: new Date(task.endDate)  ,
      id: task.id,
    }
  });

  const handleDateClick = (selected: DateClickArg) => { };

  const handleEventClick = (selected: EventClickArg) => {
    const { event } = selected;
    // The chapter task corresponding to the clicked event
    const task = tasksRecord.find(record => record.id === event.id);
    if (task) {
      navigate(`/console/tasks/${task.id}`);
    }
  };

  const getAndSetStudentTasksRecordByUid = async () => {
    if (tasksLoading || !user || !['student'].some((item: any) => user.accountType.includes(item)) || !user.followingFormations?.length) return
    setTasksLoading(true)
    try {
      const getStudentTasksRecordByUid = httpsCallable(functions, 'getStudentTasksRecordByUid');
      const userTasksRecord = (await getStudentTasksRecordByUid({
        formationIds: user.followingFormations||[],
        uid: user.id,
        filterExpired:true
      })).data as unknown as ChapterTaskUserRecord[];

      setTasksRecord(userTasksRecord);
    } catch (error) {
      console.log('Error while getting student tasks record data: ', error)
    };
    setTasksLoading(false);

  }

  
  const renderEventContent:CustomContentGenerator<EventContentArg> =(eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b> {/* Display start and end dates */}
        <i>{eventInfo.event.title}</i> {/* Display event title */}
      </>
    );
  };

  useEffect(() => {
    getAndSetStudentTasksRecordByUid()
  }, []);
  return (
    <div className="flex flex-1">
      <div className="max-w-6xl w-full mx-auto">
        <h2 className="text-4xl font-medium ml-4 mt-8 mb-2 self-start">Planning des Activites</h2>
        <div className=" mb-12 p-8 rounded-2xl w-full flex flex-col bg-secondary-light">
          <div className="w-full">
            <div className="w-full">
              <FullCalendar
                initialEvents={initialEvents}
                key={tasksRecord.length} // key prop allow us to rerender a component whenever key changes
                height="75vh"
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                ]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  // right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                  right: "timeGridDay,listMonth",
                }}
                initialView="listMonth"
                // editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                // eventContent={renderEventContent}

                // loading={() => tasksLoading}
                locales={[frLocale, enLocale]}
              // lazyFetching
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activities;
