import { useLocation, useNavigate } from "react-router-dom";
import { FormationModuleType } from "../../../global/types";
import CustomButton from "../../CustomButton";
import { useContext, useState } from "react";
import { FormationContext } from "../../../contexts/FormationContext";
import { getParentNodeId, updateModule } from "../../../utils/formationTreeHandlers";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../App";
import CustomDialog from "../../CustomDialog";

const moduleInitTemplate:Omit<FormationModuleType, 'courses'> = {
    id:'',
    index:Date.now(),
    title:'',
    name: '',
    description:'',
    
};


const ModuleInfos = () => {
    let state:{moduleInfos:FormationModuleType, initForm:boolean}|null = useLocation().state;
    const navigate = useNavigate();
    const {formationData, setFormationData} = useContext(FormationContext);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    
    if((!state?.moduleInfos) && (!state?.initForm)) {
        navigate('/console')
    }
    
    const initialContent = state?.moduleInfos? state.moduleInfos : moduleInitTemplate; 
    const [moduleInfos, setModuleInfos] = useState<Omit<FormationModuleType, 'courses'>>(initialContent)
    const [loading, setLoading] = useState(false);

    const onSave = async() => {
        if(loading) return;
        setLoading(true);
        if(moduleInfos.flag =='new') {
            const parentId = getParentNodeId(formationData, 'module', moduleInfos.id)
            try {
                const createFormationModule = httpsCallable(functions, 'createFormationModule');
                const createdModule = (await createFormationModule({pillarId:parentId, module:moduleInfos})).data as unknown as Omit<FormationModuleType, "courses">;
                // since when creating a module in the formation, a new id is generated in the db different from the 
                // current one, we should provide the previous id to allow the module data to be retrieved and updated
                if(setFormationData) setFormationData(updateModule(formationData, createdModule, moduleInfos.id));
                navigate(-1);
            } catch (error) {
                console.log('Error while creating new module: ', error)
            }
        } else {
            try {
                const updateFormationModule = httpsCallable(functions, 'updateFormationModule');
                const updatedModule = (await updateFormationModule({module:moduleInfos})).data as unknown as Omit<FormationModuleType, "courses">;
                if(setFormationData) setFormationData(updateModule(formationData, updatedModule));
                navigate(-1);
            } catch (error) {
                console.log('Error while updating module: ', error)
            }
        }
        setLoading(false);
    };

    
    const onModuleDelete = async () => {
      const deleteFormationNode = httpsCallable(functions, 'deleteFormationNode');
      try {
        await deleteFormationNode({nodeId:moduleInfos.id, nodeType:'module'});
        navigate(-1)
      } catch (error) {
        console.log('Error while deleting the module: ', error);
      }
    };
    
    return (
        <div className="flex flex-1">
            <div className="mt-4 mb-12 mx-auto w-full flex flex-col max-w-7xl ">
                <h2 className="text-4xl font-medium mb-4 self-start">Informations sur le module</h2>
                <div className='bg-secondary-light rounded-2xl  px-4 py-8 h-full'>
                    <div className="px-8">
                        <div className='mt-2 mb-1'>
                            Titre du module:
                        </div>
                        <input
                            value={moduleInfos.title}
                            onChange={(e) => {
                                setModuleInfos(infos => {
                                    return {
                                        ...infos,
                                        title:e.target.value
                                    }
                                })
                            }}
                            className='textInput'
                            placeholder='Entrez quelque chose...'
                        />
                        <div className='mt-2 mb-1'>
                            Objectif du module:
                        </div>
                        <textarea
                            value={moduleInfos.description}
                            onChange={(e) => {
                                setModuleInfos(infos => {
                                    return {
                                        ...infos,
                                        description:e.target.value
                                    }
                                })
                            }}
                            placeholder='Entrez quelque chose...'
                            className="textInput"
                        />
                    </div>
                    <div className="px-8 flex gap-x-4 mt-10">
                        <CustomButton
                            onClick={() => navigate(-1)}
                            title="Annuler les modifications"
                            variant="btnSecondary"
                            className="min-w-max"
                        />
                        <CustomButton
                            onClick={onSave}
                            title={moduleInfos.flag ==='new'? "Creer le module" : "Enregistrer"}
                            className="min-w-[10rem]"
                            loading={loading}
                        />
                        {
                          moduleInfos.flag !='new'?
                          <CustomButton
                              title="Supprimer"
                              variant="btnDanger"
                              onClick={() => setDeleteDialogOpen(true)}
                              className="w-40 ml-auto"
                          />
                          :null
                        }
                    </div>
                </div>
            </div>
            <CustomDialog
              confirmtButtonTitle="Procéder"
              cancelButtonTitle="Annuler"
              onConfirm={onModuleDelete}
              open={deleteDialogOpen}
              setOpen={setDeleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              dialogType="confirm"
              title="Êtes vous sûr de vouloir supprimer le module?"
              message="Si vous confirmez, les cours, chapitres et tâches sous ce module ne seront plus disponible aussi"
              cancelButton={{variant:'btnSecondary'}}
              confirmButton={{variant:'btnDanger'}}
            />
        </div>
    )
};

export default ModuleInfos;