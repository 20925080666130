import ProfileViewForm from "../../../../../components/ProfileViewForm";
import { UserType, otherAccountData } from "../../../../../global/types";

type AdminFormPropsType = {
    user?:UserType
    otherAccountData:otherAccountData
};

const TeacherForm:React.FC<AdminFormPropsType> = ({user, otherAccountData}) => {
    return (
        <ProfileViewForm
            extraFields={{
                // expertiseDomain:true,
                // teachedCourses:true
            }}
            accountType={["teacher"]}
            user={user}
            otherAccountData={otherAccountData}
        />
    );
};


export default TeacherForm;