const accountOptions = {
    en:{
        state:[
            {label:'Disabled', value:'disabled'},
            {label:'Enabled', value:'enabled'},
            {label:'Public', value:'public'},
        ],
        emailVisibility:[
            {label:'True', value:'public'},
            {label:'False', value:'hidden'},
        ],
        preferredLang:[
            {label:'French', value:'fr'},
            {label:'English', value:'en'}
        ]
    },
    fr:{
        state:[
            {label:'Activé', value:'enabled'},
            {label:'Désactivé', value:'disabled'},
            {label:'Publique', value:'public'},
        ],
        emailVisibility:[
            {label:'Vrai', value:'public'},
            {label:'Faux', value:'hidden'},
        ],
        preferredLang:[
            {label:'Français', value:'fr'},
            {label:'Anglais', value:'en'}
        ]
    }
}
export {accountOptions};