import { MdOutlineDelete } from 'react-icons/md';
import { MCQAnswerChoiceType, MCQQuestionType } from '../../../../global/types';
import CustomSelect from '../../../../components/CustomSelect';
import CustomRichEditor from '../../../../components/CustomRichEditor';
import CustomButton from '../../../../components/CustomButton';
import { useEffect, useState } from 'react';

type MCQAnswerPropsType = {
  aIndex: number;
  answer: MCQAnswerChoiceType;
  setAnswer: (answer: MCQAnswerChoiceType, aIndex:number) => void
  handleDeleteAnswer: () => void;
};

const MCQAnswer: React.FC<MCQAnswerPropsType> = ({
  aIndex,
  answer,
  setAnswer,
  handleDeleteAnswer,
}) => {

  return (
    <div key={aIndex} className="flex items-center gap-x-4">

      <input
        id={`answer-${aIndex}`}
        name={`answer-${aIndex}`}
        type="text"
        className="textInput !my-1"
        value={answer.text}
        placeholder="Enter answer"
        onChange={(e) => setAnswer({ ...answer, text: e.target.value }, aIndex)}
      />
      <div className='flex flex-1 items-center justify-end gap-x-3 lg:gap-x-4'>

        <label className={"font-medium flex flex-col items-center " + (answer.isCorrect ? "text-green-700 accent-green-700" : "")}>
          <input
            type="checkbox"
            title='Correct'
            className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out checked:border-transparent"
            checked={answer.isCorrect}
            onChange={(e) => setAnswer({ ...answer, isCorrect: e.target.checked }, aIndex)}
          />
          <span className="text-gray-700 hidden lg:block">Correct</span>
        </label>

        {/* <div className='w-32'>
          <CustomSelect
            onChange={(newValue) => setAnswer({ ...answer, weight: (newValue as { label: number, value: number }).value }, aIndex)}
            value={answer.weight||0}
            placeholder='Poids'
            options={(new Array(11).fill(0)).map((item, index) => ({ label: index.toString(), value: index }))}
          />
        </div> */}
        <button
          className="h-10 w-10 rounded-md text-red-600 bg-slate-50 hover:bg-slate-200 hover:shadow"
          onClick={handleDeleteAnswer}
        >
          <MdOutlineDelete className='m-auto' size={24} />
        </button>
      </div>
    </div>
  )
};


type MCQQuestionPropsType = {
  qIndex: number
  question: MCQQuestionType
  handleDeleteQuestion: (questionIndex: number) => void;
  handleChangeQuestion: (qIndex:number, question: MCQQuestionType) => void;
  explanationsEnabled:boolean;
};

export const MCQQuestion: React.FC<MCQQuestionPropsType> = ({
  qIndex,
  question,
  handleChangeQuestion,
  handleDeleteQuestion,
  explanationsEnabled
}) => {

  const handleAddAnswer = () => {
    const newAnswer: MCQAnswerChoiceType = {
      isCorrect: false,
      text: '',
      weight: 0,
    }
    const newAnswers = [...question.answers, newAnswer];
    handleChangeQuestion(qIndex, { ...question, answers: newAnswers });
  };

  const handleDeleteAnswer = (answerIndex: number) => {
    const newAnswers = [...question.answers];
    newAnswers.splice(answerIndex, 1);
    handleChangeQuestion(qIndex, { ...question, answers: newAnswers })
  };

  const handleAnswerChange = (answer:MCQAnswerChoiceType, aIndex:number) =>  {
    const newAnswers = [...question.answers];
    newAnswers[aIndex] = answer;
    handleChangeQuestion(qIndex, { ...question, answers: newAnswers });
  };

  return (
    <div key={qIndex} className="bg-slate-200 px-3 lg:px-6 pt-4 pb-2  rounded-2xl">
      <div className="flex justify-between">
        <h3 className="text-lg font-bold text-gray-900">Question {qIndex + 1}</h3>
        <div> 
          <button
            className="flex items-center h-10 pl-2 pr-3 rounded-full bg-red-600 text-white hover:bg-red-800 transition duration-100"
            onClick={() => handleDeleteQuestion(qIndex)}
          >
            <MdOutlineDelete className='m-auto' size={24} />
            Question
          </button>
        </div>
      </div>
      <div className="my-4">
        <div className='mt-4'>
          <CustomRichEditor
            value={question.question}
            onChange={(value) => handleChangeQuestion(qIndex, { ...question, question: value.toString() })}
          />
        </div>
        <div className="my-4">
          <label className="block text-sm font-medium text-gray-700">Reponses</label>
          {question.answers.map((answer, aIndex) => (
            <MCQAnswer
              key={aIndex}
              aIndex={aIndex}
              answer={answer}
              setAnswer={handleAnswerChange}
              handleDeleteAnswer={() => handleDeleteAnswer(aIndex)}
            />
          ))}
          <div className="flex mt-4 gap-x-6">
            <label className='flex items-center gap-x-2'>
              <p className="mb-1  text-sm font-medium text-gray-700">Crédits :</p>
              <input
                value={question.weight}
                type="number"
                min={0}
                onChange={(e) => handleChangeQuestion(qIndex, {...question, weight:parseInt(e.target.value)})}
                className="textInput !w-24"
              />
            </label>
            <button
              className="px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-700"
              onClick={handleAddAnswer}
            >
              Ajouter une réponse
            </button>
          </div>
        </div>
        {/* Explanation for the answers to the question */}
        {
          explanationsEnabled?
            <div className='mt-8 mb-4'>
              <label className="block text-sm font-medium text-gray-700 mb-1">Explication</label>
              <CustomRichEditor
                value={question.explanation}
                onChange={(value) => handleChangeQuestion(qIndex, { ...question, explanation: value.toString() })}
              />
            </div>
          :
          null
        }
      </div>
    </div>
  )
};

type MCQGeneratorPropsType = {
  questions: MCQQuestionType[];
  setQuestions: React.Dispatch<React.SetStateAction<MCQQuestionType[]>>;
}

const MCQGenerator: React.FC<MCQGeneratorPropsType> = ({ questions, setQuestions }) => {
  const [explanationsEnabled, setExplanationsEnabled] = useState(false);
  const [maxIndexes, setMaxIndexes] = useState({pseudo:0, meta:0});

  const handleAddQuestion = () => {
    const newQuestion: MCQQuestionType = {
      pseudoIndex: maxIndexes.pseudo+1,
      metaIndex: maxIndexes.meta+1,
      question: '',
      type:'mcq',
      weight: 1,
      answers: [
        { text: '', isCorrect: true, weight: 1, },
        { text: '', isCorrect: false, weight: 0, },
      ],
      explanation: ''
    };
    setQuestions([...questions, newQuestion]);
  };



  const handleDeleteQuestion = (questionIndex: number) => {
    const newQuestions = [...questions]; //destructure the "questions" array to clone it, and avoid rerendering issues
    newQuestions.splice(questionIndex, 1);
    setQuestions(newQuestions);
  };


  const handleChangeQuestion = (qIndex:number, question:MCQQuestionType) => {  
    const newQuestions = [...questions];
    newQuestions[qIndex] = question;
    setQuestions(newQuestions);
  };

  useEffect(() => {
    setMaxIndexes({
      meta:Math.max(maxIndexes.meta, ...questions.map(q =>q.metaIndex)),
      pseudo:Math.max(maxIndexes.pseudo, ...questions.map(q =>q.pseudoIndex))
    })
  }, [questions.length]);
  return (
    <div >
      <h2 className="text-2xl font-medium self-start flex justify-between items-center">Questions a Choix Multiples</h2>
      <div>
        <label className='text-gray-900 flex gap-x-2 items-center'>
          <input
            type="checkbox"
            title='Correct'
            checked={explanationsEnabled}
            onChange={(e) => setExplanationsEnabled(e.target.checked)}
            className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out checked:border-transparent"
            />
            Explications
        </label>
      </div>
      <div className='flex flex-col gap-y-6 mt-4'>
        {questions.map((question, qIndex) => (
          <MCQQuestion
            key={qIndex}
            qIndex={qIndex}
            question={question}
            handleChangeQuestion={handleChangeQuestion}
            handleDeleteQuestion={handleDeleteQuestion}
            explanationsEnabled={explanationsEnabled}
          />
        ))}
      </div>
      <CustomButton
        title='Ajouter une question'
        variant='btnSecondary'
        className='mt-4'
        onClick={handleAddQuestion}
      />
    </div>
  )

};

export default MCQGenerator;