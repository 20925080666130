// @ts-nocheck

import moment from "moment";
import { ChapterTaskType, GlobalFormationType, MCQQuestionType, StructuralQuestionType, UserCommentType } from "../global/types";
export const formations:GlobalFormationType[] = [
    {
        id:'dasuibnxaso892h3',
        title: "FORMATION DES PRATICIENS DE L’OBSERVATION INDEPENDANTE DES RESSOURCES FORESTIERES",
        name: "Formation Name 1",
        description: "Formation Description 1",
        longDescription:'',
        pillars: [
            {
                id:'9enda9d2h3',
                title: "Foresterie de base",
                name: "Pillar Name 1",
                description: "Décrire les ressources forestières, leur gestion et les enjeux forestiers du monde contemporain.",
                modules: [
                    {
                        id:'98r3hncndso2h3',
                        title: "Connaissance et évaluation des ressources forestières",
                        name: "Module Name 1",
                        description: "Décrire et évaluer les ressources forestières",
                        courses: [
                            {
                                id:'98hcdsnolje80dxs',
                                title: "Dendrométrie et cubage",
                                name: "Course Name 1",
                                description: "mesurer des débités, des arbres et des peuplements",
                                teachers:[{name:"Teacher Name 1", id:'inonasosadc'}],
                                startDate: "2023-05-01",
                                endDate: "2023-05-31",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Une bonne connaissance de la politique et de la loi et des autres textes qui réglementent la gestion des opérations forestières, des enjeux liés à la gestion des ressources forestières au plan national, régional et global, de l'environnement médiatique et la maîtrise des outils de communication traditionnels et des NTIC, du français et/ou de l'anglais ou les deux, avec un vocabulaire forestier dédié.",
                                applicationDuration:2,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>Cours Magistraux (CM),</li><li>Travaux Pratiques (TP) et Travaux</li><li>Personnels des Etudiants (TPE)</li></ul>",
                                evaluation:"<ul><li>60% pour les savoirs sur la mesure des tiges tropicales et la rédaction des rendus ;</li><li>20% pour l'évaluation des savoirs faire ;</li><li>20% pour le savoir être.</li></ul>",
                                needs:"<ul><li>Matériel didactique : Vidéoprojecteur - tableau et marqueurs - flip chart - matériel de projection pour des extraits vidéo.</li><li>Matériel de terrain : Boussoles, GPS, Fiches de collecte de données, Compas forestier, Blumleiss, Haga, ruban dendrométrique, dendromètre Suunto, Relascope vêtements appropriés pour les sorties sur le terrain.</li><li>Autres matériels : Véhicule pour le transport des apprenants et formateurs, - trousses de sécurité</li></ul>",
                                bibliography:"<ul><li><a href='https://www.google.com'>Johnson, C.O., 2003. Nigeria: Illegal Logging & Forest Women’s Resistance. Review of African Political Economy, 30(95), 156–162. </a></li><li>Kabeer, N., 2005. Gender equality and women's empowerment: A critical analysis of the   third millennium development goal 1. Gender & Development 13(1), pp.13-24.<a href='https://www.wikipedia.org'> Consult the document here</a></li></ul>",
                                chapters: [
                                    {
                                        id:'9jds09ujdaslkncdszc',
                                        title: "Généralités sur les arbres et définition de la dendrométrie",
                                        name: "Chapter Name 1",
                                        description: "Chapter Lorem ipsum dolor sit amet placeat obcaecati vitae adipisci iste temporibus! Description 1",
                                    },
                                    {
                                        id:'ndsa0iknmcss0asms',
                                        title: "Mesure de la grosseur des arbres",
                                        name: "Chapter Name 2",
                                        description: "Chapter Lorem ipsum dolor sit  vitae adipisci iste temporibus! Description 2",
                                    },
                                    {
                                        id:'98hndsncku99dojas0usc',
                                        title: "Hauteur des arbres",
                                        name: "Chapter Name 3",
                                        description: "Chapter Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores eos impedit eaque autem veniam deserunt ullam odit possimus expedita voluptatum",
                                    },
                                    {
                                        id:'98ncsdpkds892h3',
                                        title: "Volume des tiges, des branches et des avivés",
                                        name: "Chapter Name 3",
                                        description: "Chapter Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores eos impedit eaque autem veniam deserunt ullam odit possimus expedita voluptatum",
                                    },
                                    {
                                        id:'0jdsnmkljcasmocc',
                                        title: "Cotation des arbres et forme des tiges",
                                        name: "Chapter Name 3",
                                        description: "Chapter Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores eos impedit eaque autem veniam deserunt ullam odit possimus expedita voluptatum",
                                    },
                                ],
                            },
                            {
                                id:'89cadspo90cwnmls',
                                title: "Systématique forestière",
                                name: "Course Name 2",
                                description: " nulla molestias, omnis nemo adipisci placeat officia cum voluptatibus totam distinctio exercitationem minima minus?",
                                teachers:[{name:"Teacher Name 2", id:'inonasosa2dc'}],
                                startDate: "2023-06-01",
                                endDate: "2023-06-30",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Une bonne connaissance de la loi forestière et des autres textes qui réglementent la gestion des opérations forestières, des infractions relatives à la loi forestière et aux normes d’intervention en milieu forestier.",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>Cours Magistraux (CM)</li><li>Travaux Pratiques (TP)</li><li>Travaux Personnels des étudiants (TPE)</li></ul>",
                                evaluation:"<ul><li>60% orientés vers les savoirs faire et les savoirs être sur la reconnaissance des arbres et la rédaction des rendus</li><li>20% pour l'évaluation des savoirs faire acquis</li><li>20% pour les savoir être</li></ul>",
                                needs:"<ul><li>Matériel didactique : Vidéoprojecteur - tableau et marqueurs - flip-chart - matériel de projection pour des extraits vidéo.</li><li>Matériel de terrain : Fiches de collecte de données, Clés d’identification des arbres, vêtements appropriés pour les sorties sur le terrain, sécateurs, sacs en plastique, étiquettes pour référencer les échantillons, une presse, papier journal ou buvard, feuilles de dessin, scotch.</li><li>Autres matériels : Véhicule pour le transport des apprenants et formateurs, - trousses de sécurité</li></ul>",
                                bibliography:"No",
                                chapters: [
                                    {
                                        id:'09jcdsaknijscc',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: " autem veniam deserunt ullam odit possimus expedita voluptatum. Quis optio maiores ex, placeat obcaecati vitae adipisci iste temporibus! Description 1",
                                    },
                                    {
                                        id:'09jdsnlkjasccs3',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Lorem ipsum dolor Quis optio maiores ex, placeat obcaecati vitae adipisci iste temporibus! Description 2",
                                    },
                                    {
                                        id:'0ncasoijiFds&Xxas',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores eos impedit eaque autem veniam deserunt ullam odit possimus expedita voluptatum. Quis optio maiores ex, placeat obcaecati vitae adipisci iste temporibus! Description 3",
                                    },
                                ],
                            },
                            {
                                id:'hdnsa0ujasmoijas',
                                title: "Inventaire forestier",
                                name: "Course Name 3",
                                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum quis fugiat ipsam aut officiis asperiores et vero aspernatur porro adipisci alias facere nobis modi eaque laboriosam molestiae, ipsum provident veritatis?m",
                                teachers:[{name:"Teacher Name 3", id:'9indsaip'}],
                                startDate: "2023-06-01",
                                endDate: "2023-06-30",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>Cours Magistraux (CM)</li><li>Travaux Pratiques (TP)</li><li>Travaux Personnels des Etudiants (TPE)</li></ul>",
                                evaluation:"<ul><li>Examen périodique : 20%</li><li>Examen final : 30%</li><li>Travail pratique : 50 % (30% pour le rapport, 20% pour l'oral)</li></ul>",
                                needs:"<ul><li>Matériel didactique : Vidéoprojecteur - tableau et marqueurs - flip chart - matériel de projection pour des extraits vidéo.</li><li>Matériel de terrain : Boussoles, GPS, Fiches de collecte de données, Compas forestier, vêtements appropriés pour les sorties sur le terrain, plan de sondage.</li><li>Autres matériels : Véhicule pour le transport des apprenants et formateurs, – trousses de sécurité</li></ul>",
                                bibliography:"Non",
                                chapters: [
                                    {
                                        id:'09ndsamgfdbvdklnqode',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: " autem veniam deserunt ullam odit possimus expedita voluptatum. Quis optio maiores ex, placeat obcaecati vitae adipisci iste temporibus! Description 1",
                                    },
                                    {
                                        id:'98nd9jasmASHnkds',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Lorem ipsum dolor Quis optio maiores ex, placeat obcaecati vitae adipisci iste temporibus! Description 2",
                                    },
                                    {
                                        id:'IUndsiojS098jxsa',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores eos impedit eaque autem veniam deserunt ullam odit possimus expedita voluptatum. Quis optio maiores ex, placeat obcaecati vitae adipisci iste temporibus! Description 3",
                                    },
                                    {
                                        id:'(hndnkAU2jodwde)',
                                        title: "Chapter 4",
                                        name: "Chapter Name 4",
                                        description: "Chapter Lorem ii. M autem veniam deserunt ullam odit possimus expedita voluptatum. Quis optio maiores ex, placeat obcaecati vitae adipisci iste temporibus! Description 3",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id:'&nsaDSIkds09uN09ud',
                        title: "Aspects socioéconomique de la forêt",
                        name: "Module Name 2",
                        description: "Module Description 2",
                        courses: [
                            {
                                id:'Omds0Jxoi0u*9js',
                                title: "Anthropologie et Sociologie forestière",
                                name: "Course Name 1",
                                description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quidem maxime perferendis exercitationem quas impedit, fugit ex. Quia esse reiciendis quibusdam tempora consequatur repellat, quo nemo. Aliquam, laboriosam? Numquam, fuga modi?m",
                                teachers:[{name:"Teacher Name 1", id:'8hsaiHisahi'}],
                                startDate: "2023-07-01",
                                endDate: "2023-07-31",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'JgDSpjdwJhsnxs0',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'n9IBN09nJsnildssd',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'nOIOn9Nsaojdsa',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                            {
                                id:'nmds0jdaso',
                                title: "Economie forestière",
                                name: "Course Name 2",
                                description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident dolorum sunt sint est quis praesentium debitis culpa, quasi modi ipsa. Cumque esse dignissimos temporibus magni! Ab soluta ad illum culpa..2",
                                teachers:[{name:"Teacher Name 2", id:'7B8sabl8nL'}],
                                startDate: "2023-08-01",
                                endDate: "2023-08-31",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'65fd&GXbkdasiudsa',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'79uidash(hda9sd',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'jda9XZH8ydljdsdsa',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                            {
                                id:'8798h(*hnda98hxs98u',
                                title: "Produits et services de la forêt et leur valorisation",
                                name: "Course Name 3",
                                description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quidem maxime perferendis exercitationem quas impedit, fugit ex. Quia esse reiciendis quibusdam tempora consequatur repellat, quo nemo. Aliquam, laboriosam? Numquam, fuga modi?m",
                                teachers:[{name:"Teacher Name 3", id:'89nAx9ihSdc'}],
                                startDate: "2023-07-01",
                                endDate: "2023-07-31",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'bdas8bsa8hHdkas',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'86NBiyhfdsnOH',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'8rwBIbnsaijoijsc',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id:'89eqnxnksiodjsn',
                        title: "Gestion durable des ressources forestières",
                        name: "Module Name 3",
                        description: "Module Description 3",
                        courses: [
                            {
                                id:'89nwncsz09ucc9uXnx',
                                title: "Déforestation et dégradation des forêts",
                                name: "Course Name 1",
                                description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nobis laborum quam labore, optio cum dolor rem fugiat, iusto voluptates, modi veritatis quaerat dicta harum laboriosam reiciendis nesciunt quisquam ipsa delectus.m",
                                teachers:[{name:"Teacher Name 1", id:'8aHSoihsaUas'}],
                                startDate: "2023-09-01",
                                endDate: "2023-09-30",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'r6ubhnckzjsopizhcU',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'7HasmInsaojnasx4',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'iydsnbndsaiBXXsa',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                            {
                                id:'pHUnskn99XNJiasa',
                                title: "Certification forestière FLEGT/REDD et autres….",
                                name: "Course Name 2",
                                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, id perferendis voluptas ab nesciunt, obcaecati optio natus, accusamus deserunt explicabo in laborum cupiditate quos est asperiores omnis nam quae inventore?m",
                                teachers:[{name:"Teacher Name 2", id:'Iuh8Niasb6Bxsa'}],
                                startDate: "2023-10-01",
                                endDate: "2023-10-31",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'8woih6JNxsjxjoxaxcs',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'7Mxaasxihhxs9a',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'776IbihsaiUhsdas',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                id:'8Ihdas9uKJdas0kds',
                title: "Lorem ipsum dolor sit amet",
                name: "Pillar Name 2",
                description: "Pillar Description 2",
                modules: [
                    {
                        id:'987eq6bnfds9jxp',
                        title: "Quis fugiat enim perferendis",
                        name: "Module Name 1",
                        description: "Module Description 1",
                        courses: [
                            {
                                id:'oBicIxh7Xb787d',
                                title: "Course 1",
                                name: "Course Name 1",
                                description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio itaque non quos laudantium nemo repudiandae explicabo optio. Dignissimos facilis rerum libero. Libero ab molestiae, maxime omnis nam eveniet vitae aspernatur? ",
                                teachers:[{name:"Teacher Name 1", id:'9NnkjIhsaiojS'}],
                                startDate: "2023-11-01",
                                endDate: "2023-11-30",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'8Bxh9snOihdsbiwu',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'878qHIJdas9h9x8a',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'9uNX98xasn9xsh9s6',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                            {
                                id:'98u432njdsojds09',
                                title: "Course 2",
                                name: "Course Name 2",
                                description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis, incidunt. Facilis fugit veniam aperiam nulla! Ducimus tempore labore aperiam nobis et id neque quisquam, odit animi iure quos non! Eveniet. ",
                                teachers:[{name:"Teacher Name 2", id:'78BkjUI5S'}],
                                startDate: "2023-12-01",
                                endDate: "2023-12-31",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'87bhsohjI89DOHsnks',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'98ndnsoisdJOIDsds',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'094HIundadba5',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description3",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id:'97Hinkins7w144',
                        title: "Cum reprehenderit illo nisi, sequi",
                        name: "Module Name 2",
                        description: "Module Description 2",
                        courses: [
                            {
                                id:'45nbkdsdu923j93',
                                title: "Course 1",
                                name: "Course Name 1",
                                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam cum consectetur dolorem repellendus? Sit quae iusto autem tenetur neque non assumenda omnis voluptas accusantium, et ex cumque! Aliquid, sit numquam. ",
                                teachers:[{name:"Teacher Name 1", id:'9nIsadc'}],
                                startDate: "2024-01-01",
                                endDate: "2024-01-31",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'980ndxsoNDsiuysda',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'hd7j0NudAKAJds94',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'987NxosjIOXnsiocj',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                            {
                                id:'79qeOnklascoijSD',
                                title: "Course 2",
                                name: "Course Name 2",
                                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse enim consectetur neque tenetur tempore officiis pariatur quasi illo nulla magnam tempora omnis sit quam earum, similique maiores. Quas, libero dolorem! ",
                                teachers:[{name:"Teacher Name 2", id:'i89n43'}],
                                startDate: "2024-02-01",
                                endDate: "2024-02-29",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'98IDhnSDojPOjd',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'InxSIh45bi8sau23',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'io78yhIUbYggug553',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id:'jh78Nodsa6776HjL',
                        title: "Amet consectetur adipisicing elit voluptate",
                        name: "Module Name 3",
                        description: "Module Description 3",
                        courses: [
                            {
                                id:'87NihdasnODnsdDa',
                                title: "Course 1",
                                name: "Course Name 1",
                                description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus dolor expedita consequatur fuga error itaque iure atque, saepe aut repudiandae minima fugiat doloribus. Maxime, impedit quasi accusantium suscipit cumque tempora? ",
                                teachers:[{name:"Teacher Name 1", id:'inonasosadc'}],
                                startDate: "2024-03-01",
                                endDate: "2024-03-31",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'ojds98h*(n0890ds',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'9nds65oi7jsmklpa',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'90J9odsnOIdsndasff',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                            {
                                id:'99NDihnDSoissd89',
                                title: "Course 2",
                                name: "Course Name 2",
                                description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor molestiae aut natus explicabo exercitationem facere in, reiciendis eos odio, eligendi quidem aliquid maiores error vel perferendis suscipit facilis impedit soluta. ",
                                teachers:[{name:"Teacher Name 2", id:'id2'}],
                                startDate: "2024-04-01",
                                endDate: "2024-04-30",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'9Bidsh3anXH5sdakj',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'98NdjmOuidas5ans',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'89NosidNdsa0uds',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                id:'1Kndsao4ijNs63',
                title: "Voluptate amet consectetur adipisicing elit",
                name: "Pillar Name 3",
                description: "Pillar Description 3",
                modules: [
                    {
                        id:'6Hudnk7dsn09nds',
                        title: "Module 1",
                        name: "Module Name 1",
                        description: "Module Description 1",
                        courses: [
                            {
                                id:'8843N98dansODHnsad',
                                title: "Course 1",
                                name: "Course Name 1",
                                description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam, natus quaerat suscipit numquam adipisci quia accusantium, asperiores placeat repellendus harum nam excepturi, sit deserunt veniam nihil nobis aut eaque cupiditate! ",
                                teachers:[{name:"Teacher Name 1", id:'inonasosadc'}],
                                startDate: "2024-05-01",
                                endDate: "2024-05-31",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'73B87SXdahi63gsa',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'8Bujsy8huiUdbs7a',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'8Nds9nygda53adas',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                            {
                                id:'8NbihdaDjnda67qw',
                                title: "Course 2",
                                name: "Course Name 2",
                                description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro maiores neque, atque eum dolore eaque culpa tempore fuga quas quam quod necessitatibus reiciendis ab nemo eveniet excepturi tenetur laborum doloribus! ",
                                teachers:[{name:"Teacher Name 2", id:'id 2'}],
                                startDate: "2024-06-01",
                                endDate: "2024-06-30",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'9N9dasnoDSh756sdb',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'iu3h9hid9yIDsdsf',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'983nIUndaskn0pdsf',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id:'87bzd8bidshfdasfa',
                        title: "Module 2",
                        name: "Module Name 2",
                        description: "Module Description 2",
                        courses: [
                            {
                                id:'87bnedIxbkxjzsdhhS',
                                title: "Course 1",
                                name: "Course Name 1",
                                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam ipsa veritatis asperiores! Maiores neque tempora omnis, sapiente voluptatem est vel vero dolorem, reprehenderit eos tempore quas, nihil adipisci tenetur impedit. ",
                                teachers:[{name:"Teacher Name 1", id:'inonasosadc'}],
                                startDate: "2024-07-01",
                                endDate: "2024-07-31",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'87bnDSinda09ad',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'763qnDysndsa0jdAPJ',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'9873ENSHNDSAOHS',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                            {
                                id:'89dnjSojndasklds',
                                title: "Course 2",
                                name: "Course Name 2",
                                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi a facere debitis! Quo sequi impedit tenetur, enim consequatur vitae, optio cumque molestias doloremque placeat in tempora, eos perspiciatis aliquid accusamus. ",
                                teachers:[{name:"Teacher Name 2", id:'id2'}],
                                startDate: "2024-08-01",
                                endDate: "2024-08-31",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'8saNSD9ndsa0jdsdd',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'nIUhdsa9jdna67d32',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'89NX09nSX5fxvsxcsa',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id:'98nDSpoidadfnfo63',
                        title: "Module 3",
                        name: "Module Name 3",
                        description: "Module Description 3",
                        courses: [
                            {
                                id:'0nDS98hnfdsDndss0fsn',
                                title: "Course 1",
                                name: "Course Name 1",
                                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque beatae laborum voluptatibus perferendis rerum, assumenda perspiciatis possimus porro quod minima consequatur distinctio maxime sint magnam hic rem error. Molestias, at. ",
                                teachers:[{name:"Teacher Name 1", id:'inonasosadc'}],
                                startDate: "2024-09-01", endDate: "2024-09-30",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'897DBihdnsa0hnda',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'9N9dsndab9DhsnS2',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'9nDS0ndsasofujn6',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                            {
                                id:'97NSDonkdsaoS78ibsa',
                                title: "Course 2",
                                name: "Course Name 2",
                                description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Delectus maiores dicta magni animi, eaque doloremque sed veniam officia amet, eos numquam, corporis accusamus in similique soluta ad nisi illo velit? ",
                                teachers:[{name:"Teacher Name 2", id:'id2'}],
                                startDate: "2024-10-01",
                                endDate: "2024-10-31",
                                prerequisites:[{title:'Cours prerequis 1', id:'KJlsdaljs'}, {title:'Cours Prerequis 2', id:'omAsosKSd'}],
                                prerequisitesDescription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, mollitia rem, vitae dolorem blanditiis corporis earum ad adipisci voluptatum repudiandae cupiditate labore voluptas atque. Hic quibusdam quaerat nostrum illum temporibus!",
                                applicationDuration:2.5,
                                courseDuration:30,
                                pedagogicApproach:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                evaluation:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                needs:"<ul><li>dolor sit amet consectetur </li><li>Fugit, mollitia rem, vitae dolorem</li><li>earum ad adipisci voluptatum repudiandae cupiditat</li></ul>",
                                bibliography:"<ul><li> maxime perferendis exercitationem </li><li>  quas impedit, fugit ex.</li><li>Hic quibusdam quaerat nostrum illum adipisci vo temporibus!</li></ul>",
                                chapters: [
                                    {
                                        id:'nSIUhns782b9hdaJas',
                                        title: "Chapter 1",
                                        name: "Chapter Name 1",
                                        description: "Chapter Description 1",
                                    },
                                    {
                                        id:'Nuaoniwe787haq8w',
                                        title: "Chapter 2",
                                        name: "Chapter Name 2",
                                        description: "Chapter Description 2",
                                    },
                                    {
                                        id:'9nODSnSd9qerwr13',
                                        title: "Chapter 3",
                                        name: "Chapter Name 3",
                                        description: "Chapter Description 3",
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];






export const mockFormationMarksData = [
    {
        name: "pillar1",
        title: "Pillar 1",
        modules: [
            {
                name: "module1",
                title: "Pillar 1 Module 1",
                courses: [
                    {
                        name: "course1",
                        title: "Course 1",
                        color: "#FF0000",
                        chapters: [
                            {
                                title: "Chapter 1",
                                name: "chapter1",
                                obtainedMarks: 80,
                                totalMarks: 100,
                                complete:true
                            },
                            {
                                title: "Chapter 2",
                                name: "chapter2",
                                obtainedMarks: 90,
                                totalMarks: 100,
                                complete:true
                            }
                        ]
                    },
                    {
                        name: "course2",
                        title: "Course 2",
                        color: "#ff8800",
                        chapters: [
                            {
                                title: "Chapter 1",
                                name: "chapter1",
                                obtainedMarks: 70,
                                totalMarks: 100,
                                complete:true
                            },
                            {
                                title: "Chapter 2",
                                name: "chapter2",
                                obtainedMarks: 85,
                                totalMarks: 100,
                                complete:true
                            },
                            {
                                title: "Chapter 1",
                                name: "chapter1",
                                obtainedMarks: 70,
                                totalMarks: 100,
                                complete:false
                            },
                            {
                                title: "Chapter 2",
                                name: "chapter2",
                                obtainedMarks: 85,
                                totalMarks: 100,
                                complete:false
                            },
                            {
                                title: "Chapter 3",
                                name: "chapter2",
                                obtainedMarks: 85,
                                totalMarks: 100,
                                complete:false
                            },
                            {
                                title: "Chapter 4",
                                name: "chapter2",
                                obtainedMarks: 85,
                                totalMarks: 100,
                                complete:false
                            }
                        ]
                    }
                ]
            },
            {
                name: "module2",
                title: "Pillar 1 Module 2",
                courses: [
                    {
                        name: "course1",
                        title: "Course 1",
                        color: "#bbff00",
                        chapters: [
                            {
                                title: "Chapter 1",
                                name: "chapter1",
                                obtainedMarks: 95,
                                totalMarks: 100,
                                complete:true,
                            },
                            {
                                title: "Chapter 2",
                                name: "chapter2",
                                obtainedMarks: 75,
                                totalMarks: 100,
                                complete:true
                            }
                        ]
                    },
                    {
                        name: "course2",
                        title: "Course 2",
                        color: "#00ffd5",
                        chapters: [
                            {
                                title: "Chapter 1",
                                name: "chapter1",
                                obtainedMarks: 60,
                                totalMarks: 100,
                                complete:true
                            },
                            {
                                title: "Chapter 2",
                                name: "chapter2",
                                obtainedMarks: 80,
                                totalMarks: 100,
                                complete:false
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        name: "pillar2",
        title: "Pillar 2",
        modules: [
            {
                name: "module1",
                title: "Pillar 2 Module 1",
                courses: [
                    {
                        name: "course1",
                        title: "Course 1",
                        color: "#0084ff",
                        chapters: [
                            {
                                title: "Chapter 1",
                                name: "chapter1",
                                obtainedMarks: 85,
                                totalMarks: 100,
                                complete:true
                            },
                            {
                                title: "Chapter 2",
                                name: "chapter2",
                                obtainedMarks: 95,
                                totalMarks: 100,
                                complete:false
                            }
                        ]
                    },
                    {
                        name: "course2",
                        title: "Course 2",
                        color: "#003cff",
                        chapters: [
                            {
                                title: "Chapter 1",
                                name: "chapter1",
                                obtainedMarks: 75,
                                totalMarks: 100,
                                complete:true
                            },
                            {
                                title: "Chapter 2",
                                name: "chapter2",
                                obtainedMarks: 80,
                                totalMarks: 100,
                                complete:false
                            }
                        ]
                    }
                ]
            },
            {
                name: "module2",
                title: "Pillar 2 Module2",
                courses: [
                    {
                        name: "course1",
                        title: "Course 1",
                        color: "#ae00ff",
                        chapters: [
                            {
                                title: "Chapter 1",
                                name: "chapter1",
                                obtainedMarks: 90,
                                totalMarks: 100,
                                complete:false,
                            },
                            {
                                title: "Chapter 2",
                                name: "chapter2",
                                obtainedMarks: 70,
                                totalMarks: 100,
                                complete:false
                            }
                        ]
                    },
                    {
                        name: "course2",
                        title: "Course 2",
                        color: "#ff0055",
                        chapters: [
                            {
                                title: "Chapter 1",
                                name: "chapter1",
                                obtainedMarks: 65,
                                totalMarks: 100,
                                complete:true
                            },
                            {
                                title: "Chapter 2",
                                name: "chapter2",
                                obtainedMarks: 85,
                                totalMarks: 100,
                                complete:true
                            }
                        ]
                    }
                ]
            }
        ]
    }
];


export const mockMCQs:{questions:MCQQuestionType[]} = {
    "questions": [
        {
            "question": "<h2>What is the capital city of France?</h2>",
            "weight": 2,
            "answers": [
                {
                    "text": "Paris",
                    "isCorrect": true,
                    "weight": 2
                },
                {
                    "text": "London",
                    "isCorrect": false,
                    "weight": 0
                },
                {
                    "text": "Berlin",
                    "isCorrect": false,
                    "weight": 0
                },
                {
                    "text": "Madrid",
                    "isCorrect": false,
                    "weight": 0
                }
            ],
            explanation:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque unde consequuntur <strong> perferendis veniam libero tenetur excepturi </strong>'
        },
        {
            "question": "<h2>What is the capital city of Canada?</h2>",
            "weight": 1,
            "answers": [
                {
                    "text": "Ottawa",
                    "isCorrect": true,
                    "weight": 1
                },
                {
                    "text": "Toronto",
                    "isCorrect": false,
                    "weight": 0
                },
                {
                    "text": "Vancouver",
                    "isCorrect": false,
                    "weight": 0
                },
                {
                    "text": "Montreal",
                    "isCorrect": false,
                    "weight": 0
                }
            ],
            explanation:' repellendus<u> accusamus tempore. Magnam quo, dignissimos voluptatibus</u> non cum eius vel quaerat quidem error?'
        },
        {
            "question": "<h2>What is the largest planet in our solar system?</h2>",
            "weight": 3,
            "answers": [
                {
                    "text": "Jupiter",
                    "isCorrect": true,
                    "weight": 3
                },
                {
                    "text": "Mars",
                    "isCorrect": false,
                    "weight": 0
                },
                {
                    "text": "Venus",
                    "isCorrect": false,
                    "weight": 0
                },
                {
                    "text": "Mercury",
                    "isCorrect": false,
                    "weight": 0
                }
            ],
            explanation:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Suscipit distinctio omnis at ipsum?<br/> You can get additional informations <a href="https://www.google.com">here</a>'
        }
    ]
}


export const mockStructuralQuestions: StructuralQuestionType[] = [
    {
        question: "",
        answer: "",
        explanation: "",
        weight: 0
    },
    // {
    //   question: "What is the capital of <u>France</u>?",
    //   answer: "<b>Paris</b><br/><br/>",
    //   explanation: "<u>Paris</u> is the capital and largest city of France.<br/><br/>",
    //   weight: 0
    // },
    // {
    //   question: "What is the highest mountain in the world?",
    //   answer: "<b>Mount Everest</b><br/><br/>",
    //   explanation: "<u>Mount Everest</u> is the highest mountain in the world.<br/><br/>",
    //   weight: 10
    // },
    // {
    //   question: "What is the largest country in the world by area?",
    //   answer: "<b>Russia</b><br/><br/>",
    //   explanation: "<u>Russia</u> is the largest country in the world by area.<br/><br/>",
    //   weight: 7
    // }
];



export const chapterTasks: ChapterTaskType[] = [
    {
        id: 'daskj',
        isMarked:false,
        description: 'Watch the video and <strong>take notes</strong> on important concepts.',
        status:'completed',
        type:'videoFile',
        videoFileLink:"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        startDate:new Date(),
        endDate: moment().add({days:5}).toDate(),
    },
    {
        id: 'opjdas',
        isMarked:false,
        description: 'Complete the quiz <u>at the end of the chapter.</u>',
        status:'waiting',
        type:'MCQ',
        startDate:moment().subtract({days:2}).toDate(),
        endDate: moment().add({days:3}).toDate(),
    },
    {
        id: 'yebixai',
        isMarked:false,
        description: "Complete the assignment <strong>by the due date.</strong>",
        status:'undone',
        type:'submission',
        startDate:moment().subtract({days:0}).toDate(),
        endDate: moment().add({days:1}).toDate(),

    },
    {
        id: 'poecmpa',
        isMarked:false,
        description: "Read the <u>textbook chapter</u> and take notes on important concepts.",
        status:'completed',
        type:'documentFile',
        startDate:moment().add({days:7}).toDate(),
        endDate: moment().add({days:10}).toDate(),
    },
    {
        id: 'idsnoax',
        isMarked:false,
        description: "Check out on this <strong>wikipedia article</strong? about deforestation",
        status:'completed',
        type:'documentUrl',
        startDate:moment().add({days:2}).toDate(),
        endDate: moment().add({days:3}).toDate(),
    },
    {
        id:'dashlkna',
        isMarked:false,
        description:'<h3>Watch </h3>the following youtube video, and write a synthesis about it',
        status:'undone',
        type:'youtubeVideo',
        youtubeVideoLink:"https://www.youtube.com/watch?v=wjRi0a19Dd0",
        startDate:moment().subtract({days:1}).toDate(),
        endDate: moment().add({days:1}).toDate(),
    },
    {
        id:'opewncsp',
        isMarked:false,
        description:'Complete this Q&A section about the book in the <stron><u>previous activity</u></strong>',
        status:'waiting',
        type:'structural',
        startDate:moment().subtract({days:7}).toDate(),
        endDate: moment().subtract({days:3}).toDate(),
    },
    {
        id:'cnaaodas',
        isMarked:false,
        description:'Online meeting about [...] scheduled for Satursday 2:PM',
        status:'waiting',
        type:'teleMeeting',
        startDate:moment().subtract({days:2}).toDate(),
        endDate: moment().add({days:3}).toDate(),
    }
];

export const comments: UserCommentType[] = [
    {
        id:'dsajooij32',
        title: 'Great job on your presentation!',
        createdAt: new Date('2022-03-27T14:30:00Z'),
        text:
            'I was very impressed with the quality of your presentation. Your delivery was clear and concise, and you demonstrated a deep understanding of the topic. Keep up the good work!',
        author:{
            name: 'John',
            surname:'Doe',
            accountType: 'admin',
            id:'odsj09jnd0j',
        }
    },
    {
        id:'sdjlkdjsaa',
        title: 'Excellent analytical skills',
        createdAt: new Date('2022-03-28T10:45:00Z'),
        text:
            'Your recent project demonstrated excellent analytical skills. You were able to identify key trends and patterns in the data, and your insights were very valuable. Well done!',
        author:{
            name: 'Jane',
            surname:'Smith',
            accountType: 'admin',
            id:'9ncsad&0j',
        }
    },
    {
        id:'8nda98(nxsa',
        title: 'Great work ethic',
        createdAt: new Date('2022-03-30T09:15:00Z'),
        text: 'I have noticed that you consistently put in a lot of effort and dedication into your work. Your hard work is paying off, keep it up!',
        author: {
            name:'John',
            accountType: 'admin',
            id:'89nx(hnOxsa',
            surname:' Doe'
        }
    },
    {
        id:'nIn929(nxsa',
        title: 'Excellent reasonning skills',
        createdAt: new Date('2022-03-28T10:45:00Z'),
        text:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis magnam tempora molestias llam earum officiis repellat inventore iste? Corporis ad laborum magnam natus!',
        author: {
            name:'Jane',
            accountType: 'admin',
            id:'io)n0n983nOH',
            surname:'Smith'
        }
    },
];