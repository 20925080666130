import CustomButton from "../../CustomButton";
import { CustomFileInput } from "../../CustomInput";
import { FormationChapterType } from "../../../global/types";
import { useLocation, useNavigate } from "react-router-dom";
import { FormationContext } from "../../../contexts/FormationContext";
import { useContext, useState } from "react";
import { getParentNodeId, updateChapter } from "../../../utils/formationTreeHandlers";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../App";
import { getFileUploadSource } from "../../../utils/files";
import CustomDialog from "../../CustomDialog";

const formationInitTemplate:FormationChapterType = {
    id:'',
    index:Date.now(),
    title:'',
    name: '',
    description:'',
    imageFile:null
};

const ChapterInfos = () => {
    type StateType = {chapterInfos:FormationChapterType, initForm:boolean, parentId?:string}|null;
    let state:StateType = useLocation().state;
    const navigate = useNavigate();
    const { formationData, setFormationData } = useContext(FormationContext);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    
    if((!state?.chapterInfos) && (!state?.initForm)) {
        navigate('/console')
    };
    
    const initialContent = state?.chapterInfos? state.chapterInfos : {
        id: 'chapter_' + Date.now().toString(),
        index:Date.now(),
        flag:'new' as 'new',
        title: 'New Chapter',
        name: 'New Chapter',
        description: '',
    }; 

    const [chapterInfos, setChapterInfos] = useState<FormationChapterType>(initialContent)
    const [loading, setLoading] = useState(false);

    const onSave = async() => {
        if(loading) return;
        setLoading(true);
        let bannerImgSource=null;
        
        if(chapterInfos.imageFile) {
            bannerImgSource = await getFileUploadSource(chapterInfos.imageFile);
        }
        if(chapterInfos.flag =='new') {
            const parentId = state?.parentId? state.parentId : getParentNodeId(formationData, 'chapter', chapterInfos.id)
            try {
                const createFormationChapter = httpsCallable(functions, 'createFormationChapter');
                const createdChapter = (await createFormationChapter({courseId:parentId, chapter:{...chapterInfos, bannerImgSource}})).data as unknown as FormationChapterType;
                if(setFormationData) setFormationData(updateChapter(formationData, createdChapter, chapterInfos.id)) 
                navigate(-1);
            } catch (error) {
                console.log('Error while creating new chapter: ', error)
            }
        } else {
            try {
                const updateFormationChapter = httpsCallable(functions, 'updateFormationChapter');
                const updatedChapter = (await updateFormationChapter({chapter:{...chapterInfos, bannerImgSource}})).data as unknown as FormationChapterType;
                if(setFormationData) setFormationData(updateChapter(formationData, updatedChapter))
                navigate(-1);
            } catch (error) {
                console.log('Error while updating chapter: ', error)
            }
        }
        setLoading(false);
    };

    const onChapterDelete = async () => {
      const deleteFormationNode = httpsCallable(functions, 'deleteFormationNode');
      try {
        await deleteFormationNode({nodeId:chapterInfos.id, nodeType:'chapter'});
        navigate(-1)
      } catch (error) {
        console.log('Error while deleting the chapter: ', error);
      }
    }
    return (
        <div className="flex flex-1">
            <div className="mt-4 mb-12 mx-auto w-full flex flex-col max-w-7xl ">
                <h2 className="text-4xl font-medium mb-4 self-start">Informations Sur le Chapitre</h2>
                <div className='bg-secondary-light rounded-2xl  px-4 py-8 h-full'>
                    <div className="px-8">
                        <div className='mt-2 mb-1'>
                            Titre du chapitre:
                        </div>
                        <input
                            value={chapterInfos.title}
                            onChange={(e) => {
                                setChapterInfos(infos => {
                                    return {
                                        ...infos,
                                        title:e.target.value
                                    }
                                })
                            }}
                            className='textInput'
                            placeholder='Entrez quelque chose...'
                        />
                        <div className='mt-2'>
                            Image d'illustration:
                        </div>
                        <div className="pt-1 bg-slate-200 rounded-xl">
                            <CustomFileInput
                                accept="image/*"
                                onChange={(e) => setChapterInfos(infos => ({...infos, imageFile:e.target.files?.item(0)}))}
                                placeholder="Choisir une image d'illustration"
                                label={(
                                    <div className="rounded-xl max-w-4xl mx-auto overflow-hidden cursor-pointer hover:brightness-50 transition duration-200">
                                        <img
                                            className="mt-3"
                                            src={chapterInfos.imageFile?URL.createObjectURL(chapterInfos.imageFile):chapterInfos.imageUrl}
                                            alt=""
                                        />
                                    </div>
                                )}
                            />
                        </div>
                        <div className='mt-2 mb-1'>
                            Description du chapitre:
                        </div>
                        <textarea
                            value={chapterInfos.description}
                            onChange={(e) => {
                                setChapterInfos(infos => {
                                    return {
                                        ...infos,
                                        description:e.target.value
                                    }
                                })
                            }}
                            placeholder='Entrez quelque chose...'
                            className="textInput"
                        />
                    </div>
                    <div className="px-8 flex gap-x-4 mt-10">
                        <CustomButton
                            onClick={() => navigate(-1)}
                            title="Annuler les modifications"
                            variant="btnSecondary"
                            className="min-w-max"
                        />
                        <CustomButton
                            onClick={onSave}
                            title={chapterInfos.flag === 'new'?"Creer le chapitre":"Enregistrer"}
                            className="min-w-[10rem]"
                            loading={loading}
                        />
                        {
                          chapterInfos.flag !='new'?
                          <CustomButton
                              title="Supprimer"
                              variant="btnDanger"
                              onClick={() => setDeleteDialogOpen(true)}
                              className="w-40 ml-auto"
                          />
                          :null
                        }
                    </div>
                </div>
            </div>
            <CustomDialog
              confirmtButtonTitle="Procéder"
              cancelButtonTitle="Annuler"
              onConfirm={onChapterDelete}
              open={deleteDialogOpen}
              setOpen={setDeleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              dialogType="confirm"
              title="Êtes vous sûr de vouloir supprimer le chapitre?"
              message="Si vous confirmez, les tâches sous ce chapitre ne seront plus disponible aussi"
              cancelButton={{variant:'btnSecondary'}}
              confirmButton={{variant:'btnDanger'}}
            />
        </div>
    )
};

export default ChapterInfos;