import CustomButton from "../../CustomButton";
import CustomInput, { CustomFileInput } from "../../CustomInput";
import CustomRichEditor from "../../CustomRichEditor";
import { GlobalFormationType } from "../../../global/types";
import { useLocation, useNavigate } from "react-router-dom";
import { FormationContext } from "../../../contexts/FormationContext";
import { useContext, useState } from "react";
import { updateFormation } from "../../../utils/formationTreeHandlers";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../App";
import { getFileUploadSource } from "../../../utils/files";
import moment from "moment";

const formationInitTemplate:Omit<GlobalFormationType, 'pillars'> = {
    id:'',
    title:'',
    name: '',
    description:'',
    longDescription:'',
    imageFile:null,
    startDate:moment().format('YYYY-MM-DD'),
    endDate:moment().add({years:1}).format('YYYY-MM-DD')
};

const GeneralInfos = () => {
    let state:{formationInfos:GlobalFormationType, initForm:boolean}|null = useLocation().state;
    const navigate = useNavigate();
    const { formationData, setFormationData } = useContext(FormationContext);

    if((!state?.formationInfos) && (!state?.initForm)) {
        navigate('/console')
    }
    
    const initialContent = state?.formationInfos? state.formationInfos : formationInitTemplate; 
    const [formationInfos, setFormationInfos] = useState<Omit<GlobalFormationType, 'pillars'>>(initialContent)
    const [loading, setLoading] = useState(false);

    const onSave = async() => {
        if(loading) return;
        setLoading(true);
        let bannerImgSource=null;
        
        if(formationInfos.imageFile) {
            bannerImgSource = await getFileUploadSource(formationInfos.imageFile);
        }

        // these two are to make sure that the start and end dates are registered as actual dates
        // in the database
        if(!formationInfos.startDate) formationInfos.startDate = moment().format('YYYY-MM-DD');
        if(!formationInfos.endDate) formationInfos.endDate = moment().add({years:1}).format('YYYY-MM-DD');
        
        if(formationInfos.flag =='new') {
            try {
                const createNewFormation = httpsCallable(functions, 'createNewFormation');
                const createdFormation = (await createNewFormation({formation:{...formationInfos, bannerImgSource}})).data as unknown as Omit<GlobalFormationType, "pillars">;
                if(setFormationData) setFormationData(updateFormation(formationData, createdFormation)) 
                navigate(-1);
            } catch (error) {
                console.log('Error while creating new formation: ', error)
            }
        } else {
            try {
                const DBUpdateFormation = httpsCallable(functions, 'updateFormation');
                const updatedFormation = (await DBUpdateFormation({formation:{...formationInfos, bannerImgSource}, formationId:formationInfos.id})).data as unknown as Omit<GlobalFormationType, "pillars">;
                if(setFormationData) setFormationData(updateFormation(formationData, updatedFormation))
                navigate(-1);
            } catch (error) {
                console.log('Error while updating formation: ', error)
            }
        }
        setLoading(false);
    };
    return (
        <div className="flex flex-1">
            <div className="mt-4 mb-12 mx-auto w-full flex flex-col max-w-7xl ">
                <h2 className="text-4xl font-medium mb-4 self-start">Informations Generales</h2>
                <div className='bg-secondary-light rounded-2xl  px-4 py-8 h-full'>
                    <div className="px-8">
                        <div className='mt-2 mb-1'>
                            Titre de la formation:
                        </div>
                        <input
                            value={formationInfos.title}
                            onChange={(e) => {
                                setFormationInfos(infos => {
                                    return {
                                        ...infos,
                                        title:e.target.value
                                    }
                                })
                            }}
                            className='textInput'
                            placeholder='Entrez quelque chose...'
                        />
                        <div className='mt-2'>
                            Image d'illustration:
                        </div>
                        <div className="pt-1 bg-slate-200 rounded-xl">
                            <CustomFileInput
                                onChange={(e) => setFormationInfos(infos => ({...infos, imageFile:e.target.files?.item(0)}))}
                                placeholder="Choisir une image d'illustration"
                                label={(
                                    <div className="rounded-xl max-w-4xl mx-auto overflow-hidden cursor-pointer hover:brightness-50 transition duration-200">
                                        <img
                                            className="mt-3"
                                            src={formationInfos.imageFile?URL.createObjectURL(formationInfos.imageFile):''}
                                            alt=""
                                        />
                                    </div>
                                )}
                            />
                        </div>
                        
                        <div className='mt-3'>
                          Période de formation:
                        </div>
                        <div className="flex flex-1 mb-1 gap-x-4">
                          <label className="flex flex-1 flex-col">
                            <span>Début: </span>
                            <input
                              type="date"
                              value={moment(formationInfos.startDate).format('YYYY-MM-DD')}
                              onChange={e => setFormationInfos(infos => ({...infos, startDate:e.target.value}))}
                              className="customInput"
                              placeholder="Date de début"
                            />
                          </label>
                          <label className="flex flex-1 flex-col">
                            <span>Fin: </span>
                            <input
                              type="date"
                              value={moment(formationInfos.endDate).format('YYYY-MM-DD')}
                              onChange={e => setFormationInfos((infos => ({...infos, endDate:e.target.value})))}
                              className="customInput"
                              placeholder="Date de fin"
                            />
                          </label>
                        </div>
                        <div className='mt-2 mb-1'>
                            Description courte:
                        </div>
                        <textarea
                            value={formationInfos.description}
                            onChange={(e) => {
                                setFormationInfos(infos => {
                                    return {
                                        ...infos,
                                        description:e.target.value
                                    }
                                })
                            }}
                            placeholder='Entrez quelque chose...'
                            className="textInput"
                        />
                        <>
                            <div className='mt-3 mb-1'>
                                Description Longue:
                            </div>
                            <CustomRichEditor
                                value={formationInfos.longDescription}
                                onChange={(value) => {
                                    setFormationInfos(infos => {
                                        return {
                                            ...infos,
                                            longDescription:value
                                        }
                                    })
                                }}
                            />
                        </>
                    </div>
                    <div className="px-8 flex gap-x-8 mt-10 max-w-lg">
                        <CustomButton
                            onClick={() => navigate('/console/formations/')}
                            title="Annuler les modifications"
                            variant="btnSecondary"
                            className="w-full"
                        />
                        <CustomButton
                            onClick={onSave}
                            title={formationData.flag === 'new'?"Creer la formation":"Enregistrer"}
                            className="w-full"
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default GeneralInfos;