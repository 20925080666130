import "../../../../assets/react-quill-snow.css";
import web from '../../../../assets/web.png';
import { Link } from "react-router-dom"
import ReactQuill from "react-quill";
import CustomButton from "../../../../components/CustomButton";
import { ChapterTaskType, UserType } from "../../../../global/types";

type WebDocumentViewerPropsType = {
    hasUserDoneTask:boolean|undefined
    task:ChapterTaskType;
    user:UserType
}
const WebDocumentViewer:React.FC<WebDocumentViewerPropsType> = ({user, task}) => {  
    return (
        <div>
            <Link
                to={task.onlineUrl||''}
                target="_blank"
                rel="noreferrer"
                className="flex bg-white rounded-xl p-4 mt-4"
            >
                <img className="h-32 w-32 object-contain rounded-md" src={web} alt="" />
                <div className="mx-4">
                    <p className="text-xl mb-2">{task.title} </p>
                    <div
                        dangerouslySetInnerHTML={{__html:task.description}}
                        className="!text-gray-500"
                    />
                </div>
            </Link>
            {/* {
                ['student'].some((item:any) => user.accountType.includes(item))?
                    <div className="mt-6">
                        <p className="p-1 mb-2">Resume de votre comprehension:</p>
                        <ReactQuill
                            theme="snow"
                        />
                        <CustomButton
                            className="w-4/5 mx-auto mt-4"
                            title="Soumettre le resume"
                        />
                    </div>
                    :
                    null
            } */}
        </div>
    )
};

export default WebDocumentViewer;