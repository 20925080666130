import { useEffect, useState } from "react";
import { BlogArticle } from "../../global/types";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../App";


const About = () => {
  const [article, setArticle] = useState<BlogArticle | null>(null);
  const {aboutId} = useParams();
  const [loading, setLoading] = useState(false);
  
  const getAndSetArticle = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const articleSnap = await getDoc(doc(db, `articles/${aboutId}`));
      const snapData = articleSnap.data() as BlogArticle|undefined;
      if(snapData) setArticle({
          description:snapData.description,
          id:snapData.id,
          period:snapData.period,
          options:snapData.options,
          rawHtml:snapData.rawHtml,
          title:snapData.title,
          imageUrl:snapData.imageUrl
      })
    } catch (error) {
      console.log('Error while getting the predefined article: ', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAndSetArticle();
  }, [aboutId])
  return (
    <div className="flex flex-col w-full max-w-6xl mx-auto min-h-[60vh] bg-secondary-light rounded-2xl py-8 my-4 lg:my-8">
      <div className="mx-6 md:mx-12">
          <p className="text-xl lg:text-2xl font-medium">
            {article?.title}
          </p>
        <div className="mt-8">
          {/* <p className="text-3xl mb-2">Presentation:</p> */}
          <div
            dangerouslySetInnerHTML={{ __html: article?.rawHtml||'' }}
            className="prose-a:text-blue-500 prose-a:underline prose-a:underline-offset-2"
          />
        </div>
      </div>
    </div>
  )
};


export default About;