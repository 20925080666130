import "../assets/react-quill-snow.css";
import ReactQuill, { Value } from "react-quill";

import { FieldHookConfig, useField } from 'formik';

type FormikRichTextEditorProps = {
    label: string;
} & FieldHookConfig<string>;

export const CustomFormikRichTextEditor: React.FC<FormikRichTextEditorProps> = ({ label, hidden, disabled, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const { value } = meta;
    const { setValue } = helpers;

    return (
        <div className="mt-2">
            <label htmlFor={props.id || props.name}>{label}</label>
            {/** @ts-ignore */}
            <ReactQuill
                {...field}
                {...props}
                value={value || ''}
                readOnly={disabled}
                onChange={value => setValue(value)}
                className={"textInput max-h-none "+(hidden? '!bg-primary-light !border-opacity-60 !border-primary-light !bg-opacity-20 ':'')}
            />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    );
};



type CustomRichEditorPropsType = {
    onChange?:(value:string) => void
    value?:Value
    label?:React.ReactNode
    disabled?:boolean
    hidden?:boolean
};

const CustomRichEditor:React.FC<CustomRichEditorPropsType> = ({label, onChange, value, disabled, hidden}) => {
    return (
        <div>
            {label? <div className="mb-2">{label}</div> : null}
            <ReactQuill
                className={"textInput max-h-none"+(hidden? 'inputHidden ':'')}
                theme="snow"
                onChange={onChange}
                value={value}
                readOnly={disabled}
            />
        </div>
    )
};

export default CustomRichEditor;