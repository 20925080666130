import { useContext, useState } from 'react';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { IoSchoolSharp } from 'react-icons/io5';
import { RiAdminFill, RiUser2Fill } from 'react-icons/ri';
import { HiLink, HiShieldExclamation } from 'react-icons/hi';
import { ImBooks, ImLoop } from 'react-icons/im';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill, BsFillCalendarCheckFill, BsPersonVideo3 } from 'react-icons/bs';
import { MdOutlineEventNote } from 'react-icons/md';
import { AuthContext } from '../contexts/AuthContext';
import { useWindowSize } from '../utils/window';
import { HiChatBubbleOvalLeft, HiDocument, HiChatBubbleOvalLeftEllipsis, HiOutlineDocument } from 'react-icons/hi2';
import { FaLaptop } from 'react-icons/fa';



type ItemPropsType = {
  className?: string
  icon: any
  title: string
  to: string
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
};

const Item = ({ className, title, to, icon, selected, setSelected }: ItemPropsType) => {
  return (
    <MenuItem
      active={selected === title}
      onClick={() => setSelected(title)}
      icon={icon}
      className={className + (selected === title ? " bg-white bg-opacity-30 font-white" : '') + " hover:bg-white hover:bg-opacity-20 hover:font-white"}
    >
      <div className='bg-gray'>{title}</div>
      <Link to={to} />
    </MenuItem>
  );
};

const SideBar = () => {
  const user = useContext(AuthContext)?.user;
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const [selected, setSelected] = useState("Dashboard");
  const [isCollapsed, setIsCollapsed] = useState(true);

  if (!user) {
    navigate('/signin'); // redirect to the login page if user is not authenticated
    return null; // render nothing until user is authenticated
  };

  const SideBarToggler = ({ className }: { className?: string }) => {
    if (isCollapsed) {
      return (
        <div
          className={'hover:bg-slate-500 hover:bg-opacity-25 p-2 h-12 flex items-center justify-center ' + className}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <BsArrowRightCircleFill size={24} color='#fff' />
        </div>
      )
    } else {
      return (
        <div
          className='hover:bg-slate-500 hover:bg-opacity-25 p-2 flex items-center justify-between h-12'
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <p className='text-2xl'>Menu</p>
          <BsArrowLeftCircleFill size={24} color='#fff' />
        </div>
      )
    }
  };
  
  return (
    <div className='md:w-[60px] relative overflow-visible min-h-[36rem]'>
      {
        isCollapsed && windowSize.width < 768 ?
          <SideBarToggler
            className='fixed w-[60px] bg-black bg-opacity-80 hover:bg-opacity-100'
          />
          :
          null
      }
      <ProSidebar className='z-40 !absolute' collapsedWidth={windowSize.width < 768 ? '0px' : '60px'} collapsed={isCollapsed}>
        <SidebarHeader className='cursor-pointer'>
          <SideBarToggler />
        </SidebarHeader>
        <SidebarContent className={isCollapsed ? '-ml-2' : ''}>
          <Menu popperArrow iconShape="round">
            {/* {
                            isCollapsed?
                            null
                            :
                            <div className='text-lg px-2 text-gray-200'>
                                General
                            </div>
                        } */}
            {/* <Item
                            title='Dashboard'
                            to='dashboard'
                            icon={<HiPresentationChartLine size={19}/>}
                            selected={selected}
                            setSelected={setSelected}
                        /> */}
          </Menu>
          <Menu style={{ display: ['super', 'teacher', 'expert', 'admin'].some((item: any) => user.accountType.includes(item)) ? undefined: 'none' }} popperArrow iconShape="round">
            {
              isCollapsed ?
                null
                :
                <div className='text-lg px-2 text-gray-200'>
                  Utilisateurs
                </div>
            }

            {
              ['super', 'teacher', 'expert', 'admin'].some((item: any) => user.accountType.includes(item)) ?
                <Item
                  title='Apprenants'
                  icon={<IoSchoolSharp size={20} />}
                  to='students'
                  selected={selected}
                  setSelected={setSelected}
                  className='hover:bg-white hover:bg-opacity-20 hover:font-white'
                />
                :
                null
            }
            {
              ['teacher', 'admin', 'super'].some((item: any) => user.accountType.includes(item)) ?
                <Item
                  title='Experts'
                  icon={<RiUser2Fill size={20} />}
                  className='hover:bg-white hover:bg-opacity-20 hover:font-white'
                  to='experts'
                  selected={selected}
                  setSelected={setSelected}
                />
                :
                null
            }
            {
              ['teacher', 'admin', 'super'].some((item: any) => user.accountType.includes(item)) ?
                <Item
                  title='Formateurs'
                  // icon={<FaChalkboardTeacher size={20} />}
                  icon={<BsPersonVideo3 size={20} />}
                  className='hover:bg-white hover:bg-opacity-20 hover:font-white'
                  to='teachers'
                  selected={selected}
                  setSelected={setSelected}
                />
                :
                null
            }
            {
              ['admin', 'super'].some((item: any) => user.accountType.includes(item)) ?
                <Item
                  title='Admins'
                  icon={<RiAdminFill size={20} />}
                  className='hover:bg-white hover:bg-opacity-20 hover:font-white'
                  to='admins'
                  selected={selected}
                  setSelected={setSelected}
                />
                :
                null
            }
            {
              ['super'].some((item: any) => user.accountType.includes(item)) ?
                <Item
                  title='Supers'
                  icon={<HiShieldExclamation size={20} />}
                  className='hover:bg-white hover:bg-opacity-20 hover:font-white'
                  to='supers'
                  selected={selected}
                  setSelected={setSelected}
                />
                :
                null
            }
          </Menu>
          <Menu iconShape="round">
            {
              isCollapsed ?
                null
                :
                <div className='text-lg px-2 text-gray-200'>
                  {['student'].some((item: any) => user.accountType.includes(item)) ? 'Formation' : 'Formations'}

                </div>
            }
            {/* {
              ['student', 'teacher', 'expert'].some((item: any) => user.accountType.includes(item)) ?
                <Item
                  title='Acces rapide'
                  to='rapid-access'
                  icon={<HiLink size={22} />}
                  selected={selected}
                  setSelected={setSelected}
                />
                :
                null
            } */}
            {
              ['student'].some((item: any) => user.accountType.includes(item)) ?
                <Item
                  title='Planning'
                  to='tasks'
                  icon={<BsFillCalendarCheckFill size={18} />}
                  selected={selected}
                  setSelected={setSelected}
                />
                : null
            }
            <Item
              title={['student'].some((item: any) => user.accountType.includes(item)) ? 'Formation' : 'Formations'}
              to='formations'
              selected={selected}
              setSelected={setSelected}
              icon={<ImLoop size={18} />}
              className='hover:bg-white hover:bg-opacity-20 hover:font-white'
            />
            <Item
              icon={<HiDocument size={22} />}
              selected={selected}
              setSelected={setSelected}
              title='Pré-Tests'
              to='/pre-tests'
            />

            <Item
              icon={<FaLaptop size={21} />}
              selected={selected}
              setSelected={setSelected}
              title='Articles'
              to='articles'
            />

            <Item
              icon={<HiChatBubbleOvalLeftEllipsis size={21} />}
              selected={selected}
              setSelected={setSelected}
              title='chats'
              to='messaging/chats'
            />
            {/* <Item
              icon={<ImBooks size={20} className='ml-1' />}
              selected={selected}
              setSelected={setSelected}
              title='Bibliographies'
              to='bibliographies'
            /> */}
          </Menu>
        </SidebarContent>
        <SidebarFooter
          className='hover:bg-white hover:bg-opacity-10 hover:font-white transition duration-100 cursor-pointer'
        >
          {
            !isCollapsed ?
              <Link
                onClick={() => setIsCollapsed(true)}
                to={`/console/${user.accountType[0]}s/${user.id}`} >
                <div className='my-2 py-3 flex flex-col items-center'>
                  <div className='w-24 h-24 bg-slate-400 rounded-3xl mb-2'>
                    <img
                      src={user.profileImgUrl}
                      alt=""
                    />
                  </div>
                  <p className='text-xl'>{user.name + ' ' + user.surname}</p>
                  <div className='text-sm mt-2'>{user.accountType}</div>
                  <div className='text-sm'>
                    {user.email}
                  </div>
                </div>
              </Link>
              :
              null
          }
        </SidebarFooter>
      </ProSidebar>
    </div>
  )
};

export default SideBar;