import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { functions } from "../../../App";
import { UserType } from "../../../global/types";
import { dropdowns } from "../../../assets/languages";

type CardPropsType = UserType & { index: number };

const Card = (figure: CardPropsType) => {
  return (
    <Link
      to={figure.id}
      state={{ figure }}
      className={"teacherCard group " + (figure.index % 2 === 0 ? 'flex-row' : 'flex-row-reverse justify-between md:flex-row md:justify-start')}
    >
      <div className="">
        <img
          src={figure.profileImgUrl}
          alt=""
          className="teacherImg group-hover:shadow-lg"
        />
      </div>
      <div className="lg:py-2 lg:px-6  px-4 py-2 text-contrast">
        <div className="font-semibold text-base lg:text-xl lg:pb-2">{figure.name + ' ' + figure.surname}</div>
        <p className="text-xs lg:text-base"><p className="font-medium"></p>{['teacher'].some((item: any) => figure.accountType.includes(item)) ? 'Formateur' : 'Expert'}</p>
        <div className="text-xs lg:text-base "><p className="font-medium">Pays: </p>{dropdowns.countries.filter(item => item.value === figure.country)[0]?.label}</div>
        <div className="text-xs lg:text-base "><p className="font-medium">Occupation: </p>{figure.occupation}</div>
      </div>
    </Link>
  )
};


const Figures = () => {
  const [loading, setLoading] = useState(false);
  const [figures, setFigures] = useState<UserType[]>([]);

  const getAndSetPlatformTeachers = async () => {
    setLoading(true);
    try {
      const getAllPlatformFigures = httpsCallable(functions, 'getAllPlatformFigures');
      const figuresData = (await getAllPlatformFigures()).data as UserType[];

      setFigures(figuresData);
      // console.log('Figures: ', figuresData)
    } catch (error) {
      console.log(error)
    }
    setLoading(false);
  }


  useEffect(() => {
    getAndSetPlatformTeachers()
  }, []);
  return (
    <div className="flex flex-1 py-12 flex-col mb-4">
      <h2 className="text-3xl lg:text-4xl px-4 lg:px-10 font-medium mb-6 pt-6">Catalogue de Formateurs</h2>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 gap-6 lg:gap-8 w-full'>
        {
          figures.map((item, id) => <Card key={id} index={id} {...item} />)
        }
      </div>
    </div>
  )
};

export default Figures;