import moment from "moment";
import { FormationChapterType, FormationCourseType, FormationModuleType, FormationPillarType, GlobalFormationType } from "../global/types";

export function getModulesFromPillars(pillars: FormationPillarType[]): FormationModuleType[] {
    return pillars.flatMap(pillar => pillar.modules);
}

export function getCoursesFromPillars(pillars: FormationPillarType[]): FormationCourseType[] {
    return getModulesFromPillars(pillars).flatMap(module => module.courses);
}

export function getChaptersFromPillars(pillars: FormationPillarType[]): FormationChapterType[] {
    return getCoursesFromPillars(pillars).flatMap(course => course.chapters);
}

export function updateFormation(globalFormation: GlobalFormationType, updatedFormation: Omit<GlobalFormationType, 'pillars'>): GlobalFormationType {
    return {
        ...updatedFormation,
        pillars:globalFormation.pillars
    };
}

export function updatePillar(globalFormation: GlobalFormationType, updatedPillar: Omit<FormationPillarType, 'modules'>, matchId?:string): GlobalFormationType {
    return {
        ...globalFormation,
        pillars: globalFormation.pillars.map(pillar => pillar.id === (matchId || updatedPillar.id) ? { ...updatedPillar, modules: pillar.modules } : pillar)
    };
}

export function updateModule(globalFormation: GlobalFormationType, updatedModule: Omit<FormationModuleType, 'courses'>, matchId?:string): GlobalFormationType {
    return {
        ...globalFormation,
        pillars: globalFormation.pillars.map(pillar => ({
            ...pillar,
            modules: pillar.modules.map(module => module.id === (matchId || updatedModule.id) ? { ...updatedModule, courses: module.courses } : module)
        }))
    };
}

export function updateCourse(globalFormation: GlobalFormationType, updatedCourse: Omit<FormationCourseType, 'chapters'>, matchId?:string): GlobalFormationType {
    return {
        ...globalFormation,
        pillars: globalFormation.pillars.map(pillar => ({
            ...pillar,
            modules: pillar.modules.map(module => ({
                ...module,
                courses: module.courses.map(course => course.id === (matchId || updatedCourse.id) ? { ...updatedCourse, chapters: course.chapters } : course)
            }))
        }))
    };
}

export function updateChapter(globalFormation: GlobalFormationType, updatedChapter: FormationChapterType, matchId?:string): GlobalFormationType {
    return {
        ...globalFormation,
        pillars: globalFormation.pillars.map(pillar => ({
            ...pillar,
            modules: pillar.modules.map(module => ({
                ...module,
                courses: module.courses.map(course => ({
                    ...course,
                    chapters: course.chapters.map(chapter => chapter.id === (matchId || updatedChapter.id) ? updatedChapter : chapter)
                }))
            }))
        }))
    };
}


export function createChildNode(nodeType: 'formation'|'pillar' | 'module' | 'course', nodeId: string, formation: GlobalFormationType): GlobalFormationType {
    switch (nodeType) {
        case 'formation':
            return ({
                ...formation,
                pillars:[
                    ...formation.pillars, {
                    id:'pillar_'+ Date.now().toString(),
                    index:Date.now(),
                    formationId:formation.id,
                    flag:'new',
                    title:'Enter a title',
                    description:'',
                    name:'',
                    modules:[]
                }]
            });
        case 'pillar':
            return ({
                ...formation,
                pillars: formation.pillars.map(pillar => pillar.id === nodeId ? {
                    ...pillar,
                    modules: [...pillar.modules, {
                        id: 'module_' + Date.now().toString(),
                        index:Date.now(),
                        formationId:formation.id,
                        pillarId:pillar.id,
                        flag:'new',
                        title: 'New Module',
                        name: 'New Module',
                        description: 'New Module Description',
                        courses: []
                    }]
                } : pillar)
            });
        case 'module':
            return ({
                ...formation,
                pillars: formation.pillars.map(pillar => ({
                    ...pillar,
                    modules: pillar.modules.map(module => module.id === nodeId ? {
                        ...module,
                        courses: [
                            ...module.courses, {
                                id: 'course_' + Date.now().toString(),
                                index:Date.now(),
                                formationId:formation.id,
                                pillarId:pillar.id,
                                moduleId:module.id,
                                flag:'new',
                                title: 'New Course',
                                name: 'New Course',
                                description: 'New Course Description',
                                teachers: [],
                                startDate: moment().toDate(),
                                endDate: moment().add({year:1}).toDate(),
                                chapters: [],
                                prerequisites: [],
                                prerequisitesDescription: '',
                                courseDuration: 0,
                                applicationDuration: 0,
                                pedagogicApproach: '',
                                evaluation: '',
                                needs: '',
                                bibliography: '',
                                teachersIds:[]
                            }]
                    } : module)
                }))
            });
        case 'course':
            return ({
                ...formation,
                pillars: formation.pillars.map(pillar => ({
                    ...pillar,
                    modules: pillar.modules.map(module => ({
                        ...module,
                        courses: module.courses.map(course => course.id === nodeId ? {
                            ...course,
                            chapters: [...course.chapters, {
                                id: 'chapter_' + Date.now().toString(),
                                index:Date.now(),
                                formationId:formation.id,
                                pillarId:pillar.id,
                                moduleId:module.id,
                                courseId:course.id,
                                flag:'new',
                                title: 'New Chapter',
                                name: 'New Chapter',
                                description: '',
                            }]
                        } : course)
                    }))
                }))
            })
    }
}


export function getParentNodeId(formation: GlobalFormationType, nodeType: 'pillar'|'module'|'course'|'chapter', nodeId: string): string | undefined {
    if (nodeType === 'pillar') {
        return undefined;
    } else if (nodeType === 'module') {
        const pillar = formation.pillars.find(pillar => pillar.modules.some(module => module.id === nodeId));
        return pillar?.id;
    } else if (nodeType === 'course') {
        const pillar = formation.pillars.find(pillar =>
            pillar.modules.some(module => module.courses.some(course => course.id === nodeId))
        );
        const module = pillar?.modules.find(module => module.courses.some(course => course.id === nodeId));
        return module?.id;
    } else if (nodeType === 'chapter') {
        const pillar = formation.pillars.find(pillar =>
            pillar.modules.some(module =>
                module.courses.some(course => course.chapters.some(chapter => chapter.id === nodeId))
            )
        );
        const module = pillar?.modules.find(module =>
            module.courses.some(course => course.chapters.some(chapter => chapter.id === nodeId))
        );
        const course = module?.courses.find(course => course.chapters.some(chapter => chapter.id === nodeId));
        return course?.id;
    }
};

/**Pass a global formation type as a param and get it sorted according to the indexes of its different nodes */
export function sortByIndexes(formation: GlobalFormationType): GlobalFormationType {
  const sortedTree = JSON.parse(JSON.stringify(formation)) as GlobalFormationType;

  // the `sort` method will mutate the sortedTree array directly
  sortedTree.pillars.sort((pillar1, pillar2) => pillar1.index - pillar2.index).forEach(pillar => {

    pillar.modules.sort((module1, module2) => module1.index - module2.index).forEach(module => {

      module.courses.sort((course1, course2) => course1.index - course2.index).forEach(course => {
        course.startDate = new Date(course.startDate);
        course.endDate = new Date(course.endDate);
        
        course.chapters.sort((chapter1, chapter2) => chapter1.index - chapter2.index)
      })
    })
  })

  return sortedTree;
};
