import { FaLinkedin, FaEnvelope, FaTwitter, FaWhatsapp, FaTelegram } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import { UserType } from "../../../global/types";
import { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../App";

const Figure = () => {
    let state:{figure:UserType|undefined}|null = useLocation().state;
    const {figureId} = useParams();
    
    const [figureData, setFigureData] = useState<UserType|null>(state?.figure||null)
    
    const getAndSetUserData = async () => {
        const getUsersByUids = httpsCallable(functions, 'getUsersByUids');
        const [adminUserData] = (await getUsersByUids({uids:[figureId]})).data as UserType[];
        setFigureData(adminUserData as UserType)
    };

    
    const onEmailClick = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        window.location.href = "mailto:"+figureData?.email;
        e.preventDefault();
    };


    useEffect(() => {
        getAndSetUserData();
    }, []);
    return (
    <div className="figureViewContainer">
        <div className="flex flex-1 flex-col items-center">
            <div className="rounded-3xl overflow-hidden h-80 w-80">
                <img
                    src={figureData?.profileImgUrl}
                    alt="Figure Profile"
                    className="w-80 h-80"
                />
            </div>
            <div className="px-4">
                <h1 className="text-3xl font-bold mt-8">{figureData?.name+' '+ figureData?.surname}</h1>
                <h2 className="text-xl font-medium text-gray-500">
                    {figureData?.occupation}
                </h2>
                <h2 className="text-xl font-medium text-gray-500">
                    {['teacher'].some((item:any) => figureData?.accountType.includes(item))?'Formateur':'Expert'}
                </h2>
                <div className="flex justify-center items-center mt-6 space-x-4">
                    {
                        figureData?.options.emailVisibility ==='public'?
                        <Link
                            to="#"
                            onClick={onEmailClick}
                            className="text-gray-400 hover:text-gray-600 h-6 w-6 transition duration-200"
                        >
                            <FaEnvelope size={26}/>{" "}
                        </Link>
                        :
                        null
                    }
                    {figureData?.linkedInLink?
                        <Link
                            to={figureData.linkedInLink}
                            className="text-gray-400 hover:text-gray-600 h-6 w-6 transition duration-200"
                        >
                            <FaLinkedin size={26}/>
                        </Link>
                        :
                        null
                    }
                    {figureData?.twitterLink?
                        <Link
                            to="#"
                            className="text-gray-400 hover:text-gray-600 h-6 w-6 transition duration-200"
                        >
                            <FaTwitter size={26}/>
                        </Link>
                        :
                        null
                    }
                    {figureData?.telegramNumber?
                        <Link
                            to={'https://t.me/'+figureData.telegramNumber}
                            className="text-gray-400 hover:text-gray-600 h-6 w-6 transition duration-200"
                        >
                            <FaTelegram size={26}/>
                        </Link>
                        :
                        null
                    }
                    {figureData?.whatsAppNumber?
                        <Link
                            to={'https://wa.me/'+figureData.whatsAppNumber}
                            className="text-gray-400 hover:text-gray-600 h-6 w-6 transition duration-200"
                        >
                            <FaWhatsapp size={27}/>
                        </Link>
                        :
                        null
                    }
                </div>
            </div>
        </div>

        <div className="flex flex-1">
            <p
                className="text-center mt-8 max-w-lg px-6"
                dangerouslySetInnerHTML={{__html:figureData?.description||''}}
            />
        </div>
    </div>
  );
};
export default Figure;
