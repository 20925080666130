import { LinearProgress } from '@mui/material';
import { DataGrid, GridClasses, GridColDef, GridNoRowsOverlay, GridRowParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import 'moment/locale/fr';
import { ChapterTaskUserRecord } from '../../../../../global/types';



const columns: GridColDef[] = [
  // { field: 'id', headerName: 'ID', minWidth: 50, flex:1 },
  { field: 'title', headerName: "Titre de l'activite", minWidth: 360, flex: 2 },
  { field: 'type', headerName: "Type d'activite", minWidth: 160, flex: 2 },
  { field: 'chapter', headerName: 'Chapitre', minWidth: 240, flex: 2 },
  { field: 'course', headerName: 'Cours', minWidth: 240, flex: 2 },
  // { field: 'teacher', headerName: 'Formateur', minWidth: 75, flex:2 },
  // { field: 'status', headerName: 'Statut', minWidth: 50, flex:1 },
  // { field: 'assignedOn', headerName: 'Date Assignee', minWidth: 50, flex:1 },
  { field: 'passedOn', headerName: 'Date de Passage', minWidth: 100, flex: 1 },
];



const CustomToolbar = () => {
  return (
    <GridToolbarContainer className='bg-secondary-light' sx={{ paddingX: 2 }}>
      <GridToolbarColumnsButton className='text-dark' sx={{ paddingY: 2, color: '#7F5032' }} />
      <GridToolbarFilterButton sx={{ paddingY: 2, color: '#7F5032' }} />
      <GridToolbarDensitySelector sx={{ paddingY: 2, color: '#7F5032' }} />
      <GridToolbarExport sx={{ paddingY: 2, color: '#7F5032' }} />
    </GridToolbarContainer>
  );
};

type TasksGridPropsType = {
  isLoading: boolean
  taskData: ChapterTaskUserRecord[]
};

const TasksGrid: React.FC<TasksGridPropsType> = ({ isLoading, taskData }) => {
  const navigate = useNavigate();
  const { studentId } = useParams();

  const gridClassNames: Partial<GridClasses> = {
    footerContainer: 'bg-secondary-light'
  }

  const rows = taskData.map((data) => ({
    id: data.id.toString(),
    title: data.title,
    teacher: data.author.name + ' ' + data.author.surname,
    status: data.status,
    type: data.type,
    chapter: data.taskChapter.title,
    course: data.taskCourse.title,
    passedOn: data.hasUserDoneTask ? moment(data.completedTaskOn).locale('fr').format('DD MMMM YYYY HH:mm') : '---'
  }));

  const onRowClick = (row: GridRowParams<any>) => {
    const task = taskData.find(item => item.id === row.id);
    if (!task) return;
    // console.log('Task type: ', ['MCQ', 'structural', 'submission'].includes(task.type))
    let path = `/console/tasks/${task.id}`
    path += ['MCQ', 'structural', 'submission'].includes(task.type) ? ('/' + studentId) : ''
    navigate(path, { state: { task: task } });
  };
  return (
    <div className='flex flex-1 flex-col'>
      <div className='font-medium text-3xl my-4'>Grille de Tâches</div>
      <div className='h-[80vh] mb-8'>
        <DataGrid
          slots={{ toolbar: CustomToolbar, loadingOverlay: LinearProgress, noRowsOverlay: GridNoRowsOverlay }}
          classes={gridClassNames}
          autoPageSize
          rows={rows}
          loading={isLoading}
          columns={columns}
          pageSizeOptions={[10]}
          onRowDoubleClick={onRowClick}
        />
      </div>
    </div>
  )
};

export default TasksGrid;