import { DataNode } from "rc-tree/lib/interface";
import { GlobalFormationType } from "../global/types";

type getFormationDataForTreePropsType = {
    formation: GlobalFormationType
};

export const getFormationDataForTree: (e: getFormationDataForTreePropsType) => DataNode[] = ({ formation }) => {
    return [{
        title:formation.title,
        key:`formation_${formation.id}`,
        children:formation.pillars.map((pillar, pillarIndex) => {
        // using <entityname>_<entityid> helps to generate unique ids for the tree. Even if two entities
        // like a chapter and a module originally have the same id, the <entityname> will differenciate them
        return {
            title: 'Pilier '+ (pillarIndex+1) +': '+ pillar.title,
            key: `pillar_${pillar.id}`,
            children: pillar.modules.map((module, moduleIndex) => {
                return {
                    title: 'Module '+ (pillarIndex+1)+'.'+(moduleIndex+1) +': '+ module.title,
                    key: `module_${module.id}`,
                    children: module.courses.map((course, courseIndex) => {
                        return {
                            title: 'Cours '+ (pillarIndex+1)+'.'+(moduleIndex+1)+'.'+(courseIndex+1) +': '+ course.title,
                            key: `course_${course.id}`,
                            children: course.chapters.map((chapter, chapterIndex) => {
                                return {
                                    title:'Chapter '+ (chapterIndex+1) +': '+ chapter.title,
                                    key: `chapter_${chapter.id}`,
                                    isLeaf:true
                                }
                            })
                        };
                    }),
                };
            }),
        };
    })}]
};

type getTreeDataForFormationPropsType = {
    formation: GlobalFormationType;
    dataNodes: DataNode[];
};


export const getTreeDataForFormation: (e: getTreeDataForFormationPropsType) => GlobalFormationType = ({ formation, dataNodes }) => {
    const sortedFormation: GlobalFormationType = {
        ...formation,
        pillars: formation.pillars.sort((pillarA, pillarB) => {
            return (
                dataNodes.findIndex((pillarNode) => pillarNode.key === pillarA.id)
                - dataNodes.findIndex((pillarNode) => pillarNode.key === pillarB.id)
            )
        }).map(pillar => ({
            ...pillar,
            modules: pillar.modules.sort((moduleA, moduleB) => {
                return (
                    dataNodes.findIndex((moduleNode) => moduleNode.key === moduleA.id)
                    - dataNodes.findIndex((moduleNode) => moduleNode.key === moduleB.id)
                )
            }).map(module => ({
                ...module,
                courses: module.courses.sort((courseA, courseB) => {
                    return (
                        dataNodes.findIndex((courseNode) => courseNode.key === courseA.id)
                        - dataNodes.findIndex((courseNode) => courseNode.key === courseB.id)
                    )
                }).map(course => ({
                    ...course,
                    chapters: course.chapters.sort((chapterA, chapterB) => {
                        return (
                            dataNodes.findIndex((chapterNode) => chapterNode.key === chapterA.id)
                            - dataNodes.findIndex((chapterNode) => chapterNode.key === chapterB.id)
                        )
                    })
                }))
            }))
        }))
    }

    return sortedFormation;
}
