import * as Yup from 'yup';
import { Formik, Form, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import CustomButton from "./CustomButton";
import CustomInput from "./CustomInput";
import CustomSelect from './CustomSelect';
import { CustomFormikRichTextEditor } from './CustomRichEditor';
import { dropdowns, userProfile } from '../assets/languages';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../App';
import { getFileUploadSource } from '../utils/files';
import { useNavigate } from 'react-router-dom';
import CustomAlertDialog from '../components/CustomDialog';
import { ExtraFieldsType, GlobalFormationType, UserType, accountTypes, otherAccountData } from '../global/types';
import { AuthErrorCodes } from 'firebase/auth';



interface FormValues {
  id: string;
  name: string;
  surname: string;
  email: string;
  occupation: string;
  phone: string;
  country: string;
  description: string;
  profileImg: File | null | undefined
  expertiseDomain?: string;
  followingFormations?: string[];
  teachedCourses?: string[];
}


const initialValues: FormValues = {
  id: "",
  name: "",
  surname: "",
  email: "",
  occupation: "",
  phone: "",
  country: "",
  description: "",
  profileImg: null,
  expertiseDomain: "",
  followingFormations: [],
  teachedCourses: []
};


type ProfileAddFormPropsType = {
  otherAccountData: otherAccountData;
  extraFields: ExtraFieldsType;
  accountType: accountTypes;
};


const ProfileAddForm: React.FC<ProfileAddFormPropsType> = ({ accountType, extraFields, otherAccountData }) => {
  const navigate = useNavigate();
  const [profileImg, setProfileImg] = useState<File | null | undefined>(null);
  const [editingDisabled, setEditingDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formations, setFormations] = useState<Omit<GlobalFormationType, 'pillars'>[]>([]);
  const [error, setError] = useState<string | null>(null);

  const validationSchema = Yup.object({
    // id: Yup.string().required("Champ obligatoire"),
    name: Yup.string().required("Champ obligatoire"),
    surname: Yup.string().required("Champ obligatoire"),
    email: Yup.string().email("Format d'addresse email invalide").required("Champ obligatoire"),
    occupation: Yup.string().required("Champ obligatoire"),
    phone: Yup.string().required("Champ obligatoire"),
    country: Yup.string().required("Champ obligatoire"),
    // expertiseDomain:extraFields.expertiseDomain? Yup.string().required("Champ Obligatoire") : Yup.string().notRequired(),
    // followingFormations:extraFields.followingFormations? Yup.string().required("Champ Obligatoire") : Yup.string().notRequired(),
    // teachedCourses:extraFields.teachedCourses? Yup.string().required("Champ Obligatoire") : Yup.string().notRequired()
  });

  const onSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    if (loading) return;
    setError(null);
    setLoading(true);
    try {
      let profileImgSource = null;

      if (values.profileImg) {
        profileImgSource = await getFileUploadSource(values.profileImg)
      }

      let uploadableValues = {
        ...values,
        accountType,
        profileImgSource,
        options: otherAccountData.accountOptions,
        telegramNumber: otherAccountData.telegramNumber,
        whatsAppNumber: otherAccountData.whatsAppNumber,
        linkedInLink: otherAccountData.linkedInLink,
        twitterLink: otherAccountData.twitterLink
      };
      delete uploadableValues.profileImg;

      Object.keys(extraFields).forEach(key => {
        if (!extraFields[key as keyof ExtraFieldsType]) {
          delete uploadableValues[key as keyof ExtraFieldsType];
        }
      })

      // console.log('Uploadable values: ', uploadableValues);
      const registerUser = httpsCallable(functions, 'registerUser');
      const userId = (await registerUser(uploadableValues)).data as string;
      navigate('/console/' + accountType + 's/' + userId)
      console.log('User Id: ', userId);
      // setDialogOpen(true);
    } catch (error: any) {
      console.log('Error while creating user: ', error)
      
      if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
        setError('Addresse email deja utilisée')
      } else if(error.code === AuthErrorCodes.INVALID_PHONE_NUMBER) {
        setError('Numero de téléphone invalide')
      } else if (error.code === "functions/already-exists") {
        setError('Numero de téléphone déjà utilisé')
      } else if(error.code === AuthErrorCodes.INVALID_EMAIL) {
        setError('Format d\'addresse email invalide');
      } else {
        // functions/unknown
        setError('Une erreur inconnue est survenue.')
      }

    }
    setLoading(false)
  };


  const getAndSetFormations = async () => {
    try {
      const getAllFormations = httpsCallable(functions, 'getAllFormations');
      const resultData = (await getAllFormations()).data as Omit<GlobalFormationType, 'pillars'>[];
      setFormations(resultData || []);
      console.log('Formations: ', resultData);

    } catch (error) {
      console.log('Error while fetching list of formations', error)
    }
  }


  useEffect(() => {
    getAndSetFormations()
  }, []);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => onSubmit(values, helpers)}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form className='w-full'>
          <div className="text-2xl font-medium mb-4 self-start flex justify-between items-center">
            Presentation
            {/* <HiChatBubbleLeftEllipsis className='text-gray-700 p-2 hover:text-black cursor-pointer transition duration-200 hover:shadow-lg rounded-full' size={40} /> */}
          </div>
          <CustomAlertDialog
            confirmtButtonTitle='Ok'
            title="L'utilisateur a été créé avec succès"
            onClose={() => navigate('/console/' + accountType[0] + 's')}
            open={dialogOpen}
            setOpen={setDialogOpen}
          />
          <div className="md:flex">
            <div className="rounded-3xl overflow-hidden h-40 w-40 md:h-56 md:w-56 m-8 md:mr-12 mb-4 border border-primary-light border-opacity-60">
              <label>
                <div className="relative">
                  <img
                    src={profileImg ? URL.createObjectURL(profileImg) : require("../assets/noprofile.jpg")}
                    className="h-40 w-40 md:h-56 md:w-56 cursor-pointer"
                    alt="Student profile"
                  />
                  <div className="absolute inset-0 bg-black opacity-0 cursor-pointer hover:opacity-50 transition-opacity"></div>
                </div>
                <input
                  name="profileImg"
                  accept=".webp,.jpeg,.jpg,.png"
                  style={{ position: 'absolute', opacity: 0 }}
                  type="file"
                  // required
                  placeholder="Choisir une image"
                  onChange={(e) => {
                    setProfileImg(e.target.files?.item(0));
                    setFieldValue('profileImg', e.target.files?.item(0));
                  }}
                />
              </label>
            </div>

            <div className="flex flex-1 flex-col justify-center ">
              <CustomInput
                name="name"
                label={userProfile.labels.name}
                placeholder=""
                disabled={editingDisabled}
                hidden={editingDisabled}
              />
              <CustomInput
                name="surname"
                label={userProfile.labels.surname}
                placeholder=""
                disabled={editingDisabled}
                hidden={editingDisabled}
              />
              <CustomInput
                name="phone"
                label={userProfile.labels.phone}
                placeholder=""
                disabled={editingDisabled}
                hidden={editingDisabled}
              />
              {/* <CustomInput
                                name="id"
                                label="ID :"
                                placeholder=""
                                disabled={true}
                                hidden={editingDisabled}
                            /> */}
            </div>
          </div>
          <CustomInput
            name="email"
            label={userProfile.labels.email}
            placeholder=""
            type="email"
            disabled={editingDisabled}
            hidden={editingDisabled}
          />
          <CustomInput
            name="occupation"
            label={userProfile.labels.occupation}
            placeholder=""
            disabled={editingDisabled}
            hidden={editingDisabled}
          />
          <CustomSelect
            name="country"
            label={userProfile.labels.country}
            onChange={(newValue: any) => setFieldValue('country', newValue.value)}
            labelClassName='mb-0'
            options={dropdowns.countries}
          />
          {
            extraFields.expertiseDomain ?
              <CustomSelect
                name="expertiseDomain"
                label={userProfile.labels.expertiseDomain}
                onChange={(newValue: any) => setFieldValue('expertiseDomain', newValue.value)}
                labelClassName='mb-0'
                options={dropdowns.expertiseDomain}
              />
              :
              null
          }
          <CustomFormikRichTextEditor
            name="description"
            label={userProfile.labels.description}
            as="textarea"
            rows={4}
            placeholder=""
            className='mt-2'
            disabled={editingDisabled}
            hidden={editingDisabled}
          />
          {
            extraFields.followingFormations ?
              <CustomSelect
                name="followingFormations"
                label={userProfile.labels.followingFormations}
                onChange={(newValue: any) => setFieldValue('followingFormations', [newValue.value])}
                labelClassName='mb-0'
                options={formations.map(formation => ({ label: formation.title, value: formation.id }))}
              />
              :
              null
          }
          {
            extraFields.teachedCourses ?
              <CustomSelect
                name="teachedCourses"
                label={userProfile.labels.teachedCourses}
                onChange={(newValue: any) => setFieldValue('teachedCourses', newValue.value)}
                labelClassName='mb-0'
                options={dropdowns.expertiseDomain}
              />
              :
              null
          }
            <div className="grid grid-cols-2 gap-x-6 my-8">
              {
                error?
                <p className='text-red-500 font-medium col-span-2 mb-2'>{error}</p>
                :null
              }
            <CustomButton
              title="Annuler"
              variant="btnDanger"
              navigateTo='/console/admins'
            />
            <CustomButton
              title="Enregister"
              btnType="submit"
              loading={loading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};


export default ProfileAddForm;