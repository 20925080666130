// import TimeSpent from "./graphs/TimeSpent";

const History = () => {
    return (
        <div className="flex flex-1 flex-col">
            {/* <TimeSpent/> */}
            <div className="mx-auto text-lg mt-6 font-medium">
                Derniere connexion le: 10/03/2023
            </div>
        </div>
    )
};

export default History;