import { Fragment, useContext, useEffect, useState } from "react";
import { IoCheckmarkCircle, IoCloseCircle, IoEye } from "react-icons/io5";
import { RxPencil2 } from 'react-icons/rx';
import { RiTimer2Line } from "react-icons/ri";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import { AuthContext } from "../../../contexts/AuthContext";
import { ChapterTaskType, FormationChapterType, FormationCourseType, UserType, activitiesType } from "../../../global/types";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../App";
import { formation } from "../../../assets/languages";
import moment from 'moment';
import { HiOutlineLockClosed, HiOutlineLockOpen } from "react-icons/hi";

type ChapterPresentationPropsType = {
  user: UserType | null | undefined;
  chapter: FormationChapterType | null;
};


const ChapterPresentation: React.FC<ChapterPresentationPropsType> = ({ chapter, user }) => {
  return (
    <div className="md:flex bg-white rounded-2xl">
      <div className="rounded-xl overflow-hidden flex flex-1">
        {
          chapter?.imageUrl ?
            <img className="m-4 rounded-lg overflow-hidden object-contain h-full w-full" src={chapter.imageUrl} alt="" />
            :
            <div className="flex flex-1 bg-slate-100 m-4 text-gray-400 font-medium rounded-lg p-6 items-center justify-center">
              Aucune Illustration disponible
            </div>
        }
      </div>
      <div className="my-2 p-1 md:p-4 flex flex-1 flex-col">
        <p className="font-medium text-2xl mb-4">
          Chapitre {(chapter?.index || 0) + 1}: {chapter?.title}
        </p>
        <div>
          {chapter?.description.split('\n').map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
};

type TaskBoardPropsType = {
  user: UserType | null | undefined;
  chapterTasks: ChapterTaskType[];
  loading: boolean;
  course: FormationCourseType | undefined;
};

const TaskBoard: React.FC<TaskBoardPropsType> = ({ user, chapterTasks, course, loading }) => {
  const getTaskIcon = (task: ChapterTaskType) => {
    if (['teacher'].some((item: any) => user?.accountType.includes(item))) {
      return (
        <Link
          title="Editer la tache"
          to={'editTask'}
          state={{ taskData: task }}
        >
            <RxPencil2 className="h-8 w-8 text-slate-500" />
        </Link>
      );
    }
    //  else if (['student'].some((item: any) => user?.accountType.includes(item))) {
    //   const iconsObj = {
    //     scheduled: <RiTimer2Line className="h-8 w-8 text-contrast" />,
    //     completed: <IoCheckmarkCircle className="h-8 w-8 text-green-600" />,
    //     undone: <IoCloseCircle className="h-8 w-8 text-red-600" />,
    //     open: <HiOutlineLockOpen className="h-8 w-8 text-contrast" />,
    //     close: <HiOutlineLockClosed className="h-8 w-8 text-contrast" />,
    //   };
    //   const disabledDoneActivities: activitiesType[] =
    //     [
    //       'documentFile',
    //       'documentUrl',
    //       'videoFile',
    //       'teleMeeting',
    //       'youtubeVideo'
    //     ]
    //   if (task.hasUserDoneTask) {
    //     return iconsObj.completed;
    //   } else if (moment(task.startDate).isAfter(new Date())) {
    //     return iconsObj.scheduled;
    //   } else if (moment(task.endDate).isBefore(new Date()) && disabledDoneActivities.includes(task.type)) {
    //     return iconsObj.completed;
    //   } else if (moment(task.endDate).isBefore(new Date()) && !task.hasUserDoneTask) {
    //     return iconsObj.undone;
    //   } else if (moment().isBetween(task.startDate, task.endDate) && !task.hasUserDoneTask) {
    //     return iconsObj.open;
    //   }

    // } else return;
  };

  return (
    <div className="p-4 rounded-2xl bg-white">
      <h2 className="text-2xl font-medium ml-2 mb-4 self-start">Tâches</h2>
      {
        loading ?
          <div className="flex items-center justify-center bg-slate-100 rounded my-2 py-4 px-2 text-sm animate-pulse">
            <span className="text-xl text-slate-600 font-medium">Chargement...</span>
          </div>
          :
          !chapterTasks.length ?
            <div className="flex items-center justify-center bg-slate-100 rounded my-2 py-4 px-2 text-sm">
              <span className="text-xl text-slate-500 font-medium">Aucune tâche ici</span>
            </div>
            :
            chapterTasks.map(task => (
              <Link key={task.id} state={{ task: task }} to={'/console/tasks/' + task.id}>
                <div className="flex justify-between bg-slate-100 rounded my-2 py-2 px-2 text-sm">
                  <div className="px-2 prose-headings:prose-base">
                    {task.title}
                  </div>
                  <div className="flex items-center">
                    <span>{formation.activitiesType[task.type]}</span>
                    <div className="h-8 w-8 ml-1 md:ml-3">
                      {getTaskIcon(task)}
                    </div>
                  </div>
                </div>
              </Link>
            ))
      }
      {
        (
          ['teacher'].some((item: any) => user?.accountType.includes(item))
          && user
          && course?.teachersIds.includes(user.id)
        ) ?
          <CustomButton
            className="w-64 mt-4"
            navigateTo="addTask"
            title="Ajouter une tâche"
          />
          :
          null
      }
    </div>
  )
};

const Chapter = () => {
  const user = useContext(AuthContext)?.user;
  const navigate = useNavigate();

  const state: { chapter: FormationChapterType, course: FormationCourseType } | null = useLocation().state;
  const { formationId, chapterId } = useParams();
  const [chapterData, setChapterData] = useState<FormationChapterType & { tasks: ChapterTaskType[] } | null>(state?.chapter ? { ...state.chapter, tasks: [] } : null);
  const [loading, setLoading] = useState(false);


  const getAndSetChapterData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const getChapterData = httpsCallable(functions, 'getChapterData');
      const dbChapterData = (await getChapterData({ chapterId })).data as FormationChapterType & { tasks: ChapterTaskType[] };
      setChapterData(dbChapterData);
    } catch (error) {
      console.log('Error while getting chapter data: ', error);
    }

    setLoading(false);
  };


  useEffect(() => {
    if (!user) {
      return navigate('/signin'); // redirect to the login page if user is not authenticated
    } else {
      getAndSetChapterData();
    };
  }, []);
  return (
    <div className="flex flex-1">
      <div className="mt-4 mb-12 px-2 py-4 md:p-4 mx-auto rounded-2xl w-full flex flex-col max-w-7xl bg-secondary-light">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col flex-[2] my-2 md:m-2">
            <ChapterPresentation
              chapter={chapterData}
              user={user}
            />
          </div>
          <div className="flex flex-col flex-[2] my-2 md:m-2">
            <TaskBoard
              chapterTasks={chapterData?.tasks || []}
              user={user}
              loading={loading}
              course={state?.course}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

export default Chapter;