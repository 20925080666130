import { useLocation, useParams } from 'react-router-dom';
import { UserType, otherAccountData } from '../../../../global/types';
import SuperForm from './components/Form';
import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../App';
import Administration from '../../../../components/Administration';

const Super = () => {
    let state:{userData:UserType}|null = useLocation().state;
    const {superId} = useParams();
    const [userData, setUserData] = useState<UserType|undefined>(state?.userData);
    const [otherAccountData, setOtherAccountData] = useState<otherAccountData>({accountOptions:{state:'enabled', emailVisibility:'public'}})
    
    const getAndSetUserData = async () => {
        try {
            const getUsersByUids = httpsCallable(functions, 'getUsersByUids');
            const [superUserData] = (await getUsersByUids({uids:[superId]})).data as UserType[];
            setUserData(superUserData as UserType);
            
            const {options:accountOptions, whatsAppNumber, telegramNumber} = superUserData;
            setOtherAccountData({accountOptions, whatsAppNumber, telegramNumber});
        } catch (error) {
            console.log('Error while getting user data: ', error)
        }
    }
    
    useEffect(() => {
        if((!state?.userData) && (!userData)) { //The last condition one prevent infinite rerenders
            getAndSetUserData()
        }
    }, [])
    
    return (
        <div className="flex flex-1 flex-col">
            <div className="mt-8 mb-12 mx-auto w-full flex flex-col max-w-7xl">
                <h2 className="text-4xl font-medium ml-4 mb-8 self-start">Super Administrateur</h2>
                <div className='flex flex-col lg:flex-row gap-8'>
                    <div className='bg-secondary-light p-3 md:p-8 rounded-2xl flex flex-[5] h-min'>
                        <SuperForm
                            user={userData}
                            otherAccountData={otherAccountData}
                        />
                    </div>
                    <div className='flex flex-[2] flex-col gap-y-8'>
                        <Administration
                            otherAccountData={otherAccountData}
                            setOtherAccountData={setOtherAccountData}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Super;