import { useContext, useEffect, useState } from "react";
import { FormationCourseType, GlobalFormationType } from "../../../global/types";
import { formations } from "../../../assets/formations";
import { Link } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import { AuthContext } from "../../../contexts/AuthContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../App";


type CourseCardPropsType = {
    course:FormationCourseType&{formationId:string};
};

const CourseCard:React.FC<CourseCardPropsType> = ({course}) => {
    return (
        <div className="bg-secondary-light rounded-2xl px-6 py-3">
            <div className="font-medium text-lg">
                {course.title}
            </div>
            <div className="px-2">
                {course.description}
            </div>
            <div className="flex flex-col gap-y-1 mt-4 mb-2">
                {course.chapters.map((chapter, index) => (
                    <Link
                        to={`/console/formations/${course.formationId}/chapter/${chapter.id}`}
                    >
                        <div className="bg-slate-50 rounded-md px-3 py-1.5">
                            <strong>{'Chapitre '+ (index+1)}:</strong> {chapter.title}
                        </div>
                    </Link>
                ))}
            </div>
            <div className="w-max !py-2 mt-4 ml-auto">
                <CustomButton
                    title="Voir le Cours dans la formation"
                    navigateTo={`/console/formations/${course.formationId}`}
                />
            </div>
        </div>
    )
};

const RapidAccess = () => {
    const user = useContext(AuthContext)?.user;
    type ConcernedCourseType = FormationCourseType & {formationId:string};
    const [concernedCourses, setConcernedCourses] = useState<ConcernedCourseType[]>([]);
    
        const getAndSetConcernedCoursesByUid = async () => {
            if(!user) return;
            const getConcernedCoursesByUid = httpsCallable(functions, 'getConcernedCoursesByUid');
            try {
                const dbConcernedCourses = (await getConcernedCoursesByUid({uid:user.id, includeChapters:true, asRole:'student'})).data as {formations:GlobalFormationType[], courses:(FormationCourseType&{formationId:string})[]};
                setConcernedCourses(dbConcernedCourses.courses)
                console.log('Concerned courses: ', concernedCourses);
            } catch (error) {
                console.log('Error while getting concerned courses by uid: ', error);
            }
        };

    useEffect(() => {
        getAndSetConcernedCoursesByUid();
    }, []);
    return (
        <div className="flex flex-1">
            <div className="max-w-6xl w-full mx-auto">
                <div className=" mb-12 md:p-6 rounded-2xl w-full flex flex-col gap-y-6">
                <h2 className="text-4xl font-medium mt-4 self-start">Acces Rapide</h2>
                    {concernedCourses.map(course =>
                        <CourseCard key={course.id} course={course} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default RapidAccess;
