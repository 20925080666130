import { useContext, useEffect, useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { MCQQuestionType, MCQsAnswerType, MatchAnswerType, PretestAnswer, PretestQuestion, PretestResult, StructuralAnswerType, StructuralQuestionType } from "../../../../global/types";
import { MCQAnswer, MCQQuestion } from "../../Activity/components/MCQRenderer";
import { AnswerCard, QuestionCard } from "../../Activity/components/StructuralRenderer";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../App";
import { notUndefined } from "../../../../utils";
import { AuthContext } from "../../../../contexts/AuthContext";
import MatchAnswer from "./MatchAnswer";


const ResultsRenderer = () => {
  const [questions, setQuestions] = useState<PretestQuestion[]>([]);
  const [userQuestions, setUserQuestions] = useState<PretestQuestion[]>([]);
  const [loading, setLoading] = useState(false);
  const {pretestId} = useParams();
  const user = useContext(AuthContext)?.user;
  const [results, setResults] = useState<PretestResult[]|null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const attendantId = searchParams.get('id');
  const resultsVisible = window.location.pathname.includes('/results/');
  const attendantResults = results?.find(item => item.attendantId===attendantId);

  
  const handleQuestionChange = (qIndex:number, question:MCQQuestionType|StructuralQuestionType) => {
    const newQuestions = [...userQuestions];
    newQuestions[qIndex] = question;
    setUserQuestions(newQuestions);
  };

  const onSubmitAnswers = async () => {
    if(loading) return;
    setLoading(true);
    try {

      const structAnswers = (userQuestions.filter(item => item.type==='sq') as StructuralQuestionType[])
        .map(question => ({ type:'sq', metaIndex: question.metaIndex, answer:question.proposedAnswer||'', earnedWeight:question.earnedWeight }));
      
        const submitPretestAnswers = httpsCallable(functions, 'submitPretestAnswers');
      await submitPretestAnswers({answers:structAnswers, attendantId, pretestId})
    } catch (error) {
      console.log("An error occured while submitting answers: ", error);
    }
    setLoading(false);
  };

  
  const getAndSetPretestQuestions = async () => {
    if(loading) return;
    setLoading(true);

    try {
      const getPretestQuestions = httpsCallable(functions, 'getPretestQuestions');
      const result = (await getPretestQuestions({ pretestId, includeCorrection:true, withResultsForAttendants:true })).data as { questions: PretestQuestion[], results:PretestResult[] };

      
      setQuestions(result.questions);
      setResults(result.results)
    } catch (error) {
      console.log('An error occured while updating pretest questions: ', error);
    };

    setLoading(false);
  };

  useEffect(() => {
    const newUserQuestions:PretestQuestion[] =questions.map(question => (
      question.type ==='mcq'?
      question
      : question.type==='sq'?
      ({
        ...question,
        earnedWeight:(attendantResults?.answers.filter(item => item.type=='sq').find(item => item.metaIndex===question.metaIndex) as StructuralAnswerType|undefined)?.earnedWeight,
        proposedAnswer:(attendantResults?.answers.filter(item => item.type=='sq').find(item => item.metaIndex===question.metaIndex) as StructuralAnswerType|undefined)?.answer
      })
      :
      ({
        ...question,
        options:question.options.map((option, optionIndex) => ({...option, selected:(attendantResults?.answers.filter(item => item.type==='match').find(item => item.metaIndex ===question.metaIndex)as MatchAnswerType|undefined)?.choices?.at(optionIndex)?.part2}))
      })
    ));

    setUserQuestions(newUserQuestions);
  }, [questions])

  useEffect(() => {
    getAndSetPretestQuestions()
  }, []);
  
  return (
    <div className="flex flex-1 flex-col">
      <div className="mt-4 mb-12 px-2 py-4 md:p-4 mx-auto rounded-2xl w-full flex flex-col max-w-7xl bg-secondary-light">
        <h3 className="text-xl lg:text-3xl font-semibold">Pré-Test d'Évaluation</h3>
        <div className="flex flex-col gap-y-6 mt-4">
          {
            userQuestions.map((question, qIndex) => question.type ==='mcq'?
              <MCQAnswer
                key={qIndex}
                question={question}
                questionIndex={qIndex}
                choices={(attendantResults?.answers.filter(item => item.type=='mcq').find(item => item.metaIndex===question.metaIndex) as MCQsAnswerType|undefined)?.choices}
              />
              : question.type==='sq'?
              <AnswerCard
                key={qIndex}
                qIndex={qIndex}
                answer={question}
                earnedWeightInputVisible={true}
                onQuestionChange={handleQuestionChange}
              />
              :
              <MatchAnswer
                key={qIndex}
                qIndex={qIndex}
                question={question}
              />
            )
          }
        </div>
        {
          attendantId?
          <div className="ml-auto mt-4 lg:mt-6">
            <CustomButton
              loading={loading}
              title={"Enregistrer les Points de Questions Ouvertes"}
              onClick={onSubmitAnswers}
            />
          </div>
          :
          null
        }
      </div>
    </div>
  )
};


export default ResultsRenderer;