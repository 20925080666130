import React from 'react';
import Select, {
    ActionMeta,
    ClassNamesConfig,
    GroupBase,
    IndicatorsContainerProps,
    OptionsOrGroups,
    ValueContainerProps
} from 'react-select';
import { MdArrowDropDown } from 'react-icons/md';



type CustomSelectPropsType = {
    name?:string
    clearable?: boolean
    disabled?: boolean
    hidden?: boolean
    placeholder?: string
    searchable?: boolean
    noOptionsMessage?: string
    label?:string
    labelClassName?:string
    value?:unknown
    isMulti?:boolean
    containerClassName?:string;
    options?:OptionsOrGroups<unknown, GroupBase<unknown>>
    inputCustomClassNames?: ClassNamesConfig<{
        value: string;
        label: string;
    }, false, GroupBase<{
        value: string;
        label: string;
    }>>
    onChange?: ((newValue: unknown, actionMeta: ActionMeta<unknown>) => void)
};

export const CustomSelect: React.FC<CustomSelectPropsType> = ({
    clearable = false,
    disabled = false,
    hidden = true,
    name,
    placeholder,
    searchable = false,
    noOptionsMessage,
    inputCustomClassNames,
    options,
    isMulti=false,
    label,
    value,
    onChange,
    ...props
}) => {
    
    const customClassNames: ClassNamesConfig<unknown, false, GroupBase<unknown>>
     = {
        container: () => '!m-0',
        control: () => '!rounded-lg py-2 px-1 dark:!bg-gray-700 dark:!border-gray-600 dark:!placeholder-gray-400 ' +
            (disabled ? '!bg-primary-light !bg-opacity-20 !border-opacity-60 !border-primary-light' : '!bg-gray-50 border !border-gray-300'),
        indicatorsContainer: () => '!border-r-0 !text-black dark:!text-slate-50',
        dropdownIndicator: () => '!text-gray-700 dark:!text-slate-50',
        valueContainer: () => '!text-sm !text-gray-900',
        placeholder:() => '!text-gray-600 !mx-1.5 !text-sm dark:!text-slate-400',
        menu:() => 'dark:!bg-gray-700 dark:!text-gray-300',
        singleValue:() => 'dark:!text-slate-50',
        multiValue:() => 'dark:!text-slate-50',
    }

    const CustomValueContainer: React.FC<ValueContainerProps> = ({
        children,
        innerProps,
    }) => (
        <div
            className={"flex flex-1 mr-1 px-1 flex-wrap items-center rounded-md cursor-default hover:border-gray-400"+
            (disabled ? '!bg-primary-light !bg-opacity-20 ' : '!bg-gray-50')
        }
            {...innerProps}
        >
            {children}
        </div>
    );
    
    const CustomIndicatorsContainer: React.FC<IndicatorsContainerProps> = ({
        innerProps,
    }) => (
        <div className="top-0 bottom-0 right-0 flex items-center pointer-events-none">
            <MdArrowDropDown className="w-6 h-6 text-gray-400 pointer-events-auto" />
        </div>
    );
    
    return (
        <div className={'min-w-[4rem] '+props.containerClassName}>
            {label? <label className={props.labelClassName}>{label}</label>:null}
            <Select
                components={{
                    ValueContainer: CustomValueContainer,
                    IndicatorsContainer: CustomIndicatorsContainer,
                }}
                isDisabled={disabled}
                isClearable={clearable}
                isSearchable={searchable}
                classNames={customClassNames}
                options={options?options:[]}
                placeholder={placeholder}
                noOptionsMessage={() => noOptionsMessage}
                name={name}
                // @ts-ignore
                isMulti={isMulti}
                onChange={onChange}
                value={value}
            />
        </div>
    );
};

export default CustomSelect;
