import { useContext, useState } from 'react';
import { httpsCallable } from "firebase/functions";
import { accountOptions } from "../assets/languages";
import CustomButton from "./CustomButton";
import CustomSelect from "./CustomSelect";
import MenuCard, { MenuItem } from "./MenuCard";
import { UserType, otherAccountData } from "../global/types";
import { auth, functions } from "../App";
import { signOut } from 'firebase/auth';
import { AuthContext } from '../contexts/AuthContext';
import CustomDialog from './CustomDialog';


type UserPropsType = {
  user: UserType | undefined
  currentUser: UserType | null | undefined
  otherAccountData: otherAccountData,
  setOtherAccountData: React.Dispatch<React.SetStateAction<otherAccountData>>
};

const User: React.FC<UserPropsType> = ({ user, currentUser, otherAccountData, setOtherAccountData }) => {
  const [loading, setLoading] = useState({ passwordReset: false });
  const setUser = useContext(AuthContext)?.setUser;
  const [dialogOpen, setDialogOpen] = useState(false);

  const onPasswordReset = async () => {
    if (loading.passwordReset) return;
    setLoading({ passwordReset: true });
    try {
      if (!user?.id) return;
      const resetUserPassword = httpsCallable(functions, 'resetUserPassword');
      const result = (await resetUserPassword({ uid: user.id })).data;
      console.log('Account resetting result: ', result);
      setDialogOpen(true)
    } catch (error) {
      console.log('Error while resetting user password: ', error);
    }
    setLoading({ passwordReset: false })
  };

  const onSignout = async () => {
    await signOut(auth);
    if (setUser) setUser(null);
  }
  return (
    <MenuCard
      title="Utilisateur"
    >
      <MenuItem
        title="Langue preferee"
        suffix={(
          <div className="ml-auto w-28">
            <CustomSelect
              onChange={(newValue: any) => setOtherAccountData(data => {
                return { ...data, accountOptions: { ...data.accountOptions, preferredLang: newValue.value } }
              })}
              value={accountOptions.preferredLang.find(option => option.value === otherAccountData.accountOptions.state)}
              options={accountOptions.preferredLang}
            />
          </div>
        )}
      />
      {
        (user?.id === currentUser?.id) || ['admin', 'super'].some((item: any) => currentUser?.accountType.includes(item)) ?
          <CustomButton
            loading={loading.passwordReset}
            onClick={onPasswordReset}
            title="Réinitialiser le mot de passe"
            className="mt-4 mb-2"
            variant="btnDanger"
          />
          :
          null
      }
      {
        user?.id === currentUser?.id ?
          <CustomButton
            onClick={onSignout}
            title="Se deconnecter"
            variant="btnSecondary"
            className=""
          />
          :
          null
      }
      <CustomDialog
        title='Réinitialisation du mot de passe'
        message='Réinitialisation du mot de passe enclenchée. Veuillez vérifier / demander à vérifier la boite mail.'
        open={dialogOpen}
        setOpen={setDialogOpen}
        confirmtButtonTitle='Ok'
      />
    </MenuCard>
  )
};

export default User;