import { Button, LinearProgress } from '@mui/material';
import { DataGrid, GridClasses, GridColDef, GridNoRowsOverlay, GridPagination, GridRowParams, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import 'moment/locale/fr';
import { FormationCourseType, GlobalFormationType } from '../../../../../global/types';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../../App';
import { useEffect, useState } from 'react';
import { notUndefined } from '../../../../../utils';
import CustomSelect from '../../../../../components/CustomSelect';
import moment from 'moment';


type MarksGridCols = {
  id:string;
  courseId: string;
  formationId: string;
  course: string //course name
  teacher: string; //teacher name
  obtainedMarks: number;
  maxMarks: number;
  submittedOn: string;
};

type updateMarksType = {
  courseId:string;
  marks:number;
  maxMarks:number;
  updatedAt:string;
}[];



type CustomFooterProps = {
  updateCourses:string[];
  onSavePress:() => void;
};

const CustomFooter = ({onSavePress, updateCourses}:CustomFooterProps) => {
  return (
    <div className='p-2 flex justify-between'>
      {
        updateCourses.length?
        <Button
          color='inherit'
          disableElevation
          variant='contained'
          onClick={onSavePress}
        >
          Sauvegarder
        </Button>
        :
        null
      }
      <GridPagination className='ml-auto'/>
    </div>
  )
};


const CustomToolbar = () => {
  return (
    <GridToolbarContainer className='bg-secondary-light' sx={{ paddingX: 2 }}>
      <GridToolbarExport sx={{ paddingY: 2, color: '#7F5032' }} />
    </GridToolbarContainer>
  );
};

type MarksGridPropsType = {
  studentId: string;
  editable:boolean;
};

const MarksGrid: React.FC<MarksGridPropsType> = ({ editable, studentId }) => {
  const navigate = useNavigate();
  const [markedCourses, setMarkedCourses] = useState<FormationCourseType[]>([]);
  const [formations, setFormations] = useState<GlobalFormationType[]>([]);
  const [focusedFormation, setFocusedFormation] = useState<string|null>(null);
  const [loading, setLoading] = useState(false);
  const [updateCourses, setUpdateCourses] = useState<string[]> ([]);
  const [updatingMarks, setUpdatingMarks] = useState(false);

  const columns: GridColDef<MarksGridCols>[] = [
    // { field: 'id', headerName: 'ID', minWidth: 50, flex:1 },
    // { field: 'title', headerName: "Titre de l'activite", minWidth: 360, flex: 2 },
    { field: 'course', headerName: 'Cours', minWidth: 240, flex: 2 },
    { field: 'teacher', headerName: 'Formateur', minWidth: 75, flex: 2 },
    { field: 'obtainedMarks', headerName: 'Points Obtenus', minWidth: 50, flex: 1, type:'number', editable:editable },
    { field: 'maxMarks', headerName: 'Maximum', minWidth: 50, flex: 1, type:'number', editable:editable },
    // { field: 'status', headerName: 'Statut', minWidth: 50, flex:1 },
    // { field: 'assignedOn', headerName: 'Date Assignee', minWidth: 50, flex:1 },
    { field: 'submittedOn', headerName: "Date d'assignation", minWidth: 100, flex: 1 },
  ];

  const gridClassNames: Partial<GridClasses> = {
    footerContainer: 'bg-secondary-light'
  };

  const getAndSetConcernedCoursesByUid = async () => {
    if (loading) return;
    setLoading(true);
    const getConcernedCoursesByUid = httpsCallable(functions, 'getConcernedCoursesByUid');
    try {
      const dbConcernedCourses = (await getConcernedCoursesByUid({ asRole:'student', uid: studentId, includeMarks: true })).data as { formations: GlobalFormationType[], courses: (FormationCourseType & { formationId: string })[] };
      setMarkedCourses(dbConcernedCourses.courses);
      setFormations(dbConcernedCourses.formations);
    } catch (error) {
      console.log('Error while getting concerned courses by uid: ', error);
    }
    setLoading(false);
  };

  const onUpdateStudentMarks = async () => {
    if(updatingMarks|| updateCourses.length==0 || !focusedFormation) return;
    setUpdatingMarks(true);

    try {
      // @ts-ignore
      const updateMarks:Omit<updateMarksType, 'updatedAt'> = updateCourses.map(courseId => 
        markedCourses.find(item => item.id ===courseId)
      ).filter(notUndefined)
      .map(updatedCourse => 
        ({
          courseId:updatedCourse.id,
          marks:updatedCourse.marks?.marks||0,
          maxMarks:updatedCourse.marks?.maxMarks||0,
        }));
      
      const updateStudentMarks = httpsCallable(functions, 'updateStudentMarks');
      const dbUpdated  = (await updateStudentMarks({
        studentId,
        courses:updateMarks,
        formationId:focusedFormation
      })).data as updateMarksType&{updatedAt:string};
      
      setMarkedCourses(value => 
        [...value.map(item => dbUpdated.some(updated => updated.courseId===item.id)?
          {...item, marks:dbUpdated.filter(updated => updated.courseId === item.id)[0]}
          :
          item
        )]
      );
      setUpdateCourses([]);
    } catch (error) {
      console.log('An error occured while updating student marks: ', error);
    };

    setUpdatingMarks(false);
  };

  const rows: MarksGridCols[] = markedCourses.map(course => course.formationId ? course : undefined)
    .filter(notUndefined)
    .filter(course => course.formationId ===focusedFormation)
    .map((course) => ({
      id: course.id, // mandatory property by mui
      course: course.title,
      courseId: course.id,
      formationId: course.formationId as string,
      maxMarks: course.marks?.maxMarks||0,
      obtainedMarks: course.marks?.marks||0,
      teacher: (course.teachers.at(0)?.name||'')+' ' +(course.teachers.at(0)?.surname||'---'),
      submittedOn: moment(course.marks?.updatedAt).format('D MMM YYYY [:] HH[h] mm [mins]'),
    }
  ));

  const onRowClick = ({ row }: GridRowParams<MarksGridCols>) => {
    console.log('Clicked on row ! Row: ', row);
  };

  const processRowUpdate = (newRow:MarksGridCols, oldRow:MarksGridCols) => {
    const newCourses = [...markedCourses]
    const courseToUpdate = newCourses.find(item => item.id === newRow.id);
    const obtainedMarksCond = !isNaN(newRow.obtainedMarks) && newRow.obtainedMarks != oldRow.obtainedMarks;
    const maxMarksCond = !isNaN(newRow.maxMarks) && newRow.maxMarks != oldRow.maxMarks
    
    if(!courseToUpdate||(!obtainedMarksCond && !maxMarksCond)) return oldRow;

    if(obtainedMarksCond) {
      const marks = Math.min(Number(newRow.obtainedMarks), Number(courseToUpdate.marks?.maxMarks)||0);
      courseToUpdate.marks={
        marks: marks,
        maxMarks:Number(courseToUpdate.marks?.maxMarks)||0,
        updatedAt:courseToUpdate.marks?.updatedAt||new Date().toISOString()
      };
    };

    if(maxMarksCond) {
      const maxMarks = Math.min(Number(newRow.maxMarks), Number(courseToUpdate.marks?.marks)||0);
      courseToUpdate.marks={
        marks:maxMarks,
        maxMarks:Number(newRow.maxMarks),
        updatedAt:courseToUpdate.marks?.updatedAt||new Date().toISOString()
      };
    }
    
    setMarkedCourses(newCourses);
    setUpdateCourses(value => [...value, newRow.courseId]);
    return newRow;
  }

  useEffect(() => {
    getAndSetConcernedCoursesByUid();
  }, []);
  return (
    <div className='flex flex-1 flex-col'>
      <div className='font-medium text-3xl my-4'>Grille de Notes</div>
      <CustomSelect
          containerClassName='col-span-2 max-w-xl my-2'
          label={"Choisir la formation"}
          onChange={(newValue: any) => setFocusedFormation(newValue.value)}
          labelClassName='mb-0'
          value={!focusedFormation?undefined:{label:formations.find(item => item.id ===focusedFormation)?.title, value:focusedFormation}}
          options={formations.map(formation => ({ label: formation.title, value: formation.id }))}
          placeholder='Aucune (Spécifier une formation...)'
        />
      <div className='min-h-[80vh] max-w-[96vw] mb-8'>
        <DataGrid
          slots={{
              toolbar: CustomToolbar,
              loadingOverlay: LinearProgress,
              noRowsOverlay: GridNoRowsOverlay,
              footer:() => <CustomFooter onSavePress={onUpdateStudentMarks} updateCourses={updateCourses}/>
            }}
          classes={gridClassNames}
          autoPageSize
          rows={rows}
          processRowUpdate={processRowUpdate}
          loading={loading||updatingMarks}
          columns={columns}
          pageSizeOptions={[10]}
          onRowDoubleClick={onRowClick}
        />
      </div>
    </div>
  )
};

export default MarksGrid;