import Box from '@mui/material/Box';
import { DataGrid, GridCellEditStopParams, GridColDef, GridRowSelectionModel, GridToolbarContainer } from '@mui/x-data-grid';
import { Pretest, PretestAttendant, PretestResult } from '../../../../../global/types';
import { useEffect, useState } from 'react';
import { httpsCallable } from '@firebase/functions';
import { functions } from '../../../../../App';
import { Button, Menu, MenuItem } from '@mui/material';
import { BsClipboard, BsClipboard2, BsThreeDotsVertical } from 'react-icons/bs';
import CustomButton from '../../../../../components/CustomButton';


type CustomFooterProps = {
  setAttendants:React.Dispatch<React.SetStateAction<PretestAttendant[]>>;
  selectedRows:GridRowSelectionModel;
  onSavePress:() => void;
};

const CustomFooter = ({onSavePress, selectedRows, setAttendants}:CustomFooterProps) => {
  const onAddAttendant = () => {
    const initAttendant:PretestAttendant = {
      email: '',
      id: Date.now().toString(),
      name: '',
      flag:'new',
      surname: '',
      disabled:false
    };

    setAttendants(value => [...value, initAttendant]);
  };
  return (
    <div className='p-2 flex justify-between'>
      <div className='flex gap-x-2'>
        <Button
          color='inherit'
          variant='outlined'
          onClick={onAddAttendant}
        >
          Ajouter un Participant
        </Button>
        {
          selectedRows.length ?
            <Button
              color='inherit'
              variant='outlined'
            >
              Supprimer la sélection
            </Button>
            : null
        }
      </div>
      <Button
        className='self-end'
        color='inherit'
        disableElevation
        variant='contained'
        onClick={onSavePress}
      >
        Sauvegarder
      </Button>
    </div>
  )
};


type AttendantsProps = {
  attendants: PretestAttendant[];
  pretestId: string;
  shortResults:{attendantId:string, score:number|undefined}[];
  onAttendantResultsViewPress:(id:string) => void;
};

const Attendants = ({ pretestId, shortResults, ...props }: AttendantsProps) => {
  const [attendants, setAttendants] = useState<PretestAttendant[]>(props.attendants);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [loading, setLoading] = useState(false);


  const columns: GridColDef<PretestAttendant>[] = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Nom',
      width: 180,
      editable: true,
      flex:1,
      minWidth:160
    },
    {
      field: 'surname',
      headerName: 'Prénom',
      width: 210,
      editable: true,
      flex:1,
      minWidth:160
    },
    {
      field: 'email',
      headerName: 'Addresse email',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 270,
      editable: true,
      flex:1,
      minWidth:160
    },
    {
      field: 'earnedCredits',
      headerName: 'Résultat',
      description: "Résultat (si disponible) du participant à l'examen du pré-test",
      sortable: false,
      minWidth: 200,
      renderCell:({row}) => row.earnedCredits?
        <div className='flex items-center gap-x-2'>
          <span className='font-bold'>{row.earnedCredits}/{'-'}</span>
          <Button onClick={() => props.onAttendantResultsViewPress(row.id)} color='inherit'>
            Voir les Réponses
          </Button>
        </div>
        :
        null
    },
    {
      field:'copy',
      headerName:'',
      width:80,
      align:'center',
      renderCell:({row}) => row.flag==='new'?null: (
        <Button
          title='Copier le lien de participation'
          onClick={() => navigator.clipboard.writeText(`${window.location.href}/${row.id}`||'')}
        >
          <BsClipboard size={24} />
      </Button>
      )
    }
  ];
  
  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection);
  };


  const getAndSetAttendants = async () => {
    if(loading) return;
    setLoading(true);
    try {
      const getPretestInfos = httpsCallable(functions, 'getPretestInfos');
      const dbPretestData = (await getPretestInfos({ pretestId, listAttendants: true })).data as Pretest & { attendants: PretestAttendant[] };
      setAttendants(dbPretestData.attendants.filter(item => !item.disabled));
    } catch (error) {
      console.log('Error while getting pretest data: ', error);
    };

    setLoading(false);
  };

  
  const processRowUpdate = (newRow:PretestAttendant, oldRow:PretestAttendant):PretestAttendant => {
    const newAttendants = attendants.map(item => item.id===newRow.id?newRow:item);
    setAttendants(newAttendants);

    return newRow;
  };

  const onSavePress = async () => {
    if(loading) return;
    setLoading(true);
    try {
      const updatePretestAttendants = httpsCallable(functions, 'updatePretestAttendants');
      const result = await updatePretestAttendants({attendants, pretestId}) as {data:{attendants:PretestAttendant[]}};
      const newAttendants = result.data.attendants;
      setAttendants(newAttendants.filter(item => !item.disabled));
    } catch (error) {
      console.log('An error occured while updating pretest attendants: ', error)
    }
    setLoading(false);
  };

  useEffect(() => {
    getAndSetAttendants();
  }, []);
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={attendants.map(attendant => ({...attendant, earnedCredits:shortResults.find(item => item.attendantId===attendant.id)?.score}))}
        columns={columns}
        editMode='cell'
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            // paginationModel: {
            //   pageSize: 5,
            // },
          },
        }}
        slots={{
          footer: () => <CustomFooter onSavePress={onSavePress} selectedRows={selectedRows} setAttendants={setAttendants}/>
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectedRows}
      />
    </Box>
  );
}


export default Attendants;