import "../../../../assets/react-quill-snow.css";
import React, { useEffect, useState } from "react";
import { StructuralQuestionType, UserType } from "../../../../global/types";
import CustomButton from "../../../../components/CustomButton";
import { useParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../App";

type QuestionCardPropsType = {
  question: StructuralQuestionType;
  qIndex: number;
  handleQuestionChange: (qIndex: number, question: StructuralQuestionType) => void;
};

export const QuestionCard: React.FC<QuestionCardPropsType> = ({ question, handleQuestionChange, qIndex }) => {
  return (
    <div className="bg-slate-50 dark:bg-slate-200 rounded-lg p-2 lg:p-4 mb-4 w-full">
      <div className="mt-1 pb-2">Question ({question.weight} crédits):</div>
      <div
        className="prose prose-sm font-medium bg-slate-100 max-w-full px-3 py-1 rounded-lg mb-1"
        dangerouslySetInnerHTML={{ __html: question.question }}
      />
      <div className="prose-sm mt-1">Votre reponse:</div>
      <textarea
        value={question.proposedAnswer || ''}
        onChange={(e) => handleQuestionChange(qIndex, { ...question, proposedAnswer: e.target.value })}
        className="customInput"
      />
    </div>
  );
};


export type StructuralQuestionsRendererPropsType = {
  questions: StructuralQuestionType[];
  setQuestions: React.Dispatch<React.SetStateAction<StructuralQuestionType[]>>;
};

const StructuralQuestionsRenderer: React.FC<StructuralQuestionsRendererPropsType> = ({ questions, setQuestions }) => {
  const handleQuestionChange = (qIndex:number, question:StructuralQuestionType) => {
    const newQuestions = [...questions];
    newQuestions[qIndex] = question;
    setQuestions(newQuestions);
  };

  return (
    <div>
      {questions.map((question, qIndex) => (
        <QuestionCard
          key={qIndex}
          handleQuestionChange={handleQuestionChange}
          question={question}
          qIndex={qIndex}
        />
      ))}
    </div>
  )
};


type AnswerCardPropsType = {
  answer: StructuralQuestionType;
  qIndex:number;
  onQuestionChange?:(qIndex:number, question:StructuralQuestionType) => void;
  earnedWeightInputVisible?:boolean
};

export const AnswerCard: React.FC<AnswerCardPropsType> = ({ qIndex, answer, onQuestionChange, ...props }) => {
  return (
    <div className="bg-white rounded-lg px-6 py-2 mb-4 w-full">
      <div className="flex text-gray-700 font-medium mt-1 mb-2">
        Question {qIndex+1}: ({answer.weight} crédits)
        {
          props.earnedWeightInputVisible?
          <input
            type="number"
            className="customInput !ml-auto !w-40 !py-2"
            placeholder="Entrez une note Ici"
            min={0}
            value={answer.earnedWeight}
            max={answer.weight}
            onChange={(e) => {
              if(onQuestionChange && (answer.weight>=parseInt(e.target.value))) {
                onQuestionChange(qIndex, {...answer, earnedWeight:parseInt(e.target.value)})}
            }}
          />
          :
          null
        }
      </div>
      <div
        className="prose prose-sm font-medium bg-slate-100 max-w-full px-3 py-1 rounded-lg mb-1"
        dangerouslySetInnerHTML={{ __html: answer.question }}
      />
      <div className="prose-sm mt-1">Votre reponse:</div>
      <div
        className="prose prose-sm font-medium bg-slate-50 max-w-full px-3 py-1 rounded-lg mb-1"
      >{answer.proposedAnswer}
      </div>
    </div>
  );
}


const StructuralAnswersRenderer: React.FC<{ answers: StructuralQuestionType[] }> = ({ answers }) => {
  return (
    <>
      {answers.map((answer, qIndex) => (
        <AnswerCard
          key={answer.pseudoIndex}
          answer={answer}
          qIndex={qIndex}
        />
      ))}
    </>
  )
};


type StructuralRendererPropsType = {
  questions: StructuralQuestionType[],
  user: UserType
  hasUserDoneTask: boolean | undefined
};

const StructuralRenderer: React.FC<StructuralRendererPropsType> = ({ questions, user, hasUserDoneTask }) => {
  const { activityId } = useParams();
  const [answers, setAnswers] = useState(questions);
  const [displayCorrection, setDisplayCorrection] = useState(hasUserDoneTask);
  const [submitEnabled, setSubmitEnabled] = useState(['student'].some((item: any) => user.accountType.includes(item)) && !hasUserDoneTask);
  const [loading, setLoading] = useState(false);

  const submitStructuralAnswers = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const submitStructuralAnswers = httpsCallable(functions, 'submitStructuralAnswers');
      const response = (await submitStructuralAnswers({ taskId: activityId, answers })).data;
      setDisplayCorrection(true);
      setSubmitEnabled(false);
    } catch (error) {
      console.log('Error while submitting structural answers: ', error);
    }
    setLoading(false);
  };

  const getAnsSetUserStructuralAnswers = async () => {
    const getStructuralAnswersByUid = httpsCallable(functions, 'getStructuralAnswersByUid');
    const structuralData = (await getStructuralAnswersByUid({ taskId: activityId, uid: user?.id })).data as unknown as StructuralQuestionType[];
    setAnswers(structuralData)

  };

  useEffect(() => {
    getAnsSetUserStructuralAnswers()
  }, []);
  return (
    <div className="flex flex-col items-center pt-4">
      {
        displayCorrection ?
          <StructuralAnswersRenderer
            answers={questions}
          />
          :
          <StructuralQuestionsRenderer
            questions={answers}
            setQuestions={setAnswers}
          />
      }
      {/* For a student, submitEnabled is true only if he has done task
                for any other account type, it is always false
            */}
      {
        submitEnabled ?
          <CustomButton
            loading={loading}
            onClick={submitStructuralAnswers}
            className="w-max mx-auto mt-4"
            title={"Soumettre les reponses"}
          />
          :
          <CustomButton
            loading={loading}
            onClick={() => setDisplayCorrection(!displayCorrection)}
            className="w-max mx-auto mt-4"
            title={displayCorrection ? "Voir les questions" : "Voir les reponses"}
          />
      }
    </div>
  );
}

export default StructuralRenderer;