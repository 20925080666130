import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import 'moment/locale/fr'; // Import the French locale
import "../assets/react-datetime.css";
import moment, { Moment } from "moment";

type CustomDatePickerPropsType = {
    value?:Date|string
    onChange?:(value:Date) => void
    initialValue?:Date
};

const CustomDatePicker:React.FC<CustomDatePickerPropsType> = ({initialValue, onChange, value}) => {
  return (
    <Datetime
        initialValue={initialValue}
        value={moment(value).toDate()}
        onChange={(pickedDate) => {if(onChange) onChange(moment(pickedDate).toDate())}}
        dateFormat='DD MMMM YYYY'
        locale="fr"
        // timeFormat='HH : MM'
        inputProps={{className:"textInput"}}
    />
  );
};

export default CustomDatePicker;
