import ProfileViewForm from "../../../../../components/ProfileViewForm";
import { UserType, otherAccountData } from "../../../../../global/types";

type SuperFormPropsType = {
    user?:UserType
    otherAccountData:otherAccountData
};

const SuperForm:React.FC<SuperFormPropsType> = ({user, otherAccountData}) => {
    return (
        <ProfileViewForm
            extraFields={{}}
            accountType={["super"]}
            user={user}
            otherAccountData={otherAccountData}
        />
    );
};


export default SuperForm;