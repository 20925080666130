import { httpsCallable } from "firebase/functions";
import FormationStruct from "../../../components/FormationStruct"
import { GlobalFormationType } from "../../../global/types";
import { functions } from "../../../App";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { sortByIndexes } from "../../../utils/formationTreeHandlers";

const FormationManage = () => {
    return (
        <FormationStruct
        />
    )
};

export default FormationManage;