const formation = {
    en:{
        activitiesType:{
            structural:'Structural Questions',
            videoFile:'Video',
            teleMeeting:'Meeting',
            documentFile:'Document file',
            MCQ:'Multiple choice questions',
            documentUrl:'Link to external resources',
            youtubeVideo:'Youtube Video',
            submission:'Submission'
        }
    },
    fr:{
        activitiesType:{
            structural:'Question Ouvertes',
            videoFile:'Vidéo',
            teleMeeting:'Réunion',
            documentFile:'Fichier document',
            MCQ:'Questions a choix multiples',
            documentUrl:'Lien vers des resources externes',
            youtubeVideo:'Video Youtube',
            submission:'Submission'
        }
    }
};

export {formation};