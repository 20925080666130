import React from "react";
import MenuCard, { MenuItem } from "../../../../../components/MenuCard";
import { studentBoard } from "..";

type StudentLogsPropsType = {
  visibleBoard: studentBoard;
  setVisibleBoard: (value: studentBoard) => void;
};

const StudentLogs: React.FC<StudentLogsPropsType> = ({ visibleBoard, setVisibleBoard }) => {
  return (
    <MenuCard
      title="Journal"
    >
      <MenuItem
        title="General"
        focused={visibleBoard === "general"}
        onNodeClick={() => setVisibleBoard('general')}
      />
      {/* <MenuItem
            title="Historique des activites"
            focused={visibleBoard==="history"}
            onNodeClick={() => setVisibleBoard('history')}
        />
      */}
      <MenuItem
        title="Commentaires sur l'apprenant"
        focused={visibleBoard === "comments"}
        onNodeClick={() => setVisibleBoard('comments')}
      />
      <MenuItem
        title="Notes aux Cours"
        focused={visibleBoard === "marks"}
        onNodeClick={() => setVisibleBoard('marks')}
      />
      <MenuItem
          onNodeClick={() => setVisibleBoard('journey')}
          focused={visibleBoard==="journey"}
          title="Parcours de l'apprenant"
      />
      <MenuItem
        title="Tâches de l'Apprenant"
        focused={visibleBoard === "tasks"}
        onNodeClick={() => setVisibleBoard('tasks')}
      />
    </MenuCard>
  )
};

export default StudentLogs;