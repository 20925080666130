import { useEffect, useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { MCQsAnswerType, MatchAnswerType, Pretest, PretestAnswer, PretestAttendant, PretestQuestion, PretestResult, StructuralAnswerType } from "../../../../global/types";
import { MCQQuestion } from "../../Activity/components/MCQRenderer";
import { QuestionCard } from "../../Activity/components/StructuralRenderer";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../App";
import { notUndefined } from "../../../../utils";
import { usePretestCoundown } from "../Pretest";
import MatchQuestion from "./MatchQuestion";
import { Button } from "@mui/material";


const QuestionsRenderer = () => {
  const [questions, setQuestions] = useState<PretestQuestion[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false)
  const {attendantId, pretestId} = useParams();
  const [attendant, setAttendant] = useState<PretestAttendant|null>(null);
  const [pretestData, setPretestData] = useState<Pretest|null>(null);
  const {countDown, sessionEndDate, setCountdownConfig} = usePretestCoundown({})
  const [autoSaving, setAutoSaving] = useState(false);
  const navigate = useNavigate();

  const handleQuestionChange = (qIndex:number, question:PretestQuestion) => {
    const newQuestions = [...questions];
    newQuestions[qIndex] = question;
    setQuestions(newQuestions);
  };

  const onSubmitAnswers = async () => {
    if(loading||saving) return;
    setSaving(true)
    try {
      const answers:PretestAnswer[] = questions.map(question => 
        question.type==='mcq'?
        ({
          type:'mcq', metaIndex: question.metaIndex,
          choices: question.answers.map((answer, index) => answer.selected ? index : undefined).filter(notUndefined)
        })
        : question.type=='sq'?
        ({ type:'sq', metaIndex: question.metaIndex, answer:question.proposedAnswer||''})
        :
        ({
          type:'match', metaIndex:question.metaIndex,
          choices:question.options.map(option => ({part1:option.metaIndex, part2:option.selected!=undefined?option.selected:-1}))
        })
      );

      const submitPretestAnswers = httpsCallable(functions, 'submitPretestAnswers');
      await submitPretestAnswers({answers:answers, attendantId, pretestId})
    } catch (error) {
      console.log("An error occured while submitting answers: ", error);
    }
    setSaving(false);
  };

  const getAndSetPretestQuestions = async () => {
    if(loading) return;
    setLoading(true);

    try {
      const getPretestQuestions = httpsCallable(functions, 'getPretestQuestions');
      const getPretestInfos = httpsCallable(functions, 'getPretestInfos');
      
      const result = (await getPretestQuestions({ pretestId, withResultsForAttendants:[attendantId] })).data as { questions: PretestQuestion[], results?:PretestResult[]};
      
      const dbPretestData = (await getPretestInfos({ pretestId })).data as Pretest & { attendants: PretestAttendant[] };
      setPretestData({ ...dbPretestData, startDate: new Date(dbPretestData.startDate), endDate: new Date(dbPretestData.endDate) });
      setCountdownConfig(value => ({...value, duration:dbPretestData.duration}));
      const answers = result.results?.at(0)?.answers;
      const questionsWithAnswers:PretestQuestion[] = result.questions.map(question => question.type ==='mcq'? 
      ({
        ...question,
        answers:question.answers.map((answer, answerIndex) => ({
          ...answer, selected:(answers?.find(item => item.metaIndex ===question.metaIndex) as MCQsAnswerType|undefined)?.choices.includes(answerIndex)}
        ))
      })
      : question.type == 'sq'?
      ({...question, proposedAnswer:(answers?.find(item => item.metaIndex ===question.metaIndex) as StructuralAnswerType|undefined)?.answer})
      :
      ({...question, options:question.options.map((option, optionIndex) => ({...option, selected:(answers?.find(item => item.metaIndex ===question.metaIndex) as MatchAnswerType|undefined)?.choices?.at(optionIndex)?.part2}))})
      )
      console.log('Questions: ', questionsWithAnswers);
      setQuestions(questionsWithAnswers);
    } catch (error) {
      console.log('An error occured while updating pretest questions: ', error);
    };

    setLoading(false);
  };

  const getAndSetAttendant = async () => {
    if(!attendantId || !pretestId) return;
    try {
      const getPretestAttendantById = httpsCallable(functions, 'getPretestAttendantById');
      const result = (await getPretestAttendantById({attendantId, pretestId})).data as {attendant:PretestAttendant};
      setAttendant(result.attendant);
      setCountdownConfig(value => ({...value, startAttendance:result.attendant.startAttendance||new Date().toISOString()}));
    } catch (error) {
      console.log('An error occured while getting the pretest attendant: ', error);
    }
  };

  
  useEffect(() => {
    if(attendant) getAndSetPretestQuestions();
  }, [attendant]);

  useEffect(() => {
    getAndSetAttendant();
    const autoSaveIntervalId = setInterval(async() => {
      setAutoSaving(true);
      try {
        await onSubmitAnswers();
      } catch (error) {
        console.log('Error while auto saving: ', error);
      }
      if(sessionEndDate.isBefore(new Date())) clearInterval(autoSaveIntervalId);
      setAutoSaving(false);
    }, 300000);
    
  }, []);
  
  if(sessionEndDate.isBefore(new Date())||!pretestData||(!attendant&&loading)){
    return (
      <div className="mt-4 mb-12 px-2 py-4 md:p-4 mx-auto rounded-2xl w-full flex flex-col max-w-7xl bg-secondary-light">
        <div className="py-12 mx-12 flex items-center justify-center flex-col h-[68vh]">
          <h1 className="text-2xl lg:text-3xl xl:text-4xl my-8 font-bold text-center">
            {
              attendant&&pretestData&&!loading?
              `Cette session de prétest est terminée.
              Pour toute autre préoccupation, vous pouvez contacter l'administrateur.`
              :
              !attendant && !loading?
              'Ce participant ne correspond à aucun connu de la plateforme. Vérifiez le lien de participation et rééssayez encore'
              :loading?
              'Chargement...'
              :
              ''
            }
          </h1>
          <CustomButton
            className="mx-auto mt-6"
            title="Retourner à la page d'Acceuil"
            onClick={() => navigate(`/pre-tests/${pretestId}/${attendantId}`, {replace:true})}
          />
        </div>
      </div>
    )
  };
  
  return (
    <div className="flex flex-1 flex-col">
      <div className="bg-primary sticky top-0 py-2 px-3 lg:px-6 lg:flex lg:flex-1 lg:justify-between text-slate-200">
        <h3 className="text-base font-medium lg:text-xl xl:text-2xl">Restant: {countDown.hours} heure(s) {countDown.minutes} minute(s) {countDown.seconds} seconde(s)</h3>
        <div className="flex flex-1 justify-end">
          <Button onClick={onSubmitAnswers} size="small" variant="outlined" color="secondary">
            <span className="lowercase text-white">
              {(saving||autoSaving) ?
                <div
                  className="inline-block h-4 w-4 mx-1  animate-spin rounded-full border-2 border-[#ffffff84] border-r-white align-[-0.25em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                /> : null
              } {(saving||autoSaving) ? 'Sauvegarde...' : 'Sauvegarder'}
            </span>
          </Button>
        </div>
      </div>
      <div className="mt-4 mb-12 px-2 py-4 md:p-4 mx-auto rounded-2xl w-full flex flex-col max-w-7xl bg-secondary-light">
        <h3 className="text-xl lg:text-3xl font-semibold">Pré-Test d'Évaluation</h3>
        <div className="flex flex-col gap-y-6 mt-4">
          {
            questions.map((question, qIndex) => question.type ==='mcq'?
              <MCQQuestion
                key={qIndex}
                handleQuestionChange={handleQuestionChange}
                qIndex={qIndex}
                question={question}
              />
              :
              question.type ==='sq'?
              <QuestionCard
                key={qIndex}
                handleQuestionChange={handleQuestionChange}
                qIndex={qIndex}
                question={question}
              />
              :
              <MatchQuestion
                key={qIndex}
                handleQuestionChange={handleQuestionChange}
                qIndex={qIndex}
                question={question}
              />
            )
          }
        </div>
        {
          attendantId?
          <div className="ml-auto mt-4 lg:mt-6">
            <CustomButton
              loading={saving}
              title={"Soumettre les Questions"}
              onClick={onSubmitAnswers}
            />
          </div>
          :
          null
        }
      </div>
    </div>
  )
};


export default QuestionsRenderer;